﻿angular.module('projectModule')
    .factory("LuchtdoorlatenFactory",
        ['ntaData', 'ntaValidation', 'ntaRounding', 'ntaEntityData', 'ntaSharedLogic', '$log',
function (ntaData,   ntaValidation,   ntaRounding,   ntaEntityData,   ntaSharedLogic,   $log) {
    'use strict';

    return function LuchtdoorlatenLogic(ntaDependencyValidation) {
        ///////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              ////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////

        const ldLogic = this;
        const _gebouwtypes = createGebouwTypes();


        /* Properties ****************************************************************************/
        const _infiltratieProperties = ntaData.properties['INFIL'];
        const _infiltratieUnitsProperties = ntaData.properties['INFILUNIT'];
        const _vleidingenProperties = ntaData.properties['VLEIDING'];
        const _leidingenProperties = ntaData.properties['VLEIDINGL'];
        const _zomernachtProperties = ntaData.properties['LUCHTZOMNAC'];

        ldLogic.infiltratieProperties = _infiltratieProperties;
        ldLogic.infiltratiePropertiesTop = _infiltratieProperties.filter((prop, index) => index < 4); // de eerste vier properties
        ldLogic.infiltratiePropertiesBottom = _infiltratieProperties.filter((prop, index) => index >= 4); // de overige properties
        ldLogic.infiltratieUnitsProperties = _infiltratieUnitsProperties;
        ldLogic.vleidingenPropertiesTop = _vleidingenProperties.filter((prop, index) => index < 1); // de eerste property
        ldLogic.vleidingenPropertiesBottom = _vleidingenProperties.filter((prop, index) => index >= 1); // de overige properties
        ldLogic.leidingenProperties = _leidingenProperties;
        ldLogic.zomernachtProperties = _zomernachtProperties;

        /* Data **********************************************************************************/
        const _measureId = ntaSharedLogic.getEditingMeasureId();
        const _entdataMeasure = ntaData.original.get(_measureId) || null;
        const _entdataMeasureInfil = _entdataMeasure && ntaData.original.getFirstChild(_entdataMeasure, 'MEASURE-INFIL');
        const _entdataMeasureVLeiding = _entdataMeasure && ntaData.original.getFirstChild(_entdataMeasure, 'MEASURE-VLEIDING');

        const _infiltratieData = ntaEntityData.getFirstWithEntityId('INFIL');
        const _vleidingenData = ntaEntityData.getFirstWithEntityId('VLEIDING');
        const _zomernachtData = ntaEntityData.getFirstWithEntityId('LUCHTZOMNAC');
        const _infiltratieUnitsData = ntaEntityData.getListWithEntityId('INFILUNIT');
        const _units = ntaEntityData.getListWithEntityId('UNIT');
        const _unitrzs = ntaEntityData.getListWithEntityId('UNIT-RZ');
        const _unitRekenzonesPerUnit = {}; //-- array die per unit de unitrz's bij houdt.

        ldLogic.infiltratieData = _infiltratieData;
        ldLogic.getInfiltratieUnitDatas = getInfiltratieUnitDatas;
        ldLogic.vleidingenData = _vleidingenData;
        ldLogic.zomernachtData = _zomernachtData;

        ldLogic.measureTypeId = _entdataMeasure && _entdataMeasure.PropertyDatas['MEASURE_TYPE'].Value || '';

        ldLogic.units = _units;
        ldLogic.unitrzs = _unitrzs;
        ldLogic.unitRekenzonesPerUnit = _unitRekenzonesPerUnit;
        ldLogic.leidingenByUnitRz = leidingenByUnitRz;
        ldLogic.leidingen = leidingen;

        ldLogic.isHidden = isHidden;
        ldLogic.isReadOnly = isReadOnly;

        ldLogic.validate = validate;
        ldLogic.validateDependencies = validateDependencies;

        ldLogic.startFormValidation = startFormValidation;
        ldLogic.endFormValidation = endFormValidation;

        ldLogic.saveValue = saveValue;
        ldLogic.getCodedValues = getCodedValues;

        ldLogic.getListItem = getListItem;
        ldLogic.dependencyValidator = ntaDependencyValidation;

        ldLogic.getInvoerInfiltratie = getInvoerInfiltratie;
        ldLogic.calculateAllQV10s = calculateAllQV10s;

        ldLogic.isInfiltratieParticipating = isInfiltratieParticipating;
        ldLogic.conditionInfilC = conditionInfilC;
        ldLogic.isVerticaleLeidingenParticipating = isVerticaleLeidingenParticipating;
        ldLogic.verticaleLeidingenIsHidden = verticaleLeidingenIsHidden;
        ldLogic.isZomernachtParticipating = isZomernachtParticipating;
        ldLogic.zomernachtIsHidden = zomernachtIsHidden;


        ///////////////////////////////////////////////////////////////////////////////////////////
        ///         INITIALIZATION         ////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////
        setUnitRzByUnits();
        if (isInfiltratieParticipating()) {
            setRelevancyInfiltratieUnits();
            calculateAllQV10s();
        }


        ///////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         ////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////

        function getInvoerInfiltratie() {
            const propdataInvoer = _infiltratieData.PropertyDatas['INFIL_INVOER'];
            let invoer = null;
            if (_entdataMeasureInfil || ntaSharedLogic.isValidatingVariantWithMeasureType('MEASURE-INFILTRATIE')) {
                // [INF-D] toon tabel I05 als volgt:
                // * indien bij INFIL02 gekozen voor 'forfaitair op basis van renovatiejaar' toon de tabel zoals deze getoond wordt bij I04 = 'geen meetwaarde voor infiltratie'
                // * indien bij INFIL02 gekozen voor 'meetwaarde voor infiltratie' EN Z23≠per gebouw en per 'appartement' 'unit' / 'voor projectwoningen' toon de tabel zoals deze getoond wordt bij I04 = 'meetwaarde voor infiltratie - per gebouw'
                // * indien bij INFIL02 gekozen voor 'meetwaarde voor infiltratie' EN Z23=per gebouw en per 'appartement' 'unit' / 'voor projectwoningen' toon de tabel zoals deze getoond wordt bij I04 = 'meetwaarde voor infiltratie - per 'appartement'/'unit'/'woning''
                const method = _infiltratieData.PropertyDatas['INFIL_VERV_METHODE'].Value; // INFIL02
                switch (method) {
                    case 'INFIL_VERV_METHODE_FORF': { // forfaitair op basis van renovatiejaar
                        invoer = 'INFIL_GMW'; // 	geen meetwaarde voor infiltratie
                        break;
                    }
                    case 'INFIL_VERV_METHODE_MEETW': { // meetwaarde infiltratie
                        const calcUnit = ntaSharedLogic.getCalcUnit();
                        invoer = {
                            'RZUNIT_GEB': 'INFIL_MWG', // meetwaarde voor infiltratie - per gebouw
                            'RZUNIT_GEBAPP': 'INFIL_MWA', // meetwaarde voor infiltratie - per appartement
                            'RZUNIT_GEBUNIT': 'INFIL_MWU', // meetwaarde voor infiltratie - per unit
                            'RZUNIT_PROJECT': 'INFIL_MWW', // meetwaarde voor infiltratie - per woning
                        }[calcUnit] || null;
                        break;
                    }
                }
                ntaEntityData.saveprop(propdataInvoer, invoer);
            } else {
                invoer = _infiltratieData.PropertyDatas['INFIL_INVOER'].Value || null;
            }
            return invoer;
        } //-- end: getInvoer ---------------------------------------------------------------------

        function leidingen() {
            return ntaEntityData.getListWithEntityId('VLEIDINGL');
        } //-- end: leidingen ---------------------------------------------------------------------

        function leidingenByUnitRz(unitrz) {
            return ntaEntityData.getChildren(unitrz, 'VLEIDINGL');
        } //-- end: leidingen ---------------------------------------------------------------------

        function getInfiltratieUnitDatas() {
            //-- controleer eerst of het gebouw en/of de units überhaupt relevant zijn, ongeacht de gekozen invoer
            let isGebouwRelevant = !ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw() && !ntaSharedLogic.voorProjectwoningen();
            let zijnUnitsRelevant = (ntaSharedLogic.isUtiliteit() || ntaSharedLogic.isNietGrondGebonden()) && !ntaSharedLogic.perGebouw() || ntaSharedLogic.voorProjectwoningen();

            //-- afhankelijk van het invoertype moet ik de infilunits of perGebouw of perAppOfUnit teruggeven
            const invoerInfiltratie = getInvoerInfiltratie();
            switch (invoerInfiltratie) {
                case 'INFIL_GMW': // geen meetwaarde
                    // dan moet de infiltratie van zowel gebouw als units berekend worden
                    break;
                case 'INFIL_MWG': // meetwaarde per gebouw
                    // dan moeten we alleen de infiltratie van het gebouw hebben
                    //  (in geval van een appartement of losse unit, bij bestaande bouw, is dat de unit)
                    zijnUnitsRelevant = !isGebouwRelevant;
                    break;
                case 'INFIL_MWA': // meetwaarde per appartement
                case 'INFIL_MWU': // meetwaarde per unit
                case 'INFIL_MWW': // meetwaarde per woning
                    // in deze gevallen moeten we alleen de infiltratie van de units hebben
                    isGebouwRelevant = false;
                    break;
                default:
                    $log.warn(`Onverwachte invoer infiltratie ‘${invoerInfiltratie}’!`);
            }

            const infilUnits = [];
            if (isGebouwRelevant) infilUnits.push(...infilUnitVoorGebouw());
            if (zijnUnitsRelevant) infilUnits.push(...infilUnitVoorAppOfUnit());
            return infilUnits;
        } //-- end: getInfiltratieUnitDatas -------------------------------------------------------

        function infilUnitVoorGebouw() {
            //-- hier gaat maar één infilunit terug
            return _infiltratieUnitsData.filter(x => !hasAnyParent(x));
        } //-- end: infilUnitVoorGebouw -----------------------------------------------------------

        function infilUnitVoorAppOfUnit() {
            //-- hier gaan alleen de infilunits terug die een relatie hebben met UNIT
            return _infiltratieUnitsData.filter(x => hasAnyParent(x))
                .sort((a, b) => { // sorteer op volgorde van de bijbehorende unit
                    const unitA = ntaEntityData.getFirstParent(a, 'UNIT');
                    const unitB = ntaEntityData.getFirstParent(b, 'UNIT');
                    return ntaSharedLogic.orderElements(unitA, unitB);
                });
        } //-- end: infilUnitVoorAppOfUnit --------------------------------------------------------

        function calculateDefaultQV10(entInfilUnit) {
            if (!entInfilUnit)
                return null;

            let gebouwtype;
            const gebType = ntaSharedLogic.getGebouwType();
            let isMeerlaagsGebouw = ['TGEB_APP', 'TGEB_APPGEB', 'TGEB_UTILIT', 'TGEB_UTILUNIT'].includes(gebType);

            // Controleren of een meerlaagsgebouw toch de instelling heeft enkellaags te zijn
            if (isMeerlaagsGebouw) {
                const calcUnit = ntaSharedLogic.getCalcUnit();
                if (calcUnit === 'RZUNIT_GEB') {
                    const unit = ntaEntityData.getFirstWithEntityId('UNIT');
                    const propdataGebouwType = unit.PropertyDatas['UNIT_TYPEGEB'];
                    if (propdataGebouwType.Relevant && propdataGebouwType.Value !== 'TWON_GEB_APPGEB' && propdataGebouwType.Value !== 'UNIL_GEB_ML') {
                        isMeerlaagsGebouw = false;
                    }
                }
            }

            const isGrondgebonden = !isMeerlaagsGebouw;
            if (isGrondgebonden) {
                //-- grondgebonden woning
                const unit = ntaEntityData.getFirstParent(entInfilUnit, 'UNIT') // in geval van projectwoningen
                    || ntaEntityData.getFirstWithEntityId('UNIT');
                if (unit.PropertyDatas['UNIT_TYPEGEB'].Relevant) {
                    gebouwtype = unit.PropertyDatas['UNIT_TYPEGEB'].Value;
                } else {
                    gebouwtype = unit.PropertyDatas['UNIT_TYPEWON'].Value;
                }
            } else {
                //-- meerlaags gebouw
                const unit = ntaEntityData.getFirstParent(entInfilUnit, 'UNIT');
                switch (gebType) {
                    case 'TGEB_APPGEB': // appartementengebouw
                    case 'TGEB_APP': // los appartement (bestaande bouw)
                        gebouwtype = unit ? unit.PropertyDatas['UNIT_POS'].Value : 'TWON_GEB_APPGEB';
                        break;

                    case 'TGEB_UTILUNIT': // losse unit in utiliteitsgebouw (bestaande bouw)
                        gebouwtype = unit ? unit.PropertyDatas['UNIT_POS'].Value : 'UNIL_GEB_ML';
                        break;

                    case 'TGEB_UTILIT': // utiliteitsgebouw
                        if (unit) {
                            const calcUnit = ntaSharedLogic.getCalcUnit();
                            if (calcUnit === 'RZUNIT_GEB') {
                                gebouwtype = unit.PropertyDatas['UNIT_TYPEGEB'].Value;
                            } else {
                                gebouwtype = unit.PropertyDatas['UNIT_POS'].Value;
                            }
                        } else {
                            gebouwtype = 'UNIL_GEB_ML';
                        }
                        break;

                    default:
                        $log.error(`Onverwacht type gebouw ${gebType} in calculateDefaultQV10(${entInfilUnit.EntityDataId}) in building ${entInfilUnit.BuildingId}.`);
                        break;
                }
            }
            const [ftype, qv10SpecReken] = _gebouwtypes[gebouwtype] || [];
            if (!ftype) {
                console.warn(`Onbekend gebouwtype ${gebouwtype}`);
                return null;
            }

            //-- Bepaal fy uit jaar
            const bouwjaar = ntaSharedLogic.getBouwjaar();
            const renovatiejaar = getRenovatiejaar();
            const jaar = renovatiejaar > bouwjaar ? renovatiejaar : bouwjaar;
            let fy;
            if (jaar < 1000) {
                fy = 0;
            } else if (jaar < 1970) {
                fy = 3;
            } else if (jaar < 1980) {
                fy = 2.5;
            } else if (jaar < 1990) {
                fy = 2;
            } else if (jaar < 2000) {
                fy = 1.5;
            } else if (jaar < 2010) {
                fy = 1;
            } else if (jaar < 2100) {
                fy = 0.7;
            } else {
                fy = 0;
            }

            return fy * ftype * qv10SpecReken;
        } //-- end: calculateDefaultQV10 ----------------------------------------------------------

        function calculateAllQV10s() {
            if (!ntaData.canSaveBuilding(ntaData.buildingId)) {
                return;
            }

            const invoerInfiltratie = getInvoerInfiltratie();
            const calculateDefault = invoerInfiltratie === 'INFIL_GMW'; // geen meetwaarde; bereken de defaultwaarde

            const infilUnits = getInfiltratieUnitDatas();
            for (const infilUnit of infilUnits) {
                //-- editable prop voor eigen invoer
                const propdataQv10 = infilUnit.PropertyDatas['INFILUNIT_QV'];

                //-- non-editable prop voor weergave afgeronde waarde
                const propdataQv10Non = infilUnit.PropertyDatas['INFILUNIT_QV_NON'];

                //-- default onafgeronde waarden waar rekenkern mee gaat rekenen. Deze moet doorgegeven worden in de koppeling
                const propdataQv10Default = infilUnit.PropertyDatas['INFILUNIT_QV_DEFAULT'];

                if (calculateDefault) {
                    const qv10 = calculateDefaultQV10(infilUnit); //-- er komt een float waarde terug onafgerond
                    //-- deze onafgeronden waarde moet naar de rekenkern
                    if (qv10 !== ntaSharedLogic.parseFloat(propdataQv10Default.Value, 0)) {
                        ntaEntityData.saveprop(propdataQv10Default, qv10 || null);
                    }
                    //-- afgeronde waarde voor weergave
                    const propQv10Non = ntaData.properties[propdataQv10Non.PropertyId];
                    const qv10Rounded = ntaRounding.roundAndAddZerosNewValue(propQv10Non, qv10);
                    ntaEntityData.saveprop(propdataQv10Non, qv10Rounded);
                }

                //-- Set relevant en opslaan (deze zoek uit of er echt iets gewijzigd is of niet)
                ntaEntityData.setPropdataRelevancy(propdataQv10Default, calculateDefault);
                ntaEntityData.setPropdataRelevancy(propdataQv10, !calculateDefault);
            }

            setRelevancyInfiltratieUnits();
        } //-- end: calculateAllQV10s -------------------------------------------------------------

        function getRenovatiejaar(geb = null) {
            if (!geb) geb = ntaSharedLogic.getGebEntdata();

            if (ntaSharedLogic.isBestaandeBouw(geb)) {
                let propdataRenovatiejaar = geb.PropertyDatas['GEB_RENOVJR'];
                if (!!_entdataMeasureInfil || ntaSharedLogic.isValidatingVariantWithMeasureType('MEASURE-INFILTRATIE')) {
                    const renovjrProp = _infiltratieProperties['INFIL_REN_JAAR'];
                    propdataRenovatiejaar = renovjrProp.getData(_infiltratieData);
                }
                if (propdataRenovatiejaar.Relevant) {
                    const renovatiejaar = parseInt(propdataRenovatiejaar.Value);
                    if (!isNaN(renovatiejaar))
                        return renovatiejaar;
                }
            }
            return 100;
        } //-- end: getRenovatiejaar ------------------------------------------------------------------------//

        function hasAnyParent(child) {
            const relations = ntaEntityData.getParentRelations(child);
            return relations.length > 0;
        } //-- end: hasAnyParent ------------------------------------------------------------------

        function setRelevancyInfiltratieUnits() {
            const relevantInfilUnits = new Set(getInfiltratieUnitDatas());
            const irrelevantInfilUnits = _infiltratieUnitsData.filter(infiltratieunit => !relevantInfilUnits.has(infiltratieunit));

            ntaEntityData.setEntityRelevancy(relevantInfilUnits, true);
            ntaEntityData.setEntityVisibility(relevantInfilUnits, true);

            ntaEntityData.setEntityRelevancy(irrelevantInfilUnits, false);
            ntaEntityData.setEntityVisibility(irrelevantInfilUnits, false);
        } //-- end: setRelevancyInfiltratieUnits --------------------------------------------------

        function setUnitRzByUnits() {
            for (const unit of _units) {
                _unitRekenzonesPerUnit[unit.EntityDataId] = ntaEntityData.getChildren(unit, 'UNIT-RZ');
            }
        } //-- end: setUnitRzByUnits --------------------------------------------------------------

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }

            return listItem;
        } //-- end: getListItem -------------------------------------------------------------------

        function validate(prop, propdata, entdata = null) {
            if (!ntaData.canSaveBuilding(ntaData.buildingId)) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntaData.buildingId) {
                return;
            }
            const hidden = isHidden(prop, entdata);
            const readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(ldLogic.form_luchtdoorlaten, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                default:
            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            ntaSharedLogic.saveValue(prop, entdata, newValue, ldLogic);
        } //-- end: saveValue ---------------------------------------------------------------------

        function isHidden(prop, entdata) {
            //-- VO 2020-11-12: deze isHidden wordt ook gebruikt voor de tabelheader. In andere factory's heet dit propertyHeaderIsHidden. Voor
            //-- bouwkunidg/luchtdoorlaten doen we dit in de isHidden, vandaar anders checkken op entdata.
            if (!prop) {
                return false;
            }

            let propdata = null;
            if (entdata) {
                if (entdata.BuildingId !== ntaData.buildingId) {
                    return false;
                }
                propdata = prop.getData(entdata);
            }

            let showit = true;
            let relevant = null;

            const invoerInfiltratie = getInvoerInfiltratie();

            switch (prop.Id) {
                case 'INFIL_BGH': { // [P]
                    relevant = !ntaSharedLogic.voorProjectwoningen();
                    showit = !_entdataMeasure && relevant;
                    break;
                }
                case 'INFIL_INVOER': {
                    showit = !_entdataMeasure;
                    relevant = true;
                    break;
                }
                case 'INFIL_VERV_METHODE': {    // INFIL02
                    // alleen weergeven tijdens het bewerken van de infiltratiemaatregel
                    showit = !!_entdataMeasureInfil;
                    break;
                }
                case 'INFIL_REN_JAAR': {        // INFIL04
                    // weergeven tijdens het bewerken van een infiltratiemaatregel, mits conditie [INFIL-B] is vervuld
                    showit = !!_entdataMeasureInfil && conditionInfilB(entdata);
                    break;
                }
                case 'VLEIDING_TOI': {
                    const type = _vleidingenData.PropertyDatas['VLEIDING_INVOER'].Value;
                    showit = ntaSharedLogic.isUtiliteit() && type === 'VLEIDINGL_ONBEKEND';
                    break;
                }
                case 'VLEIDING_SUBS_TOT':       //VERTI07
                case 'VLEIDING_KOSTEN_TOT':     // VERTI04
                case 'VLEIDING_KOSTEN_OPM': {   // VERTI05
                    // weergeven tijdens het bewerken van een maatregel verticale leidingen en tijdens het valideren van een variant met zo’n maatregel, maar alleen als er sprake is van een maatwerkadvies
                    showit = (!!_entdataMeasureVLeiding || ntaSharedLogic.isValidatingVariantWithMeasureType('MEASURE-VERT_LEIDINGEN')) && ntaSharedLogic.showCosts();
                    break;
                }
                case 'LUCHTZOMNAC_BED': {
                    showit = !zomernachtIsHidden();
                    break;
                }
                case 'INFILUNIT_QV': {
                    showit = invoerInfiltratie !== 'INFIL_GMW';
                    break;
                }
                case 'INFILUNIT_QV_NON': {
                    showit = invoerInfiltratie === 'INFIL_GMW';
                    break;
                }
                case 'INFILUNIT_QV_DEFAULT': {
                    showit = false; //-- VO deze is altijd invisible omdat hij alleen gebruikt wordt om een onafgeronde waarde door te geven aan de rekenkern
                    relevant = invoerInfiltratie === 'INFIL_GMW'; //-- alleen relevant als er geen meetwaarde is
                    break;
                }
                case 'INFILUNIT_BGH': { // [Q]
                    showit = ntaSharedLogic.voorProjectwoningen();
                    break;
                }
                case 'INFIL_SUBS_TOT':      // INFIL11
                case 'INFIL_KOSTEN_TOT':    // INFIL08
                case 'INFIL_KOSTEN_OPM': {  // INFIL09
                    // weergeven tijdens het bewerken van een infiltratiemaatregel en tijdens het valideren van een variant met zo’n maatregel, maar alleen als er sprake is van een maatwerkadvies
                    showit = (!!_entdataMeasureInfil || ntaSharedLogic.isValidatingVariantWithMeasureType('MEASURE-INFILTRATIE')) && ntaSharedLogic.showCosts();
                    break;
                }
                case 'VLEIDING_KOSTEN_TOT_MIN_SUBSIDIE':
                case 'INFIL_KOSTEN_TOT_MIN_SUBSIDIE' :
                case 'INFIL_OPEN': {
                    showit = false;
                    break;
                }
            }

            //-- standaard is relevant gelijk aan showit
            if (relevant === null) relevant = showit;

            if (propdata) {
                //--- voor de isHidden van de header van tabellen is de relevantie zetten niet nodig.
                ntaEntityData.setPropdataRelevancy(propdata, relevant, ldLogic.form_luchtdoorlaten);
                ntaEntityData.setPropdataVisibility(propdata, showit);
            }
            return !showit;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionInfilB(entdataInfil) {
            // [INF-B] toon indien INFIL02 = forfaitair op basis van renovatiejaar
            return entdataInfil.PropertyDatas['INFIL_VERV_METHODE'].Value === 'INFIL_VERV_METHODE_FORF';
        } //-- end: conditionInfilA ---------------------------------------------------------------

        function conditionInfilC() {
            // [INF-C] toon indien INFIL02 = meetwaarde infiltratie
            // weergeven tijdens het bewerken van een infiltratiemaatregel. Tijdens het valideren van een variant wordt deze subtitel überhaupt niet weergegeven.
            return !!_entdataMeasureInfil && _infiltratieData.PropertyDatas['INFIL_VERV_METHODE'].Value === 'INFIL_VERV_METHODE_MEETW';
        } //-- end: conditionInfilA ---------------------------------------------------------------

        function isInfiltratieParticipating() {
            return ldLogic.measureTypeId !== 'MEASURE-VERT_LEIDINGEN';
        } //-- end: infiltratieUnitsParticipate ---------------------------------------------------

        function isZomernachtParticipating() {
            return !ldLogic.measureTypeId; // doet niet mee als we in een maatregel zitten
        } //-- end: isZomernachtParticipating -----------------------------------------------------

        function zomernachtIsHidden() {
            let showit = isZomernachtParticipating();

            if (showit) {
                const transparanteConstructies = ntaEntityData.getListWithEntityId('CONSTRT');
                const aanwezig = transparanteConstructies.some(constructie => {
                    const propdata = constructie.PropertyDatas['CONSTRT_ZNVENT'];
                    return propdata && propdata.Value === 'ZOMERNVENT_AANW';
                });

                ntaEntityData.setEntityRelevancy(_zomernachtData, aanwezig);
                ntaEntityData.setEntityVisibility(_zomernachtData, aanwezig);

                showit = aanwezig;
            }
            return !showit;
        } //-- end: zomernachtIsHidden ------------------------------------------------------------

        function isVerticaleLeidingenParticipating() {
            return ldLogic.measureTypeId !== 'MEASURE-INFILTRATIE';
        } //-- end: isVerticaleLeidingenParticipating ---------------------------------------------

        function verticaleLeidingenIsHidden() {
            // in infiltratiemaatregel, tabel verbergen, maar ook niets aan de relevantie van de entiteiten veranderen
            let showit = isVerticaleLeidingenParticipating();

            if (showit) {
                //-- Tabel alleen tonen als verticale leidingen bekend zijn
                const invoer = _vleidingenData.PropertyDatas['VLEIDING_INVOER'].Value;
                showit = invoer === 'VLEIDINGL_BEKEND';

                ntaEntityData.setEntityRelevancy(leidingen(), showit);
                ntaEntityData.setEntityVisibility(leidingen(), showit);
            }

            return !showit;
        } //-- end: verticaleLeidingenIsHidden ----------------------------------------------------

        function isReadOnly(prop, entdata) {
            let bReadOnly = false;

            if (!prop || !entdata || entdata.BuildingId !== ntaData.buildingId) {
                return bReadOnly;
            }

            switch (prop.Id) {
                case 'INFILUNIT_QV_NON':
                    {
                        bReadOnly = true;
                        break;
                    }
                case 'VLEIDINGL_ARZ':
                    {
                        let aantalLeidingen = entdata.PropertyDatas['VLEIDINGL_AAN'].Value;
                        bReadOnly = aantalLeidingen && parseInt(aantalLeidingen) === 0;
                        break;
                    }
            }

            return bReadOnly;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function getCodedValues(prop, entdata) {
            if (!entdata || !prop || entdata.BuildingId !== ntaData.buildingId) {
                return [];
            }

            let typesList = ntaValidation.codedValues(prop);
            switch (prop.Id) {
                case 'INFIL_INVOER': {
                    typesList = typesList.filter(codedValue => {
                        switch (codedValue.Id) {
                            case 'INFIL_GMW': return true;
                            case 'INFIL_MWG': return !ntaSharedLogic.perGebouwEnAppartementOfUnit() && !ntaSharedLogic.voorProjectwoningen();   // conditie [N]
                            case 'INFIL_MWA': return ntaSharedLogic.isNietGrondGebonden() && ntaSharedLogic.perGebouwEnAppartement();           // conditie [A]
                            case 'INFIL_MWU': return ntaSharedLogic.isUtiliteit() && ntaSharedLogic.perGebouwEnUnit();                          // conditie [A]
                            case 'INFIL_MWW': return ntaSharedLogic.voorProjectwoningen()                                                       // conditie [A]
                            default:          return true;
                        }
                    });
                    break;
                }
                case 'INFIL_VERV_METHODE': {
                    // [INF-A] toon alleen als G05 ≠ nieuwbouw
                    if (ntaSharedLogic.isNieuwbouw()) {
                        typesList = typesList.filter(cv => cv.Id !== 'INFIL_VERV_METHODE_FORF');
                    }
                    break;
                }
                case 'VLEIDINGL_ISO': {
                    //-- [H] [I] als er geen leidingen zijn moet de enige optie NVT zijn, anders moet NVT er uit gefilterd worden
                    const leidingenAanwezig = (parseInt(entdata.PropertyDatas['VLEIDINGL_AAN'].Value) || 0) > 0;
                    typesList = typesList.filter(codedValue => leidingenAanwezig !== (codedValue.Id === 'VLEIDINGL_NVT'));
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, ldLogic);
            return typesList;
        } //-- end: getCodedValues ----------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'INFIL_INVOER': {
                    //-- gecontroleer moet worden of de waarde voor INFIL_INVOER nog goed is ivm gebouwType.
                    const propValue = prop.getValue(entdata);
                    if (propValue === 'INFIL_MWA' || propValue === 'INFIL_MWU' || propValue === 'INFIL_MWW') {
                        //-- wanneer invoer per app of per unit is dan verder checken
                        if (propValue === 'INFIL_MWA' && ntaSharedLogic.isUtiliteit() ||
                            propValue === 'INFIL_MWA' && ntaSharedLogic.perGebouw() ||
                            propValue === 'INFIL_MWU' && !ntaSharedLogic.isUtiliteit() ||
                            propValue === 'INFIL_MWU' && ntaSharedLogic.perGebouw() ||
                            propValue === 'INFIL_MWW' && !ntaSharedLogic.voorProjectwoningen())
                        {
                            const newValue = 'INFIL_GMW';
                            saveValue(prop, entdata, newValue);
                            calculateAllQV10s();
                        }
                    } else if (propValue === 'INFIL_GMW') {
                        calculateAllQV10s(); //-- opnieuw de default waarde bepalen.
                    }
                    //-- Altijd relevantie van INFILUNITs correct zetten.
                    setRelevancyInfiltratieUnits();
                    break;
                }
                case 'INFIL_REN_JAAR':
                case 'INFIL_VERV_METHODE': {
                    //-- opnieuw de defaultwaarde bepalen.
                    calculateAllQV10s();
                    //-- Altijd relevantie van INFILUNITs correct zetten.
                    setRelevancyInfiltratieUnits();
                    break;
                }
                case 'VLEIDINGL_AAN': {
                    const propdata = prop.getData(entdata);
                    const iAantal = parseInt(propdata.Value);
                    const propIso = ntaData.properties['VLEIDINGL_ISO'];
                    const propValueIso = propIso.getValue(entdata);

                    if (propValueIso === 'VLEIDINGL_NVT' && iAantal > 0 ||
                        propValueIso !== 'VLEIDINGL_NVT' && iAantal === 0) {
                        saveValue(propIso, entdata, null);
                    }

                    const propAantalAangrenzendeRekenzones = _leidingenProperties['VLEIDINGL_ARZ'];
                    if (iAantal === 0) {
                        saveValue(propAantalAangrenzendeRekenzones, entdata, 'n.v.t.')
                    } else {
                        const propdataAantalAangrenzendeRekenzones = propAantalAangrenzendeRekenzones.getData(entdata);
                        if (propdataAantalAangrenzendeRekenzones.Value === 'n.v.t.') {
                            saveValue(propAantalAangrenzendeRekenzones, entdata, '')
                        }
                    }
                    break;
                }
                case 'INFILUNIT_BGH': // I25
                case 'INFILUNIT_QV': { // I07
                    /// [R] als hoogte in eerste regel wordt ingevuld en andere cellen in deze kolom zijn nog leeg vul dan in alle regels de hoogte van de eerste regel in.
                    const newValue = prop.getValue(entdata);
                    if (newValue) {
                        const otherInfilUnits = getInfiltratieUnitDatas()
                            .filter(ed => ed !== entdata);
                        const isFirstValue = otherInfilUnits.every(ed => !prop.getValue(ed));
                        if (isFirstValue) {
                            for (const infilUnit of otherInfilUnits) {
                                saveValue(prop, infilUnit, newValue);
                            }
                        }
                    }
                    break;
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), ldLogic);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), ldLogic);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(isInfiltratieParticipating() ? _infiltratieData : null)
                .concat(isInfiltratieParticipating() ? _infiltratieUnitsData : null)
                .concat(isVerticaleLeidingenParticipating() ? _vleidingenData : null)
                .concat(isVerticaleLeidingenParticipating() ? leidingen() : null)
                .concat(isZomernachtParticipating() ? _zomernachtData : null)

                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        //-- Tabel 11.14 met ftype en qv10SpecReken per gebouwtype
        function createGebouwTypes() {
            return {
                'MGEB_BLH1':            [1.4, 0.5],
                'MGEB_BLH2':            [1.4, 0.5],
                'MGEB_BLT1':            [1.2, 0.5],
                'MGEB_BLT2':            [1.2, 0.5],
                'MGEB_OLHMD1':          [1.4, 0.5],
                'MGEB_OLHMD2':          [1.4, 0.5],
                'MGEB_OLHZD1':          [1.3, 0.5],
                'MGEB_OLHZD2':          [1.3, 0.5],
                'MGEB_OLTMD1':          [1.2, 0.5],
                'MGEB_OLTMD2':          [1.2, 0.5],
                'MGEB_OLTZD1':          [1.0, 0.5],
                'MGEB_OLTZD2':          [1.0, 0.5],
                'MGEB_TLH1':            [1.3, 0.5],
                'MGEB_TLH2':            [1.3, 0.5],
                'MGEB_TLT1':            [1.0, 0.5],
                'MGEB_TLT2':            [1.0, 0.5],
                'MUTIL_BLH1':           [1.4, 0.5],
                'MUTIL_BLT1':           [1.2, 0.5],
                'MUTIL_OLHMD1':         [1.4, 0.5],
                'MUTIL_OLHZD1':         [1.3, 0.5],
                'MUTIL_OLTMD1':         [1.2, 0.5],
                'MUTIL_OLTZD1':         [1.0, 0.5],
                'MUTIL_TLH1':           [1.3, 0.5],
                'MUTIL_TLT1':           [1.0, 0.5],
                'TWON_21_K':            [1.2, 1.0],
                'TWON_21_PD':           [1.2, 0.7],
                'TWON_HOEK_K':          [1.2, 1.0],
                'TWON_HOEK_PD':         [1.2, 0.7],
                'TWON_TUS_K':           [1.0, 1.0],
                'TWON_TUS_PD':          [1.0, 0.7],
                'TWON_VRIJ_HPD':        [1.2, 1.0],
                'TWON_VRIJ_K':          [1.4, 1.0],
                'TWON_VRIJ_PD':         [1.4, 0.7],
                'TWON_GEB_APPGEB':      [1.2, 0.5],
                'UNIL_GEB_APPGEB':      [1.2, 0.5],
                'UNIL_GEB_ELKV_HMK':    [1.2, 1.0],
                'UNIL_GEB_ELKV_HPD':    [1.2, 0.7],
                'UNIL_GEB_ELKV_TMK':    [1.0, 1.0],
                'UNIL_GEB_ELKV_TPD':    [1.0, 0.7],
                'UNIL_GEB_ELKV_VHPD':   [1.2, 1.0],
                'UNIL_GEB_ELKV_VMK':    [1.4, 1.0],
                'UNIL_GEB_ELKV_VPD':    [1.4, 0.7],
                'UNIL_GEB_ML':          [1.2, 0.5],
            };
        } //-- end: createGebouwTypes -------------------------------------------------------------
    };
}]);
