﻿angular.module('projectModule')
    .controller('MeasureUController',
        ['$scope', '$controller', '$routeParams', 'ntaValidation', 'ntaMeasure',
function ($scope,   $controller,   $routeParams,   ntaValidation,   ntaMeasure) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    const logic = ntaMeasure.getLogic($routeParams.shadowId);
    const _measureTypeName = logic.measureType.Value;

    const _subtitleByPropId = new Map(Object.entries({
        'MEASURE-U_LIBCONSTR_ID': 'Vervang ' + _measureTypeName + ' van constructie',
        'MEASURE-U_VERVANG_ITEM': 'Vervang ' + _measureTypeName + ' van constructies met de volgende kenmerken',
        'MEASURE-U_U': 'Nieuwe ' + _measureTypeName + ' van constructie(s)',
        'MEASURE-U_GGL': 'Nieuwe ' + _measureTypeName + ' van constructie(s)',
        'MEASURE-U_KOSTEN_INV': 'Kosten',
        'MEASURE-U_SUBS_INV': 'Subsidie',
    }));


    //== Exports ==================================================================================
    ctrl.logic = logic;
    ctrl.getName = getName;
    ctrl.properties = logic.properties;
    ctrl.entdata = logic.entdataMeasureU;
    ctrl.measureType = logic.measureType;
    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.isHidden = logic.isHidden;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.isItemChecked = logic.isItemChecked;
    ctrl.toggleItemChecked = logic.toggleItemChecked;
    ctrl.onValidateCallback = logic.onValidateCallback;

    ctrl.getForm = () => logic.form;
    ctrl.getPropData = prop => base.getPropData(prop, logic.entdataMeasureU);
    ctrl.getPropertyType = prop => prop && prop.PropertyType;

    Object.assign(ctrl, {
        getSubtitle,
        saveValue,
    });

    $scope.endFormValidation = logic.endFormValidation;


    //== Initialization ===========================================================================

    ntaMeasure.setBtwPropIds(ctrl);

    angular.element(function () { //alle touched properties valideren na het laden van form
        logic.startFormValidation();
        logic.setGeopend();
    });

    //== Implementation ===========================================================================

    function getName(prop) {
        let name = base.getName(prop);
        switch (prop.Id) {
            case 'MEASURE-U_LIBCONSTR_ID': {
                name = name.replace("{{ctrl.measureType}}", _measureTypeName);
                break;
            }
            case 'MEASURE-U_U':
            case 'MEASURE-U_U_GT': {
                let uwaarde = 'U<sub>D</sub>';
                if (ctrl.measureType.Id === 'MEASURE-UW_GGL_RAAM') {
                    uwaarde = 'U<sub>W</sub>';
                }
                name = name.replace("{{U}}", uwaarde);
                break;
            }
            default:
        }
        return name;
    } //-- end: getName --------------------------------------------------------------------------------//

    function getSubtitle(prop) {
        /// voor property MEASURE-U_U en MEASURE-U_GGL is deze oplossing lastig. MEASURE-U_U is namelijk
        /// niet altijd zichtbaar.
        let subTitle = _subtitleByPropId.get(prop.Id) || '';
        if (prop.Id === 'MEASURE-U_GGL' && ctrl.entdata.PropertyDatas['MEASURE-U_U'].Relevant) {
            subTitle = '';
        }

        return subTitle;
    } //-- end: getSubtitle -----------------------------------------------------------------------

    function saveValue(prop) {
        logic.saveValue(prop, logic.entdataMeasureU);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

}]);
