﻿angular.module('projectModule')
    .factory('MeasureRcFactory',
        ['$log', 'ntaData', 'ntaMeasureData', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ListCache', 'ntaDeltas', 'EntityData', 'RelationData', 'BuildingBegrenzingFactory', 'ntaMeldingen',
function ($log,   ntaData,   ntaMeasureData,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   ListCache,   ntaDeltas ,  EntityData,   RelationData,   BuildingBegrenzingFactory,   ntaMeldingen) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn

    const _libVlaktypeByMeasureType = new Map(Object.entries({
        'MEASURE-RC_VLOER':         'LIBVLAK_VLOER',
        'MEASURE-RC_VLOER_BOVBUI':  'LIBVLAK_VLOER_BOVBUI',
        'MEASURE-RC_GEVEL':         'LIBVLAK_GEVEL',
        'MEASURE-RC_DAK':           'LIBVLAK_DAK',
        'MEASURE-RC_KELDERWAND':    'LIBVLAK_KELDERW',
        'MEASURE-RC_BODEM':         'LIBVLAK_BODEM',
    }));

    return function MeasureRcLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================

        const _entdataMeasure = ntaEntityData.get(measureId);
        const _entdataMeasureRc = ntaEntityData.getFirstChild(_entdataMeasure, 'MEASURE-RC');
        const _listCache = new ListCache();
        const _isVersionGe33 = ntabuilding.ntaVersionId >= 300;


        const begrenzingLogic = new BuildingBegrenzingFactory(0, null);


        // [RC-E] indien bij maatregelen gekozen voor 'Rc vloer' toon 'vloer'; indien 'Rc vloer boven buitenlucht' toon 'vloer boven buitenlucht', idem voor gevel / dak / kelderwand / bodem
        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        //== Exports ==============================================================================
        self.entdataMeasureRc = _entdataMeasureRc;
        self.properties = ntaData.properties[_entdataMeasureRc.EntityId];
        self.measureType = _measureType;
        self.isItemChecked = ntaMeasureData.isItemChecked;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods voor maatregelen
            getTileDetails,
            getAlleKosten,
            generateDeltas,
            getMatchingConstrEntdatas,
            toggleItemChecked,

            // standaard methods tbv validatie
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
            onValidateCallback,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)

        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];
            lines.push({ name: 'nieuwe R<sub>c</sub>', value: render.value('MEASURE-RC_RC') });
            lines.push({});
            lines.push({ name: 'totale oppervlakte', value: render.value('MEASURE-RC_A_TOT') });
            if (ntaSharedLogic.showCosts()) {
                lines.push({});
                lines.push(render.detail('MEASURE-RC_KOSTEN_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-RC_SUBS_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-RC_KOSTEN_TOT_MIN_SUBSIDIE'));
            }
            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getAlleKosten() {
            const totCostProp = ntaData.properties["MEASURE-RC_KOSTEN_TOT"];
            const investering = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureRc), 0);

            const totSubsProp = ntaData.properties["MEASURE-RC_SUBS_TOT"];
            const subsidie = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureRc), 0);

            const totMinSubsProp = ntaData.properties["MEASURE-RC_KOSTEN_TOT_MIN_SUBSIDIE"];
            const totaal = ntaSharedLogic.parseFloat(totMinSubsProp?.getValue(_entdataMeasureRc), 0);

            return {
                investering,
                subsidie,
                totaal,
            };
        } //-- end: getAlleKosten ----------------------------------------------------------------

        function generateDeltas(shadowId) {
            const deltas = [];
            const rcLibProp = ntaData.properties['LIBCONSTRD_RC'];
            const rcMeasureProp = ntaData.properties['MEASURE-RC_RC'];
            const rcValue = rcMeasureProp.getValue(_entdataMeasureRc);

            const newLibConstrDPseudoEntdatas = new Map();
            // maak een nieuw virtueel LIBCONSTRD-item voor elke (gebruikte) forfaitaire CodedValue
            for (const forfaitaireRbf of getForfaitaireRbfsInUse()) {
                // maak nieuw LIBCONSTRD-item aan met waarde rc-property van MEASURE-RC_RC
                const newLibConstrEntdata = new EntityData('LIBCONSTRD', undefined, [
                    { PropertyId: 'LIBCONSTRD_OMSCHR',      Value: forfaitaireRbf.Value },
                    { PropertyId: 'LIBCONSTRD_TYPE',        Value: 'LIBVLAK_BODEM' },       // vlak = bodem
                    { PropertyId: 'LIBCONSTRD_METH',        Value: 'VRIJE_INV' },           // methodiek = vrije invoer
                    { PropertyId: 'LIBCONSTRD_BEPALING',    Relevant: false,    Visible: false },
                    { PropertyId: 'LIBCONSTRD_DIKTE_ISO',   Relevant: false,    Visible: false },
                    { PropertyId: 'LIBCONSTRD_DIKTE_RIET',  Relevant: false,    Visible: false },
                    { PropertyId: 'LIBCONSTRD_RC',          Value: rcValue },
                ]);

                // genereer de delta’s om dit LIBCONSTR-item aan te maken
                deltas.push(new ntaDeltas.DeltaEntityData(shadowId, newLibConstrEntdata.EntityDataId, newLibConstrEntdata, 'Replace'));
                deltas.push(...newLibConstrEntdata.PropertyDatas.map(pd => new ntaDeltas.DeltaPropertyData(shadowId, pd.PropertyDataId, pd, 'Replace')));

                // en sla het op onder de CodedValueId
                newLibConstrDPseudoEntdatas.set(forfaitaireRbf.Id, newLibConstrEntdata);
            }

            if (conditionA()) {
                const libConstrD = ntaEntityData.getFirstParent(_entdataMeasureRc, 'LIBCONSTRD');
                if (libConstrD) {
                    // vervang waarde rc-property van gekozen lib-item met waarde van MEASURE-RC_RC
                    const propdata = rcLibProp.getData(libConstrD);
                    const delta = new ntaDeltas.DeltaPropertyData(shadowId, propdata.PropertyDataId, propdata, 'Replace');
                    delta.Value = rcValue;
                    deltas.push(delta);
                } else {
                    // dan is het een forfaitaire waarde die vervangen moet worden;
                    // pas de (virtuele) LIBCONSTRD toe van de gebruikte forfaitaire CodedValue.
                    for (const constrEntdata of getMatchingConstrEntdatas()) {
                        const libConstrD = newLibConstrDPseudoEntdatas.get(constrEntdata.PropertyDatas['KENMKR_WW_KR'].Value);
                        // maak nieuwe relatie aan tussen de gekozen begrenzingen en nieuw aangemaakte libconstr
                        const newRelation = new RelationData(libConstrD, constrEntdata, 0, 0);
                        const newRelDelta = new ntaDeltas.DeltaRelationData(shadowId, newRelation.EntityRelationDataId, newRelation, 'Replace');
                        deltas.push(newRelDelta);
                    }
                }

            } else if (conditionB()) {
                const constrEntdatas = getMatchingConstrEntdatas();

                // Maak een nieuwe virtuele LIBCONSTRD-item voor elke gebruikte LIBCONSTRD
                const libConstrDs = constrEntdatas.flatMap(ed => ntaEntityData.getParents(ed, 'LIBCONSTRD')).distinct();
                for (const libConstrD of libConstrDs) {
                    // maak nieuw lib-item aan met waarde rc-property van MEASURE-RC_RC (kopie van lib-item van de constructie)
                    const newId = uuid.v4();
                    deltas.push(new ntaDeltas.DeltaEntityData(shadowId, newId, libConstrD, 'Replace'));

                    for (const propdata of libConstrD.PropertyDatas) {
                        const propDelta = new ntaDeltas.DeltaPropertyData(shadowId, newId + ':' + propdata.PropertyId, propdata, 'Replace');
                        propDelta.EntityDataId = newId;
                        if (propdata.PropertyId === 'LIBCONSTRD_RC') {
                            propDelta.Value = rcValue;
                        }
                        deltas.push(propDelta);
                    }

                    const pseudoEntdata = {
                        BuildingId: libConstrD.BuildingId,
                        EntityDataId: newId,
                        EntityId: libConstrD.EntityId,
                    };
                    newLibConstrDPseudoEntdatas.set(libConstrD.EntityDataId, pseudoEntdata);
                }

                for (const constrEntdata of constrEntdatas) {
                    const oldRelations = ntaEntityData.getParentRelations(constrEntdata, 'LIBCONSTRD');

                    // verwijder relatie(s) van de gekozen begrenzingen met lib-items
                    for (const oldRelation of oldRelations) {
                        const oldRelDelta = new ntaDeltas.DeltaRelationData(shadowId, oldRelation.EntityRelationDataId, oldRelation, 'Delete');
                        deltas.push(oldRelDelta);
                    }

                    // haal nieuw (pseudo)lib-item met waarde rc-property van MEASURE-RC_RC (kopie van lib-item van de constructie)
                    const oldRelation = oldRelations[0];
                    const newLibConstrPseudoEntdata = oldRelation
                        ? newLibConstrDPseudoEntdatas.get(oldRelation.Parent)
                        : newLibConstrDPseudoEntdatas.get(constrEntdata.PropertyDatas['KENMKR_WW_KR'] && constrEntdata.PropertyDatas['KENMKR_WW_KR'].Value);

                    if (newLibConstrPseudoEntdata) {
                        // maak nieuwe relatie aan tussen de gekozen begrenzingen en nieuw aangemaakte libconstr
                        const newRelation = new RelationData(newLibConstrPseudoEntdata, constrEntdata, 0, 0);
                        const newRelDelta = new ntaDeltas.DeltaRelationData(shadowId, newRelation.EntityRelationDataId, newRelation, 'Replace');
                        deltas.push(newRelDelta);
                    }
                }
            }

            return deltas;
        } //-- end: generateDeltas ----------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                case 'MEASURE-RC_A_TOT':
                    return true;

                case 'MEASURE-RC_KOSTEN_TOT':
                    return conditionD();
                case 'MEASURE-RC_SUBS_TOT':
                    return conditionK();
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataMeasureRc) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return true;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (!propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                case 'MEASURE-RC_KOSTEN_TOT_MIN_SUBSIDIE': {
                    visible = false;
                    break;
                }
                case 'MEASURE-RC_LIBCONSTR_ID': { // RC04
                    visible = conditionA();
                    break;
                }
                case 'MEASURE-RC_BEGR_IDS': // RC06
                case 'MEASURE-RC_RC_LT':    // RC07
                case 'MEASURE-RC_A_GT': {   // RC08
                    visible = conditionB();
                    break;
                }
                case 'MEASURE-RC_KOSTEN_INV':       // RC13
                case 'MEASURE-RC_KOSTEN_TOT': {     // RC15
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-RC_KOSTEN_PER_M2': {  // RC14
                    visible = conditionC() && conditionD();
                    break;
                }
                case 'MEASURE-RC_SUBS_INV':       // RC18
                case 'MEASURE-RC_SUBS_TOT': {     // RC20
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-RC_SUBS_PER_M2': {  // RC19
                    visible = conditionC() && conditionK();
                    break;
                }

            }

            if (relevant === null) relevant = visible;
            ntaEntityData.setPropdataStatus(propdata, relevant, visible);
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionA() {
            // [RC-A] toon indien RC02 = vervang Rc waarde uit bibliotheek
            return _entdataMeasureRc.PropertyDatas['MEASURE-RC_METHODE'].Value === 'MEASURE-RC_METHODE_LIB';
        } //-- end: conditionA --------------------------------------------------------------------

        function conditionB() {
            // [RC-B] toon indien RC02 = zoek en vervang Rc in berekening
            return _entdataMeasureRc.PropertyDatas['MEASURE-RC_METHODE'].Value === 'MEASURE-RC_METHODE_RC';
        } //-- end: conditionB --------------------------------------------------------------------

        function conditionC() {
            // [RC-C] toon indien bij instellingen gekozen voor 'maatwerk advies'
            return ntaSharedLogic.showCosts();
        } //-- end: conditionC --------------------------------------------------------------------

        function conditionD() {
            // [RC-D] toon indien RC13 = kostenkentallen per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureRc.PropertyDatas['MEASURE-RC_KOSTEN_INV'].Value === 'MEASURE_KOSTEN_PER_M2';
        } //-- end: conditionD --------------------------------------------------------------------

        function conditionI_J(entdata, valueToSave) {
            //[RC-I] Indien dit veld gewijzigd wordt controleer of de maatregel gebruikt wordt in één of meerdere varianten.
            //Controleer of bij deze varianten de wijzigingen van R - waarden niet dezelfde constructie betreffen.
            //Indien maatregelen dezelfde constructie  betreffen geef dan melding[W119] en zet dit veld terug op de oorspronkelijke keuze.

            //[RC-J] Indien een checkbox gewijzigd wordt controleer of de maatregel gebruikt wordt in één of meerdere varianten.
            //Controleer of bij deze varianten de wijzigingen van Rc - waarden niet dezelfde constructie betreffen.
            //Indien maatregelen dezelfde constructie  betreffen geef dan melding[W120] en zet de checkbox terug op de oorspronkelijke keuze.

            const measure = ntaEntityData.getFirstParent(entdata, "MEASURE");
            const propMeasureType = ntaData.properties['MEASURE_TYPE'];
            const typeValue = propMeasureType.getValue(measure);

            const variants = ntaEntityData.findEntities(measure, 'VARIANT-MEASURE.^VARIANT', 'VARIANT');
            const propVariantName = ntaData.properties['VARIANT_NAAM'];
            const variantNames = [];
            for (const variant of variants) {
                const measures = ntaEntityData.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE').filter(x => propMeasureType.getValue(x) === typeValue);
                if (ntaSharedLogic.hasConstructionOverlap(measure, measures, valueToSave)) {
                    variantNames.push(propVariantName.getValue(variant));
                }
            }
            return variantNames;
        } //-- end: conditionI_J--------------------------------------------------------------------//

        function conditionK() {
            //[RC - K] toon indien RC18 = subsidie per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureRc.PropertyDatas['MEASURE-RC_SUBS_INV']?.Value === 'MEASURE_SUBSIDIE_PER_M2';
        } //-- end: conditionK ----------------------------------------------------------------

        function calculateTotalArea() {
            const constrEntdatas = getMatchingConstrEntdatas();
            return constrEntdatas.reduce((area, constr) => area + getConstrArea(constr), 0);
        } //-- end: calculateTotalArea ------------------------------------------------------------

        function getMatchingConstrEntdatas() {
            const constrParentEntdatas = [];
            let constrEntdatas = [];
            const entityIds = new Set(['CONSTRD', 'CONSTRWG', 'CONSTRWWKLDR']);

            if (conditionA()) {
                // de som van alle oppervlakte waar deze constructie in de berekening is gebruikt (veld C04)
                const libConstrD = ntaEntityData.getFirstParent(_entdataMeasureRc, 'LIBCONSTRD');
                if (libConstrD) {
                    constrParentEntdatas.push(libConstrD);
                } else {
                    // als er GEEN verwijzing is naar een LIBCONSTRD-entiteit, dan gaat het om één van de forfaitaire Rbf-waardes (veld C36)
                    const propRbf = ntaData.properties['KENMKR_WW_KR'];
                    const codedValueId = _entdataMeasureRc.PropertyDatas['MEASURE-RC_LIBCONSTR_ID'].Value;
                    const codedValueRbf = propRbf.Domain.Codes.find(cv => cv.Id === codedValueId);
                    if (codedValueRbf) {
                        const constrKelders = ntaEntityData.getListWithEntityId('CONSTRWWKLDR')
                            .filter(ed => ed.Relevant)
                            .filter(ed => propRbf.getValue(ed) === codedValueId);
                        constrEntdatas.push(...constrKelders);
                    }
                }

            } else if (conditionB()) {
                // de som van alle oppervlakte die voldoen aan de filters in de velden RC06 t/m RC08 [RC-F]
                const begrEntdatas = ntaEntityData.getParents(_entdataMeasureRc, 'BEGR');
                constrParentEntdatas.push(...begrEntdatas);
                constrParentEntdatas.push(...begrEntdatas.flatMap(ed => ntaEntityData.getChildren(ed))
                    .filter(ed => entityIds.has(ed.EntityId))
                    .filter(ed => ed.Relevant));
            }

            constrEntdatas = constrEntdatas.concat(constrParentEntdatas.flatMap(ed => ntaEntityData.getChildren(ed)))
                .filter(ed => entityIds.has(ed.EntityId))
                .filter(ed => ed.Relevant);

            if (conditionB()) {

                //CONSTRWWKLDR heeft geen oppervlak, wordt niet gekozen bij conditionB
                constrEntdatas = constrEntdatas.filter(ed => ed.EntityId !== "CONSTRWWKLDR");


                // [RC-F] het gaat om de constructies die aan alle kenmerken van de velden RC06 t/m RC08 voldoen (dus EN)
                const maxRc = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_RC_LT'].Value); // RC07
                if (!isNaN(maxRc)) {
                    constrEntdatas = constrEntdatas.filter(constr => {
                        const rc = getConstrRc(constr);
                        return !isNaN(rc) && rc < maxRc;
                    });
                }

                const minA = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_A_GT'].Value); // RC08
                if (!isNaN(minA)) {
                    constrEntdatas = constrEntdatas.filter(constr => {
                        const area = getConstrArea(constr);
                        return !isNaN(area) && area > minA;
                    });
                }
            }

            return constrEntdatas;
        } //-- end: getMatchingConstructieEntdatas ------------------------------------------------

        function getConstrRc(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRD');
            if (lib) {
                const rcValue = lib && lib.PropertyDatas['LIBCONSTRD_RC'].Value;
                return ntaSharedLogic.parseFloat(rcValue);
            } else {
                const propRbf = ntaData.properties['KENMKR_WW_KR'];
                const codedValueRbf = propRbf.getCode(entdataConstr);
                return codedValueRbf ? ntaSharedLogic.parseFloat(codedValueRbf.CalcValue) : NaN;
            }
        } //-- end: getConstrRc -------------------------------------------------------------------

        function getConstrArea(entdataConstr) {
            if (!entdataConstr) return 0;

            const unit = ntaEntityData.findEntities(entdataConstr, '^BEGR^UNIT-RZ^UNIT', '^CONSTRD^BEGR^UNIT-RZ^UNIT')[0];
            let aantalUnits = 1;
            if (unit) {
                const propAantA = ntaData.properties['UNIT_AANTA'];
                const propAantU = ntaData.properties['UNIT_AANTU'];
                const propdataAantA = propAantA.getData(unit);
                const propdataAantU = propAantU.getData(unit);
                aantalUnits = ntaSharedLogic.parseFloat(propdataAantA.Relevant ? propdataAantA.Value : propdataAantU.Relevant ? propdataAantU.Value : "1");
            }

            const propertyIds = ['CONSTRD_OPP', 'CONSTRWG_OPP'];
            let propdataArea = propertyIds.map(propId => entdataConstr.PropertyDatas[propId]).find(pd => pd);
            if (!propdataArea) {
                const constrD = ntaEntityData.getFirstParent(entdataConstr, 'CONSTRD');
                propdataArea = constrD && constrD.PropertyDatas['CONSTRD_OPP'];
            }
            if (!propdataArea) {
                const begr = ntaEntityData.getFirstParent(entdataConstr, 'BEGR')
                propdataArea = begr && begr.PropertyDatas['BEGR_A'];
            }
            if (!propdataArea) return 0;

            const areaValue = propdataArea && propdataArea.Value;

            return ntaSharedLogic.parseFloat(areaValue) * aantalUnits;
        } //-- end: getConstrArea -----------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return [];
            }

            let codedValues = [];
            switch (prop.Id) {
                case 'MEASURE-RC_LIBCONSTR_ID': { // RC04
                    const libConstrs = getLibConstrs();
                    codedValues = libConstrs
                        .map(libConstrD => ({
                            Id: libConstrD.EntityDataId,
                            Value: libConstrD.PropertyDatas['LIBCONSTRD_OMSCHR'].Value + ` (R<sub>c</sub> = ${libConstrD.PropertyDatas['LIBCONSTRD_RC'].Value})`,
                        }))
                        .concat(getForfaitaireRbfsInUse());
                    break;
                }
                case 'MEASURE-RC_BEGR_IDS': { // RC06
                    codedValues = getBegrenzingen();

                    break;
                }
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function getLibConstrs() {
            const libVlaktype = _libVlaktypeByMeasureType.get(_measureType.Id);
            const libConstrs = ntaEntityData.getListWithEntityId('LIBCONSTRD')
                .filter(libConstrD => libConstrD.PropertyDatas['LIBCONSTRD_TYPE'].Value === libVlaktype)
                .filter(libConstrD => libConstrD.Relevant);
            return libConstrs;
        } //-- end: getLibConstrs -----------------------------------------------------------------

        function getForfaitaireRbfsInUse() {
            const codedValues = [];
            if (_measureType.Id === 'MEASURE-RC_BODEM') {
                const propRbf = ntaData.properties['KENMKR_WW_KR'];
                const forfCodes = propRbf.Domain.Codes;
                const constrKelders = ntaEntityData.getListWithEntityId('CONSTRWWKLDR')
                    .filter(ed => ed.Relevant);
                for (const codedValue of forfCodes) {
                    if (constrKelders.some(ed => propRbf.getValue(ed) === codedValue.Id)) {
                        codedValues.push(codedValue);
                    }
                }
            }
            return codedValues;
        } //-- end: getForfaitaireRbfsInUse -------------------------------------------------------

        function getBegrenzingen() {
            const vlaktype = ntaMeasureData.begrVlaktypeByMeasureType.get(_measureType.Id);
            const begrenzingen = ntaEntityData.getListWithEntityId('BEGR')
                .filter(begr => !vlaktype || begr.PropertyDatas['BEGR_VLAK'].Value === vlaktype)
                .filter(begr => ntaEntityData.getParents(begr, 'RZ').length === 0);

            const begrenzingOrientaties = ntaMeasureData.getBegrenzingByUniqueBegrenzing(begrenzingen, begrenzingLogic);
            if (_listCache.isChanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual)) {
                //controleer de relaties tussen measure en begrenzingen
                const propBegrIds = ntaData.properties['MEASURE-RC_BEGR_IDS'];
                ntaMeasureData.checkBegrRelations(propBegrIds, _entdataMeasureRc, begrenzingOrientaties);
            }

            return _listCache.useCacheIfUnchanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual);
        } //-- end: getBegrenzingen ---------------------------------------------------------------

        async function toggleItemChecked(prop, entdata, item) {
            ntaMeasureData.toggleItemChecked(prop, entdata, item);
            validate(prop, prop.getData(entdata));
            //controleer of maatregel in variant zit en of deze keuze niet een conflict oplevert met andere maatregelen in deze variant
            // Zo ja, dan check terugdraaien
            const variantNames = conditionI_J(entdata);
            if (variantNames.length > 0) {
                await ntaMeldingen.warning('[W120]', undefined, [{ from: '{naam varianten}', to: variantNames.join(' / ') }]);
                ntaMeasureData.toggleItemChecked(prop, entdata, item);
                validate(prop, prop.getData(entdata));
                return;
            }

            const totalArea = calculateTotalArea();
            const propTotalArea = ntaData.properties['MEASURE-RC_A_TOT'];
            saveValue(propTotalArea, entdata, totalArea);
        } //-- end: toggleItemChecked -------------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

            if (!prop) {
                return false;
            }

            let result = ntaSharedLogic.saveValue(prop, entdata, newValue, self);

            const value = prop.getValue(entdata);
            switch (prop && prop.Id) {
                case 'MEASURE-RC_LIBCONSTR_ID': {
                    const libEntdata = ntaEntityData.get(value);
                    const libEntdataId = libEntdata && libEntdata.EntityDataId;

                    const existingRelations = ntaEntityData.getParentRelations(entdata, 'LIBCONSTRD');
                    const validRelation = existingRelations.find(rel => rel.Parent === libEntdataId);
                    const relationsToDelete = existingRelations.filter(rel => rel !== validRelation);
                    for (const relation of relationsToDelete) {
                        ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                        result = true;
                    }
                    if (!validRelation && libEntdataId) {
                        ntaEntityData.createRelation(libEntdataId, entdata.EntityDataId, false, false);
                        result = true;
                    }
                    break;
                }
                case 'MEASURE-RC_METHODE': {
                    // na opslag methode deze check uitvoeren of de maatregel in een variant zit en of deze keuze niet een conflict oplevert met andere maatregelen in deze variant
                    // dit kan niet in de dependency-validate omdat deze vaak van 'buiten' wordt aangeroepen voor het bepalen van de oppervlakte
                    const variantNames = conditionI_J(entdata, value);
                    if (variantNames.length > 0) {
                        if (value === 'MEASURE-RC_METHODE_LIB') {
                            // Keuze LIBCONSTR leeg maken
                            const libProp = ntaData.properties['MEASURE-RC_LIBCONSTR_ID'];
                            result = saveValue(libProp, entdata, null) || result;
                        } else { //'MEASURE-RC_METHODE_RC'
                            // alle begrenzingen uitvinken
                            const propBegrId = ntaData.properties['MEASURE-RC_BEGR_IDS'];
                            const begrenzingItems = getCodedValues(propBegrId);
                            for (const begrItem of begrenzingItems) {
                                if (ntaMeasureData.isItemChecked(propBegrId, entdata, begrItem)) {
                                    ntaMeasureData.toggleItemChecked(propBegrId, entdata, begrItem);
                                    result = true;
                                }
                            }
                        }
                    }
                    break;
                }
            }

            return result;
        } //-- end: saveValue ---------------------------------------------------------------------

        async function onValidateCallback(prop, entdata, valueToSave) {
            switch (prop && prop.Id || prop) {
                case 'MEASURE-RC_LIBCONSTR_ID': {
                    const oldValue = prop.getValue(entdata); // oude waarde bewaren
                    saveValue(prop, entdata, valueToSave); // nieuwe alvast opslaan voor getMatchingConstrEntdatas
                    const variantNames = conditionI_J(entdata, valueToSave);
                    if (variantNames.length > 0) {
                        await ntaMeldingen.warning('[W119]', undefined, [{ from: '{naam varianten}', to: variantNames.join(' / ') }]);
                        saveValue(prop, entdata, oldValue); // oude waarde terugzetten
                        return;
                    }
                    break;
                }
            }
        } //-- end: onValidateCallback ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = isReadOnly(prop) && prop.Id !== 'MEASURE-RC_A_TOT'; // Validatie moet alsnog wél gebeuren voor de totale oppervlakte, ondanks dat deze read-only is

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                case 'MEASURE-RC_A_TOT': {
                    const isValid = ntaSharedLogic.parseFloat(propdata.Value) > 0;

                    // melding heeft alleen zin als er nog geen andere melding is.
                    ntaSharedLogic.setMelding('[E113]', propdata, self.form, isValid || !valid, propdata.Touched);

                    valid = valid && isValid;
                    break;
                }
                case 'MEASURE-RC_KOSTEN_TOT_MIN_SUBSIDIE': {
                    calculateCosts();
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = true;

            switch (prop.Id) {
                case 'MEASURE-RC_METHODE':      // RC02
                case 'MEASURE-RC_LIBCONSTR_ID': // RC04
                case 'MEASURE-RC_BEGR_IDS':     // RC06
                case 'MEASURE-RC_RC_LT':        // RC07
                case 'MEASURE-RC_A_GT': {       // RC08
                    // Als één van deze velden gewijzigd is, dan moet de totale oppervlakte opnieuw berekend worden
                    const totalArea = calculateTotalArea();
                    saveValue('MEASURE-RC_A_TOT', entdata, totalArea);
                    break;
                }
                case 'MEASURE-RC_A_TOT':            // RC09
                case 'MEASURE-RC_KOSTEN_INV':       // RC13
                case 'MEASURE-RC_KOSTEN_TOT':       // RC15
                case 'MEASURE-RC_KOSTEN_PER_M2':    // RC14
                case 'MEASURE-RC_SUBS_INV':         // RC18
                case 'MEASURE-RC_SUBS_TOT':         // RC20
                case 'MEASURE-RC_SUBS_PER_M2': {    // RC19
                    // Als één van deze velden gewijzigd is, dan moeten de totale kosten en subsidie opnieuw berekend worden
                    calculateCosts();
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function calculateCosts() {
            let changed = false;

            const totCostProp = ntaData.properties["MEASURE-RC_KOSTEN_TOT"];
            const totSubsProp = ntaData.properties["MEASURE-RC_SUBS_TOT"];
            let totalCost = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureRc), 0);
            let totalSubs = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureRc), 0);
            if (conditionD()) { // [RC-D] RC13 = kostenkentallen per m²
                // indien RC13 = kostenkentallen per m²: RC14 x RC09
                const area = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_A_TOT'].Value); // RC09
                const costPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_KOSTEN_PER_M2'].Value); // RC14
                totalCost = area * costPerM2;
                changed = saveValue('MEASURE-RC_KOSTEN_TOT', _entdataMeasureRc, totalCost) || changed;
            }
            if (conditionK()) { // [RC-K] RC18 = subsidie per m²
                // indien RC18 = subsidie per m²: RC19 x RC09
                const area = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_A_TOT']?.Value); // RC09
                const subsPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureRc.PropertyDatas['MEASURE-RC_SUBS_PER_M2']?.Value); // RC19
                totalSubs = area * subsPerM2;
                changed = saveValue('MEASURE-RC_SUBS_TOT', _entdataMeasureRc, totalSubs) || changed;
            }
            /// en bereken de totale kosten door de subsidie van de investeringskosten af te trekken
            changed = saveValue('MEASURE-RC_KOSTEN_TOT_MIN_SUBSIDIE', _entdataMeasureRc, totalCost - totalSubs) || changed;

            /// en bereken tot slot de nieuwe variantkosten voor de varianten die gebruik maken van deze measure
            if (changed) {
                const variants = ntaEntityData.findEntities(_entdataMeasure, "VARIANT-MEASURE.^VARIANT", "VARIANT");
                self.dependencyValidator.calculateCostsVariants(variants);
            }
        } //-- end: calculateCosts ----------------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasureRc];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
