﻿angular.module('projectModule')
    .factory('ResultsTOjuliFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaEntityData', 'ntaResults', 'ntaSharedLogic', 'BuildingResultsFactory',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaEntityData,   ntaResults,   ntaSharedLogic,   BuildingResultsFactory) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn

    return function ResultsTOjuli(ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================

        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;


        //== Instance data ========================================================================

        const resultsLogic = new BuildingResultsFactory();

        const _validateProperties = new Set([
            'RESULT_TOJULI_TYPE_KOEL',
            'RESULT-TOJULI_KOELCAP',
            'RESULT_TOJULI_RISICO',
            'RESULT-TOJULI_AANW_AANV_BER',
            'RESULT-TOJULI_FORM_TEMPMETH',
            'RESULT-TOJULI_FORM_GTO',
        ]);

        const _resultMapsByShadowId = new Map();

        let _actieveBerekeningId;

        const _isVersionLe32 = ntabuilding.ntaVersionId < 300;
        const _geb = ntaEntityDataOrg.getFirstWithEntityId('GEB');
        const _rzform = ntaEntityDataOrg.getFirstWithEntityId('RZFORM');
        const _gebType = _geb.PropertyDatas["GEB_TYPEGEB"].Value;
        const _calcUnit = _rzform.PropertyDatas["RZFORM_CALCUNIT"].Relevant && _rzform.PropertyDatas["RZFORM_CALCUNIT"].Value;

        //== Exports ==============================================================================

        /// properties
        self.gtoProperties = ntaData.properties['RESULT-GTO'];
        self.lstrmProperties = ntaData.properties['RESULT-LSTRM'];
        self.tojuliProperties = ntaData.properties['RESULT-TOJULI'].slice() || []; // kopie maken. worden in bij init lege regels toegevoegd
        self.tojuliFormProperties = ntaData.properties['RESULT-TOJULI_FORM'] || [];

        self.berekeningen = resultsLogic.getBerekeningen();
        self.getResult = resultsLogic.getResult;
        self.getRisicoOververhitting = resultsLogic.getRisicoOververhitting;
        self.getForParents = ntaResults.getForParents;
        self.getIcon = resultsLogic.getIcon;

        self.isVersionLe32 = _isVersionLe32;

        self.TOjuliResultaten = () => currentResults().toJuliResultaten;
        self.GTOResultaten = () => currentResults().gtoResultaten;
        self.LSTRMResultaten = () => currentResults().lstrmResultaten;
        self.TOjuliForm = () => currentResults().toJuliForm;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            isGebouw,
            isAppPerGebouw,
            isAppPerAppartement,
            isBerekendPerApp_Unit,
            showToJuliTable,
            showGtoTable,
            showLstrmTable,
            conditionG,
            conditionS,
            conditionW,
            setActiveDataAndValidate,
            // standaard methods tbv validatie
            isHidden,
            getCodedValues,
            getListItem,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
        });


        //== Initialization =======================================================================
        ntaResults.checkResultTOjuliRelations();
        _actieveBerekeningId = self.berekeningen[0]?.Id;

        // De lege regels boven TO24, TO27 en TO28 tussenvoegen
        self.tojuliProperties.forEach(p => self.tojuliProperties[p.Id] = p);
        for (const legeRegel of ['RESULT-TOJULI_WEINIG_RAMEN', 'RESULT-TOJULI_AANW_AANV_BER', 'RESULT_TOJULI_RISICO']) {
            const index = self.tojuliProperties.findIndex(prop => prop && prop.Id === legeRegel);
            if (index > -1) {
                self.tojuliProperties.splice(index, 0, { Id: null, showId: legeRegel });
            }
        }


        //== Implementation =======================================================================

        function getBerekeningId(entdata) {
            // Deze functie geeft voor een entityData de Id van de berekening waar deze bij hoort
            //  (dus de Id van GEB of van een UNIT; overeenkomend met de rij tegels bovenaan het
            //  formulier).
            // Als er geen entdata is meegegeven, of er kan niet bepaald worden waar deze bij
            //  hoort, dan wordt de Id van de actieve berekening teruggegeven.
            let berekeningId;

            const unitOrRz = entdata && ntaEntityDataOrg.findEntity(entdata, '^UNIT-RZ.^UNIT', '^UNIT', '^RZ', '^GEB');
            if (unitOrRz?.EntityId === 'UNIT') {
                berekeningId = unitOrRz.EntityDataId;
            } else if (['RZ', 'GEB'].includes(unitOrRz?.EntityId)) {
                berekeningId = self.berekeningen.find(b => b.isGebouw)?.Id;
            }

            return berekeningId ?? _actieveBerekeningId;
        } //-- end: getBerekeningId ---------------------------------------------------------------

        function currentResults(berekeningId = _actieveBerekeningId, shadowId = ntaEntityData.getShadowId()) {
            /// Deze functie geeft de resultaten terug voor de opgegeven berekening en variant (dan wel basis).
            /// Als de gevraagde resultatenset nog niet bekend is, wordt deze aangemaakt en gecachet.
            let resultsByBerekeningId = _resultMapsByShadowId.get(shadowId);
            if (!resultsByBerekeningId) {
                resultsByBerekeningId = makeResultsByBerekeningId(shadowId);
                _resultMapsByShadowId.set(shadowId, resultsByBerekeningId);
            }
            return resultsByBerekeningId.get(berekeningId);
        } //-- end: currentResults ----------------------------------------------------------------

        function setActiveDataAndValidate(berekeningId) {
            _actieveBerekeningId = berekeningId;

            return validateBerekening();
        } //-- end: setActiveDataAndValidate ------------------------------------------------------

        function makeResultsByBerekeningId(shadowId = ntaEntityData.getShadowId()) {
            const resultsByBerekeningId = new Map();

            /// We moeten de resultatenset teruggeven van de huidige variant indien van toepassing.
            /// Maatregelen hebben geen eigen resultatenset; daarvoor geven we dan de
            ///  resultaatentiteiten van de basisberekening.
            const shadowEntdata = ntaEntityDataOrg.get(shadowId);
            const basisOrVariant = shadowEntdata?.EntityId === 'VARIANT'
                ? shadowEntdata
                : ntaEntityDataOrg.getFirstWithEntityId("BASIS");

            const { resultEntdatasByEntityId } = ntaResults.getForParents(null, basisOrVariant, false, ['RESULT-TOJULI', 'RESULT-GTO', 'RESULT-LSTRM']);
            const allToJuliResultaten = resultEntdatasByEntityId.get('RESULT-TOJULI') || [];
            const allGtoResultaten = resultEntdatasByEntityId.get('RESULT-GTO') || [];
            const allLstrmResultaten = resultEntdatasByEntityId.get('RESULT-LSTRM') || [];

            /// maak dictionaries per berekening met tojuli entiteiten voor RESULT-TOJULI, RESULT-TOJULI_FORM, RESULT-TOJULI_GTO en RESULT-LSTRM
            for (const berekening of self.berekeningen) {
                /// check of Id gebouw of unit is
                /// Indien gebouw haal rzs (excl. AOR/AOS) op en daarna de TOjulis/GTOs
                /// Indien unit haal unitrzs op en daarna de TOjulis/GTOs
                const gebOrUnit = ntaEntityDataOrg.get(berekening.Id);
                const rekenzoneIds = gebOrUnit.EntityId === "GEB"
                    ? ntaEntityDataOrg.getListWithEntityId('RZ').filter(rz => rz.PropertyDatas["RZ_TYPEZ"].Value === "RZ").map(rz => rz.EntityDataId)
                    : ntaEntityDataOrg.getChildIds(gebOrUnit, "UNIT-RZ");
                const orderByUnitRz = gebOrUnit.EntityId === "GEB"
                    ? orderByUnitRz4Gebouw
                    : orderByUnitRz4Unit;

                const results = {
                    toJuliResultaten: [],
                    gtoResultaten: [],
                    lstrmResultaten: [],
                    toJuliForm: null,
                };
                results.toJuliResultaten = allToJuliResultaten
                    .filter(ed => rekenzoneIds.intersection(ntaEntityDataOrg.getParentIds(ed)).length)
                    .sort(orderByUnitRz);
                results.gtoResultaten = allGtoResultaten
                    .filter(ed => rekenzoneIds.intersection(ntaEntityDataOrg.getParentIds(ed)).length)
                    .sort(orderByUnitRz);
                results.lstrmResultaten = allLstrmResultaten
                    .filter(ed => rekenzoneIds.intersection(ntaEntityDataOrg.getParentIds(ed)).length)
                    .sort(orderByUnitRz);

                const { resultEntdatasByEntityId } = ntaResults.getForParents(gebOrUnit, basisOrVariant, false, ['RESULT-TOJULI_FORM']);
                results.toJuliForm = (resultEntdatasByEntityId.get('RESULT-TOJULI_FORM') || [])[0];

                resultsByBerekeningId.set(berekening.Id, results);
            }

            return resultsByBerekeningId;
        } //-- end: makeResultsByBerekeningId -----------------------------------------------------

        function orderByUnitRz4Gebouw(a, b) {
            const unitRzA = ntaEntityDataOrg.findEntity(a, '^RZ.UNIT-RZ', '^UNIT-RZ');
            const unitRzB = ntaEntityDataOrg.findEntity(b, '^RZ.UNIT-RZ', '^UNIT-RZ');
            if (unitRzA && unitRzB) {
                return unitRzA.Order - unitRzB.Order;
            } else {
                return a.Order - b.Order;
            }
        } //-- end: orderByUnitRz4Gebouw ----------------------------------------------------------

        function orderByUnitRz4Unit(a, b) {
            const unitRzA = ntaEntityDataOrg.getFirstParent(a, 'UNIT-RZ');
            const unitRzB = ntaEntityDataOrg.getFirstParent(b, 'UNIT-RZ');
            if (unitRzA && unitRzB) {
                return unitRzA.Order - unitRzB.Order;
            } else {
                return a.Order - b.Order;
            }
        } //-- end: orderByUnitRz4Unit ------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

            /// Resultaatentiteiten moeten altijd opgeslagen worden in de basisberekening, dus geven we ntaEntityDataOrg mee.
            ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function getCodedValues(prop, entdata) {
            let typesList = prop && prop.Domain && prop.Domain.Codes || [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'RESULT-TOJULI_AANW_AANV_BER': {
                    //condition [T]
                    if (isAppPerGebouw()) {
                        typesList = typesList.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER2');
                    }
                    //condition [M]
                    if (!ntaSharedLogic.isUtiliteit() &&
                        (!ntaSharedLogic.isNieuwbouw() || (entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL'].Value !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE' ||
                                                           entdata.PropertyDatas['RESULT-TOJULI_KOELCAP'].Value !== 'ja'))) {
                        typesList = typesList.filter(x => x.Id !== 'RESULT-TOJULI_AANW_AANV_BER3' && x.Id !== 'RESULT-TOJULI_AANW_AANV_BER4');
                    }
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, self);

            return typesList;
        } //-- end: getCodedValues ----------------------------------------------------------------

        function isHidden(prop, entdata = null) {
            /// als entdata null dan komt het vanuit het formulier en gaat het om het weergeven van de rij
            /// in de tabel
            if (typeof prop === 'string') prop = ntaData.properties[prop];

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop && prop.Id) {
                case 'RESULT-TOJULI_FORM_GTO':
                    visible = conditionS(getBerekeningId(entdata));
                    break;
                case 'RESULT-TOJULI_FORM_TEMPMETH':
                    visible = conditionL();
                    break;
                case 'RESULT-TOJULI_NOORD':         // TO04
                case 'RESULT-TOJULI_NOORD_OOST':    // TO05
                case 'RESULT-TOJULI_OOST':          // TO06
                case 'RESULT-TOJULI_ZUID_OOST':     // TO07
                case 'RESULT-TOJULI_ZUID':          // TO08
                case 'RESULT-TOJULI_ZUID_WEST':     // TO09
                case 'RESULT-TOJULI_WEST':          // TO10
                case 'RESULT-TOJULI_NOORD_WEST': {  // TO11
                    /// conditie [C]:
                    /// toon alleen de orientaties die voorkomen in het gebouw of de unit (als een oriëntatie in geen enkele rekenzone van het gebouw of de unit voorkomt de rij verbergen)
                    const berekeningId = getBerekeningId(entdata);
                    visible = currentResults(berekeningId).toJuliResultaten.some(result => prop.getValue(result))
                        && !conditionH(berekeningId);
                    break;
                }
                case 'RESULT-TOJULI_MAX': {         // TO12
                    visible = !conditionH(getBerekeningId(entdata));
                    break;
                }
                case 'RESULT-TOJULI_RAAMFACTOR':
                    /// deze property nooit laten zien. Hij wordt getoond in combinatie met TO24
                    visible = false;
                    break;
                case 'RESULT-TOJULI_WEINIG_RAMEN':  // TO24
                case 'RESULT-TOJULI_BEP_ZON': {     // TO25
                    //-- conditie [I] toon alleen in gt3-2
                    visible = ntabuilding.ntaVersionId >= 300 && !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw();
                    if (prop.Id === 'RESULT-TOJULI_WEINIG_RAMEN') {
                        prop.Name = 'weinig ramen';
                    }
                    break;
                }
                case 'RESULT-TOJULI_KOELCAP': {     // TO26
                    //-- conditie [J] toon alleen in gt3-2 EN berekening is WN EN als bij minimaal 1 rekenzone is sprake van een actief koelsysteem
                    const propKoeling = self.tojuliProperties["RESULT_TOJULI_TYPE_KOEL"];
                    visible = ntabuilding.ntaVersionId >= 300
                        && !ntaSharedLogic.isUtiliteit()
                        && ntaSharedLogic.isNieuwbouw()
                        && !!propKoeling
                        && currentResults(getBerekeningId(entdata))
                            .toJuliResultaten.some(ed => propKoeling.getValue(ed) === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE');
                    break;
                }
                case 'RESULT-TOJULI_AANW_AANV_BER': {  // TO27
                    visible = ntabuilding.ntaVersionId >= 300; //condition [V]
                    break;
                }
                case 'RESULT_TOJULI_RISICO': {  // TO28 -> De underscore is expres ivm de BuildingSummary
                    visible = !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw() && ntabuilding.ntaVersionId >= 300; //condition [N] en [V]
                    break;
                }
                case 'RESULT_TOJULI_TYPE_KOEL': { // specifiek voor opslag actieve koeling. De underscore is expres ivm de BuildingSummary
                    visible = false;
                    break;
                }
                case null: {                     // t.b.v. de lege rijen boven TO24, TO27 en TO28
                    switch (prop.showId) {
                        case 'RESULT-TOJULI_RAAMFACTOR':
                            /// deze property nooit laten zien. Hij wordt getoond in combinatie met TO24
                            visible = false;
                            break;
                        case 'RESULT-TOJULI_WEINIG_RAMEN': {
                            visible = !isHidden('RESULT-TOJULI_MAX', entdata)
                                && !isHidden('RESULT-TOJULI_WEINIG_RAMEN', entdata); // condition [H]
                            break;
                        }
                        case 'RESULT-TOJULI_AANW_AANV_BER':
                        case 'RESULT_TOJULI_RISICO': {
                            visible = ntabuilding.ntaVersionId >= 300; // condition [V]
                            break;
                        }
                        default: {
                            visible = true;
                            break;
                        }
                    }
                    break;
                }
                default: {
                    $log.warn(`Unexpected prop.Id in ResultsTOjuliFactory.isHidden:`, prop);
                    break;
                }

            }

            if (prop && entdata) {
                /// als er wel een entdata is, kan ik de relevantie zetten.
                const propdata = entdata.PropertyDatas[prop.Id];
                if (propdata) {
                    if (relevant === null) relevant = visible;
                    ntaEntityDataOrg.setPropdataStatus(propdata, relevant, visible);
                }
            }

            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function showToJuliTable() {
            /// conditie [A] verberg risico op oververhitting tabel indien:
            ///    - le3.2 EN TO01 ≠ TOjuli conform NTA 8800
            ///    - le3.2 EN G04 = appartementengebouw en Z23 = per gebouw
            ///    - ge3-3 EN G04 = appartementengebouw en Z23 = per gebouw EN bestaande bouw
            ///    - G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening (eerste tegel)

            var sitA = _isVersionLe32 && currentResults().toJuliForm?.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"].Value !== "TEMPMETH_TOJULI";
            var sitB = _isVersionLe32 && isAppPerGebouw();
            var sitC = !_isVersionLe32 && isAppPerGebouw() && !ntaSharedLogic.isNieuwbouw() ;
            var sitD = isAppPerAppartement() && isGebouw();
            var hide = sitA || sitB || sitC || sitD;

            return !hide;
        } //-- end: showToJuliTable ---------------------------------------------------------------

        function showGtoTable() {
            /// [E] toon deze tabel indien:
            ///  - TO01 wordt getoond EN = GTO berekening conform Regeling Bouwbesluit <-- conditie [S]
            ///  - TO27 = 'GTO berekening volgens omgevingsregeling en GTO ≤ 450' bij minimaal 1 rekenzone
            return conditionS()
                || currentResults().toJuliResultaten
                    .map(ed => ed.PropertyDatas["RESULT-TOJULI_AANW_AANV_BER"]) // TO27
                    .some(pd => pd?.Relevant && pd.Value === "RESULT-TOJULI_AANW_AANV_BER2"); // GTO berekening volgens Omgevingsregeling en GTO ≤ 450;
        } //-- end: showGtoTable ------------------------------------------------------------------

        function showLstrmTable() {
            /// [V] toon alleen als versie ge 3.3
            /// [Y] toon deze tabel indien:
            ///  - TO27 = 'koelcapaciteit volgens NTA 8800 bijlage AA' bij minimaal 1 rekenzone
            return !_isVersionLe32
                && currentResults().toJuliResultaten
                    .map(ed => ed.PropertyDatas["RESULT-TOJULI_AANW_AANV_BER"]) // TO27
                    .some(pd => pd?.Relevant && pd.Value === "RESULT-TOJULI_AANW_AANV_BER3"); // koelcapaciteit volgens NTA 8800 bijlage AA;
        } //-- end: showLstrmTable ------------------------------------------------------------------

        function conditionH(berekeningId = _actieveBerekeningId) {
            ///  conditie [H]:
            ///  verberg rij indien G04 = appartementengebouw en Z23 = per gebouw;
            ///  verberg rij indien G04 = appartementengebouw en Z23 = per gebouw en per appartement bij de 'gebouw' berekening(eerste tegel)
            return isAppPerGebouw() || (isAppPerAppartement() && isGebouw(berekeningId));
        }

        function conditionS(berekeningId = _actieveBerekeningId) {
            /// condite [S] toon alleen als TO01 wordt getoond EN TO01 = GTO berekening conform Regeling Bouwbesluit
            return _isVersionLe32
                && currentResults(berekeningId).toJuliForm?.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"].Value === "TEMPMETH_GTO";
        } //-- end: conditionS --------------------------------------------------------------------

        function conditionL(){
            // conditie [L] tonen indien le3-2
            return !ntaSharedLogic.isUtiliteit() && _isVersionLe32;
        } //-- end: conditionL --------------------------------------------------------------------

        function conditionG(berekeningId = _actieveBerekeningId) {
            /// conditie [G] indien le3-2 als G04='appartementengebouw' en Z23='per gebouw' OF
            /// als G04 = 'appartementengebouw' en Z23 = 'per gebouw en appartement' en het betreft de gebouwberekening(eerste tegel),
            /// dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen(...)
            const result = _isVersionLe32
                && (isAppPerGebouw() || isAppPerAppartement() && isGebouw(berekeningId));

            return result;
        } //-- end: conditionG --------------------------------------------------------------------

        function conditionW(berekeningId = _actieveBerekeningId) {
            ///  conditie [W] indien ge3-3:
            ///   - als G04='appartementengebouw' EN  Z23='per gebouw en appartement' en het betreft de gebouwberekening (eerste tegel)
            ///   - als WB EN G04 = 'appartementengebouw' EN  Z23='per gebouw'
            ///  dan alle onderstaande velden op dit formulier verbergen en de volgende tekst tonen (...)
            const result = !_isVersionLe32
                && (isAppPerAppartement() && isGebouw(berekeningId)
                || !ntaSharedLogic.isUtiliteit() && !ntaSharedLogic.isNieuwbouw() && isAppPerGebouw());

            return result;
        } //-- end: conditionW --------------------------------------------------------------------

        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                let itemId = prop.getValue(entdata);
                switch (prop.Id) {

                }
                if (!list) {
                    //-- anders ongefilterde codedValue list gebruiken
                    list = prop.Domain.Codes;
                }
                if (list && list.length > 0) {
                    listItem = list.find(item => item.Id === itemId);
                }
            }

            return listItem;
        } //-- end: getListItem -------------------------------------------------------------------

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !entdata || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }
            const hidden = isHidden(prop, entdata);
            let valid = ntaValidation.IsValid(self.form_tojuli, prop, propdata, hidden);

            switch (prop.Id) {
                // hier evt. property-specifieke validaties opnemen
                case 'RESULT-TOJULI_AANW_AANV_BER': {
                    /// in de getCodedValues wordt voor deze property een lijst samengesteld en gecontroleerd
                    /// of je juiste propdata nog klopt. Door het aanroepen van deze getCodedValue wordt
                    /// deze property gevalideerd.
                    getCodedValues(prop, entdata);
                    break;
                }
                case 'RESULT_TOJULI_TYPE_KOEL': { // specifiek voor opslag actieve koeling. De underscore is expres ivm de BuildingSummary
                    const variant = ntaEntityDataOrg.getFirstParent(entdata, 'VARIANT');
                    const variantId = variant?.EntityDataId ?? null;
                    if (variantId === ntaEntityData.getShadowId()) {
                        let typeKoel = 'RESULT-TOJULI_TYPE_KOEL_NONE';

                        // Gebruik hier ntaEntityData (en niet ntaEntityDataOrg) omdat we bij een variant de koelsystemen van die variant willen beschouwen (niet die van de basisberekening).
                        const koelopwekkers = ntaEntityData.findEntities(entdata, '^RZ.^KOEL.KOEL-OPWEK', '^UNIT-RZ.^RZ.^KOEL.KOEL-OPWEK');
                        if (koelopwekkers.length > 0) {
                            /// (er is sprake van actieve koeling indien bij een koelsysteem KO05 ≠ dauwpuntskoeling / booster)
                            const actieveKoeling = koelopwekkers.some(opwek => !resultsLogic.opwekkertypesPassieveKoeling.has(opwek.PropertyDatas['KOEL-OPWEK_TYPE'].Value)); // KO05
                            typeKoel = actieveKoeling ? 'RESULT-TOJULI_TYPE_KOEL_ACTIVE' : 'RESULT-TOJULI_TYPE_KOEL_PASSIVE';
                        }

                        saveValue(prop, entdata, typeKoel);
                    }
                    break;
                }
                case 'RESULT-TOJULI_KOELCAP': {
                    /// toon 'n.v.t.' indien geen (actieve) koeling in de rekenzone aanwezig;
                    let koelCapValue = 'n.v.t';

                    /// conditie [K]
                    const typeKoel = entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL'].Value;
                    if (typeKoel === 'RESULT-TOJULI_TYPE_KOEL_ACTIVE') {
                        /// toon 'ja' in alle overige gevallen
                        koelCapValue = 'ja';

                        /// toon 'nee' indien:
                        /// - TO12 ≤ 1,20 (overslaan indien niet aanwezig) OF
                        const propdataToJuliMax = entdata.PropertyDatas['RESULT-TOJULI_MAX']; // TO12
                        const tojuliMax = isHidden(propdataToJuliMax.PropertyId, entdata)
                            ? null
                            : ntaSharedLogic.parseFloat(propdataToJuliMax.Value, null); /// als waarde leeg of null is, is tojuliMax niet berekend
                        if (tojuliMax !== null && tojuliMax <= 1.20) {
                            koelCapValue = 'nee';
                        } else {

                            /// - er sprake is van actieve koeling EN TO24 = JA OF
                            /// - er sprake is van actieve koeling EN TO25 = JA;

                            var weinigRamen = entdata.PropertyDatas['RESULT-TOJULI_WEINIG_RAMEN'].Value === 'true'; // TO24
                            var beperktZon = entdata.PropertyDatas['RESULT-TOJULI_BEP_ZON'].Value === 'true';       // TO25

                            if (weinigRamen || beperktZon) {
                                koelCapValue = "nee";
                            }
                        }
                    }

                    saveValue(prop, entdata, koelCapValue);

                    break;
                }
                case 'RESULT_TOJULI_RISICO': { // TO28; de underscore is expres ivm de BuildingSummary
                    let risico;
                    const texts = resultsLogic.textRisicoOververhitting;

                    const typeKoeling = entdata.PropertyDatas['RESULT_TOJULI_TYPE_KOEL']?.Value;

                    const propdataToJuliMax = entdata.PropertyDatas['RESULT-TOJULI_MAX']; // TO12
                    const tojuliMax = isHidden(propdataToJuliMax.PropertyId, entdata)
                        ? null
                        : ntaSharedLogic.parseFloat(propdataToJuliMax.Value, null); /// als waarde leeg of null is, is tojuliMax niet berekend

                    const isVariant = ntaEntityDataOrg.getFirstParent(entdata, 'VARIANT');
                    if (!isVariant) {
                        //condition [P]
                        const propdataKoelcap = entdata.PropertyDatas['RESULT-TOJULI_KOELCAP']; // TO26
                        const koelcapAantonen = propdataKoelcap?.Relevant && propdataKoelcap.Value === 'ja';
                        const koelcapNietAantonen = propdataKoelcap?.Relevant && propdataKoelcap.Value === 'nee'; /// kan namelijk ook n.v.t. zijn
                        const aanvAanwBerekening = entdata.PropertyDatas['RESULT-TOJULI_AANW_AANV_BER']?.Value; // TO27

                        if (isAppPerGebouw() && (
                            (koelcapAantonen && aanvAanwBerekening === 'RESULT-TOJULI_AANW_AANV_BER1') ||
                            (typeKoeling !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE'))) {
                            risico = texts.maakBerekeningPerAppartement;
                        } else if ((tojuliMax !== null && tojuliMax <= 1.2) || koelcapNietAantonen || aanvAanwBerekening !== 'RESULT-TOJULI_AANW_AANV_BER1') {
                            risico = texts.voldoet;
                        } else {
                            risico = texts.voldoetNiet;
                        }
                    } else {
                        // [RO-BE] neem bij de basisberekening de waarde uit TO28 over en bij de varianten het volgende:
                        // * als TOjuli voldoet (en RO19 zichtbaar) aan de eis dan 'voldoet' met een vinkje
                        // * als TOjuli niet voldoet (en RO19 zichtbaar) aan de eis en geen actieve koeling in de variant dan 'voldoet niet' met een kruisje
                        // * als TOjuli niet voldoet (en RO19 zichtbaar) aan de eis en actieve koeling aanwezig dan 'n.t.b' en beoordeling zonder kruisje of vinkje

                        if (tojuliMax !== null) {
                            if (tojuliMax <= 1.2) {
                                risico = texts.voldoet;
                            } else if (typeKoeling !== 'RESULT-TOJULI_TYPE_KOEL_ACTIVE') {
                                risico = texts.voldoetNiet;
                            } else {
                                risico = texts.ntb;
                            }
                        } else {
                            risico = texts.ntb;
                        }
                    }
                    saveValue(prop, entdata, risico);
                    break;
                }
            }

            return valid;
        } //-- end: validate ----------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                /// hier evt. property-specifieke validaties opnemen waardoor andere invoervelden die horen bij
                /// deze logic aangepast zouden moeten worden
                case "RESULT-TOJULI_AANW_AANV_BER": {
                    /// als deze waarde wijzigt, kan dat effect hebben op ander RESULT-TOJULI properties.
                    validateBerekening(getBerekeningId(entdata));
                    break;
                }
                case "RESULT-TOJULI_WEINIG_RAMEN":
                case "RESULT-TOJULI_BEP_ZON":
                case "RESULT-TOJULI_MAX": {
                    /// Als deze waarde gewijzigd is, dan moeten we de andere properties in dezelfde entiteit valideren.
                    const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                    for (const pd of propdatasToValidate) {
                        self.validate(ntaData.properties[pd.PropertyId], pd, entdata);
                    }
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop, entdata);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            const invalidPropdatas = new Set();
            /// voor het TOjuli resultaten formulier kan ik geen gebruik maken van de startFormValidation van
            /// sharedLogic, omdat ik wel alle entiteiten van het gebouw moet valideren, maar ze niet altijd
            /// zichtbaar zijn omdat ik resultaten per unit laat zien. Ik kan dus niet uitgaan van de standaard
            /// isHidden.

            if (ntabuilding.canSave()) {
                /// ik moet per berekening de validaties af
                const oldBerekeningId = _actieveBerekeningId;
                try {
                    for (const berekening of self.berekeningen) {
                        _actieveBerekeningId = berekening.Id;
                        const wrongPropdatas = validateBerekening(berekening.Id);
                        for (const propdata of wrongPropdatas) {
                            invalidPropdatas.add(propdata);
                        }
                    }
                } finally {
                    _actieveBerekeningId = oldBerekeningId;
                }
            }

            return [...invalidPropdatas];
        } //-- end: startFormValidation -----------------------------------------------------------

        function validateBerekening(berekeningId = _actieveBerekeningId) {
            const invalidPropdatas = [];

            const entdatas = getAllEntDatasForBerekening(berekeningId);
            for (const entdata of entdatas) {
                const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                for (const propdata of propdatasToValidate) {
                    try {
                        const isValid = validate(ntaData.properties[propdata.PropertyId], propdata, entdata);
                        if (!isValid) {
                            invalidPropdatas.push(propdata);
                        }
                    } catch (err) {
                        $log.warn('while validating ', propdata, ':', err.message, err);
                    }
                }
            }

            return invalidPropdatas;
        }   //-- end: validateBerekening ----------------------------------------------------------

        function endFormValidation() {
            /// voor het TOjuli resultaten formulier kan ik geen gebruik maken van de startFormValidation van
            /// sharedLogic, omdat ik wel alle entiteiten van het gebouw moet valideren, maar ze niet altijd
            /// zichtbaar zijn omdat ik resultaten per unit laat zien. Ik kan dus niet uitgaan van de standaard
            /// isHidden.

            const entdatas = getAllEntDatas();

            for (const entdata of entdatas) {
                const propdatasToValidate = entdata.PropertyDatas.filter(pd => _validateProperties.has(pd.PropertyId));
                for (const propdata of propdatasToValidate) {
                    if (!propdata.Touched) {
                        propdata.Touched = true;
                        ntaEntityDataOrg.saveprop(propdata);
                    }
                }
            }
            return startFormValidation();
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatasForBerekening(berekeningId = _actieveBerekeningId) {
            const shadowId = ntaEntityData.getShadowId();

            /// We gaan geen validaties afvuren voor de maatregelen; die hebben geen eigen resultatenset.
            const shadowEntdata = ntaEntityDataOrg.get(shadowId);
            if (shadowEntdata?.EntityId === 'MEASURE')
                return [];

            const results = currentResults(berekeningId, shadowId);
            return []
                .concat(results.gtoResultaten)
                .concat(results.lstrmResultaten)
                .concat(results.toJuliResultaten)
                .concat(results.toJuliForm)
                .filter(ed => ed);
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function getAllEntDatas() {
            const entdatas = self.berekeningen.flatMap(b => getAllEntDatasForBerekening(b.Id));
            return [...new Set(entdatas)]; // dedupliceren
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function isGebouw(id = _actieveBerekeningId) {
            const berekening = self.berekeningen.find(b => b.Id === id);
            return resultsLogic.isGebouw(berekening ?? id);
        } //-- end: isGebouw ----------------------------------------------------------------------

        function isAppPerGebouw() {
            return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEB";
        } //-- end: isAppPerGebouw ----------------------------------------------------------------

        function isAppPerAppartement() {
            return _gebType === "TGEB_APPGEB" && _calcUnit === "RZUNIT_GEBAPP";
        } //-- end: isAppPerAppartement -----------------------------------------------------------

        function isBerekendPerApp_Unit() {
            return _calcUnit === "RZUNIT_GEBAPP" || _calcUnit === "RZUNIT_GEBUNIT";
        } //-- end: isBerekendPerApp_Unit ---------------------------------------------------------
    };
}]);
