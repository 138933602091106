﻿angular.module('projectModule')
    .factory('MeasureZonweringFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ntaRounding', 'ListCache', 'ntaDeltas', 'ntaMeasureData', 'BuildingBegrenzingFactory', 'ntaMeldingen',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   ntaRounding,   ListCache,   ntaDeltas,   ntaMeasureData,   BuildingBegrenzingFactory,   ntaMeldingen) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn

    const _begrenzingPropId = {
        VLAK_VLOER: 'BEGR_VLOER',
        VLAK_GEVEL: 'BEGR_GEVEL',
        VLAK_DAK: 'BEGR_DAK',
        VLAK_KELDERW: 'BEGR_KWAND',
    };

    return function MeasureZonweringLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        {
            // We maken het zo dat je zowel de measureId als de measureZonwId kunt meegeven:
            //  vanuit de controller wordt de EntityDataId van de MEASURE meegegeven, maar
            //  in ntaDependencyValidation is vaak juist alleen de EntityDataId van de MEASURE-ZONW
            //  bekend, dus accepteren we die ook.
            const measure = ntaEntityData.get(measureId);
            if (measure.EntityId === 'MEASURE-ZONW') {
                measureId = ntaEntityData.getFirstParent(measure, 'MEASURE').EntityDataId;
            }
        }
        const _entdataMeasure = ntaEntityData.get(measureId);
        const _entdataMeasureZonw = ntaEntityData.getFirstChild(_entdataMeasure, 'MEASURE-ZONW');
        const _listCache = new ListCache();
        const _isVersionGe33 = ntabuilding.ntaVersionId >= 300;

        const begrenzingLogic = new BuildingBegrenzingFactory(0, null);

        //== Exports ==============================================================================
        self.entdataMeasureZonw = _entdataMeasureZonw;
        self.properties = ntaData.properties[_entdataMeasureZonw.EntityId];
        self.isItemChecked = ntaMeasureData.isItemChecked;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods
            getTileDetails,
            getAlleKosten,
            generateDeltas,
            getMatchingConstrEntdatas,
            toggleItemChecked,

            // standaard methods tbv validatie
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            const orientations = ntaEntityData.getParents(_entdataMeasureZonw, 'BEGR')
                .map(begr => getBegrOrientation(begr))
                .distinct()
                .join(', ');

            lines.push({ name: 'type zonwering', value: render.value('MEASURE-ZONW_TYPE') });
            lines.push({ name: 'oriëntaties', value: orientations });
            lines.push({ name: 'totale oppervlakte', value: render.value('MEASURE-ZONW_A_TOT') });
            if (ntaSharedLogic.showCosts()) {
                lines.push({});
                lines.push(render.detail('MEASURE-ZONW_KOSTEN_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-ZONW_SUBS_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-ZONW_KOSTEN_TOT_MIN_SUBSIDIE'));
            }
            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getAlleKosten() {
            const totCostProp = ntaData.properties["MEASURE-ZONW_KOSTEN_TOT"];
            const investering = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureZonw), 0);

            const totSubsProp = ntaData.properties["MEASURE-ZONW_SUBS_TOT"];
            const subsidie = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureZonw), 0);

            const totMinSubsProp = ntaData.properties["MEASURE-ZONW_KOSTEN_TOT_MIN_SUBSIDIE"];
            const totaal = ntaSharedLogic.parseFloat(totMinSubsProp?.getValue(_entdataMeasureZonw), 0);

            return {
                investering,
                subsidie,
                totaal,
            };
        } //-- end: getAlleKosten ----------------------------------------------------------------

        function generateDeltas(shadowId) {
            const deltas = [];
            const constructies = getMatchingConstrEntdatas();

            const zonwDeltaProps = [
                { measure: 'MEASURE-ZONW_TYPE', constrT: 'CONSTRT_ZONW' },
                { measure: 'MEASURE-ZONW_REGELING', constrT: 'CONSTRT_REGEL' },
                { measure: 'MEASURE-ZONW_GGL_ALT', constrT: 'CONSTRT_GGL_ALT' },
                { measure: 'MEASURE-ZONW_GGL_DIF', constrT: 'CONSTRT_GGL_DIF' },
            ];

            for (const zonwDeltaProp of zonwDeltaProps) {
                const propMeasure = ntaData.properties[zonwDeltaProp.measure];
                const propdataMeasureZonw = propMeasure.getData(_entdataMeasureZonw);
                const propConstrT = ntaData.properties[zonwDeltaProp.constrT];

                for (const constructie of constructies) {
                    const propdataConstrT = propConstrT.getData(constructie);
                    const delta = new ntaDeltas.DeltaPropertyData(shadowId, propdataConstrT.PropertyDataId, propdataConstrT, 'Replace');
                    delta.Relevant = propdataMeasureZonw.Relevant;
                    delta.Value = propdataMeasureZonw.Value;
                    deltas.push(delta);
                }
            }
            return deltas;
        } //-- end: generateDeltas ----------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'MEASURE-ZONW_A_TOT':
                    return true;
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataMeasureZonw) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return true;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (!propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                case 'MEASURE-ZONW_KOSTEN_TOT_MIN_SUBSIDIE': {
                    visible = false;
                    break;
                }
                case 'MEASURE-ZONW_BESTAAND': { // ZON06
                    visible = conditionE();
                    break;
                }
                case 'MEASURE-ZONW_KOSTEN_INV':       // ZON11
                case 'MEASURE-ZONW_KOSTEN_TOT': {     // ZON12
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-ZONW_KOSTEN_PER_M2': {  // ZON13
                    visible = conditionC() && conditionD();
                    break;
                }
                case 'MEASURE-ZONW_SUBS_INV':       // ZON16
                case 'MEASURE-ZONW_SUBS_TOT': {     // ZON18
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-ZONW_SUBS_PER_M2': {  // ZON17
                    visible = conditionC() && conditionK();
                    break;
                }
                case 'MEASURE-ZONW_REGELING': {      // ZON15
                    visible = conditionG();
                    break;
                }
                case 'MEASURE-ZONW_GGL_ALT':        // ZON16
                case 'MEASURE-ZONW_GGL_DIF': {      // ZON17
                    visible = conditionI();
                    break;
                }
            }

            if (relevant === null) relevant = visible;
            ntaEntityData.setPropdataStatus(propdata, relevant, visible);
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionC() {
            // [ZON-C] toon indien bij instellingen gekozen voor 'maatwerk advies'
            return ntaSharedLogic.showCosts();
        } //-- end: conditionC --------------------------------------------------------------------

        function conditionD() {
            // [ZON-D] toon indien ZON11 = kostenkentallen per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_KOSTEN_INV'].Value === 'MEASURE_KOSTEN_PER_M2';
        } //-- end: conditionD --------------------------------------------------------------------

        function conditionE() {
            // [ZON-E] verberg indien op de formulieren 'constructies' in de basisberekening bij alle rijen bij veld C15 (zonwering) is gekozen voor 'geen zonwering'
            const visible = !ntaData.original.getParents(_entdataMeasureZonw, 'BEGR')
                .flatMap(begr => ntaData.original.getChildren(begr, 'CONSTRT'))
                .map(constrT => constrT.PropertyDatas['CONSTRT_ZONW'])
                .every(propdataZonw => !propdataZonw.Relevant || propdataZonw.Value === 'ZONW_GEEN');
            return visible;
        } //-- end: conditionE --------------------------------------------------------------------

        function conditionG() {
            // [ZON-G] hide if
            // - the selected zonwering in ZON09(MEASURE - ZONW_TYPE) has key = geen or vast(ZONW_GEEN, ZONW_VAST) OF
            // - versie le3.2 en WN/WB
            const propZonwType = ntaData.properties['MEASURE-ZONW_TYPE'];
            const zonwTypeValue = propZonwType.getValue(_entdataMeasureZonw);

            const hide = zonwTypeValue === 'ZONW_GEEN' ||
                         zonwTypeValue === 'ZONW_VAST' ||
                         (ntabuilding.ntaVersionId < 300 && !ntaSharedLogic.isUtiliteit());
            return !hide;
        } //-- end: conditionG --------------------------------------------------------------------

        function conditionH() {
            // [ZON-H] verberg keuze indien in ZON09 (MEASURE-ZONW_TYPE) is gekozen voor een zonwering waarbij in table7-5-6 kolom key=auto (ZONW_GMW)
            const propZonwType = ntaData.properties['MEASURE-ZONW_TYPE'];
            const zonwTypeValue = propZonwType.getValue(_entdataMeasureZonw);
            return zonwTypeValue === 'ZONW_GMW';
        } //-- end: conditionH --------------------------------------------------------------------

        function conditionI() {
            // [ZON-I] toon als in veld ZON15 (MEASURE-ZONW_REGELING) table7-5-6 key=vast
            const propZonwType = ntaData.properties['MEASURE-ZONW_TYPE'];
            const zonwTypeValue = propZonwType.getValue(_entdataMeasureZonw);
            return zonwTypeValue === 'ZONW_VAST';
        } //-- end: conditionI --------------------------------------------------------------------

        function conditionJ(entdata, valueToSave) {
            //[ZON-J] Indien een checkbox gewijzigd wordt controleer of de maatregel gebruikt wordt in één of meerdere varianten.
            //Controleer of bij deze varianten de wijzigingen van zonwering niet dezelfde constructie betreffen.
            //Indien maatregelen dezelfde constructie  betreffen geef dan melding[W121] en zet de checkbox terug op de oorspronkelijke keuze.

            const measure = ntaEntityData.getFirstParent(entdata, "MEASURE");
            const propMeasureType = ntaData.properties['MEASURE_TYPE'];
            const typeValue = propMeasureType.getValue(measure);

            const variants = ntaEntityData.findEntities(measure, 'VARIANT-MEASURE.^VARIANT', 'VARIANT');
            const propVariantName = ntaData.properties['VARIANT_NAAM'];
            const variantNames = [];
            for (const variant of variants) {
                const measures = ntaEntityData.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE').filter(x => propMeasureType.getValue(x) === typeValue);
                if (ntaSharedLogic.hasConstructionOverlap(measure, measures, valueToSave)) {
                    variantNames.push(propVariantName.getValue(variant));
                }
            }
            return variantNames;
        } //-- end: conditionJ--------------------------------------------------------------------//

        function conditionK() {
            //[ZON-K] toon indien ZON16 = subsidie per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_SUBS_INV']?.Value === 'MEASURE_SUBSIDIE_PER_M2';
        } //-- end: conditionK ----------------------------------------------------------------

        function getSelectedBegrenzingen() {
            return ntaEntityData.getParents(_entdataMeasureZonw, 'BEGR');
        } //-- end: getSelectedBegrenzingen -------------------------------------------------------

        function getBegrOrientation(begr) {
            const propdataVlak = begr.PropertyDatas['BEGR_VLAK'];
            const propId = _begrenzingPropId[propdataVlak && propdataVlak.Value];
            const propdataBegrenzing = propId && begr.PropertyDatas[propId];
            const begrenzingCode = propdataBegrenzing && propdataBegrenzing.Value;
            const match = /_([NOZW]{1,2}|HOR)$/.exec(begrenzingCode);
            const orientatie = match && match[1];
            return orientatie || '';
        } //-- end: getBegrOrientation ----------------------------------------------------------------

        function getMatchingConstrEntdatas() {
            // alle constructies die voldoen aan de filters in de velden ZON03 t/m ZON05 [ZON-A]
            let constrs = getSelectedBegrenzingen()
                .flatMap(begr => ntaEntityData.getChildren(begr, 'CONSTRT'))
                .filter(constrt => getConstrGgl(constrt) > 0);

            // [ZON-A] het gaat om de constructies die aan alle kenmerken van de velden ZON03 t/m ZON06 voldoen (dus EN)
            const minGgl = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_GGL_GT'].Value); // ZON04
            if (!isNaN(minGgl)) {
                constrs = constrs.filter(constr => {
                    const gGl = getConstrGgl(constr);
                    return !isNaN(gGl) && minGgl < gGl;
                });
            }

            const minA = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_A_GT'].Value); // ZON05
            if (!isNaN(minA)) {
                constrs = constrs.filter(constr => {
                    const area = getConstrArea(constr);
                    return !isNaN(area) && area > minA;
                });
            }

            const propdataExisting = _entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_BESTAAND'];
            const skipExisting = propdataExisting.Value === 'MEASURE-ZONW_BEST_NIET_VERV'; // ZON06
            if (skipExisting) {
                constrs = constrs.filter(constr => {
                    const propdataZonw = constr.PropertyDatas['CONSTRT_ZONW'];
                    return !propdataZonw.Relevant || propdataZonw.Value === 'ZONW_GEEN';
                });
            }

            return constrs;
        } //-- end: getMatchingConstrEntdatas ------------------------------------------------------------

        function calculateTotalArea() {
            // de som van alle oppervlakte die voldoen aan de filters in de velden ZON03 t/m ZON05 [ZON-A]
            return getMatchingConstrEntdatas()
                .reduce((area, constr) => area + getConstrArea(constr), 0);
        } //-- end: calculateTotalArea ------------------------------------------------------------

        function getConstrGgl(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRT');
            const value = lib && lib.PropertyDatas['LIBCONSTRT_G'].Value;
            return ntaSharedLogic.parseFloat(value);
        } //-- end: getConstrGgl ------------------------------------------------------------------

        function getConstrArea(entdataConstr) {
            if (!entdataConstr) return 0;

            const unit = ntaEntityData.findEntities(entdataConstr, '^BEGR^UNIT-RZ^UNIT')[0];
            let aantalUnits = 1;
            if (unit) {
                const propAantA = ntaData.properties['UNIT_AANTA'];
                const propAantU = ntaData.properties['UNIT_AANTU'];
                const propdataAantA = propAantA.getData(unit);
                const propdataAantU = propAantU.getData(unit);
                aantalUnits = ntaSharedLogic.parseFloat(propdataAantA.Relevant ? propdataAantA.Value : propdataAantU.Relevant ? propdataAantU.Value : "1");
            }

            const propdataArea = entdataConstr && entdataConstr.PropertyDatas['CONSTRT_OPP'];
            return ntaSharedLogic.parseFloat(propdataArea && propdataArea.Value) * aantalUnits;
        } //-- end: getConstrArea -----------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return [];
            }

            let codedValues = [];
            switch (prop.Id) {
                case 'MEASURE-ZONW_BEGR_IDS': { // ZON03
                    codedValues = getBegrenzingen();
                    break;
                }
                case 'MEASURE-ZONW_REGELING': { // ZON15
                    const filterValue = ntaSharedLogic.isUtiliteit() ? 'U' : 'W';
                    codedValues = ntaValidation.codedValues(prop).filter(x => x.FilterValue1 === filterValue && (!conditionH() || x.Id === 'ZONWREG_A' || x.Id === 'ZONWREG_AI' || x.Id === 'ZONWREG_AO')); //uitzondering: automatisch geregeld of automatisch intelligent of automatisch overig
                    break;
                }
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function getBegrenzingen() {
            const begrenzingen = ntaEntityData.getListWithEntityId('BEGR')
                .filter(begr => {
                    const libConstrs = ntaEntityData.findEntities(begr, 'CONSTRT.^LIBCONSTRT');
                    return libConstrs.some(ed => ntaSharedLogic.parseFloat(ed.PropertyDatas['LIBCONSTRT_G'].Value) > 0);
                })
                .filter(begr => ntaEntityData.getParents(begr, 'RZ').length === 0 && isTypeBegrenzingBuitenLucht(begr));


            const begrenzingOrientaties = ntaMeasureData.getBegrenzingByUniqueBegrenzing(begrenzingen, begrenzingLogic);
            if (_listCache.isChanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual)) {
                //controleer de relaties tussen measure en begrenzingen
                const propBegrIds = ntaData.properties['MEASURE-ZONW_BEGR_IDS'];
                ntaMeasureData.checkBegrRelations(propBegrIds, _entdataMeasureZonw, begrenzingOrientaties);
            }

            return _listCache.useCacheIfUnchanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual);
        } //-- end: getBegrenzingen ---------------------------------------------------------------

        function isTypeBegrenzingBuitenLucht(begrenzing) {
            const propdataVlak = begrenzing.PropertyDatas['BEGR_VLAK'];
            const propId = _begrenzingPropId[propdataVlak && propdataVlak.Value];
            const prop = propId && ntaData.properties[propId];
            const propdataBegrenzing = propId && begrenzing.PropertyDatas[propId];
            const codedValueId = propdataBegrenzing && propdataBegrenzing.Value;
            const codedValue = prop && prop.Domain.Codes.find(cv => cv.Id === codedValueId);
            return codedValue && codedValue.Value.includes('buitenlucht');
        }

        async function toggleItemChecked(prop, entdata, item) {
            ntaMeasureData.toggleItemChecked(prop, entdata, item);
            validate(prop, prop.getData(entdata));

            //controleer of maatregel in variant zit en of deze keuze niet een conflict oplevert met andere maatregelen in deze variant
            // Zo ja, dan check terugdraaien
            const variantNames = conditionJ(entdata);
            if (variantNames.length > 0) {
                await ntaMeldingen.warning('[W121]', undefined, [{ from: '{naam varianten}', to: variantNames.join(' / ') }]);
                ntaMeasureData.toggleItemChecked(prop, entdata, item);
                validate(prop, prop.getData(entdata));
                return;
            }

            const totalArea = calculateTotalArea();
            const propTotalArea = ntaData.properties['MEASURE-ZONW_A_TOT'];
            saveValue(propTotalArea, entdata, totalArea);
        } //-- end: toggleItemChecked -------------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = isReadOnly(prop) && prop.Id !== 'MEASURE-ZONW_A_TOT'; // Validatie moet alsnog wél gebeuren voor de totale oppervlakte, ondanks dat deze read-only is

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            const contrl = self.form && self.form['ntainput' + propdata.PropertyDataId];

            switch (prop.Id) {
                case 'MEASURE-ZONW_A_TOT': {
                    // [ZON-B] toon [E113] indien ZON07 = 0
                    const isValid = ntaSharedLogic.parseFloat(propdata.Value) > 0;

                    ntaMeldingen.melding('[E113]', propdata.PropertyDataId, isValid);
                    contrl && contrl.$setValidity('[E113]', isValid);

                    valid = valid && isValid;
                    break;
                }
                case 'MEASURE-ZONW_KOSTEN_TOT_MIN_SUBSIDIE': {
                    calculateCosts();
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = true;

            switch (prop.Id) {
                case 'MEASURE-ZONW_BEGR_IDS':   // ZON03
                case 'MEASURE-ZONW_GGL_GT':     // ZON04
                case 'MEASURE-ZONW_A_GT':       // ZON05
                case 'MEASURE-ZONW_BESTAAND': { // ZON06
                    // Als één van deze velden gewijzigd is, dan moet de totale oppervlakte opnieuw berekend worden
                    const totalArea = calculateTotalArea();
                    const propTotalArea = ntaData.properties['MEASURE-ZONW_A_TOT']; // ZON07
                    const value = ntaRounding.roundAndAddZerosNewValue(propTotalArea, totalArea);
                    if (value !== propTotalArea.getValue(entdata)) {
                        saveValue(propTotalArea, entdata, value);
                    }
                    break;
                }
                case 'MEASURE-ZONW_A_TOT':              // ZON07
                case 'MEASURE-ZONW_KOSTEN_INV':         // ZON11
                case 'MEASURE-ZONW_KOSTEN_PER_M2':      // ZON12
                case 'MEASURE-ZONW_KOSTEN_TOT':         // ZON13
                case 'MEASURE-ZONW_SUBS_INV':           // ZON16
                case 'MEASURE-ZONW_SUBS_PER_M2':        // ZON17
                case 'MEASURE-ZONW_SUBS_TOT':   {       // ZON18
                    // Als één van deze velden gewijzigd is, dan moeten de totale kosten en subsidie opnieuw berekend worden
                    calculateCosts();
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function calculateCosts() {
            let changed = false;

            const totCostProp = ntaData.properties["MEASURE-ZONW_KOSTEN_TOT"];
            const totSubsProp = ntaData.properties["MEASURE-ZONW_SUBS_TOT"];
            let totalCost = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureZonw), 0);
            let totalSubs = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureZonw), 0);
            // Als één van deze velden gewijzigd is, dan moeten de totale kosten opnieuw berekend worden
            if (conditionD()) { // [ZON-D] ZON11 = kostenkentallen per m²
                // indien ZON11 = kostenkentallen per m²: ZON12 x ZON07
                const area = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_A_TOT'].Value); // ZON07
                const costPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_KOSTEN_PER_M2'].Value); // ZON12
                totalCost = area * costPerM2;
                const propTotalCost = ntaData.properties['MEASURE-ZONW_KOSTEN_TOT']; // ZON13
                const value = ntaRounding.roundAndAddZerosNewValue(propTotalCost, totalCost);
                if (value !== propTotalCost.getValue(_entdataMeasureZonw)) {
                    changed = saveValue(propTotalCost, _entdataMeasureZonw, value) || changed;
                }
            }
            if (conditionK()) { // [ZON-K] ZON16 = subsidie per m²
                // indien ZON16 = subsidie per m²: ZON16 x ZON07
                const area = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_A_TOT']?.Value);
                const subsPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureZonw.PropertyDatas['MEASURE-ZONW_SUBS_PER_M2']?.Value);
                totalSubs = area * subsPerM2;
                changed = saveValue('MEASURE-ZONW_SUBS_TOT', _entdataMeasureZonw, totalSubs) || changed;
            }
            /// en bereken de totale kosten door de subsidie van de investeringskosten af te trekken
            changed = saveValue('MEASURE-ZONW_KOSTEN_TOT_MIN_SUBSIDIE', _entdataMeasureZonw, totalCost - totalSubs) || changed;

            /// en bereken tot slot de nieuwe variantkosten voor de varianten die gebruik maken van deze measure
            if (changed) {
                const variants = ntaEntityData.findEntities(_entdataMeasure, "VARIANT-MEASURE.^VARIANT", "VARIANT");
                self.dependencyValidator.calculateCostsVariants(variants);
            }

            return changed;
        } //-- end: calculateCosts ----------------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasureZonw];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
