﻿angular.module('projectModule')
    .factory('BuildingResultsFactory',
        ['ntaData', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaRounding', 'ListCache', 'ntaValidation', 'ntaResults',
function (ntaData,   ntaEntityDataOrg,   ntaSharedLogic,   ntaRounding,   ListCache,   ntaValidation,   ntaResults) {
    'use strict';

    const _resultCategoryOrder = new Map(['RESULT_VERW', 'RESULT_TAPW', 'RESULT_KOEL', 'RESULT_VENT', 'RESULT_VERL', 'RESULT_BEVO'].map((cat, index) => [cat, index + 1]));
    const _energiefunctieCodeOrder = new Map(['E', 'GAS', 'OLIE', 'BIOM1', 'BIOM2', 'BIOM3', 'EW_VERW', 'EW_TAPW', 'EW_KOEL'].map((code, index) => [code, index + 1]));
    const _risicoOververhittingByBuildingId = new Map();


    return function ResultsLogic(variantId = null, forTailoredAdvice = false) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const resultsLogic = this;

        const textRisicoOververhitting = {
            maakBerekeningPerAppartement: 'maak EP berekening per appartement',
            voldoetNiet: 'voldoet niet',
            voldoet: 'voldoet',
            ntb: 'n.t.b.',
        };

        const _isEMGforfByVariantId = new Map();
        const _listCache = new ListCache();

        let _variantParent; // de resultaten kunnen van de basisberekening zijn, of van een VARIANT
        let _forTailoredAdvice; // de resultaten kunnen van de NTA-berekening of voor de MWA-berekening (maatwerkadvies) zijn
        let _isEMGforf;
        setExtraResultParents(variantId, forTailoredAdvice);

        Object.assign(resultsLogic, {
            opwekkertypesPassieveKoeling: new Set(['KOEL-OPWEK_TYPE_6', 'KOEL-OPWEK_TYPE_11']), // dauwpuntskoeling / booster
            textRisicoOververhitting,

            getBerekeningen,
            getActiveBerekeningId,
            getResult,
            getResults,
            isGebouw,
            getIcon,
            getEnergiefunctieResultaten,
            getEnergiegebruikResultaat,
            setEnergieResultaten,
            setExtraResultParents,
            getExtraResultParents,
            getEnergieprestatieRows,
            saveValue,
            isHidden,
            getPropData,
            getPropDataValue,
            getPropDataShowValue,
            getFunctionPropdataValue,
            parseShowFloat,
            propertyHeaderIsHidden,
            getCodedValues,
            getName,
            getUnit,
            getCalcProperty,
            selecteerBerekening,
            isGebGebInstallatieEInvoer,
            gebruikEntHasResult,
            getSumOfFunction,
            catHasResult,
            getCategoryGrootheid,
            getCategoryName,
            heeftEisen,
            checkResultEis,
            functieEntHasResult,
            isKoelcapaciteit,
            hasRisicoOververhitting,
            getRisicoOververhitting,
            hasRisicoOververhittingAlleApp,
            getRisicoOververhittingAlleApp,
            hasRisicoOververhittingAlleWoningen,
            getRisicoOververhittingAlleWoningen,
            isItemChecked,
            toggleItemChecked,
            startFormValidation,
            endFormValidation,
        });

        resultsLogic.energiegebruikProperty = (name) => ntaData.properties[name];

        resultsLogic.isNieuwbouw = ntaSharedLogic.isNieuwbouw;
        resultsLogic.isUtiliteit = ntaSharedLogic.isUtiliteit;
        resultsLogic.isEMGforf = ntaSharedLogic.isEMGforf;
        resultsLogic.isVersionLe32 = () => ntaData.ntaVersion.ntaVersionId < 300;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const _energiefunctieProperties = ntaData.properties['RESULT-ENERGIEFUNCTIE'];
        const _energiegebruikProperties = ntaData.properties['RESULT-ENERGIEGEBRUIK'];
        const _eProperty = ntaData.properties["RESULT-ELEKTR_NIETGEBGEB"];
        const _eGebruikProperty = ntaData.properties["RESULT-ELEKTR_NIETGEBGEB_GEBR"];

        let _energiefunctieResultaten = [];
        const _energiefunctieCategories = [];
        let _energiegebruikResultaat;
        const _piedata = [];
        const _labels = [];

        const _piedatatype = [
            { "cat": "RESULT_VERW", "text": "verwarming", "color": '#E02224', "resultaat": "" },
            { "cat": "RESULT_TAPW", "text": "warm tapwater", "color": '#FFAB40', "resultaat": "" },
            { "cat": "RESULT_KOEL", "text": "koeling", "color": '#FFFF00', "resultaat": "" },
            { "cat": "RESULT_VENT", "text": "ventilatoren", "color": '#73BE26', "resultaat": "" },
            { "cat": "RESULT_VERL", "text": "verlichting", "color": '#03AEE2', "resultaat": "", show: () => ntaSharedLogic.isUtiliteit() },
            { "cat": "RESULT_BEVO", "text": "bevochtiging", "color": '#5D4694', "resultaat": "", show: () => ntaSharedLogic.isUtiliteit() },
        ];
        const _legenda = _piedatatype.filter(piecat => !piecat.show || piecat.show());
        const _piecolors = _legenda.map(piecat => piecat.color);

        const jaarlijkseEnergie = [
            { "text": "Jaarlijkse karakteristieke energiegebruik", "properties": ["RESULT_KARAKT_SOM_EPEH", "RESULT_KARAKT_OPGEW_E", "RESULT-KARAKT_TOT"] },
            { "text": "Jaarlijkse hoeveelheid hernieuwbare energie", "properties": ["RESULT-HERNIEUW_VERW", "RESULT-HERNIEUW_TAPW", "RESULT-HERNIEUW_KOEL", "RESULT-HERNIEUW_ELEKTR", "RESULT-HERNIEUW_TOT"], "totalProperty": "RESULT-HERNIEUW_TOT" },
        ];

        const overig_gebruik = [
            { "text": "Elektriciteitsgebruik op de meter", "properties": ["RESULT-ELEKTR_GEBGEB", "RESULT-ELEKTR_NIETGEBGEB", "RESULT-ELEKTR_OPGEWEKT", "RESULT-ELEKTR_TOT"] },
            { "text": "Aardgasgebruik (exclusief koken)", "properties": ["RESULT-GAS_GEBGEB"] },
            { "text": "Externe warmte- en/of koudelevering gebruik", "properties": ["RESULT-EWEK_EW", "RESULT-EWEK_EK"] },
            { "text": "Biomassa (vaste brandstof) gebruik", "properties": ["RESULT-BOIM_GEBGEB"] },
            { "text": "Oliegebruik", "properties": ["RESULT-OLIE_TOT"] /*["RESULT_VERW_OLIE", "RESULT_TAPW_OLIE"]*/ },
            { "text": "Oppervlakten", "properties": ["RESULT-OPP_GEBROPP", "RESULT-OPP_VERLOPP", "RESULT-OPP_VORMFACTOR"] },
            { "text": "CO₂-emissie", "properties": ["RESULT-CO2_CO2"] }
        ];

        resultsLogic.energiefunctieProperties = _energiefunctieProperties;
        resultsLogic.energiefunctieCategories = _energiefunctieCategories;
        resultsLogic.eGebruikProperty = _eGebruikProperty;
        resultsLogic.eProperty = _eProperty;

        resultsLogic.piedata = _piedata;
        resultsLogic.piecolors = _piecolors;
        resultsLogic.labels = _labels;
        resultsLogic.getLegend = getLegend;

        resultsLogic.jaarlijkseEnergie = jaarlijkseEnergie;
        resultsLogic.overig_gebruik = overig_gebruik;

        resultsLogic.showPie = true;

        let _activeberekeningId = getBerekeningen()[0].Id;
        selecteerBerekening(_activeberekeningId);


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function getBerekeningen() {
            const gebouwBerekend = !ntaSharedLogic.isAppartementOfUtiliteitVoorBestaandeBouw() && !ntaSharedLogic.voorProjectwoningen();
            const unitsBerekend = !gebouwBerekend || ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen();

            const geb = ntaEntityDataOrg.getFirstWithEntityId('GEB');

            // Er zijn oude berekeningen (van los appartement/losse unit) waarvan de resultaten vroeger bij het gebouw zaten, maar nu onder de unit.
            // Die zijn te herkennen aan het feit dat de gebouwresultaten Visible zijn, terwijl dat niet zou moeten.
            const useBuildingResultsForUnit = !gebouwBerekend
                && !ntaSharedLogic.voorProjectwoningen()
                && ntaEntityDataOrg.getChildren(geb)
                    .filter(entdata => entdata.EntityId.startsWith('RESULT-'))
                    .some(entdata => entdata.Visible);

            const entdatas = [];
            if (gebouwBerekend || useBuildingResultsForUnit) entdatas.push(geb);
            if (unitsBerekend && !useBuildingResultsForUnit) entdatas.push(...ntaEntityDataOrg.getListWithEntityId("UNIT"));

            const gebInfo = gebouwBerekeningsInfo();

            let unitImage = "images/B_APP.svg";
            if (ntaSharedLogic.isUtiliteit()) {
                unitImage = "images/B_UNIT.svg";
            } else if (ntaSharedLogic.voorProjectwoningen()) {
                const prop = ntaData.properties['GEB_TYPEGEB'];
                const codedValue = prop.getCode(geb);
                if (codedValue) {
                    unitImage = codedValue.ImageUrl;
                }
            }

            const berekeningen = entdatas
                .map(entdata => entdata === geb
                    ? {
                        Id: geb.EntityDataId,
                        Name: "Hele gebouw",
                        Image: gebInfo.GebouwIcon,
                        isGebouw: true,
                    }
                    : {
                        Id: entdata.EntityDataId,
                        Name: entdata.PropertyDatas["UNIT_OMSCHR"].Value,
                        Image: unitImage,
                        isGebouw: ntaSharedLogic.voorProjectwoningen(),
                    });

            // Zorg dat dat altijd dezelfde lijst teruggegeven wordt, tenzij er iets aan is veranderd, om te voorkomen dat AngularJS infdig triggert.
            return _listCache.useCacheIfUnchanged('berekeningen', berekeningen, (a, b) => a.Id === b.Id && a.Name === b.Name && a.Image === b.Image);
        }

        function getResult(berekeningOrId, propId) {
            const results = getResults(berekeningOrId, propId);
            return results[0] || '';
        } //-- end: getResult ---------------------------------------------------------------------

        function getResults(berekeningOrId, propId) {
            const berekeningId = berekeningOrId && (berekeningOrId.EntityDataId || berekeningOrId.Id) || berekeningOrId;
            const prop = ntaData.properties[propId];
            const ntaResults = prop && getNtaResultEntityDatas(berekeningId, prop.EntityId) || [];
            return ntaResults.map(ntaResult => {
                const value = ntaResult && prop.getValue(ntaResult) || '';
                if (prop.PropertyType === 1) {
                    // RESULT-NETTO_WARMTEVRAAG heeft afronding R014 (2 decimalen, omhoog afronden), maar moet bij woningbouw R016 (0 decimalen, omhoog afronden) gebruiken
                    let rounding = null;
                    if (propId === 'RESULT-NETTO_WARMTEVRAAG' && !ntaSharedLogic.isUtiliteit()) {
                        rounding = 'R016';
                    }
                    const roundedValue = ntaRounding.roundAndAddZerosNewValue(prop, value, rounding);
                    return roundedValue || '--';
                } else {
                    return value;
                }
            });
        } //-- end: getResults --------------------------------------------------------------------

        function selecteerBerekening(id) {

            setEnergieResultaten(id);

            setPieData();

            const tile = document.getElementById('berekening+' + id);
            tile && tile.scrollIntoView({
                block: 'nearest',
                inline: 'center',
                behavior: 'smooth',
            });
        };

        function setExtraResultParents(variantOrId = null, forTailoredAdvice = false) {
            _variantParent = variantOrId && (variantOrId.EntityDataId ? variantOrId : ntaEntityDataOrg.get(variantOrId))
                || ntaEntityDataOrg.getFirstWithEntityId('BASIS');
            _forTailoredAdvice = !!forTailoredAdvice;

            const variantId = _variantParent.EntityId === 'BASIS' ? null : _variantParent.EntityDataId;
            let isEMGforf = _isEMGforfByVariantId.get(variantId);
            if (isEMGforf === undefined) {
                isEMGforf = ntaSharedLogic.isEMGforf(variantId);
                _isEMGforfByVariantId.set(variantId, isEMGforf);
            }
            _isEMGforf = isEMGforf;
            _listCache.evictFromCacheWhen(cacheId => cacheId.startsWith(`ntaResults:`)); // ntaResults uit cache gooien; die zijn afhankelijk van variant en maatwerkadvies.
        }

        function getExtraResultParents() {
            return {
                variantParent: _variantParent,
                forTailoredAdvice: _forTailoredAdvice,
            };
        }

        function orderCategories(a, b) {
            const aIndex = _resultCategoryOrder.get(a) || Number.MAX_SAFE_INTEGER;
            const bIndex = _resultCategoryOrder.get(b) || Number.MAX_SAFE_INTEGER;

            return aIndex - bIndex
                || a.Order - b.Order;
        }

        function setEnergieResultaten(berekeningId) {
            _activeberekeningId = berekeningId; //active berekening moet worden gezet bij setEnergieResultaten, omdat isGebouw hiervan afhankelijk is.

            const gebOrUnit = ntaEntityDataOrg.get(berekeningId);
            const { resultEntdatasByEntityId } = ntaResults.getForParents(gebOrUnit, _variantParent, _forTailoredAdvice, ['RESULT-ENERGIEFUNCTIE', 'RESULT-ENERGIEGEBRUIK']);

            _energiefunctieResultaten = resultEntdatasByEntityId.get('RESULT-ENERGIEFUNCTIE') || [];

            const energiegebruikResultaten = resultEntdatasByEntityId.get('RESULT-ENERGIEGEBRUIK') || [];
            _energiegebruikResultaat = energiegebruikResultaten[0];

            _energiefunctieCategories.length = 0;
            _energiefunctieCategories.push(..._energiefunctieResultaten
                .map(x => x.PropertyDatas["RESULT-ENERGIEFUNCTIE_CAT"].Value)
                .distinct()
                .sort(orderCategories)
                .filter(x => ntaSharedLogic.isUtiliteit() || (x !== "RESULT_VERL" && x !== "RESULT_BEVO")));

            _energiegebruikProperties.forEach(prop => calculategebruikprop(prop.Id));

            setPieData();
        }

        function getNtaResultEntityData(berekeningOrId, entityId) {
            const resultEntdatas = getNtaResultEntityDatas(berekeningOrId, entityId);
            return resultEntdatas[0];
        } //-- end: getNtaResultEntityData --------------------------------------------------------

        function getNtaResultEntityDatas(berekeningOrId, entityId) {
            const berekeningId = berekeningOrId && berekeningOrId.Id || berekeningOrId;

            const cacheId = `ntaResults:${entityId}:${berekeningId}`;
            let resultEntdatas = _listCache.getIfRecent(cacheId, 2500);
            if (!resultEntdatas) {
                const gebOrUnit = ntaEntityDataOrg.get(berekeningId);
                const { resultEntdatasByEntityId } = ntaResults.getForParents(gebOrUnit, _variantParent, false, [entityId]) //conditie [Y] -> TailoredAdvice altijd false
                resultEntdatas = resultEntdatasByEntityId.get(entityId) || [];
                _listCache.add(cacheId, resultEntdatas);
            }
            return resultEntdatas;
        } //-- end: getNtaResultEntityDatas -------------------------------------------------------

        function getActiveBerekeningId() {
            return _activeberekeningId;
        };

        function saveValue(prop, entdata) {
            if (!entdata || !prop) {
                return;
            }
            var propdata = entdata.PropertyDatas[prop.Id];
            propdata.Touched = true; // opslaan veld is aangeraakt
            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return;
            }

            let hidden = false;
            const propdataNggeGebr = _energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB_GEBR"];
            if (prop.Id === _eProperty.Id && !ntaSharedLogic.isUtiliteit() && (!propdataNggeGebr || propdataNggeGebr.Value === "ELEKTR_NIETGEBGEB_GEBR_EPV")) {
                hidden = true;
            }
            if (prop.Id === _eGebruikProperty?.Id && ntaSharedLogic.isUtiliteit()) {
                hidden = true;
            }
            ntaValidation.IsValid(resultsLogic.form_buildingresults, prop, propdata, hidden);

            ntaEntityDataOrg.saveprop(propdata);

            if (prop.Id === "RESULT-ELEKTR_NIETGEBGEB_GEBR") {
                entdata.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB"].Touched = false;
            }
        };


        function setPieData() {
            if (!resultsLogic.showPie) return;

            const D45 = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT_KARAKT_SOM_EPEH"].Value, 0);
            if (D45 > 0) {
                _legenda.forEach(function (leg, index) {
                    const prim = getFunctionPropdataValue(leg.cat, "", "RESULT-ENERGIEFUNCTIE_RES_ENER_PRIM");
                    const hulp = getFunctionPropdataValue(leg.cat, "", "RESULT-ENERGIEFUNCTIE_RES_HULPENER_PRIM");
                    const resultaat = Math.round(((prim + hulp) / D45) * 100);
                    if (_piedata[index] !== resultaat) {
                        _piedata[index] = resultaat;
                        leg.resultaat = resultaat;
                    }
                });
            } else {
                _piedata.length = 0;
                for (const piecat of _legenda) {
                    if (piecat.resultaat !== 0) {
                        piecat.resultaat = 0;
                    }
                }
            }
        };

        function getLegend () {
            const legend = _legenda.filter(cat => cat.resultaat > 0);
            if (legend.length > 0) {
                return legend;
            } else {
                return _legenda;
            }
        };

        function getFunctionPropdataValue(cat, code, propName) {
            const resEnts = cat ? getEnergiefunctieResultaten(cat) : _energiefunctieResultaten;
            const resValues = resEnts
                .filter(resEnt => {
                    const entCode = resEnt.PropertyDatas["RESULT-ENERGIEFUNCTIE_CODE"].Value;
                    // De parameter ‘code’ kan leeg zijn, een string of een regex.
                    // Dit omdat 'E' staat voor elektriciteit, maar 'EW_VERW', 'EW_TAPW', en 'EW_KOEL' voor externe warmtelevering, en die laatsten moeten samengevoegd worden.
                    // Dus moet voor externe warmtelevering een regular expression opgegeven worden: /^EW_/, wat betekent alle strings die beginnen met EW_.
                    // Als een gewone string wordt meegegeven, moet deze volledig onvereenkomen.
                    if (!code) {
                        return true;
                    } else if (code instanceof RegExp) {
                        return code.test(entCode);
                    } else {
                        return entCode === code;
                    }
                })
                .map(resEnt => resEnt.PropertyDatas[propName].Value);
            return resValues
                .reduce((sum, value) => sum + ntaSharedLogic.parseFloat(value, 0), 0);
        } //-- end: getFunctionPropdataValue ------------------------------------------------------

        function orderByCodes(a, b) {
            const aIndex = _energiefunctieCodeOrder.get(a.PropertyDatas["RESULT-ENERGIEFUNCTIE_CODE"].Value) || Number.MAX_SAFE_INTEGER;
            const bIndex = _energiefunctieCodeOrder.get(b.PropertyDatas["RESULT-ENERGIEFUNCTIE_CODE"].Value) || Number.MAX_SAFE_INTEGER;

            return aIndex - bIndex || a.Order - b.Order;
        }

        function getEnergiefunctieResultaten(cat) {
            return _energiefunctieResultaten.filter(ed => ed.PropertyDatas["RESULT-ENERGIEFUNCTIE_CAT"].Value === cat).sort(orderByCodes);
        };

        function getEnergiegebruikResultaat() {
            return _energiegebruikResultaat;
        };

        function getCategoryGrootheid(cat) {
            return _energiefunctieResultaten.find(ed => ed.PropertyDatas["RESULT-ENERGIEFUNCTIE_CAT"].Value === cat).PropertyDatas["RESULT-ENERGIEFUNCTIE_GROOTHEID"].Value;
        };

        function getPropData(prop, entdata) {
            const propdata = ntaSharedLogic.getPropData(prop, entdata);
            if (propdata && !propdata.Value) {
                propdata.Value = prop.DefaultValue;
            }
            return propdata;
        };

        function parseShowFloat(showFloat) {
            /// deze float is eigenlijk een tekst en kan duizend punten bevatten of andere tekst. De duizendpunten filteren we er eerst
            /// even uit.
            let result = String(showFloat).replace('.', '');
            result = ntaSharedLogic.parseFloat(result, 0);

            return result;
        }

        function getPropDataValue(prop, entdata) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return;
            }
            if (prop.Id === "RESULT-ENERGIEFUNCTIE_GROOTHEID" && getEnergiefunctieResultaten(entdata.PropertyDatas["RESULT-ENERGIEFUNCTIE_CAT"].Value).length > 1) {
                return "";
            }

            if (calculategebruikprop(prop.Id)) {
                setPieData();
            }

            const propdata = entdata.PropertyDatas[prop.Id];
            let value = propdata.Value;

            //-- conditie [AB]
            if (ntaData.ntaVersion.ntaVersionId >= 300
                && prop.Id === 'RESULT-ENERGIEFUNCTIE_NAAM'
                && entdata.EntityId === 'RESULT-ENERGIEFUNCTIE'
                && entdata.PropertyDatas['RESULT-ENERGIEFUNCTIE_CODE'].Value === 'BIOM1') {
                value = 'biomassa > 500 kW';
            }

            if (ntaData.ntaVersion.ntaVersionId >= 303 && prop.Id === 'RESULT-ELEKTR_NIETGEBGEB') {
                value = getNietGebouwgebondenElektriciteit(propdata);
                ntaEntityDataOrg.saveprop(propdata, value);
            }

            return value;
        }

        function getPropDataShowValue(prop, entdata) {
            /// de waarde die hier teruggegeven wordt is de waarde die getoond wordt in het formulier. Kan een tekst bevatten zoals 'biomassa > 500 kW'
            /// of een tekst met duizend punten. Deze showValue kan dus niet geparsed worden naar een float.

            var value = getPropDataValue(prop, entdata);

            if (prop.PropertyType === 1) { // number
                //afgeronde waarde laten weergeven
                let rounding;
                switch (prop.Id) {
                    case 'RESULT-ENERGIEFUNCTIE_RES_ENER_PRIM':
                    case 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM':
                    case 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_PRIM':
                    case 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_NONPRIM':
                    case 'RESULT-HERNIEUW_TOT':
                        rounding = 'R016';
                        break;
                }
                const roundedValue = ntaRounding.roundAndAddZerosNewValue(prop, value, rounding) || '0';
                return roundedValue && roundedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            } else {
                return value;
            }
        };

        function getNietGebouwgebondenElektriciteit(propdata) {
            const entdataNietGebGebEl = ntaEntityDataOrg.getFirstWithEntityId('NGEBGEB-E');
            if (!entdataNietGebGebEl) {
                return propdata?.Value || null;
            }

            if (entdataNietGebGebEl.PropertyDatas['NGEBGEB-E_METHODE'].Value === 'NGEBGEB-E_INTERNE_WARMTE') {
                // Dan komt deze waarde uit de rekenkern
                return _energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB"].Value;
            }

            if (entdataNietGebGebEl.PropertyDatas['NGEBGEB-E_INVOER'].Value === 'NGEBGEB-E_VAST') {
                // vaste waarde [kWh]
                const entdata = ntaEntityDataOrg.get(propdata.EntityDataId);
                if (ntaEntityDataOrg.findEntities(entdata, '^GEB').length > 0) { // Hele gebouw -> dan vaste waarde * aantal Units
                    const units = ntaEntityDataOrg.getListWithEntityId('UNIT');
                    let aantalUnits = units.length;
                    if (ntaSharedLogic.perGebouwEnAppartementOfUnit() || ntaSharedLogic.voorProjectwoningen()) { //aantal is zichtbaar (Indeling gebouw: conditie H)
                        const propStr = ntaSharedLogic.isUtiliteit() ? 'UNIT_AANTU' : 'UNIT_AANTA';
                        aantalUnits = units.flatMap(x => x.PropertyDatas[propStr].Value).reduce((x, y) => (+x) + (+y));
                    }
                    const value = ntaSharedLogic.parseFloat(entdataNietGebGebEl.PropertyDatas['NGEBGEB-E_VAST'].Value);
                    return ntaRounding.roundAndAddZerosNewValue(null, aantalUnits * value, 'R016');
                }
                return entdataNietGebGebEl.PropertyDatas['NGEBGEB-E_VAST'].Value;
            }

            // kWh per m²
            const [energyPerArea, min, max] = ['NGEBGEB-E_PER_M2', 'NGEBGEB-E_MIN', 'NGEBGEB-E_MAX']
                .map(propId => ntaSharedLogic.parseFloat(entdataNietGebGebEl.PropertyDatas[propId].Value));
            const area = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT-OPP_GEBROPP"].Value, 0);
            let energy = energyPerArea * area;
            if (!isNaN(min) && energy < min) energy = min;
            if (!isNaN(max) && max < energy) energy = max;

            return ntaRounding.roundAndAddZerosNewValue(null, energy, 'R016');
        } //-- end: getNietGebouwgebondenElektriciteit --------------------------------------------


        function calculategebruikprop(propId) {
            const propdata = _energiegebruikResultaat.PropertyDatas[propId];
            if (!propdata) {
                return false;
            }

            let sum = null;
            switch (propId) {
                case "RESULT_KARAKT_SOM_EPEH":
                    sum = calcSumOfFunction('RESULT-ENERGIEFUNCTIE_RES_ENER_PRIM') + calcSumOfFunction('RESULT-ENERGIEFUNCTIE_RES_HULPENER_PRIM');
                    break;
                case "RESULT-ELEKTR_GEBGEB": {
                    const D17253339 = getFunctionPropdataValue('', 'E', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const D36 = getFunctionPropdataValue('RESULT_VENT', '', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const D37 = getFunctionPropdataValue('RESULT_VERL', '', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const F172533 = getFunctionPropdataValue('', 'E', 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_NONPRIM');
                    sum = D17253339 + D36 + D37 + F172533;
                    break;
                }
                case "RESULT-ELEKTR_TOT": {
                    const gebgeb = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_GEBGEB"].Value, 0);
                    const nietgebgeb = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB"].Value, 0);
                    const opgew = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_OPGEWEKT"].Value, 0);
                    sum = gebgeb + nietgebgeb - opgew;
                    break;
                }
                case "RESULT-GAS_GEBGEB": {
                    const D18263440 = getFunctionPropdataValue('', 'GAS', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const F1826 = getFunctionPropdataValue('', 'GAS', 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_NONPRIM');
                    sum = (D18263440 + F1826) / (35.17 / 3.6);
                    break;
                }
                case "RESULT-OLIE_TOT": {
                    const tapw = getFunctionPropdataValue('RESULT_TAPW', 'OLIE', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const verw = getFunctionPropdataValue('RESULT_VERW', 'OLIE', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    sum = (tapw + verw) * 0.1;
                    break;
                }
                case "RESULT-EWEK_EW": {
                    const D23 = getFunctionPropdataValue('RESULT_VERW', /^EW/, 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    const D31 = getFunctionPropdataValue('RESULT_TAPW', /^EW/, 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    sum = ((D23 + D31) * 3.6) / 1000;
                    break;
                }
                case "RESULT-EWEK_EK": {
                    const D35 = getFunctionPropdataValue('RESULT_KOEL', /^EW/, 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    sum = (D35 * 3.6) / 1000;
                    break;
                }
                case "RESULT-BOIM_GEBGEB": {
                    const D202122282930 = getFunctionPropdataValue('', /^BIOM/, 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                    sum = D202122282930 / (35.17 / 3.6);
                    break;
                }
                case "RESULT-ELEKTR_NIETGEBGEB": {
                    const propdataNggeGebr = _energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB_GEBR"];
                    if (!ntaSharedLogic.isUtiliteit() && (propdataNggeGebr?.Value === "ELEKTR_NIETGEBGEB_GEBR_EPV")) {
                        const Agtot = ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas["RESULT-OPP_GEBROPP"].Value, 0);
                        sum = Agtot * 26;

                        const aantal = isGebouw()
                            ? parseInt(ntaSharedLogic.getDefaultValueAantalWoonfuncties()) || 1
                            : 1;

                        sum = Math.max(sum, 1800 * aantal);
                        sum = Math.min(sum, 2600 * aantal);
                    }
                    break;
                }
            }
            if (sum !== null) {
                const oldValue = propdata.Value;
                propdata.Value = ntaRounding.roundAndAddZerosNewValue(ntaData.properties[propId], sum);
                return propdata.Value !== oldValue;
            }

            return false;
        } //-- end: calculategebruikprop ----------------------------------------------------------

        function calcSumOfFunction(propName) {
            const propValues = _energiefunctieResultaten
                .map(entdata => entdata.PropertyDatas[propName].Value);
            return propValues
                .reduce((sum, propValue) => sum + ntaSharedLogic.parseFloat(propValue, 0), 0);
        } //-- end: calcSumOfFunction -------------------------------------------------------------

        function getSumOfFunction(propName) {
            const sum = calcSumOfFunction(propName);

            //afgeronde waarde laten zien
            const prop = ntaData.properties[propName];
            let rounding;
            switch (prop.Id) {
                case 'RESULT-ENERGIEFUNCTIE_RES_ENER_PRIM':
                case 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM':
                case 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_PRIM':
                case 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_NONPRIM':
                    rounding = 'R016';
                    break;
            }
            const roundedSum = ntaRounding.roundAndAddZerosNewValue(prop, sum, rounding)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            return roundedSum + " " + getUnit(prop);
        };

        function getStandaardName() {
            if (ntaSharedLogic.isUtiliteit()) {
                return 'renovatiestandaard';
            }
            return 'standaard voor woningisolatie';
        }

        function catHasResult(cat) {
            const results = cat && getEnergiefunctieResultaten(cat) || [];
            return results.some(entdata => functieEntHasResult(entdata));
        };

        function functieEntHasResult(energiefunctieEnt) {
            return !!energiefunctieEnt && energiefunctieEnt.PropertyDatas
                .filter(propdata => propdata.PropertyId.startsWith('RESULT-ENERGIEFUNCTIE_RES_'))
                .some(propdata => ntaSharedLogic.parseFloat(propdata.Value, 0) !== 0);
        };

        function getEnergieprestatieRows() {
            return _energieprestatieRows
                .filter(row => row.isVisible());
        };

        // default-functies van elke energieprestatie-rij
        const _energieprestatieRow = {
            isVisible: () => true,
            getValue: function () { return getResult(_activeberekeningId, this.propId); },
            hasLimit: () => false,
            getLimit: function () { return getResult(_activeberekeningId, this.propId + '_EIS'); },
            checkResult: function () { return this.hasLimit() ? checkResultEis(this.propId, _activeberekeningId) : null; },
        };

        function isEnergielabel () {
            if (isGebouw() || isAppartementOfUnit()) { // Losse appartementen en units vallen niet onder het gebouw, maar hebben volgens [P] wel een energielabel nodig
                //[P] toon rij op de eerste tegel 'hele gebouw' indien G04 = 'grondgebonden woning' of 'appartement' of 'unit in utiliteitsgebouw' of 'vakantiewoning' of 'woonwagen' of 'woonboot - ligplaats voor 1 januari 2018' of 'woonboot - ligplaats vanaf 1 januari 2018' of[G04 = 'utiliteitsgebouw' EN Z23 = 'per gebouw']
                const gebouwtype = ntaSharedLogic.getGebouwType();
                const calcUnit = ntaSharedLogic.getCalcUnit();
                return ['TGEB_GRWON', 'TGEB_APP', 'TGEB_UTILUNIT', 'TGEB_VAKWON', 'TGEB_WOONW', 'TGEB_WOONBN', 'TGEB_WOONBB'].includes(gebouwtype)
                    || gebouwtype === 'TGEB_UTILIT' && calcUnit === "RZUNIT_GEB";
            } else {
                //toon rij op de overige tegels 'per appartement/unit' indien[G04 = 'appartementengebouw' en Z23 = 'per gebouw en per appartement']of[G04 = 'utiliteitsgebouw' EN Z23 = 'per gebouw en per unit']
                return ntaSharedLogic.perGebouwEnAppartementOfUnit()
                    || ntaSharedLogic.voorProjectwoningen();
            }
        };

        function isGebouw(berekeningOrId = _activeberekeningId) {
            const berekening = typeof berekeningOrId === 'string'
                ? getBerekeningen().find(b => b.Id === berekeningOrId)
                : berekeningOrId;
            return Boolean(berekening) && berekening.isGebouw;
        }

        function isTOjuli(berekeningOrId = _activeberekeningId) {
            if (ntaSharedLogic.isUtiliteit())
                return false;

            return isGebouw(berekeningOrId)
                //[R] toon rij op de eerste tegel 'hele gebouw' indien G05='nieuwbouw' EN G04='grondgebonden woning' of 'appartement'
                ? ['TGEB_GRWON', 'TGEB_APP'].includes(ntaSharedLogic.getGebouwType(ntaEntityDataOrg.getFirstWithEntityId('GEB')))
                //toon rij op de overige tegels 'per appartement' indien G05 = 'nieuwbouw' EN G04 = 'appartementengebouw' en Z23 = 'per gebouw en per appartement'
                : ntaSharedLogic.getCalcUnit(ntaEntityDataOrg.getFirstWithEntityId('RZFORM')) === 'RZUNIT_GEBAPP';
        }

        function checkResultEis(propId, berekeningOrId = ntaEntityDataOrg.getFirstWithEntityId('GEB').EntityDataId) {
            if (propId !== 'EP_TOJULI' && propId !== 'RESULT_TOJULI_RISICO' && !isGebouw(berekeningOrId))
                return null;
            if (propId === 'EP_TOJULI' && !isTOjuli(berekeningOrId))
                return null;
            if (propId === 'EP_TOJULI' && !ntaSharedLogic.isVakantiewoning() && ntaData.ntaVersion.ntaVersionId >= 300 && ntaSharedLogic.isNieuwbouw())
                return null;
                // [RO-AZ] indien RO86 of RO87 getoond dan geen beoordeling met vinkje of kruisje.
            if (propId === 'RESULT_TOJULI_RISICO') {
                switch (getRisicoOververhitting(berekeningOrId)) {
                    case textRisicoOververhitting.voldoet:      return true;
                    case textRisicoOververhitting.voldoetNiet:  return false;
                    default:                                    return null;
                }
            }

            return ntaSharedLogic.checkResultEis(propId, getNtaResultEntityData(berekeningOrId, 'PRESTATIE'));
        }

        function hasTemperatuuroverschrijding(entityDataId) {
            //[R] toon rij op de eerste tegel 'hele gebouw' indien G05='nieuwbouw' EN G04='grondgebonden woning' of 'appartement'
            //[AD] toon als:
            //- versie le3.2 OF
            //- versie ge3.3 EN WB EN geen actieve koeling

            if (ntaData.ntaVersion.ntaVersionId < 300) {
                return isTOjuli();
            } else if (ntaSharedLogic.isNieuwbouw()) {
                return false;
            } else if (!isTOjuli()) {
                return false;
            } else {
                const tojulis = getNtaResultEntityDatas(entityDataId, 'RESULT-TOJULI');
                const activeKoeling = tojulis.some(ed => ed.PropertyDatas["RESULT_TOJULI_TYPE_KOEL"]?.Value === "RESULT-TOJULI_TYPE_KOEL_ACTIVE");
                return !activeKoeling;
            }
        }

        function hasRisicoOververhitting(berekeningId = _activeberekeningId) {
            //Energieprestatie conitie [AE] toon als versie ge3.3 EN WN EN:
            //- G04 = grondgebonden woning / appartement OF
            //- G04 = appartementengebouw EN Z23 = per gebouw
            //Indien Z23 = 'per gebouw en per appartement" verberg rij op tegel 'hele gebouw' maar toon op de tegels van de appartementen.

            //BENG indicator condition [Y] toon als versie ge3.3 EN WN EN: - G04 = grondgebonden woning / appartement OF - G04 = appartementengebouw EN Z23 = per gebouw

            const gebouwtype = ntaSharedLogic.getGebouwType();

            return ntaData.ntaVersion.ntaVersionId >= 300
                && ntaSharedLogic.isNieuwbouw()
                && (
                    gebouwtype === 'TGEB_GRWON'
                    || gebouwtype === 'TGEB_APP'
                    || gebouwtype === 'TGEB_APPGEB' && ntaSharedLogic.perGebouw()
                    || ntaSharedLogic.perGebouwEnAppartement() && !isGebouw(berekeningId)
                );
        } //-- end: hasRisicoOververhitting -------------------------------------------------------

        function isKoelcapaciteit(entityDataId = _activeberekeningId) { // dontCheckTojuli igv BENG Indicator
            //[J][X][AC] toon alleen in gt3-2 EN berekening WN EN als bij minimaal 1 rekenzone is sprake van een actief koelsysteem (EN SA73 wordt getoond)

            const parentEntityDataId = _variantParent.EntityId === 'BASIS' ? null : _variantParent.EntityDataId;
            const buildingData = ntaSharedLogic.getVariantBuildingData(parentEntityDataId);
            const entityData = buildingData.get(entityDataId);
            if (!entityData) return false; // bij switchen wordt entityData in eerste instantie niet gevonden [4OkyZUF9]
            const koelingOpwekkers = entityData.EntityId === "GEB"
                ? buildingData.getListWithEntityId('KOEL-OPWEK')
                : buildingData.findEntities(entityData, 'UNIT-RZ.^RZ.^KOEL.KOEL-OPWEK' /*UNIT*/, '^RZ.^KOEL.KOEL-OPWEK', '^UNIT-RZ.^RZ.^KOEL.KOEL-OPWEK' /*RESULT-TOJULI*/);
            return koelingOpwekkers.some(opwekker => !resultsLogic.opwekkertypesPassieveKoeling.has(opwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value));
        } //-- end: isKoelcapaciteit --------------------------------------------------------------

        function getRisicoOververhitting(berekeningOrId) { //R12 [RO-BE]

            const entityDataId = typeof berekeningOrId === 'string' ? berekeningOrId : berekeningOrId.Id;

            // R12: 'Toon 'maak EP berekening per appartement' als TO28 = 'maak EP berekening per appartement' bij 1 of meer rekenzones
            // en anders toon 'voldoet niet' als TO28 = 'voldoet niet' bij 1 of meer rekenzones
            // en anders toon 'voldoet' als TO28 op het formulier TOjuli bij alle rekenzones is 'voldoet'.
            const texts = textRisicoOververhitting;
            const risicos = getResults(entityDataId, 'RESULT_TOJULI_RISICO');
            let risico = '';
            if (risicos.some(r => r === texts.maakBerekeningPerAppartement)) {
                risico = texts.maakBerekeningPerAppartement;
            } else if (risicos.some(r => r === texts.voldoetNiet)) {
                risico = texts.voldoetNiet;
            } else if (risicos.every(r => r === texts.voldoet || r === '') && risicos.some(r => r === texts.voldoet)) {
                risico = texts.voldoet;
            } else if (risicos.some(r => r === texts.ntb)) {
                risico = texts.ntb;
            }

            // opslaan voor de samenvatting (alleen voor het gebouw van de basisberekening NTA)
            if (!_variantParent?.EntityId !== 'VARIANT'
                && !_forTailoredAdvice
                && ntaEntityDataOrg.get(entityDataId)?.EntityId === 'GEB'
                && !hasRisicoOververhittingAlleApp()
                && !hasRisicoOververhittingAlleWoningen())
            {
                saveRisicoOververhittingIfChanged(risico);
            }

            return risico;
        } //-- end: getRisicoOververhitting -------------------------------------------------------

        function hasRisicoOververhittingAlleApp() {
            // BENG indicator condition [Z] toon als versie ge3.3 EN WN EN Z23 = per gebouw en per appartement
            return ntaData.ntaVersion.ntaVersionId >= 300
                && ntaSharedLogic.isNieuwbouw()
                && ntaSharedLogic.perGebouwEnAppartement();
        } //-- end: hasRisicoOververhittingAlleApp ------------------------------------------------

        function getRisicoOververhittingAlleApp() { //R12
            //R13: 'Toon 'voldoet' als TO28 op het formulier TOjuli bij alle rekenzones van alle appartementen is 'voldoet'.
            //      Toon 'voldoet niet' als TO28 = 'voldoet niet' bij 1 of meer rekenzones in 1 of meer appartementen.
            const texts = textRisicoOververhitting;
            const berekeningen = getBerekeningen()
                .filter(b => ntaEntityDataOrg.get(b.Id)?.EntityId !== 'GEB');
            const risicos = berekeningen.flatMap(b => getResults(b.Id, 'RESULT_TOJULI_RISICO'));
            let risico = '';
            if (risicos.every(r => r === texts.voldoet)) {
                risico = texts.voldoet;
            } else if (risicos.some(r => r === texts.voldoetNiet)) {
                risico = texts.voldoetNiet;
            } else if (risicos.some(r => r === texts.ntb)) {
                risico = texts.ntb;
            }

            // opslaan voor de samenvatting (alleen voor de basisberekening NTA)
            if (_variantParent?.EntityId !== 'VARIANT' && !_forTailoredAdvice)
            {
                saveRisicoOververhittingIfChanged(risico);
            }

            return risico;
        } //-- end: getRisicoOververhittingAlleApp ------------------------------------------------

        function hasRisicoOververhittingAlleWoningen() {
            return ntaData.ntaVersion.ntaVersionId >= 300
                && ntaSharedLogic.isNieuwbouw()
                && ntaSharedLogic.voorProjectwoningen();
        } //-- end: hasRisicoOververhittingAlleWoningen -------------------------------------------

        function getRisicoOververhittingAlleWoningen() { //R12
            //'(Toon 'voldoen' als G04 ≠ 'vakantiewoning' EN bij alle projectwoningen een groen vinkje staat bij BENG1, 2, 3 en risico oververhitting; OF
            //                     G04 = 'vakantiewoning' EN bij alle projectwoningen een groen vinkje staat bij BENG1, 2, 3;
            //  anders 'voldoen niet'.
            const woningen = getBerekeningen();
            const resBeng123 = woningen.every(berekening => ['EP_BENG1', 'EP_BENG2', 'EP_BENG3'].every(propId => checkResultEis(propId, berekening.Id)));
            const texts = textRisicoOververhitting;
            let risico = '';
            if (resBeng123 && (ntaSharedLogic.isVakantiewoning() || woningen.every(berekening => getRisicoOververhitting(berekening.Id) === texts.voldoet))) {
                risico = texts.voldoet;
            } else {
                risico = texts.voldoetNiet;
            }

            // opslaan voor de samenvatting (alleen voor de basisberekening NTA)
            if (_variantParent?.EntityId !== 'VARIANT' && !_forTailoredAdvice)
            {
                saveRisicoOververhittingIfChanged(risico);
            }

            return risico;
        } //-- end: getRisicoOververhittingAlleWoningen -------------------------------------------

        function isAppartementOfUnit() {
            const gebouwtype = ntaSharedLogic.getGebouwType();
            return gebouwtype === 'TGEB_APP' || gebouwtype === 'TGEB_UTILUNIT';
        };

        function isGebGebInstallatieEInvoer(propId = 'RESULT-ELEKTR_NIETGEBGEB') {
            if (ntaData.ntaVersion.ntaVersionId >= 303) {
                return false;
            }
            if (propId === 'RESULT-ELEKTR_NIETGEBGEB') {
                const propdata = _energiegebruikResultaat.PropertyDatas[propId];
                if (!propdata.Touched && propdata.Value !== "0") {
                    propdata.Value = "0";
                    const prop = ntaData.properties[propId];
                    if (ntaData.ntaVersion.ntaVersionId < 303) { // [H] indien versie ge3.3 dan niet aan te passen door gebruiker
                        saveValue(prop, _energiegebruikResultaat);
                    }
                }
                return ntaSharedLogic.isUtiliteit() || _energiegebruikResultaat.PropertyDatas["RESULT-ELEKTR_NIETGEBGEB_GEBR"].Value === "ELEKTR_NIETGEBGEB_GEBR_EW";
            } else {
                return false;
            }
        };

        function gebruikEntHasResult(gebruik) {
            return Boolean(gebruik) && gebruik.properties.some(propId => {
                return isGebGebInstallatieEInvoer(propId)
                    || ntaSharedLogic.parseFloat(_energiegebruikResultaat.PropertyDatas[propId]?.Value, 0) !== 0;
            });
        };

        function getCategoryName(cat) {
            switch (cat) {
                case "RESULT_VERW":
                    return "verwarming";
                case "RESULT_TAPW":
                    return "warm tapwater";
                case "RESULT_KOEL":
                    return "koeling";
                case "RESULT_VENT":
                    return "ventilatoren";
                case "RESULT_VERL":
                    return "verlichting";
                case "RESULT_BEVO":
                    return "bevochtiging";
                default:
                    return false;
            }
        };

        function heeftEisen() {
            return getEnergieprestatieRows()
                .some(row => row.hasLimit());
        };

        function propertyHeaderIsHidden(prop) {
            if (!prop) {
                return;
            }

            if (prop.Id === 'RESULT-ENERGIEFUNCTIE_CODE' ||
                prop.Id === 'RESULT-ENERGIEFUNCTIE_CAT' ||
                prop.Id === 'RESULT-ENERGIEFUNCTIE_EENHEID' ||
                prop.Id === 'RESULT-ENERGIEFUNCTIE_RESULTAAT') {
                return true;
            }

            return false;
        };

        function getCodedValues(prop) {
            if (!prop) {
                return;
            }
            return ntaValidation.codedValues(prop);
        };

        function isHidden(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let showit = true;

            const propdata = prop.getData(entdata);
            switch (prop.Id) {
                case 'RESULT-ENERGIEFUNCTIE_CODE':
                case 'RESULT-ENERGIEFUNCTIE_CAT':
                case 'RESULT-ENERGIEFUNCTIE_EENHEID':
                case 'RESULT-ENERGIEFUNCTIE_RESULTAAT':
                    showit = false;
                    break;

                case 'RESULT-ELEKTR_NIETGEBGEB_GEBR':
                    // [AC] verberg als versie ge3.3
                    showit = ntaData.ntaVersion.ntaVersionId < 303;
                    break;

            //    case 'RESULT-TOJULI_FORM_GTO':
            //        showit = isGtoVisible(entdata);
            //        ntaEntityDataOrg.setPropdataStatus(propdata, !showit, showit);
            //        break;

            //    case 'RESULT-TOJULI_FORM_TEMPMETH':
            //        // [L] tonen indien le3-2
            //        showit = !ntaSharedLogic.isUtiliteit() && ntaData.ntaVersion.ntaVersionId < 300;
            //        break;
            }

            return !showit;
        } //-- end: isHidden ----------------------------------------------------------------------

        //function isGtoVisible(toJuliForm) {
        //    if (ntaSharedLogic.isUtiliteit())
        //        return false;

        //    // [S] toon alleen als TO01 wordt getoond EN TO01 = GTO berekening conform Regeling Bouwbesluit
        //    const propdataMethode = toJuliForm.PropertyDatas["RESULT-TOJULI_FORM_TEMPMETH"]; // TO01
        //    if (propdataMethode?.Relevant && propdataMethode.Value === "TEMPMETH_GTO") // GTO berekening conform Regeling Bouwbesluit
        //        return true;

        //    const parentEntdata = ntaEntityDataOrg.getFirstParent(toJuliForm, ['GEB', 'UNIT']);
        //    const basis = ntaEntityDataOrg.getFirstWithEntityId("BASIS");
        //    const { resultEntdatasByEntityId } = ntaResults.getForParents(null, basis, false, ['RESULT-TOJULI_FORM']);
        //    let toJuliResults = resultEntdatasByEntityId.get('RESULT-TOJULI_FORM');
        //    if (parentEntdata.EntityId === 'GEB') {
        //        const gebToJuliResults = ntaEntityDataOrg.getListWithEntityId('RZ')
        //            .flatMap(rz => ntaEntityDataOrg.getChildren(rz, 'RESULT-TOJULI'));
        //        const gebToJuliResultSet = new Set(gebToJuliResults);
        //        toJuliResults = toJuliResults.filter(ed => gebToJuliResultSet.has(ed));
        //    } else if (parentEntdata.EntityId === 'UNIT') {
        //        const unitToJuliResults = ntaEntityDataOrg.findEntities(parentEntdata, 'UNIT-RZ.RESULT-TOJULI');
        //        const unitToJuliResultSet = new Set(unitToJuliResults);
        //        toJuliResults = toJuliResults.filter(ed => unitToJuliResultSet.has(ed));
        //    }
        //    const isGtoBerekening = toJuliResults
        //        .map(ed => ed.PropertyDatas["RESULT-TOJULI_AANW_AANV_BER"]) // TO27
        //        .some(pd => pd?.Relevant && pd.Value === "RESULT-TOJULI_AANW_AANV_BER2"); // GTO berekening volgens Omgevingsregeling en GTO ≤ 450

        //    return isGtoBerekening;
        //} //-- end: isGtoVisible ------------------------------------------------------------------

        function getName(prop) {
            return prop.Name || "";
        };

        function getUnit(prop) {
            return prop.Unit || "";
        };

        function getCalcProperty(prop) {
            return prop.CalcProperty || '    ';
        };


        function getIcon(berekening, propId) {
            const valid = checkResultEis(propId, berekening);
            if (valid === true) {
                return 'fa-check checkmark';
            } else if (valid === false) {
                return 'fa-times cross';
            } else {
                return '';
            }
        }

        function startFormValidation() {
            if (ntaData.ntaVersion.ntaVersionId < 303) {
                const propdataInvoer = _energiegebruikResultaat.PropertyDatas[_eGebruikProperty.Id];
                ntaValidation.IsValid(resultsLogic.form_buildingresults, _eGebruikProperty, propdataInvoer, ntaSharedLogic.isUtiliteit());

                const propdataNGgWaarde = _energiegebruikResultaat.PropertyDatas[_eProperty.Id];
                ntaValidation.IsValid(resultsLogic.form_buildingresults, _eProperty, propdataNGgWaarde, !isGebGebInstallatieEInvoer());
            }
        };

        function endFormValidation() {
            if (ntaData.ntaVersion.ntaVersionId < 303) {
                var propdata = _energiegebruikResultaat.PropertyDatas[_eGebruikProperty.Id];
                if (!propdata.Touched) {
                    propdata.Touched = true;
                    ntaEntityDataOrg.saveprop(propdata);
                }
                propdata = _energiegebruikResultaat.PropertyDatas[_eProperty.Id];
                if (!propdata.Touched) {
                    propdata.Touched = true;
                    ntaEntityDataOrg.saveprop(propdata);
                }
            }
            startFormValidation();
        };

        function gebouwBerekeningsInfo() {
            return ntaData.projecttree.Gebouwberekeningen.find(function (x) { return x.GebouwId === ntaData.buildingId; });
        };

        function isItemChecked(prop, entdata, item) {
            if (!prop || !entdata || !item)
                return false;
            const valuesSet = new Set(String(prop.getValue(entdata)).split('|'));
            return valuesSet.has(item.Id);
        } //-- end: isItemChecked -----------------------------------------------------------------

        function toggleItemChecked(prop, entdata, item) {
            let value = prop.getValue(entdata);
            const newValue = isItemChecked(prop, entdata, item) ? value.replace(item.Id + "|", "") : value ? value + item.Id + "|" : item.Id + "|";
            let propdata = prop.getData(entdata);
            if (propdata.Value !== newValue) {
                propdata.Value = newValue;
                saveValue(prop, entdata);
            }
        } //-- end: toggleItemChecked -------------------------------------------------------------

        const _energieprestatieRows = [
            {
                indicator: 'energiebehoefte',
                tile: 'Energiebehoefte',
                formula: 'Ewe<sub>H+C;nd;ventsys=C1</sub>',
                propId: 'EP_BENG1',
                unit: 'kWh/m²',
                hasLimit: () => ntaSharedLogic.isNieuwbouw() && isGebouw(),
            },
            {
                indicator: 'primaire fossiele energie',
                tile: 'Fossiele energie',
                formula: 'Ewe<sub>PTot</sub>',
                propId: 'EP_BENG2',
                unit: 'kWh/m²',
                hasLimit: () => ntaSharedLogic.isNieuwbouw() && isGebouw(),
            },
            {
                indicator: 'primaire fossiele energie - EMG forfaitair',
                isVisible: () => _isEMGforf,
                formula: 'Ewe<sub>PTot</sub>;EMGforf',
                propId: 'EP_BENG2_EMGFORF',
                unit: 'kWh/m²',
            },
            {
                indicator: 'aandeel hernieuwbare energie',
                tile: 'Hernieuwbare energie',
                formula: 'RER<sub>PrenTot</sub>',
                propId: 'EP_BENG3',
                unit: '%',
                hasLimit: () => ntaSharedLogic.isNieuwbouw() && isGebouw(),
            },
            {
                indicator: 'hernieuwbare energie indicator',
                formula: 'E<sub>wePRenTot</sub>',
                propId: 'RESULT-EP_HERNIEUWBARE_ENERGIE_INDICATOR',
                unit: 'kWh/m²',
            },
            {
                indicator: 'hernieuwbare energie indicator - EMG forfaitair',
                isVisible: () => _isEMGforf,
                formula: 'E<sub>wePRenTot;EMGforf</sub>',
                propId: 'RESULT-EP_HERNIEUWBARE_ENERGIE_INDICATOR_EMG_FORF',
                unit: 'kWh/m²',
            },
            {
                indicator: 'temperatuuroverschrijding',
                isVisible: () => hasTemperatuuroverschrijding(_activeberekeningId),
                tile: 'TO<sub>juli;max</sub>',
                formula: 'TO<sub>juli;max</sub>',
                propId: 'EP_TOJULI',
                unit: '',
                hasLimit: () => ntaSharedLogic.isNieuwbouw(),
            },
            {
                indicator: 'risico op oververhitting',
                isVisible: () => hasRisicoOververhitting(_activeberekeningId),
                tile: 'risico op oververhitting',
                formula: '',
                propId: 'RESULT_TOJULI_RISICO',
                unit: '',
                getValue: () => getRisicoOververhitting(_activeberekeningId),
                hasLimit: () => true,
            },
            {
                indicator: 'energielabel',
                isVisible: isEnergielabel,
                tile: 'Energielabel',
                formula: '',
                propId: 'EP_ENERGIELABEL',
                unit: '',
                getValue: () => getResult(_activeberekeningId, 'EP_ENERGIELABEL'),
            },
            {
                indicator: 'netto warmtebehoefte (EPV)',
                isVisible: () => !ntaSharedLogic.isUtiliteit(),
                formula: 'E<sub>H;nd;net</sub>',
                propId: 'RESULT-EP_WARMTEBEHOEFTE',
                unit: 'kWh/m²',
            },
            {
                indicator: getStandaardName(),
                isVisible: () => !ntaSharedLogic.isNieuwbouw(),
                formula: ntaSharedLogic.isUtiliteit() ? 'E<sub>wePTot;Renovatiestandaard</sub>' : 'E<sub>H;Standaard</sub>',
                propId: 'RESULT-NETTO_WARMTEVRAAG',
                unit: 'kWh/m²',
            },
        ].map(row => Object.assign({}, _energieprestatieRow, row));

    }; //== end: ResultsLogic =====================================================================

    async function saveRisicoOververhittingIfChanged(risico, buildingId = ntaData.buildingId) {
        const currentRisico = _risicoOververhittingByBuildingId.get(buildingId);
        if (currentRisico !== risico) {
            _risicoOververhittingByBuildingId.set(buildingId, risico);
            console.trace(`saveRisicoOververhitting(‘${risico}’, ${buildingId})`);
            await saveRisicoOververhitting(risico, buildingId);
        }
    } // -- end: saveRisicoOververhittingIfChanged ------------------------------------------------

    async function saveRisicoOververhitting(risico, buildingId) {
        if (!ntaData.canSaveBuilding(buildingId))
            return;

        try {
            const body = {
                buildingId,
                values: {
                    RESULT_TOJULI_RISICO: risico,
                },
            };
            const response = await fetch('/projects/updateSummary', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw response;
            }
            return await response.json();
        } catch (error) {
            $log.error(`Building ${buildingId}: saveRisicoOververhitting(‘${risico}’) mislukt:`, error);
        }
    } //-- end: saveRisicoOververhitting ----------------------------------------------------------

}]);
