﻿angular.module('projectModule')
    .factory("ZonneboilerFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaData', 'ntaRounding', 'ntaSharedLogic', 'ntaSelectionTable', 'ListCache',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaData,   ntaRounding,   ntaSharedLogic,   ntaSelectionTable,   ListCache) {
    'use strict';
    function ZonneboilerLogic(installId, ntaDependencyValidation) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INTERFACE              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const zonneboilerLogic = this;

        const _installationId = installId;
        const _appVersion = ntabuilding.appVersionId;

        const _propOpmerking = ntabuilding.properties['ZONNB_OPM'];
        const _propertiesZB = ntabuilding.properties['ZONNBSYS'];
        const _propertiesBV = ntabuilding.properties['BOILERVAT'];
        const _propertiesZC = ntabuilding.properties['ZONNECOL'];
        const _propertiesUZC = ntabuilding.properties['UNIT-ZONNC'];

        var _zonnbData = [];
        var _systeem = null;
        var _boilervat = null;
        var _collector = null;

        if (_installationId) {
            _zonnbData = ntaEntityData.getFirstChild(_installationId, 'ZONNB');

            _systeem = ntaEntityData.getFirstChild(_zonnbData, 'ZONNBSYS');
            _boilervat = ntaEntityData.getFirstChild(_systeem, 'BOILERVAT');
            _collector = ntaEntityData.getFirstChild(_systeem, 'ZONNECOL');
        }

        const _selectionTable = {
            'ZBSYS': [],
            'ZBCOL': [],
            'ZBVAT': [],
            'PV': [],
        };

        const _opstelplaatsen = [];
        const _listCache = new ListCache();

        const _productTypes = [
            { modelType: 'ZBSYS', entityId: 'ZONNB', productIdPropertyId: 'ZONNB_TSTL', invoerPropertyId: null, },
            { modelType: 'ZBCOL', entityId: 'ZONNC', productIdPropertyId: 'ZONNC_TYPE', invoerPropertyId: 'ZONNC_INV', valueSpecifiek: 'ZONNC_INV_PROD', valueForfaitair: 'ZONNC_INV_FORF', },
            { modelType: 'ZBVAT', entityId: 'BOILV', productIdPropertyId: 'BOILV_TYPE', invoerPropertyId: 'BOILV_INV', valueSpecifiek: 'BV_INV_PS', valueForfaitair: 'BV_INV_FF', },
            { modelType: 'PV', entityId: 'ZONNC', productIdPropertyId: 'ZONNC_PVT_TYPECEL', invoerPropertyId: 'ZONNC_INV', valueSpecifiek: 'n.v.t.', valueForfaitair: 'ZONNC_INV_FORF', },
        ];

        zonneboilerLogic.zonnbData = _zonnbData;
        zonneboilerLogic.systeem = _systeem;
        zonneboilerLogic.boilervat = _boilervat;
        zonneboilerLogic.collector = _collector;

        zonneboilerLogic.propOpmerkingen = _propOpmerking;
        zonneboilerLogic.propertiesZB = _propertiesZB;
        zonneboilerLogic.propertiesBV = _propertiesBV;
        zonneboilerLogic.propertiesZC = _propertiesZC;
        zonneboilerLogic.propertiesUZC = _propertiesUZC;

        zonneboilerLogic.isHidden = isHidden;
        zonneboilerLogic.isReadOnly = isReadOnly;

        zonneboilerLogic.validate = validate;
        zonneboilerLogic.validateDependencies = validateDependencies;

        zonneboilerLogic.startFormValidation = startFormValidation;
        zonneboilerLogic.endFormValidation = endFormValidation;

        zonneboilerLogic.saveValue = saveValue;
        zonneboilerLogic.getCodedValues = getCodedValues;

        zonneboilerLogic.getListItem = getListItem;
        zonneboilerLogic.getSelectionTable = getSelectionTable;
        zonneboilerLogic.saveValueSelectionTable = saveValueSelectionTable;
        zonneboilerLogic.setSelectedItemSelectionTable = setSelectedItemSelectionTable;
        zonneboilerLogic.dependencyValidator = ntaDependencyValidation;

        zonneboilerLogic.isCollectorTableVisible = isCollectorTableVisible;
        zonneboilerLogic.getCollectorsByUnits = getCollectorsByUnits;

        zonneboilerLogic.hasBelemmering = hasBelemmering;


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         INITIALIZATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        loadProductsFromServer('ZBSYS', _systeem);
        loadProductsFromServer('ZBVAT', _boilervat);
        loadProductsFromServer('ZBCOL', _collector);
        if (_appVersion > 1) {
            //-- VO 2021-09-09: Vanaf versie 2 wordt PVT voor zonnecollectoren volledig afgehandeld op het zonneboiler formulier en niet meer op het PV(T) formulier. Vandaar
            //-- dat hier nu ook de keuze gemaakt moet worden voor het type zonnecellen dat geldt voor dit systeem. Het type zonnecellen mag alleen gekozen worden uit de
            //-- forfaitaire producten uit de selectietabel tabblad 'PV'. Daarom heeft _productTypes met key 'PV' voor valueSpecifiek en valueForfaitair allebei 'ZONNC_INV_FORF'
            loadProductsFromServer('PV', _collector);
        }
        checkUnitCollectors();

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///         IMPLEMENTATION         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function getListItem(prop, entdata, list = null) {
            let listItem = null;

            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {

                let tmpList = null;
                let tmpId;
                const propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    case 'ZONNB_TSTL':
                    case 'BOILV_TYPE':
                    case 'ZONNC_TYPE':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItems;
                        break;
                    case 'ZONNC_PVT_TYPECEL':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItemsPvt;
                        break;
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                if (propData.Value && tmpList && tmpList.length > 0) {
                    listItem = tmpList.find(function (x) { return x.Id === tmpId; });
                }
            }
            return listItem;
        } //-- end: getListItem ------------------------------------------------------------------------//

        function setSelectedItemSelectionTable(prop, entdata, selectedItem = null) {
            // dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'ZONNB_TSTL':
                    case 'BOILV_TYPE':
                    case 'ZONNC_TYPE':
                        {
                            if (selectedItem) {
                                entdata.selectedItem = selectedItem;
                                entdata.searchText = selectedItem.Value;
                            } else {
                                entdata.selectedItem = null;
                                entdata.searchText = "";
                            }
                            break;
                        }
                    case 'ZONNC_PVT_TYPECEL':
                        {
                            if (selectedItem) {
                                entdata.selectedItemPvt = selectedItem;
                                entdata.searchTextPvt = selectedItem.Value;
                            } else {
                                entdata.selectedItemPvt = null;
                                entdata.searchTextPvt = "";
                            }
                            break;
                        }
                    default:
                        break;
                }
            }
        } //-- end: setSelectedItemSelectionTable ------------------------------------------------------------------------//

        function loadProductsFromServer(modelType, entdata) {
            const productTypeInfo = _productTypes.find(pt => pt.modelType === modelType);

            const products = ntaSelectionTable.getProducts(modelType, ntaSharedLogic.isUtiliteit());
            _selectionTable[modelType] = products;

            const prop = ntabuilding.properties[productTypeInfo.productIdPropertyId];
            if (prop) {
                getSelectionTable(prop, entdata);
                setSelectedItemSelectionTable(prop, entdata);
                setValuesDependingOnSelectionTable();
            }
        } //-- end: loadProductsFromServer ------------------------------------------------------------------------//

        function getSelectionTable(prop, entdata, searchText = "") {
            const productTypeInfo = _productTypes.find(pt => pt.productIdPropertyId === prop.Id);
            const table = productTypeInfo ? _selectionTable[productTypeInfo.modelType] : null;
            if (table && table.length > 0) {
                if (prop.Domain.DomainType === 4) { //selection table value
                    let res = table;

                    // Eerst filteren op productspecifiek/forfaitair, indien relevant
                    if (productTypeInfo.invoerPropertyId) {
                        const propInvoer = ntabuilding.properties[productTypeInfo.invoerPropertyId];
                        const invoerValue = propInvoer.getValue(entdata);
                        if (invoerValue === productTypeInfo.valueSpecifiek) {
                            res = res.filter(item => item.Certificate === 1);
                        } else if (invoerValue === productTypeInfo.valueForfaitair) {
                            res = res.filter(item => item.Certificate === 0);
                        }
                    }

                    // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                    // moeten de verlopen verklaringen er uit gefilterd worden.
                    if (ntaSharedLogic.showOnlyValidProducts()) {
                        res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                    }

                    switch (prop.Id) {
                        case 'ZONNB_TSTL': { // ZBA06
                            const type = entdata.PropertyDatas['ZONNB_TYPE'].Value; // ZBA04
                            if (type === 'ZONNB_TVOORW') {
                                res = res.filter(item => !item.Vbu);
                            } else if (type === 'ZONNB_TNAV') {
                                res = res.filter(item => item.Vbu > 0);
                            }
                            break;
                        }
                    }

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    // Tot slot filteren op de zoektekst
                    if (searchText) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }

                    //-- omdat voor zonncollectoren en pvtsystemen een selectitabel item voor dezelfde entitie geldt , moet filteredItems op deze manier
                    switch (prop.Id) {
                        case 'ZONNC_PVT_TYPECEL': {
                            entdata.filteredItemsPvt = res;
                            break;
                        }
                        default: {
                            entdata.filteredItems = res;
                            break;
                        }
                    }
                    ntaSharedLogic.checkPropdataInList(prop, entdata, res, zonneboilerLogic);
                    return res;
                }
            }
            return [];
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        function setValuesDependingOnSelectionTable() {
            let invoerZonnerboiler = _systeem.PropertyDatas['ZONNB_INV'];

            if (invoerZonnerboiler && invoerZonnerboiler.Value === 'ZONNB_COMPL') {
                if (_selectionTable.ZBSYS && _selectionTable.ZBSYS.length > 0) {
                    const propZonneboilerSysteem = _propertiesZB['ZONNB_TSTL'];
                    const product = getListItem(propZonneboilerSysteem, _systeem);

                    const propVolumeBoilervat = _propertiesBV["BOILV_VOL_NON"];
                    const propVolumeBackup = _propertiesBV["BOILV_VOL_BACKUP_NON"];
                    const propOppPerCollector = _propertiesZC["ZONNC_A_NON"];
                    const propAantalCollectoren = _propertiesZC["ZONNC_AANT_NON"];

                    if (!isHidden(propVolumeBoilervat, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Vsto', _boilervat, propVolumeBoilervat.Id); }
                    if (!isHidden(propVolumeBackup, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Vbu', _boilervat, propVolumeBackup.Id); }
                    if (!isHidden(propOppPerCollector, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Acol', _collector, propOppPerCollector.Id); }
                    if (!isHidden(propAantalCollectoren, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Ncol', _collector, propAantalCollectoren.Id); }

                    if (isCollectorTableVisible()) {
                        const propUnitAantalCollectoren = ntaData.properties['UNIT-ZONNC_AANT_NON'];
                        for (const unitCollector of getUnitCollectors()) {
                            if (!isHidden(propUnitAantalCollectoren, unitCollector)) {
                                ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Ncol', unitCollector, propUnitAantalCollectoren.Id);
                            }
                        }
                    }
                }
            }
            else if (invoerZonnerboiler && invoerZonnerboiler.Value === 'ZONNB_SEPA') {
                // Set boilervat en zonnecollector (zichtbaar)
                if (_selectionTable.ZBVAT && _selectionTable.ZBVAT.length > 0) {
                    /* Dit is een uitzonderlijke situatie. De selectietabel voor boilervaten is in de Excelspreadsheet (nog) niet gevuld met forfaitaire waarden.
                     * Alleen als ik gekozen heb voor productspecief moet ik de functie setValuesDependingOnSelectionTable aanroepen,
                     * anders worden de _NON velden met forfaitaire waarden uit het rekenhart overschreven door waarden uit de selectietabel die
                     * er niet zijn en worden ze op NULL of 0 gezet. Dat willen we niet. Vandaar hier deze specifieke aanroep. Als ik van productspecifiek
                     * naar forfaitair ga moeten de _NON velden wel op 0 gezet worden.*/

                    let propBoilervatInvoer = _propertiesBV['BOILV_INV'];
                    let valueBoilervatInvoer = propBoilervatInvoer.getValue(_boilervat);

                    if (valueBoilervatInvoer === 'BV_INV_PS') {
                        let propBoilervat = _propertiesBV['BOILV_TYPE'];
                        let product = getListItem(propBoilervat, _boilervat);

                        let propVolumeBoilervat = _propertiesBV["BOILV_VOL_NON"];
                        let propVolumeBackup = _propertiesBV["BOILV_VOL_BACKUP_NON"];
                        let propTransmissiefactor = _propertiesBV["BOILV_TRANSF_NON"];

                        if (!isHidden(propVolumeBoilervat, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Vsto', _boilervat, propVolumeBoilervat.Id); }
                        if (!isHidden(propVolumeBackup, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Vbu', _boilervat, propVolumeBackup.Id); }
                        if (!isHidden(propTransmissiefactor, _boilervat)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Hsto_ls', _boilervat, propTransmissiefactor.Id); }
                    }
                }

                if (_selectionTable.ZBCOL && _selectionTable.ZBCOL.length > 0) {
                    let propZonnecollector = _propertiesZC['ZONNC_TYPE'];
                    let product = getListItem(propZonnecollector, _collector);

                    // en hier dan iets voor de collector
                    let propMaximaleCollectorrendement = _propertiesZC["ZONNC_MAX_REND_NON"];
                    let propHoekAfhankelijkheid = _propertiesZC["ZONNC_HCOEFF_NON"];
                    let propWarmteverliescoefficient = _propertiesZC["ZONNC_WVCOEFF_NON"];
                    let propTemperatuurafhankelijkheid = _propertiesZC["ZONNC_TMP_WVCOEFF_NON"];
                    let propOppPerCollector = _propertiesZC["ZONNC_A_NON"];

                    if (!isHidden(propMaximaleCollectorrendement, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'n0', _collector, propMaximaleCollectorrendement.Id); }
                    if (!isHidden(propHoekAfhankelijkheid, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Khem_50', _collector, propHoekAfhankelijkheid.Id); }
                    if (!isHidden(propWarmteverliescoefficient, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'a1', _collector, propWarmteverliescoefficient.Id); }
                    if (!isHidden(propTemperatuurafhankelijkheid, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'a2', _collector, propTemperatuurafhankelijkheid.Id); }
                    if (!isHidden(propOppPerCollector, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'Acol', _collector, propOppPerCollector.Id); }
                }

                if (_selectionTable.PV && _selectionTable.PV.length > 0) {
                    //-- VO 2021-09-09: bij appVersion == 1 kom ik hier niet, want dan is mijn PV selectiontabel leeg
                    let propPVTcellen = _propertiesZC['ZONNC_PVT_TYPECEL'];
                    let product = getListItem(propPVTcellen, _collector);

                    // en hier dan iets voor de pvt cellen
                    let propWattPiekVermogen = _propertiesZC["ZONNC_PVT_WPM2_NON"];

                    if (!isHidden(propWattPiekVermogen, _collector)) { ntaSharedLogic.saveProductValueToPropData(zonneboilerLogic, product, 'KpkA', _collector, propWattPiekVermogen.Id); }
                }
            }
        } //-- end: setValuesDependingOnSelectionTable ------------------------------------------------------------------------//

        function validate(prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!propdata && prop && entdata) propdata = prop.getData(entdata);
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let hidden = isHidden(prop, entdata);
            let readonly = isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(zonneboilerLogic.form_zonnb, prop, propdata, hidden, readonly);

            switch (prop.Id) {
                case 'BOILV_VOL_BACKUP':
                    {
                        // Eerst correct zetten
                        ntaSharedLogic.setMelding("[D014]", propdata, zonneboilerLogic.form_zonnb, true);

                        if (!hidden) {
                            //-- voor deze propertie moet er een dynamisch melding gezet worden
                            let volumeBoilervat = null;

                            let propVolumeBoilervat1 = _propertiesBV['BOILV_VOL1'];
                            let propVolumeBoilervat2 = _propertiesBV['BOILV_VOL2'];

                            // Als BOILV_VOL1 zichtbaar is zijn waarde gebruiken
                            if (!isHidden(propVolumeBoilervat1, entdata)) {
                                volumeBoilervat = ntaSharedLogic.parseFloat(entdata.PropertyDatas['BOILV_VOL1'].Value);
                            }

                            // Als BOILV_VOL2 zichtbaar is zijn waarde gebruiken
                            if (!isHidden(propVolumeBoilervat2, entdata)) {
                                volumeBoilervat = ntaSharedLogic.parseFloat(entdata.PropertyDatas['BOILV_VOL2'].Value);
                            }

                            if (!isNaN(volumeBoilervat)) {
                                if (ntaSharedLogic.parseFloat(propdata.Value, 0) <= 0 || ntaSharedLogic.parseFloat(propdata.Value) > volumeBoilervat) {
                                    ntaSharedLogic.updateErrorReplaceObject(propdata, "volumeBoilervat", volumeBoilervat);
                                    ntaSharedLogic.setMelding("[D014]", propdata, zonneboilerLogic.form_zonnb, false);
                                }
                            }
                        }
                        break;
                    }
                case 'ZONNB_TYPE':
                    {
                        let validE069 = true;
                        let validE070 = true;

                        //-- op zoek naar tapwater toestel opwekker (TAPW-OPWEK_TYPE) die bij deze zonneboiler hoort.
                        const tapwatersysteem = ntaEntityData.getFirstParent(_zonnbData, 'TAPW');
                        if (tapwatersysteem && propdata.Value) {
                            const tapwateropwekker = ntaEntityData.getFirstChild(tapwatersysteem, 'TAPW-OPWEK');
                            if (tapwateropwekker) {
                                const typeopwekker = tapwateropwekker.PropertyDatas['TAPW-OPWEK_TYPE'].Value;
                                if (typeopwekker === 'TAPW-OPWEK_TYPE_18' || typeopwekker === 'TAPW-OPWEK_TYPE_19') {
                                    //-- [ZBBR] Indien voor 'voorverwarmer zonneboiler' gekozen wordt en key1 van table13_0 behorend bij de opwekker gekozen in TO05 van
                                    //-- het tapwatersysteem dat op de tegel van deze zonneboiler is aangevinkt = 'ZB' toon[E069]
                                    validE069 = propdata.Value === 'ZONNB_TNAV';
                                } else {
                                    //-- [ZBBQ] Indien voor 'zonneboiler met geïntegreerde naverwarming' gekozen wordt en key1 van table13_0 behorend bij de
                                    //-- opwekker gekozen in TO05 van het tapwatersysteem dat op de tegel van deze zonneboiler is aangevinkt ≠ 'ZB' toon[E070]
                                    validE070 = propdata.Value === 'ZONNB_TVOORW' || !propdata.Value; //nog geen keuze bij ZBA04
                                }
                            }
                        }

                        ntaSharedLogic.setMelding('[E069]', propdata, zonneboilerLogic.form_zonnb, validE069);
                        ntaSharedLogic.setMelding('[E070]', propdata, zonneboilerLogic.form_zonnb, validE070);

                        break;
                    }
                case 'ZONNC_TYPE':
                    {
                        let selectionTableValid = true;
                        let producten = entdata.filteredItems;

                        //-- Conditie [ZBCP]
                        if (producten && producten.length <= 0) { //-- Toon E098
                            selectionTableValid = false;
                        }

                        ntaSharedLogic.setMelding("[E098]", propdata, zonneboilerLogic.form_zonnb, selectionTableValid);

                        break;
                    }
                case 'UNIT-ZONNC_HEL': { // ZBC34
                    //-- Conditie [ZBCP]
                    const propdataOriëntatie = entdata.PropertyDatas['UNIT-ZONNC_ORIENT'];
                    if (propdataOriëntatie.Value === 'PVORIE_HOR') {
                        valid = true;
                        ntaSharedLogic.setMelding("[D003]", propdata, zonneboilerLogic.form_zonnb, valid);

                    }
                    break;
                }
            }

            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function saveValue(prop, entdata, newValue) {
            const propdata = prop && entdata && prop.getData(entdata);

            if (prop.Id === 'BOILV_OPSTELPL') {
                const opstelplaatsId = newValue === undefined ? propdata.Value : newValue;
                // controleer de koppeling met de opstelplaats. Het boilervat (entdata) is de child, de opstelplaats (de rekenzone met EntityDataId === opstelplaatsId) is de parent.
                const existingRelations = ntaEntityData.getParentRelations(entdata, 'RZ');
                const newRelationExists = existingRelations.some(rel => rel.Parent === opstelplaatsId);
                if (!newRelationExists) {
                    // Dan moeten we evt. bestaande koppeling(en) verwijderen
                    for (const existingRelation of existingRelations) {
                        ntaEntityData.deleteRelation(existingRelation.EntityRelationDataId);
                    }
                    // En als er daadwerkelijk een rekenzone gekozen is, maken we een nieuwe koppeling aan
                    const opstelplaatsRz = ntaEntityData.get(opstelplaatsId);
                    if (opstelplaatsRz) {
                        ntaEntityData.createRelation(opstelplaatsId, entdata.EntityDataId, 0, 0); //geen cascade -> als RZ wordt gekopieerd wil je niet Boilervat meekopiëren
                    }
                }
                // De property value moet wel gewoon opgeslagen worden, en alle validaties natuurlijk ook uitgevoerd
            }

            ntaSharedLogic.saveValue(prop, entdata, newValue, zonneboilerLogic);
        } //-- end: saveValue ------------------------------------------------------------------------//

        function saveValueSelectionTable(prop, item, entdata) {
            ntaSharedLogic.saveValueSelectionTable(prop, item, entdata, zonneboilerLogic);
        } //-- end: saveValueSelectionTable ------------------------------------------------------------------------//

        function setDescriptionInstallation() {
            //-- ik moet eerst weten of dit de eerste opwekker is.
            const propdataInstallDescr = ntaEntityData.get(_installationId).PropertyDatas['INSTALL_OMSCHR'];
            if (propdataInstallDescr) {
                let descr = "";

                const propInvoer = _propertiesZB['ZONNB_INV'];
                const invoer = propInvoer.getValue(_systeem);
                if (invoer === 'ZONNB_COMPL') {
                    const propToestel = _propertiesZB['ZONNB_TSTL'];
                    const toestelProductId = parseInt(propToestel.getValue(_systeem));
                    const product = _selectionTable.ZBSYS.find(p => p.Id === toestelProductId);
                    if (!product) {
                        descr = 'Compleet systeem - nog geen toestelkeuze gemaakt';
                    } else {
                        descr = 'Compleet systeem - ' + product.Value;
                    }

                } else if (invoer) {
                    const propCollector = _propertiesZC['ZONNC_TYPE'];
                    const propdataCollector = _collector.PropertyDatas[propCollector.Id];
                    const collectorProductId = parseInt(propdataCollector && propdataCollector.Value);
                    const product = _selectionTable.ZBCOL.find(p => p.Id === collectorProductId);
                    if (!product) {
                        descr = 'Separate componenten - nog geen componentenkeuze gemaakt';
                    } else {
                        descr = 'Separate componenten - ' + product.Value;
                    }

                } else {
                    descr = 'Er is nog geen keuze gemaakt voor het zonneboilersysteem';
                }

                ntaEntityData.saveprop(propdataInstallDescr, descr);
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//

        function isHidden(prop, entdata = null) {
            //-- VO 2022-09-29: deze isHidden wordt ook gebruikt voor de tabelheader en die gebruikt alleen de unitcollector properties, dus bij alle UNIT-ZONNC properties
            //-- goed opletten dat je geen entdata gebruikt en zo ja ff checken != null. In andere factory's heet dit propertyHeaderIsHidden. Voor
            //-- zonnecollectoren doen we dit in één, vandaar anders checken op entdata.
            if (!prop) return false;
            if (entdata && entdata.BuildingId !== ntabuilding.buildingId) return false;

            const propdata = entdata && prop.getData(entdata);

            let showit = true;
            let relevant = null;

            switch (prop.Id) {
                case 'ZONNB_TSTL': { // ZBA06
                    showit = false;

                    // Conditie ZBAA
                    let invoerZonneboiler = entdata.PropertyDatas['ZONNB_INV'].Value;
                    if (invoerZonneboiler && invoerZonneboiler === 'ZONNB_COMPL') {
                        showit = true;
                        /// Gebeurd hier en niet in de validate omdat deze property niet altijd zichtbaar is, maar wel direct
                        /// de melding moet tonen als hij zichtbaar wordt.
                        const selectionTableValid = getSelectionTable(prop, entdata).length > 0;
                        ntaSharedLogic.setMelding('[E042]', propdata, zonneboilerLogic.form_zonnb, selectionTableValid, true);
                    } else {
                        ntaSharedLogic.setMelding('[E042]', propdata, zonneboilerLogic.form_zonnb, true); //reset
                    }
                    break;
                }
                case 'BOILV_INV': { // ZBB01
                    // Conditie ZBBJ
                    showit = !conditieZBBJ();
                    break;
                }
                case 'BOILV_TYPE': { // ZBB02
                    showit = false;

                    // Conditie ZBBB
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                    if (invoerBoilervat && invoerBoilervat === 'BV_INV_PS' && !isHidden(propInvoerBoilervat, entdata)) {
                        showit = true;
                    }

                    break;
                }
                case 'BOILV_VOL1': { // ZBB03
                    showit = false;

                    // Conditie ZBBC
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                    if (invoerBoilervat && invoerBoilervat === 'BV_INV_EW' && !isHidden(propInvoerBoilervat, entdata)) {
                        showit = true;
                    }

                    break;
                }
                case 'BOILV_VOL2': { // ZBB04
                    showit = true;

                    // Conditie ZBBD
                    if (conditieZBBD()) {
                        showit = false;
                    }

                    break;
                }
                case 'BOILV_VOL_NON': { // ZBB05
                    showit = false;

                    // Conditie ZBAA
                    let zonneboiler = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
                    let invoerZonneboiler = zonneboiler.PropertyDatas['ZONNB_INV'].Value;
                    if (invoerZonneboiler && invoerZonneboiler === 'ZONNB_COMPL') {
                        showit = true;
                        relevant = true;
                        break;
                    }

                    // Conditie ZBBB
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                    if (invoerBoilervat && invoerBoilervat === 'BV_INV_PS' && !isHidden(propInvoerBoilervat, entdata)) {
                        showit = true;
                        relevant = true;
                        break;
                    }

                    break;
                }
                case 'BOILV_VOL_BACKUP': { // ZBB07
                    showit = false;

                    // Conditie ZBBG
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let valueInvoerBoilervat = propInvoerBoilervat.getValue(entdata);
                    let zonneboiler = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
                    let typeZonneboiler = zonneboiler.PropertyDatas['ZONNB_TYPE'].Value;
                    if (!isHidden(propInvoerBoilervat, entdata) && (valueInvoerBoilervat === 'BV_INV_EW' || valueInvoerBoilervat === 'BV_INV_FF_B') && typeZonneboiler === 'ZONNB_TNAV') {
                        showit = true;
                    }

                    break;
                }
                case 'BOILV_VOL_BACKUP_NON': { // ZBB08
                    showit = false;
                    relevant = false;

                    // Conditie ZBBM
                    let zonneboiler = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
                    let invoerZonneboiler = zonneboiler.PropertyDatas['ZONNB_INV'].Value;
                    let typeZonneboiler = zonneboiler.PropertyDatas['ZONNB_TYPE'].Value;

                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let valueInvoerBoilervat = propInvoerBoilervat.getValue(entdata);
                    let visibleInvoerBoilervat = !isHidden(propInvoerBoilervat, entdata);
                    if ((typeZonneboiler === 'ZONNB_TNAV' && invoerZonneboiler === 'ZONNB_COMPL') ||
                        (typeZonneboiler === 'ZONNB_TNAV' && visibleInvoerBoilervat && valueInvoerBoilervat === 'BV_INV_PS') ||
                        (typeZonneboiler === 'ZONNB_TNAV' && visibleInvoerBoilervat && valueInvoerBoilervat === 'BV_INV_FF_O')) {
                        showit = true;
                    }

                    if (valueInvoerBoilervat === 'BV_INV_FF_O') {
                        relevant = false; // Bij forfaitair onbekend moet het veld niet relevant zijn om een waarde uit de rekenkern te kunnen ontvangen.
                    }
                    break;
                }
                case 'BOILV_FABRJ': { // ZBB09
                    showit = true;

                    // Conditie ZBBD
                    if (conditieZBBD()) {
                        showit = false;
                    }
                    break;
                }
                case 'BOILV_ELBL': { // ZBB10
                    showit = false;

                    // Conditie ZBBH
                    let volumeBoilervat = ntaSharedLogic.parseFloat(entdata.PropertyDatas['BOILV_VOL2'].Value);
                    if (!isNaN(volumeBoilervat)) {
                        if (volumeBoilervat <= 500) {
                            let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                            let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                            let fabricagejaarBoilervat = entdata.PropertyDatas['BOILV_FABRJ'].Value;

                            if (invoerBoilervat && (invoerBoilervat === 'BV_INV_FF' || invoerBoilervat === 'BV_INV_FF_B' || invoerBoilervat === 'BV_INV_FF_O') && !isHidden(propInvoerBoilervat, entdata) && fabricagejaarBoilervat && fabricagejaarBoilervat !== 'BV_FJ_V16' && fabricagejaarBoilervat !== 'BV_FJ_ONBEK') {
                                showit = true;
                            }
                        }
                    }

                    break;
                }
                case 'BOILV_TRANSF': { // ZBB11
                    showit = false;

                    // Conditie ZBBC
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                    if (invoerBoilervat && invoerBoilervat === 'BV_INV_EW' && !isHidden(propInvoerBoilervat, entdata)) {
                        showit = true;
                    }
                    break;
                }
                case 'BOILV_TRANSF_NON': { // ZBB12
                    showit = false;
                    relevant = false;

                    // ZBBI
                    let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
                    let invoerBoilervat = entdata.PropertyDatas['BOILV_INV'].Value;
                    if (invoerBoilervat && (invoerBoilervat === 'BV_INV_FF' || invoerBoilervat === 'BV_INV_FF_B' || invoerBoilervat === 'BV_INV_FF_O' || invoerBoilervat === 'BV_INV_PS') && !isHidden(propInvoerBoilervat, entdata)) {
                        showit = true;
                        relevant = false; //is Usage 3 -> altijd niet relevant

                        if (invoerBoilervat === 'BV_INV_PS') {
                            relevant = true; // Bij product specifiek wel relevant
                        }
                    }
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'BOILV_OPSTELPL': { // ZBB13
                    // Condities [ZBBJ] en [ZBBN]
                    const rekenzones = ntaEntityData.findEntities(_zonnbData, '^VERW.RZ', '^TAPW.TAPW-UNIT!.^UNIT.UNIT-RZ.^RZ', '^TAPW.TAPW-UNIT-RZ!.^UNIT-RZ.^RZ');

                    showit = !(conditieZBBJ() || rekenzones.length <= 1);

                    relevant = true; // de opstelplaats is altijd relevant want moet altijd gekoppeld worden.
                    break;
                }
                case 'ZONNC_INV': { // ZBC01
                    // Conditie ZBBJ
                    showit = !conditieZBBJ();
                    break;
                }
                case 'ZONNC_TYPE': { // ZBC02
                    showit = false;

                    // Conditie ZBCB
                    let propInvoerCollector = _propertiesZC['ZONNC_INV'];
                    let invoerCollector = entdata.PropertyDatas['ZONNC_INV'].Value;
                    if (invoerCollector && (invoerCollector === 'ZONNC_INV_FORF' || invoerCollector === 'ZONNC_INV_PROD') && !isHidden(propInvoerCollector, entdata)) {
                        showit = true;
                        break;
                    }

                    break;
                }
                case 'ZONNC_MAX_REND': { // ZBC03
                    // Conditie ZBCC
                    showit = !conditieZBCC();
                    break;
                }
                case 'ZONNC_MAX_REND_NON': { // ZBC04
                    // Conditie ZBBJ en ZBCD
                    showit = !conditieZBBJenZBCD();
                    break;
                }
                case 'ZONNC_HCOEFF': { // ZBC05
                    // Conditie ZBCC
                    showit = !conditieZBCC();
                    break;
                }
                case 'ZONNC_HCOEFF_NON': { // ZBC06
                    // Conditie ZBBJ en ZBCD
                    showit = !conditieZBBJenZBCD();
                    break;
                }
                case 'ZONNC_WVCOEFF': { // ZBC07
                    // Conditie ZBCC
                    showit = !conditieZBCC();
                    break;
                }
                case 'ZONNC_WVCOEFF_NON': { // ZBC08
                    // Conditie ZBBJ en ZBCD
                    showit = !conditieZBBJenZBCD();
                    break;
                }
                case 'ZONNC_TMP_WVCOEFF': { // ZBC09
                    // Conditie ZBCC
                    showit = !conditieZBCC();
                    break;
                }
                case 'ZONNC_TMP_WVCOEFF_NON': { // ZBC10
                    // Conditie ZBBJ en ZBCD
                    showit = !conditieZBBJenZBCD();
                    break;
                }
                case 'ZONNC_INV_WVCOEFF': { // ZBC13
                    // Conditie ZBBJ
                    showit = !conditieZBBJ();
                    break;
                }
                case 'ZONNC_WVCOEFF_ALL': { // ZBC14
                    // Conditie ZBCH en als de unitcollectortabel niet zichtbaar is [ZBCO]
                    let propInvoerWarmtecoeff = _propertiesZC['ZONNC_INV_WVCOEFF'];
                    let invoerWarmtecoeff = entdata.PropertyDatas['ZONNC_INV_WVCOEFF'].Value;
                    showit = (invoerWarmtecoeff && invoerWarmtecoeff === 'ZONNC_WVCOEFF_BEK' && !isHidden(propInvoerWarmtecoeff, entdata) && !conditieZBCO());
                    break;
                }
                case 'ZONNC_VERM_PMP': { // ZBC15
                    // Conditie ZBCC en als de unitcollectortabel niet zichtbaar is [ZBCO]
                    showit = !conditieZBCC() && !conditieZBCO();
                    break;
                }
                case 'ZONNC_VERM_PMP_NON': { // ZBC16
                    // Conditie ZBBJ en ZBCD en als de unitcollectortabel niet zichtbaar is [ZBCO]
                    showit = !conditieZBBJenZBCD() && !conditieZBCO() && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'ZONNC_PVT': { // ZBC17
                    // Conditie [ZBCE]
                    const propInvoerCollector = ntabuilding.properties['ZONNC_INV'];
                    const invoerCollector = propInvoerCollector.getValue(entdata);
                    showit = ['ZONNC_INV_FORF', 'ZONNC_INV_EIG'].includes(invoerCollector) && !isHidden(propInvoerCollector, entdata);

                    break;
                }
                case 'ZONNC_PVT_TYPECEL':
                case 'ZONNC_PVT_WPM2_NON':
                    {
                        // Conditie [ZBCM]
                        const propInvoerCollector = ntabuilding.properties['ZONNC_INV'];
                        const invoerCollector = propInvoerCollector.getValue(entdata);
                        const propInvoerPvt = ntabuilding.properties['ZONNC_PVT'];
                        const invoerPvt = propInvoerPvt.getValue(entdata);
                        showit =
                            invoerCollector === 'ZONNC_INV_FORF' &&
                            !isHidden(propInvoerCollector, entdata) &&
                            invoerPvt !== 'ZONNC_PVT_GEEN' &&
                            !isHidden(propInvoerPvt, entdata);
                        break;
                    }
                case 'ZONNC_PVT_WPM2':
                    {
                        // Conditie [ZBCO]
                        const propInvoerCollector = ntabuilding.properties['ZONNC_INV'];
                        const invoerCollector = propInvoerCollector.getValue(entdata);
                        const propInvoerPvt = ntabuilding.properties['ZONNC_PVT'];
                        const invoerPvt = propInvoerPvt.getValue(entdata);
                        showit =
                            invoerCollector === 'ZONNC_INV_EIG' &&
                            !isHidden(propInvoerCollector, entdata) &&
                            invoerPvt !== 'ZONNC_PVT_GEEN' &&
                            !isHidden(propInvoerPvt, entdata);
                        break;
                    }
                case 'ZONNC_A': { // ZBC18
                    showit = true;

                    // Conditie ZBBJ
                    let zonneboiler = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
                    let invoerZonneboiler = zonneboiler.PropertyDatas['ZONNB_INV'].Value;
                    if (invoerZonneboiler && invoerZonneboiler === 'ZONNB_COMPL') {
                        showit = false;
                        relevant = false;
                        break;
                    }

                    // Conditie ZBCJ
                    let propInvoerCollector = _propertiesZC['ZONNC_INV'];
                    let invoerCollector = entdata.PropertyDatas['ZONNC_INV'].Value;
                    if (invoerCollector && invoerCollector === 'ZONNC_INV_PROD' && !isHidden(propInvoerCollector, entdata)) {
                        showit = false;
                        relevant = false;
                        break;
                    }

                    break;
                }
                case 'ZONNC_A_NON': { // ZBC19
                    showit = false;

                    // Conditie ZBAA
                    let zonneboiler = ntaEntityData.getFirstParent(entdata, 'ZONNBSYS');
                    let invoerZonneboiler = zonneboiler.PropertyDatas['ZONNB_INV'].Value;
                    if (invoerZonneboiler && invoerZonneboiler === 'ZONNB_COMPL') {
                        showit = true;
                        relevant = true;
                        break;
                    }

                    // Conditie ZBCK
                    let propInvoerCollector = _propertiesZC['ZONNC_INV'];
                    let invoerCollector = entdata.PropertyDatas['ZONNC_INV'].Value;
                    if (invoerCollector && invoerCollector === 'ZONNC_INV_PROD' && !isHidden(propInvoerCollector, entdata)) {
                        showit = true;
                        relevant = true;
                        break;
                    }

                    break;
                }
                case 'ZONNC_AANT': { // ZBC20
                    // Condities [ZBCN] en [ZBBJ]
                    showit = conditieZBCN() && !conditieZBBJ();
                    break;
                }
                case 'ZONNC_AANT_NON': { // ZBC21
                    // Conditie [ZBAA]
                    const invoerZonneboiler = _systeem.PropertyDatas['ZONNB_INV'].Value;
                    showit = conditieZBCN() && invoerZonneboiler === 'ZONNB_COMPL';
                    break;
                }
                case 'ZONNC_ORIENT': { // ZBC22
                    // Conditie [ZBCN]
                    showit = conditieZBCN();
                    break;
                }
                case 'ZONNC_HEL': { // ZBC23
                    showit = false;

                    // Conditie [ZBCF]
                    const orientatie = entdata.PropertyDatas['ZONNC_ORIENT'].Value;
                    if (orientatie !== 'PVORIE_HOR') {
                        showit = conditieZBCN();
                    }
                    relevant = conditieZBCN();
                    break;
                }
                case 'ZONNC_PVT_BOUWINTRG': { // ZBC25
                    // Conditie [ZBCQ]
                    const propInvoerPvt = ntabuilding.properties['ZONNC_PVT'];
                    const invoerPvt = propInvoerPvt.getValue(entdata);
                    showit = conditieZBCN()
                        && invoerPvt !== 'ZONNC_PVT_GEEN'
                        && !isHidden(propInvoerPvt, entdata);
                    break;
                }
                case 'ZONNC_BESCH': { // ZBC24
                    showit = conditieZBCN();
                    break;
                }
                case 'UNIT-ZONNC_VERM_PMP_NON': {
                    showit = !conditieZBBJenZBCD() && conditieZBCO() && conditieZBCI() && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'UNIT-ZONNC_VERM_PMP': {
                    showit = !conditieZBCC() && conditieZBCO() && conditieZBCI();
                    break;
                }
                case 'UNIT-ZONNC_AANT': { // ZBC31
                    // Conditie [ZBBJ]
                    showit = !conditieZBBJ();
                    break;
                }
                case 'UNIT-ZONNC_AANT_NON': { // ZBC32
                    // Conditie [ZBAA]
                    const invoerZonneboiler = _systeem.PropertyDatas['ZONNB_INV'].Value;
                    showit = invoerZonneboiler === 'ZONNB_COMPL';
                    break;
                }
                case 'UNIT-ZONNC_HEL': { // ZBC34
                    showit = true;
                    break;
                }
                case 'UNIT-ZONNC_WVCOEFF_ALL': { // ZBC14
                    // Conditie ZBCH en als de unitcollectortabel wel zichtbaar is [ZBCO]
                    let propInvoerWarmtecoeff = _propertiesZC['ZONNC_INV_WVCOEFF'];
                    let invoerWarmtecoeff = _collector.PropertyDatas['ZONNC_INV_WVCOEFF'].Value;
                    showit = (!isHidden(propInvoerWarmtecoeff, _collector)) && conditieZBCO() && (invoerWarmtecoeff === 'ZONNC_WVCOEFF_BEK');
                    break;
                }
                case 'UNIT-ZONNC_PVT_BOUWINTRG': {
                    // Conditie [ZBCQ]
                    const propInvoerPvt = ntabuilding.properties['ZONNC_PVT'];
                    const invoerPvt = propInvoerPvt.getValue(_collector);
                    showit = invoerPvt !== 'ZONNC_PVT_GEEN'
                        && !isHidden(propInvoerPvt, _collector);
                    break;
                }
                case 'MEASURE-COSTS_SUBS_INV':
                case 'MEASURE-COSTS_SUBS_PER_M2':
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
                default: {
                    if (ntabuilding.properties['BELEMMERING'].includes(prop) && entdata) {
                        if (entdata.EntityId !== 'BELEMMERING') {
                            showit = hasBelemmering(entdata);
                        } else {
                            const parent = ntaEntityData.getFirstParent(entdata);
                            const propdataBelemmering = parent && (parent.PropertyDatas['ZONNC_BESCH'] || parent.PropertyDatas['UNIT-ZONNC_BESCH']);
                            if (propdataBelemmering) {
                                showit = propdataBelemmering.Visible
                                    && ntaSharedLogic.showBelemmeringsProperty(prop, entdata, propdataBelemmering.Value, true);
                            }
                        }
                    }
                }
            }

            // standaard is relevant gelijk aan visible
            if (relevant === null) relevant = showit;

            if (propdata) {
                //--- voor de isHidden van de header van tabellen is de relevantie zetten niet nodig.
                const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
                ntaEntityData.setPropdataStatus(propdatas, relevant, showit);
            }

            return !showit;
        } // isHidden


        zonneboilerLogic.getPropData = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            let propdata = ntaSharedLogic.getPropData(prop, entdata);
            switch (prop.Id) {
                case 'BOILV_OPSTELPL': {
                    const zone = ntaEntityData.getFirstParent(entdata, 'RZ');
                    if (zone) {
                        propdata.Value = zone.EntityDataId;
                    }
                    break;
                }
                case 'UNIT-ZONNC_HEL': {
                    // Conditie [ZBCP]
                    if (entdata.PropertyDatas['UNIT-ZONNC_ORIENT'].Value === 'PVORIE_HOR') {
                        propdata = Object.assign({}, propdata, {
                            Value: 'n.v.t.',
                        });
                    }
                }
            }

            return propdata;
        } //-- end: getPropData ------------------------------------------------------------------------//

        function conditieZBCI() {
            // [ZBCI] verberg kolom indien zonneboiler systeem alleen aan een verwarmingssysteem is gekoppeld op het formulier installaties
            return ntaEntityData.getParentRelations(_zonnbData).filter(x => x.ParentEntityId !== "INSTALLATIE" && x.ParentEntityId !== "VERW").length > 0;
        } //-- end: conditieZBCI ----------------------------------------------------------------//

        function conditieZBCN() {
            // [ZBCN] verberg als Z23=voor woningen. Verberg ook als: Z23 = per gebouw en per appartement en het verwarmingssysteem HO42 / tapwatersysteem TO10 is een niet-gemeenschappelijk systeem
            return !conditieZBCO();
        } //-- end: conditieZBCN ----------------------------------------------------------------//

        function conditieZBCO() {
            // [ZBCO] toon tabel met de rijen alleen als Z23 = voor projectwoningen
            let showit = false;

            switch (ntaSharedLogic.getCalcUnit()) {
                case "RZUNIT_PROJECT":  // voor projectwoningen
                    showit = true;
                    break;
            }

            return showit;
        } //-- end: conditieZBCO ----------------------------------------------------------------//

        function conditieZBBJ() {
            const invoerZonneboiler = _systeem.PropertyDatas['ZONNB_INV'].Value;
            return invoerZonneboiler === 'ZONNB_COMPL';
        } //-- end: conditieZBBJ ------------------------------------------------------------------------//

        function conditieZBCC() {
            let propInvoerCollector = _propertiesZC['ZONNC_INV'];
            let invoerCollector = _collector.PropertyDatas[propInvoerCollector.Id].Value;
            if (invoerCollector === 'ZONNC_INV_EIG' && !isHidden(propInvoerCollector, _collector)) {
                return false;
            }
            return true;
        } //-- end: conditieZBCC ------------------------------------------------------------------------//

        function conditieZBBJenZBCD() {
            // Conditie ZBBJ verberg
            let invoerZonneboiler = _systeem.PropertyDatas['ZONNB_INV'].Value;
            if (invoerZonneboiler === 'ZONNB_COMPL') {
                return true;
            }

            // Conditie ZBCD verberg
            let propInvoerCollector = _propertiesZC['ZONNC_INV'];
            let invoerCollector = _collector.PropertyDatas['ZONNC_INV'].Value;
            if (invoerCollector === 'ZONNC_INV_EIG' && !isHidden(propInvoerCollector, _collector)) {
                return true;
            }

            return false;
        } //-- end: conditieZBBJenZBCD ------------------------------------------------------------------------//

        function conditieZBBD() {
            let propInvoerBoilervat = _propertiesBV['BOILV_INV'];
            let invoerBoilervat = _boilervat.PropertyDatas['BOILV_INV'].Value;
            if (invoerBoilervat && (invoerBoilervat === 'BV_INV_FF' || invoerBoilervat === 'BV_INV_FF_B' || invoerBoilervat === 'BV_INV_FF_O') && !isHidden(propInvoerBoilervat, _boilervat)) {
                return false;
            }
            return true;
        } //-- end: conditieZBBD ------------------------------------------------------------------------//

        function getOpstelplaatsen() {
            const rekenzones = ntaEntityData.findEntities(_zonnbData, '^VERW.RZ', '^TAPW.TAPW-UNIT!.^UNIT.UNIT-RZ.^RZ', '^TAPW.TAPW-UNIT-RZ!.^UNIT-RZ.^RZ');

            const gebouwtype = ntaSharedLogic.getGebouwType();
            const opstelplaatsen = ntabuilding.properties['BOILV_OPSTELPL'].Domain.Codes
                .filter(function (codedValue) {
                    switch (codedValue.Id) { // condities [ZBBS] en [ZBBT]
                        case 'BOILV_NIET_IN_APPT': return gebouwtype === 'TGEB_APP';
                        case 'BOILV_NIET_IN_UNIT': return gebouwtype === 'TGEB_UTILUNIT';
                        default: return true;
                    }
                });

            // Controleer of er iets is veranderd; zo ja, dan moeten we de lijst opnieuw genereren
            const changed = (rekenzones.length + opstelplaatsen.length) !== _opstelplaatsen.length
                || rekenzones.some((rz, index) => rz.EntityDataId !== _opstelplaatsen[index].Id);

            if (changed) {
                const codedValues = rekenzones.map(function (rz) {
                    return {
                        Id: rz.EntityDataId,
                        Value: 'boilervat in zone: ' + rz.PropertyDatas['RZ_OMSCHR'].Value,
                        Order: rz.Order,
                        ImageUrl: '',
                    };
                });
                _opstelplaatsen.length = 0;
                _opstelplaatsen.push(...codedValues);
                _opstelplaatsen.push(...opstelplaatsen);
            }

            return _opstelplaatsen;
        } //-- end: getOpstelplaatsen ------------------------------------------------------------------------//

        function isCollectorTableVisible() {
            // [ZBCO] toon tabel met de rijen alleen als Z23 = voor projectwoningen
            const showit = conditieZBCO();

            //-- verzamel alleen de UNIT-ZONNC die horen bij de units die aangesloten zijn op dit zonnecollectorsysteem
            const unitCollectors = getCollectorsByUnits().map(cu => cu.entdata);
            ntaEntityData.setEntityVisibility(unitCollectors, showit);
            ntaEntityData.setEntityRelevancy(unitCollectors, showit);

            for (const unitCollector of unitCollectors) {
                // hasBelemmering zet de BELEMMERING-entiteit op Relevant (of niet), en kijkt daarbij ook of de unitCollector relevant is of niet.
                hasBelemmering(unitCollector);
            }

            //--  tabel mag alleen zichtbaar zijn als er unitcollectoren zijn. het kan zijn dat het zonneboiler systeem nog niet is aangesloten op een verwarming of tapwater
            //-- systeem, en dan zijn er geen rijen om weer te geven. De pager kan niet omgaan met 0 rijen, daarom vang ik het hier af.
            return unitCollectors.length > 0 && showit;
        } //-- end: isCollectorTableVisible -----------------------------------------------------//

        function checkUnitCollectors() {
            //-- controleer of er per unit die aangesloten is op dit zonnecollectorsysteem een UNIT-ZONNC is dit gebeurd eigenlijk allemaal in isCollectorTableVisible();
            isCollectorTableVisible();
        } //-- end: checkUnitCollectors --------------------------------------------------------------------------------//

        function getUnitCollectors() {
            return ntaEntityData.getChildren(_collector, 'UNIT-ZONNC');
        } //-- end: getUnitCollectors -----------------------------------------------------------//

        function getCollectorsByUnits() {
            const unitCollectors = getUnitCollectors();
            const units = ntaEntityData.findEntities(_zonnbData, "^VERW.RZ.UNIT-RZ.^UNIT", "^TAPW.TAPW-UNIT!.^UNIT", "^TAPW.TAPW-UNIT-RZ!.^UNIT-RZ.^UNIT")
                .sort(ntaData.orderByEntityIdAndOrder);
            const items = units.map(unit => ({
                unit,
                entdata: unitCollectors.find(uc => ntaEntityData.isRelation(unit, uc)),
            }));
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (!item.entdata) {
                    // Dan ontbreekt er een UNIT-ZONNC, en moeten we deze aanmaken
                    const parentRels = [
                        { "OnCopy": 1, "OnDelete": 1, "Parent": item.unit.EntityDataId, "ParentEntityId": item.unit.EntityId },
                        { "OnCopy": 1, "OnDelete": 1, "Parent": _collector.EntityDataId, "ParentEntityId": _collector.EntityId },
                    ];
                    const newId = ntaEntityData.create('UNIT-ZONNC', -1, parentRels);
                    item.entdata = ntaEntityData.get(newId);
                }
            }

            // Gebruik de gecachete lijst, en pas deze alleen aan als er iets is gewijzigd, om geen problemen met AngularJS te krijgen.
            return _listCache.useCacheIfUnchanged('unitCollectors', items, (a, b) => a.unit === b.unit && a.entdata === b.entdata);
        } //-- end: getCollectorsByUnits -----------------------------------------------------------//

        function hasBelemmering(entdata) {
            const belemmering = ntaEntityData.getFirstChild(entdata, 'BELEMMERING')
                || ntaEntityData.get(ntaEntityData.create('BELEMMERING', -1, [{ OnCopy: true, OnDelete: true, Parent: entdata.EntityDataId, ParentEntityId: entdata.EntityId }]));

            let result = entdata.Relevant;
            if (result) {
                const propdataBelemmeringtype = entdata.PropertyDatas['UNIT-ZONNC_BESCH'] || entdata.PropertyDatas['ZONNC_BESCH'];
                if (propdataBelemmeringtype) {
                    result = !['BELEMTYPE_MIN', 'BELEMTYPE_VOLLEDIG', 'BELEMTYPE_OVERIG', 'n.v.t.'].includes(propdataBelemmeringtype.Value);
                }
            }

            ntaEntityData.setEntityRelevancy(belemmering, result);
            ntaEntityData.setEntityVisibility(belemmering, result);

            return result;
        } //-- end: hasBelemmering --------------------------------------------------------------//

        function isReadOnly(prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            switch (prop.Id) {
                case "BOILV_VOL_NON":
                case "BOILV_TRANSF_NON":
                case "BOILV_VOL_BACKUP_NON":
                case "ZONNC_MAX_REND_NON":
                case "ZONNC_HCOEFF_NON":
                case "ZONNC_WVCOEFF_NON":
                case "ZONNC_TMP_WVCOEFF_NON":
                case "ZONNC_VERM_PMP_NON":
                case "ZONNC_A_NON":
                case "ZONNC_AANT_NON":
                case "ZONNC_PVT_WPM2_NON":
                case "UNIT-ZONNC_VERM_PMP_NON":
                case "UNIT-ZONNC_AANT_NON":
                case 'MEASURE-COSTS_SUBS_TOTAAL':
                case 'MEASURE-COSTS_TOTAAL': {
                    return true;
                }
                case "UNIT-ZONNC_HEL": { // ZBC34
                    // Conditie [ZBCP]
                    return entdata.PropertyDatas["UNIT-ZONNC_ORIENT"].Value === "PVORIE_HOR";
                }
                default: {
                    return false;
                }
            }
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        function getCodedValues(prop, entdata) {
            let typesList = prop && prop.Domain && prop.Domain.Codes || [];

            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return typesList;
            }

            switch (prop.Id) {
                case 'ZONNB_TYPE': { // ZBA04
                    // Conditie [ZBAD]
                    /// Het rendement voor ruimteverwarming voor de naverwarmer wordt niet
                    /// gegeven in de NTA, en een geïntegreerde naverwarmer voor verwarming
                    /// komt in praktijk ook niet voor.
                    const heeftVerwarming = ntaEntityData.getParentRelations(_zonnbData, 'VERW').length > 0;
                    if (heeftVerwarming) {
                        typesList = typesList.filter(function (x) { return x.Id !== 'ZONNB_TNAV'; });
                    }
                    break;
                }
                case 'ZONNB_INV': { // ZBA05
                    // Conditie [ZBAB]
                    const heeftVerwarming = ntaEntityData.getParentRelations(_zonnbData, 'VERW').length > 0;
                    if (heeftVerwarming) {
                        typesList = typesList.filter(function (x) { return x.Id !== 'ZONNB_COMPL'; });
                    }
                    break;
                }
                case 'BOILV_INV': { // ZBB01
                    let typeZonneboiler = _systeem.PropertyDatas['ZONNB_TYPE'].Value;

                    // Conditie ZBBO
                    if (typeZonneboiler === 'ZONNB_TNAV') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'BV_INV_FF'; });
                    }

                    // Conditie ZBBP
                    if (typeZonneboiler === 'ZONNB_TVOORW') {
                        typesList = typesList.filter(function (x) { return x.Id !== 'BV_INV_FF_B' && x.Id !== 'BV_INV_FF_O'; });
                    }

                    // Conditie ZBBK
                    if (_selectionTable.ZBVAT && _selectionTable.ZBVAT.length <= 0) {
                        typesList = typesList.filter(function (x) { return x.Id !== 'BV_INV_PS'; });
                    }

                    break;
                }
                case 'BOILV_FABRJ': { // ZBB09
                    let volumeBoilervat = ntaSharedLogic.parseFloat(entdata.PropertyDatas['BOILV_VOL2'].Value);
                    if (!isNaN(volumeBoilervat)) {
                        if (volumeBoilervat <= 500) { // Conditie ZBBE
                            typesList = typesList.filter(function (x) { return x.Id !== 'BV_FJ_V18'; });
                        }
                        else { // Conditie ZBBF
                            typesList = typesList.filter(function (x) { return x.Id !== 'BV_FJ_V16' && x.Id !== 'BV_FJ_1617'; });
                        }
                    }
                    else {
                        typesList = typesList.filter(function (x) { return x.Id !== 'BV_FJ_V16' && x.Id !== 'BV_FJ_1617'; });
                    }

                    break;
                }
                case 'BOILV_OPSTELPL': { // ZBB13
                    typesList = getOpstelplaatsen();
                    break;
                }
                case 'ZONNC_BESCH':  // ZBC24
                case 'UNIT-ZONNC_BESCH': { // ZBC24
                    const filterValue = "SYS";
                    typesList = ntaValidation.codedValues(prop, filterValue, true);
                    break;
                }
            }

            ntaSharedLogic.checkPropdataInList(prop, entdata, typesList, zonneboilerLogic);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//



        function validateDependencies(prop, entdata) {
            //-- Ontvangt afhankelijk veld dat gevalideerd moet worden
            if (!prop || !entdata) {
                return;
            }

            let propdata = entdata.PropertyDatas[prop.Id];

            switch (prop.Id) {
                case 'BOILV_VOL1':
                case 'BOILV_VOL2':
                    {
                        let propVolumeBackupVat = _propertiesBV['BOILV_VOL_BACKUP'];
                        let propdataVolumeBackupVat = _boilervat.PropertyDatas[propVolumeBackupVat.Id];
                        validate(propVolumeBackupVat, propdataVolumeBackupVat, entdata);
                        break;
                    }
                case 'BOILV_INV':
                    {
                        /* Dit is een uitzonderlijke situatie. De selectietabel voor boilervaten is (nog) niet gevuld met forfaitaire waarden.
                         * Alleen als ik gekozen heb voor productspecief moet ik de functie setValuesDependingOnSelectionTable aanroepen,
                         * anders worden de _NON velden met forfaitaire waarden uit het rekenhart overschreven door waarden uit de selectietabel die
                         * er niet zijn en worden ze op NULL of 0 gezet. Dat willen we niet. Vandaar hier deze specifieke aanroep. Als ik van productspecifiek
                         * naar forfaitair ga moeten de _NON velden wel op 0 gezet worden.`Dit kan ik niet doen in de setValues want dan worden de berekende
                         * forfaitaire waarden altijd op NULL gezet. Dus moet het alleen als ik het invoertype wijzig naar forfaitair.*/
                        if (propdata.Value === 'BV_INV_PS') {
                            setValuesDependingOnSelectionTable();
                        }
                        if (propdata.Value === 'BV_INV_FF' || propdata.Value === 'BV_INV_FF_B' || propdata.Value === 'BV_INV_FF_O') {
                            let propVolumeBoilervat = _propertiesBV["BOILV_VOL_NON"];
                            let propVolumeBackup = _propertiesBV["BOILV_VOL_BACKUP_NON"];
                            let propTransmissiefactor = _propertiesBV["BOILV_TRANSF_NON"];
                            if (!isHidden(propVolumeBoilervat, _boilervat)) { saveValue(propVolumeBoilervat, _boilervat, null); }
                            if (!isHidden(propVolumeBackup, _boilervat)) { saveValue(propVolumeBackup, _boilervat, null); }
                            if (!isHidden(propTransmissiefactor, _boilervat)) { saveValue(propTransmissiefactor, _boilervat, null); }
                        }
                        break;
                    }
                case 'ZONNC_ORIENT':
                    {
                        const propHellingshoek = _propertiesZC['ZONNC_HEL'];
                        const propdataHellingshoek = _collector.PropertyDatas[propHellingshoek.Id];

                        // Conditie [ZBCF]
                        if (propdata.Value === 'PVORIE_HOR') {
                            const zeroHoek = ntaRounding.roundAndAddZerosNewValue(propHellingshoek, 0);
                            if (propdataHellingshoek.Value !== zeroHoek) {
                                propdataHellingshoek.Value = zeroHoek;
                                ntaSharedLogic.setMelding("[D003]", propdataHellingshoek, null, true);
                                ntaEntityData.saveprop(propdataHellingshoek);
                            }
                        } else {
                            validate(propHellingshoek, propdataHellingshoek, entdata);
                        }
                        break;
                    }
                case 'UNIT-ZONNC_ORIENT': {
                    const propHellingshoek = ntaData.properties['UNIT-ZONNC_HEL'];
                    const propdataHellingshoek = propHellingshoek.getData(entdata);

                    // Conditie [ZBCP]
                    if (propdata.Value === 'PVORIE_HOR') {
                        const zeroHoek = ntaRounding.roundAndAddZerosNewValue(propHellingshoek, 0);
                        if (propdataHellingshoek.Value !== zeroHoek) {
                            propdataHellingshoek.Value = zeroHoek;
                            ntaEntityData.saveprop(propdataHellingshoek);
                        }
                    }
                    validate(propHellingshoek, propdataHellingshoek, entdata);
                    break;
                }
                case 'ZONNB_TYPE':
                case 'ZONNB_INV': {
                    validate('ZONNB_TSTL', null, entdata);
                    setValuesDependingOnSelectionTable();
                    setDescriptionInstallation();
                    break;
                }
                case 'ZONNC_INV':
                    {
                        let propTypeCollector = _propertiesZC['ZONNC_TYPE'];
                        let propdataTypeCollector = _collector.PropertyDatas[propTypeCollector.Id];
                        getSelectionTable(propTypeCollector, _collector);
                        validate(propTypeCollector, propdataTypeCollector, _collector);
                        setValuesDependingOnSelectionTable();
                        break;
                    }
                case 'ZONNB_TSTL':
                    {
                        setValuesDependingOnSelectionTable();
                        setDescriptionInstallation();
                        break;
                    }
                case 'BOILV_TYPE':
                case 'ZONNC_TYPE':
                    {
                        setValuesDependingOnSelectionTable();
                        setDescriptionInstallation();
                        break;
                    }
                case 'ZONNC_PVT_TYPECEL':
                    {
                        setValuesDependingOnSelectionTable();
                        break;
                    }
                default:
                    isHidden(prop, entdata);
                    if (ntaValidation.hasCodedValues(prop)) {
                        getCodedValues(prop, entdata);
                    }
                    break;
            }
        } //-- end: validateDependencies ------------------------------------------------------------------------//

        function startFormValidation() {
            const opstelplaatsRz = ntaEntityData.getFirstParent(_boilervat, 'RZ');
            if (!opstelplaatsRz) {
                // nog geen relatie met rz. Dit moet wel. Bij één RZ is veld hidden en kan de gebruiker de opstelplaats niet koppelen. Dus bij de init koppelen -> relatie aanmaken
                const rzs = ntaEntityData.getListWithEntityId("RZ");
                if (rzs.length > 0) {
                    ntaEntityData.createRelation(rzs[0].EntityDataId, _boilervat.EntityDataId, 0, 0); //geen cascade -> als RZ wordt gekopieerd wil je niet Boilervat meekopieren
                }
            } else {
                const propdataOpstelplaats = _boilervat.PropertyDatas['BOILV_OPSTELPL'];
                ntaEntityData.saveprop(propdataOpstelplaats, opstelplaatsRz.EntityDataId);
            }

            isCollectorTableVisible();

            return ntaSharedLogic.startFormValidation(getAllEntDatas(), zonneboilerLogic);
        } //-- end: startFormValidation ------------------------------------------------------------------------//

        function endFormValidation() {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), zonneboilerLogic);
        } //-- end: endFormValidation ------------------------------------------------------------------------//

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            const unitCollectors = getUnitCollectors();
            return []
                .concat(_systeem)
                .concat(_boilervat)
                .concat(_collector)
                .concat(unitCollectors)
                .concat(unitCollectors.map(entdata => ntaEntityData.getFirstChild(entdata, 'BELEMMERING')))
                .concat(ntaSharedLogic.getCostEntdata(_installationId))
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ------------------------------------------------------------------------//


    }

    return ZonneboilerLogic;
}]);
