﻿angular.module('projectModule')
    .factory("KoelingFactory",
        ['ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaMeldingen', 'ntaRounding', 'ntaSharedLogic', 'ntaSelectionTable', 'ntaData',
function (ntabuilding,   ntaValidation,   ntaEntityData,   ntaMeldingen,   ntaRounding,   ntaSharedLogic,   ntaSelectionTable,   ntaData) {
    'use strict';

    function KoelingLogic(installId, ntaDependencyValidation) {
        const koelingLogic = this;

        koelingLogic.installId = installId;
        koelingLogic.selectionTable = [];
        koelingLogic.selectionTableRegios = [];

        koelingLogic.startFormValidation = function () {
            ntaSharedLogic.startFormValidation(getAllEntDatas(), koelingLogic);
        };

        koelingLogic.endFormValidation = function () {
            ntaSharedLogic.endFormValidation(getAllEntDatas(), koelingLogic);
        };

        function getAllEntDatas() {
            return [].concat(
                koelingLogic.koelOpwekkersData,
                koelingLogic.koelDistributieData,
                koelingLogic.koelDistributieBinnenData,
                koelingLogic.koelDistributieBuitenData,
                koelingLogic.koelDistributieEigenschappenLeidingenData,
                koelingLogic.koelDistributiePompData,
                koelingLogic.koelAfgifteData,
                koelingLogic.koelAfgifteData.flatMap(afgifte => getAfgifteVentilatoren(afgifte)),
                ntaSharedLogic.getCostEntdata(installId),
            ).filter(ed => ed); // null en undefined wegfilteren
        }

        // Properties
        koelingLogic.propOpmerkingen = ntabuilding.properties['KOEL_OPM'];
        koelingLogic.koelOpwekkerProperties = ntabuilding.properties['KOEL-OPWEK'];
        koelingLogic.koelDistributieProperties = ntabuilding.properties['KOEL-DISTR'];
        koelingLogic.koelDistributiePompProperties = ntabuilding.properties['KOEL-DISTR-POMP'];
        koelingLogic.koelAfgifteProperties = ntabuilding.properties['KOEL-AFG'];
        koelingLogic.koelAfgifteVentilatorenProperties = ntabuilding.properties['KOEL-AFG-VENT'];

        // Table10_0
        function createTable10_0() {
            const table10_0 = [
                { Id: 'KOEL-OPWEK_TYPE_1', prio: 9, key2: 'COMP', key3: 'product', Value: '' }, //compressiekoeling - elektrisch
                { Id: 'KOEL-OPWEK_TYPE_2', prio: 10, key2: 'COMP', key3: '', Value: '' }, //compressiekoeling - gas
                { Id: 'KOEL-OPWEK_TYPE_3', prio: 2, key2: 'VKO', key3: 'product', Value: '' }, //koudeopslag - grondwater
                { Id: 'KOEL-OPWEK_TYPE_4', prio: 3, key2: 'VKO', key3: 'product', Value: '' }, //koudeopslag - bodem
                { Id: 'KOEL-OPWEK_TYPE_5', prio: 4, key2: 'VKO', key3: '', Value: '' }, //koudeopslag - oppervlaktewater
                { Id: 'KOEL-OPWEK_TYPE_6', prio: 5, key2: 'DPK', key3: '', Value: '' }, //dauwpuntskoeling
                { Id: 'KOEL-OPWEK_TYPE_7', prio: 11, key2: 'EK', key3: 'product', Value: '' }, //externe koudelevering
                { Id: 'KOEL-OPWEK_TYPE_8', prio: 7, key2: 'ABS', key3: '', Value: '' }, //absorptiekoeling - gas
                { Id: 'KOEL-OPWEK_TYPE_9', prio: 8, key2: 'ABS', key3: '', Value: '' }, //absorptiekoeling - externe warmtelevering
                { Id: 'KOEL-OPWEK_TYPE_10', prio: 6, key2: 'ABS', key3: '', Value: '' }, //absorptiekoeling - WKK
                { Id: 'KOEL-OPWEK_TYPE_11', prio: 1, key2: 'BWP', key3: 'product', Value: '' }, //boosterwarmtepomp
                { Id: 'KOEL-OPWEK_TYPE_12', prio: 12, key2: 'ONB', key3: '', Value: '' } //onbekende gemeenschappelijke opwekker
            ];

            const opwekkerCodedValues = ntabuilding.properties["KOEL-OPWEK_TYPE"].Domain.Codes;

            table10_0.forEach(function (row, index) {
                const codedValue = opwekkerCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue) {
                    row.Value = codedValue.Value;
                } else {
                    row.Delete = true;
                }
            });

            return table10_0.filter(row => !row.Delete);
        }

        koelingLogic.opwekkerTypes = createTable10_0();

        // Table10_2
        function createTable10_2() {
            const table10_2 = [
                { Id: 'KOEL-AFG_TYPE_RUIM_1', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_2', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '0,5', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_3', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '1', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_4', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '1,2', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_5', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_6', key0: 'le3.2', key1: 'forfaitair',    delta_ctr: '-2,5', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_7', key0: 'le3.2', key1: 'gecertificeerd volgens NEN-EN 215 of NEN-EN 15500', delta_ctr: '-1,5', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_8', key0: 'gt3.2', key1: '',              delta_ctr: '-1,25', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_9', key0: 'gt3.2', key1: '',              delta_ctr: '-0,75', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_10', key0: 'gt3.2', key1: '',             delta_ctr: '-2,5', delta_roomaut: '0', Value: '' },
                { Id: 'KOEL-AFG_TYPE_RUIM_11', key0: 'gt3.2', key1: '',             delta_ctr: '-2,5', delta_roomaut: '0', Value: '' },
            ];

            const temperatureRegulationCodedValues = ntabuilding.properties["KOEL-AFG_TYPE_RUIM"].Domain.Codes;

            table10_2.forEach(function (row, index) {
                const codedValue = temperatureRegulationCodedValues.find(function (x) { return x.Id === row.Id; });
                if (codedValue) {
                    row.Value = codedValue.Value;
                }
            });

            return table10_2;
        }

        koelingLogic.ruimteTemperatuurRegelingTypes = createTable10_2();

        // Koeling id
        koelingLogic.koelData = ntaEntityData.getFirstChild(koelingLogic.installId, 'KOEL');
        koelingLogic.koelDataId = koelingLogic.koelData.EntityDataId;

        // Koeling-opwekking relaties
        koelingLogic.setKoelOpwekkers = function () {
            koelingLogic.koelOpwekkersData = ntaEntityData.getChildren(koelingLogic.koelDataId, 'KOEL-OPWEK');
            setEnergiefracties();
        };

        koelingLogic.opwekkerNr = function (opwekker) {
            return koelingLogic.koelOpwekkersData.indexOf(opwekker) + 1;
        };

        koelingLogic.pompNr = function (pomp) {
            return koelingLogic.koelDistributiePompData.indexOf(pomp) + 1;
        };

        koelingLogic.pompNaam = function (pomp) {
            return 'pomp ' + koelingLogic.pompNr(pomp);
        };

        // Distributie
        koelingLogic.koelDistributieData = ntaEntityData.getFirstChild(koelingLogic.koelDataId, 'KOEL-DISTR');

        // Distributie binnen alleen nog relevant ntaVersionId < 300 (le3.2)
        koelingLogic.koelDistributieBinnenProperties = ntabuilding.properties['KOEL-DISTR-BIN'];
        koelingLogic.koelDistributieBinnenData = ntaEntityData.getFirstChild(koelingLogic.koelDistributieData, 'KOEL-DISTR-BIN');
        koelingLogic.conditieKDAN = conditieKDAN; //-- is binnen distr zichtbaar

        // Distributie buiten
        koelingLogic.koelDistributieBuitenProperties = ntabuilding.properties['KOEL-DISTR-BUI'];
        koelingLogic.koelDistributieBuitenData = ntaEntityData.getFirstChild(koelingLogic.koelDistributieData, 'KOEL-DISTR-BUI');

        // Distributie leidingen
        function getDistributieEigenschappenLeidingenData() {
            koelingLogic.koelDistributieEigenschappenLeidingenBinnenData = ntaEntityData.getFirstChild(koelingLogic.koelDistributieBinnenData, 'KOEL-DISTR-EIG');
            koelingLogic.koelDistributieEigenschappenLeidingenBuitenData = ntaEntityData.getFirstChild(koelingLogic.koelDistributieBuitenData, 'KOEL-DISTR-EIG');
            let data = [];
            if (koelingLogic.koelDistributieEigenschappenLeidingenBinnenData) data.push(koelingLogic.koelDistributieEigenschappenLeidingenBinnenData);
            if (koelingLogic.koelDistributieEigenschappenLeidingenBuitenData) data.push(koelingLogic.koelDistributieEigenschappenLeidingenBuitenData);

            return data;
        }

        koelingLogic.koelDistributieEigenschappenLeidingenProperties = ntabuilding.properties['KOEL-DISTR-EIG'];
        koelingLogic.koelDistributieEigenschappenLeidingenData = getDistributieEigenschappenLeidingenData();

        koelingLogic.koelDistributiePompData = ntaEntityData.getChildren(koelingLogic.koelDistributieData, 'KOEL-DISTR-POMP');

        // Afgifte
        koelingLogic.afgifteTypes = koelingLogic.koelAfgifteProperties["KOEL-AFG_TYPE_AFG"].Domain.Codes;

        koelingLogic.setKoelAfgiftes = function () {
            koelingLogic.koelAfgifteData = ntaEntityData.getChildren(koelingLogic.koelDataId, 'KOEL-AFG')
                .filter(afgifte => afgifte.Relevant);
        };
        koelingLogic.setKoelAfgiftes();

        koelingLogic.rekenzones = ntaEntityData.getChildren(koelingLogic.koelDataId, 'RZ')
            .filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ');

        // Afgiftesysteem nummer
        koelingLogic.afgifteNr = function (afgifte) {
            return koelingLogic.koelAfgifteData.indexOf(afgifte) + 1;
        };

        koelingLogic.getAfgifteRekenzones = getAfgifteRekenzones;
        function getAfgifteRekenzones(afgifte) {
            return ntaEntityData.getParents(afgifte, 'RZ')
                .filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ');
        }

        koelingLogic.getAfgifteVentilatoren = getAfgifteVentilatoren;

        const _afgifteventilatoren = {};

        function getAfgifteVentilatoren(afgifte, rekenzone = null) {
            //-- als rekenzone null is, dan worden alle ventilatoren van het afgiftesysteem opgehaald
            const rekenzoneVentilatorIds = rekenzone && new Set(ntaEntityData.getChildIds(rekenzone, 'KOEL-AFG-VENT'));
            const rekenzoneAfgifteventilatoren = ntaEntityData.getChildren(afgifte, 'KOEL-AFG-VENT')
                .filter(afgVent => !rekenzone || rekenzoneVentilatorIds.has(afgVent.EntityDataId));

            /// Er moet altijd minstens één KOEL-AFG-VENT zijn voor elke combinatie van afgifte en rekenzone.
            if (rekenzoneAfgifteventilatoren.length === 0 && rekenzone) {
                const afgifteventilatoren = getAfgifteVentilatoren(afgifte, null);

                /// Het kan zijn dat dit een oude berekening is, die geen koppeling heeft tussen KOEL-AFG-VENT en RZ.
                ///  In dat geval moeten alle VERW-AFG-VENTs aan de eerste rekenzone gekoppeld worden.
                const ventilatorenZonderRZ = afgifteventilatoren
                    .filter(afgVent => ntaEntityData.getParents(afgVent, 'RZ').filter(rz => rz.PropertyDatas['RZ_TYPEZ'].Value === 'RZ').length === 0);
                if (ventilatorenZonderRZ.length > 0) {
                    const firstRekenzone = getAfgifteRekenzones(afgifte)[0];
                    if (firstRekenzone) {
                        for (const ventilator of ventilatorenZonderRZ) {
                            ntaEntityData.createRelation(firstRekenzone.EntityDataId, ventilator.EntityDataId, 1, 1);
                            ventilator.PropertyDatas.forEach(propdata => propdata.Touched = true);
                        }
                        return getAfgifteVentilatoren(afgifte, rekenzone);
                    }
                }

                /// Anders moeten we een nieuwe afgifteventilator maken, en deze koppelen aan dit afgiftesysteem en deze rekenzone.
                const ventilatorId = ntaEntityData.create('KOEL-AFG-VENT', -1, [
                    { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
                    { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
                ], [], null);

                const newVentilator = ntaEntityData.get(ventilatorId);
                rekenzoneAfgifteventilatoren.push(newVentilator);
                newVentilator.PropertyDatas.forEach(propdata => propdata.Touched = true);
            }

            const key = afgifte.EntityDataId + ':' + (rekenzone && rekenzone.EntityDataId || '');
            let cachedList = _afgifteventilatoren[key];
            if (!cachedList || cachedList.length !== rekenzoneAfgifteventilatoren.length || cachedList.some((cached, index) => cached !== rekenzoneAfgifteventilatoren[index])) {
                cachedList = rekenzoneAfgifteventilatoren;
                _afgifteventilatoren[key] = cachedList;
            }
            return cachedList;
        } //-- end: getafgifteVentilatoren ------------------------------------------------------------------------//

        for (const afgifte of koelingLogic.koelAfgifteData) {
            let rekenzones = getAfgifteRekenzones(afgifte);

            // Als het afgiftesysteem is gekoppeld aan een rekenzone waar het koelsysteem zelf niet aan gekoppeld is, dan moeten we die loskoppelen
            const fouteRekenzoneIds = new Set(rekenzones.filter(rz => !koelingLogic.rekenzones.includes(rz)).map(rz => rz.EntityDataId));
            if (fouteRekenzoneIds.size > 0) {
                const relations = ntaEntityData.getParentRelations(afgifte, 'RZ').filter(rel => fouteRekenzoneIds.has(rel.Parent));
                for (const relation of relations) {
                    ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                }
                rekenzones = rekenzones.filter(rz => !fouteRekenzoneIds.has(rz.EntityDataId));
            }

            for (const rekenzone of rekenzones) {
                getAfgifteVentilatoren(afgifte, rekenzone);
            }
        }

        koelingLogic.setDescriptionInstallation = function (opwekker) {
            //-- ik moet eerst weten of dit de eerste opwekker is.
            if (koelingLogic.opwekkerNr(opwekker) === 1) {
                const propdataInstallDescr = ntaEntityData.get(koelingLogic.installId).PropertyDatas['INSTALL_OMSCHR'];
                if (propdataInstallDescr) {
                    let descr = "";
                    const propType = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TYPE'];
                    const codeType = propType.getCode(opwekker);
                    if (codeType) {
                        const propToestel = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
                        const toestelProductId = parseInt(propToestel.getValue(opwekker));
                        const product = toestelProductId && koelingLogic.selectionTable.find(p => p.Id === toestelProductId);
                        if (koelingLogic.isHidden(propToestel, opwekker) || !product) {
                            //-- beschrijving is type opwekker
                            descr = codeType.Value;
                        } else {
                            //-- beschrijving is de keuze uit selectietabel
                            descr = product.Value;
                        }
                    }
                    ntaEntityData.saveprop(propdataInstallDescr, descr);
                }
            }
        } //-- end: setDescriptionInstallation ------------------------------------------------------------------------//

        ///////////////////////////////SELECTIONTABLE//////////////////////////////////////////////////////////////////////////////////////////////
        function initSelectionTable() {
            koelingLogic.getSelectionTableServer();
        } //-- end: initSelectionTable ------------------------------------------------------------------------//

        koelingLogic.setDefaultValuesOpwekker = function (opwekker) {
            let propdataAgTotaal = opwekker.PropertyDatas['KOEL-OPWEK_A'];

            if (!propdataAgTotaal.Touched) {
                propdataAgTotaal.Value = determineAgTotaal();
            }

            opwekker.filteredItems = [];
            opwekker.filteredItemsRegios = [];
        };

        function setDefaultValuesOpwekkers() {
            koelingLogic.koelOpwekkersData.forEach(function (opwekker, index) {
                koelingLogic.setDefaultValuesOpwekker(opwekker);
            });
        } //-- end: setDefaultValuesOpwekkers ------------------------------------------------------------------------//

        // Gefilterde selectiontable opvragen zonder validate aan toe roepen om infinite loop te voorkomen
        function getCodedValuesSelectionTable(prop, entdata, searchText = "") {
            if (prop.Domain.DomainType === 4) { //selection table value
                var propInv = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_INVOER'];
                var propReg = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_REGIO'];

                var propdataInvoer = entdata.PropertyDatas[propInv.Id];

                var res = koelingLogic.selectionTable;

                var propdataType = entdata.PropertyDatas['KOEL-OPWEK_TYPE'];
                var propdataRegio = entdata.PropertyDatas[propReg.Id];

                //filter op opwekker
                let opwekType = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === propdataType.Value; });
                if (opwekType) {
                    res = res.filter(function (x) { return x.Opwekker === opwekType.Value; });
                }
                //nu moet ik weten of ik forfaitair ben of niet. checken op deze values omdat de zelfde lijst als bij verwarming wordt gebruikt
                switch (propdataInvoer.Value) {
                    case "VERW-OPWEK_INVOER_PROD":
                        res = res.filter(function (x) { return x.Certificate === 1; });
                        break;
                    case "VERW-OPWEK_INVOER_FORF":
                        res = res.filter(function (x) { return x.Certificate === 0; });
                        break;
                    default:
                        res = [];
                        break;
                }

                // Als bij Settings is aangevinkt dat alleen de actuele verklaringen gekozen mogen,
                // moeten de verlopen verklaringen er uit gefilterd worden.
                if (ntaSharedLogic.showOnlyValidProducts()) {
                    res = res.filter(r => r.ExpiryDate == null || Date.parse(r.ExpiryDate) > Date.now());
                }

                // filter voor welke regio het product geldt.
                if (!koelingLogic.isHidden(propReg, entdata)) {
                    res = res.filter(function (x) { return x.Regio === propdataRegio.Value; });
                }

                // Filter op jaar
                if (conditieKOBC(entdata)) { // filter op conditie [KOBC] ipv op KO33, want die bestaat niet vóór versie 3.1.
                    let vergunningsjaar;
                    const propVergunningjaar = ntabuilding.properties['KOEL-OPWEK_VERGJR_BRON'];
                    if (propVergunningjaar) { // dan zitten we in versie 3.1+
                        const codedValueVergunningjaar = propVergunningjaar && propVergunningjaar.getCode(entdata);
                        vergunningsjaar = codedValueVergunningjaar && codedValueVergunningjaar.Value;
                    } else { // dan zitten we in versie 3.0
                        vergunningsjaar = 'realisatiejaar grondwater bron voor 2013';
                    }
                    if (vergunningsjaar) {
                        res = res.filter(x => x.Fabricagejaar === null || x.Fabricagejaar === vergunningsjaar);
                    }
                } else {
                    let propFabricagejaar = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_FABR'];
                    let propdataFabricagejaar = entdata.PropertyDatas[propFabricagejaar.Id];
                    if (propdataFabricagejaar && propdataFabricagejaar.Relevant) {
                        let fabrjaarCodedValues = propFabricagejaar.Domain.Codes;
                        if (propdataFabricagejaar.Value) {
                            let sFilter = fabrjaarCodedValues.find(function (x) { return x.Id === propdataFabricagejaar.Value; }).Value;
                            res = res.filter(function (x) { return x.Fabricagejaar === sFilter || x.Fabricagejaar === null; });
                        }
                    }
                }

                // Conditie KOAQ, selecteer dezelfde boosterwarmtepomp als in de opwekker van tapwater
                let boosterwarmtepompTapwater = getBoosterwarmtepompTapwater();
                if (koelingLogic.opwekkerNr(entdata) === 1 && boosterwarmtepompTapwater) {
                    let toestelPropdata = boosterwarmtepompTapwater.PropertyDatas['TAPW-OPWEK_TOES'];
                    let intId = parseInt(toestelPropdata.Value);
                    res = res.filter(function (x) { return x.Id === intId; });
                }

                if (opwekType && opwekType.Id === "KOEL-OPWEK_TYPE_10" && res.length === 5) {
                    //-- ik ben nu wkk. Hier ga ik de volgorden van de producten even handmatig aanpassen. Dit gebeurd automatisch op alfabetische volgorde, maar dan
                    //-- staat 1000 kW boven 2 kW.Dat is niet wenselijk. Ik plaats dus de eerste naar de laatste.
                    const firstelement = res.shift();
                    res.push(firstelement);
                }

                if (res.length === 1) {
                    res.Singleresult = true;
                }

                // Filter op zoektekst
                if (res.length > 1 && searchText && searchText.length > 0) {
                    res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                }

                return res;
            }
        } //-- end: getCodedValuesSelectionTable ------------------------------------------------------------------------//

        koelingLogic.getSelectionTable = function (prop, entdata, searchText = "") {
            if (koelingLogic.selectionTable.length > 0) {

                var res = [];
                if (prop.Domain.DomainType === 4) { //selection table value
                    res = getCodedValuesSelectionTable(prop, entdata, searchText);
                } else if (prop.Domain.DomainType === 5) { // identiek namen uit de selectietabel voor een bepaalde filter kolom.
                    res = koelingLogic.selectionTableRegios;

                    if (res.length === 1) {
                        res.Singleresult = true;
                    }

                    if (res.length > 1 && searchText && searchText.length > 0) {
                        res = res.filter(function (x) { return x.Value && x.Value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1; });
                    }
                }

                switch (prop.Id) {
                    case 'KOEL-OPWEK_TOEW':
                        entdata.filteredItems = res;
                        break;
                    case 'KOEL-OPWEK_REGIO':
                        entdata.filteredItemsRegios = res;
                        break;
                    default:
                        break;
                }

                CheckPropdataInList(prop, entdata, res);
                return res;
            }
        } //-- end: getSelectionTable ------------------------------------------------------------------------//

        koelingLogic.getSelectionTableServer = function () {
            const products = ntaSelectionTable.getProducts('C', ntaSharedLogic.isUtiliteit());
            koelingLogic.selectionTableRegios = products.filter(function (x) { return x.Regio !== null; });
            let uniqueList = [];
            koelingLogic.selectionTableRegios.forEach(function (regio, index) {
                let unique = true;
                uniqueList.forEach(function (uniqueRegio, index2) {
                    if (regio.Regio === uniqueRegio.Value) {
                        unique = false;
                    }
                });
                if (unique) {
                    var newRegio = {
                        Id: regio.Regio,
                        Value: regio.Regio,
                        Certificate: regio.Certificate,
                        Individueel: regio.Individueel,
                        Opwekker: regio.Opwekker,
                        IsValid: true, // regio is altijd valid
                    };
                    uniqueList.push(newRegio);
                }
            });
            koelingLogic.selectionTableRegios = uniqueList.sort((a, b) => a.Value > b.Value ? 1 : -1);

            koelingLogic.selectionTable = products;
            //-- nu van alle opwekkers de selectedItem en de searchText zetten voor product en regio
            koelingLogic.koelOpwekkersData.forEach(function (opwekker, index) {
                var propProduct = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
                var propRegio = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_REGIO'];
                koelingLogic.getSelectionTable(propProduct, opwekker);
                koelingLogic.getSelectionTable(propRegio, opwekker);
                koelingLogic._setAutocompleteSelectedItem(propProduct, opwekker);
                koelingLogic._setAutocompleteSelectedItem(propRegio, opwekker);
                koelingLogic._setSelectionTableValues(opwekker, 'C');
            });
        } //-- end: getSelectionTableServer ------------------------------------------------------------------------//

        koelingLogic._setSelectionTableValues = function (entdata, modelType) {
            //-- product is een product uit de selectie tabel
            if (!entdata) {
                return;
            }
            switch (modelType) {
                case 'C':
                    {
                        const propToestel = ntabuilding.properties['KOEL-OPWEK_TOEW'];
                        const product = koelingLogic.getProduct(propToestel, entdata);

                        saveProductValueToPropData(product, 'P',         entdata, 'KOEL-OPWEK_TOE_NON');
                        saveProductValueToPropData(product, 'fPprendc',  entdata, 'KOEL-OPWEK_FPREN_NON');
                        saveProductValueToPropData(product, 'KCO2deldc', entdata, 'KOEL-OPWEK_KCO_NON');

                        // Moet onder bepaalde condities uit de selectietabel komen
                        const typeOpwekkerValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                        const typeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === typeOpwekkerValue; });
                        if (typeOpwekker) {
                            if (typeOpwekker.key2 === 'EK') { // Primaire energiefractie alleen als opwekkertype Externe Koudelevering (EK)
                                saveProductValueToPropData(product, 'fPdeldc', entdata, 'KOEL-OPWEK_PRI_NON');

                                //-- type verklaring alleen updaten bij product of forfaitair. Bij eigen keuzen moet de gebruiker dit zetten
                                const propNEN7125Verkl = ntabuilding.properties['KOEL-OPWEK_NEN7125VERKL'];
                                if (koelingLogic.isHidden(propNEN7125Verkl, entdata)) {
                                    saveProductValueToPropData(product, 'NEN7125Verklaring', entdata, propNEN7125Verkl,
                                        value => ({ 'gemeten': 'NEN7125VERKL_ALLGEMETEN', 'berekend': 'NEN7125VERKL_BEREKEND' }[value] || ''));
                                }
                            }

                            if (typeOpwekker.key2 !== 'EK' && (!product || product.Certificate === 1)) {
                                saveProductValueToPropData(product, 'Norm', entdata, 'KOEL-OPWEK_NORM',
                                    function (value, prop) {
                                        const codedValue = prop.Domain.Codes.find(cv => cv.Value === value);
                                        return codedValue && codedValue.Id || value;
                                    });
                            }

                            if (typeOpwekker.key2 !== 'EK' && typeOpwekker.key2 !== 'BWP') {
                                /// Eerst uitzoeken of de EER van het gekozen product bepaald moet worden door de rekenkern
                                if (product && !product.EERRekenkern) {
                                    const propEER = ntabuilding.properties['KOEL-OPWEK_EER_NON'];
                                    /// Hanteer afwijkende afronding volgens "Uitlezen selectietabel.docx" §2.3 'Rendement'.
                                    /// We hebben externe koudelevering en boosterwarmtepomp hierboven uitgesloten.
                                    const originalRounding = propEER.Rounding;
                                    const invoerOpwekker = entdata.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
                                    let rounding = originalRounding;
                                    if (invoerOpwekker === 'VERW-OPWEK_INVOER_FORF') {
                                        rounding = 'R000'; // stuur de onafgeronde waarde naar de rekenkern
                                    } else if (['KOEL-OPWEK_TYPE_2', 'KOEL-OPWEK_TYPE_8', 'KOEL-OPWEK_TYPE_10'].includes(typeOpwekker.Id)) {
                                        rounding = 'R013';
                                    } else if (isKoudeOpslag(entdata)) {
                                        rounding = 'R006';
                                    } else {
                                        rounding = 'R011';
                                    }
                                    propEER.Rounding = rounding;
                                    try {
                                        saveProductValueToPropData(product, 'EER', entdata, propEER);
                                    } finally {
                                        propEER.Rounding = originalRounding;
                                    }
                                }
                            }
                        }
                        setEnergiefracties();
                        break;

                    }
                default:
                    break;
            }
        } //-- end: _setSelectionTableValues ------------------------------------------------------------------------//

        function saveProductValueToPropData(product, propName, entdata, propId, valueConverter = undefined) {
            const prop = typeof propId === 'string' ? ntabuilding.properties[propId] : propId;
            const propdata = prop.getData(entdata);
            let value = prop.DefaultValue;
            if (product && product.hasOwnProperty(propName) && product[propName] !== null) {
                value = product[propName];
                if (typeof valueConverter === 'function') {
                    value = valueConverter(value, prop);
                }
                if (prop.PropertyType === 1 /* number */) {
                    value = ntaRounding.roundAndAddZerosNewValue(prop, value);
                }
            } else if (product && !product.hasOwnProperty(propName)) {
                console.error(`Opgevraagd product heeft geen property '${propName}'!`, product);
            }
            // In geval van een numerieke property, vergelijk dan de afgeronde getalswaarde, anders is '3' !== '3.00'
            //  (en als het geen geldig getal is, gebruik dan MAX_SAFE_INTEGER; NaN === NaN geeft altijd false!)
            const changed = prop.PropertyType === 1 /* number */
                ? ntaSharedLogic.parseFloat(value, Number.MAX_SAFE_INTEGER) !== ntaSharedLogic.parseFloat(propdata.Value, Number.MAX_SAFE_INTEGER)
                : value !== propdata.Value;
            if (changed) {
                propdata.Value = value;
                if (ntabuilding.canSave()) {
                    koelingLogic.saveValue(prop, entdata); // saveValue roept al validate aan
                }
            }
            return changed;
        } // saveProductValueToPropData


        function setEnergiefracties() {
            const opwekkers = koelingLogic.koelOpwekkersData;
            const [opwekker1, opwekker2] = opwekkers;
            const propEnergiefractie = ntabuilding.properties['KOEL-OPWEK_ENER_NON'];
            if (opwekkers.length === 1) {
                const invoer = opwekker1.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
                switch (invoer) {
                    case 'VERW-OPWEK_INVOER_FORF':
                    case 'VERW-OPWEK_INVOER_PROD':
                    case 'VERW-OPWEK_INVOER_EIG_A': {
                        const newValue = ntaRounding.roundAndAddZerosNewValue(propEnergiefractie, 1.0);
                        const propdataEnergiefractie1 = opwekker1.PropertyDatas[propEnergiefractie.Id];
                        if (newValue !== propdataEnergiefractie1.Value) {
                            propdataEnergiefractie1.Value = newValue;
                            koelingLogic.saveValue(propEnergiefractie, opwekker1);
                        }
                        break;
                    }
                    case 'VERW-OPWEK_INVOER_EIG_B':
                    case 'VERW-OPWEK_INVOER_EIG_C': {
                        // dan moet KO23 (KOEL-OPWEK_ENER) gebruikt worden
                        break;
                    }
                }
            } else if (opwekkers.some(opwekker => ['VERW-OPWEK_INVOER_EIG_B', 'VERW-OPWEK_INVOER_EIG_C'].includes(opwekker.PropertyDatas['KOEL-OPWEK_INVOER'].Value))) {
                // voor opwekker1 moet KO23 (KOEL-OPWEK_ENER) gebruikt worden
                const energiefractie1 = ntaSharedLogic.parseFloat(opwekker1.PropertyDatas['KOEL-OPWEK_ENER'].Value);
                // voor opwekker2 moet 1 - KO23 van de eerste opwekker gebruikt worden
                const energiefractie2 = 1.0 - energiefractie1;
                const newValue = isNaN(energiefractie2) ? null : ntaRounding.roundAndAddZerosNewValue(propEnergiefractie, energiefractie2);
                const propdataEnergiefractie2 = opwekker2.PropertyDatas[propEnergiefractie.Id];
                if (newValue !== propdataEnergiefractie2.Value) {
                    propdataEnergiefractie2.Value = newValue;
                    koelingLogic.saveValue(propEnergiefractie, opwekker2);
                }
            } else {
                // dan moeten de fracties bepaald worden aan de hand van de vermogens.
            }

            koelingLogic.checkFractiePrefOpwekker();
        } //-- setEnergiefracties

        koelingLogic.saveSelectionTableValue = function (prop, item, entdata, value) {
            if (!prop || !entdata) {
                return;
            }

            var propdata = entdata.PropertyDatas[prop.Id];

            if (propdata) {
                propdata.Touched = true;
            }

            if (item && item.Id) {
                propdata.Value = item.Id;
            } else {
                propdata.Value = null;
            }

            koelingLogic.validate(prop, propdata);
            ntaEntityData.saveprop(propdata);

            switch (prop.Id) {
                case 'KOEL-OPWEK_TOEW':
                case 'KOEL-OPWEK_REGIO':
                    {
                        koelingLogic.validateDependencies(prop, entdata);
                        break;
                    }
                default:
                    break;
            }
        } //-- end: saveSelectionTableValue ------------------------------------------------------------------------//

        /////////////////////////////////////////////////////////////////////////////////////////////// PROPERTIES /////////////////////////////////////////////////////////////////////////////////////////////////////////
        koelingLogic.saveValue = function (prop, entdata, newValue = undefined) {
            if (!prop || !entdata) {
                return;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            propdata.Touched = true; // opslaan veld is aangeraakt

            if (propdata.Value === undefined) { // niks doen -> undefined komt van een waarschuwing
                return;
            }

            if (ntaEntityData.saveprop(propdata, newValue)) { //Server validatie en evt. opslaan

                // Ook opslaan in evt. unit-specifieke propdatas (normaal gesproken doet ntaSharedLogic.saveValue dit, maar die wordt hier niet gebruikt)
                ntaEntityData.saveprops(ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata), propdata.Value);

                if (prop.Id === 'KOEL-DISTR-BIN_ISO_LEI') {
                    let propdataBuiten = koelingLogic.koelDistributieBuitenData.PropertyDatas['KOEL-DISTR-BUI_ISO_LEI'];

                    if (!propdataBuiten.Touched || !propdataBuiten.Value) {
                        let propBuiten = koelingLogic.koelDistributieBuitenProperties['KOEL-DISTR-BUI_ISO_LEI'];

                        propdataBuiten.Value = propdata.Value;
                        koelingLogic.validate(propBuiten, propdataBuiten);
                        ntaEntityData.saveprop(propdataBuiten);
                    }
                }
                if (prop.Id === 'KOEL-AFG_RUIM' || prop.Id === 'KOEL-AFG_TYPE_RUIM') {

                    let temperatuurcorrectieType = entdata.PropertyDatas['KOEL-AFG_TTR_NON'];
                    let temperatuurcorrectieAuto = entdata.PropertyDatas['KOEL-AFG_TAR_NON'];

                    let temperatuurcorrectieTypeProperty = koelingLogic.koelAfgifteProperties['KOEL-AFG_TTR_NON'];
                    let temperatuurcorrectieAutoProperty = koelingLogic.koelAfgifteProperties['KOEL-AFG_TAR_NON'];

                    let propdataRuimte = entdata.PropertyDatas['KOEL-AFG_RUIM'];

                    if (propdataRuimte && propdataRuimte.Value === 'VERW-AFG_RUIM_FOR') {
                        let propdataTypeRuimte = entdata.PropertyDatas['KOEL-AFG_TYPE_RUIM'];
                        let ruimteTemperatuurRegeling = koelingLogic.ruimteTemperatuurRegelingTypes.find(function (x) { return x.Id === propdataTypeRuimte.Value; });

                        if (!ruimteTemperatuurRegeling) {
                            return;
                        }

                        temperatuurcorrectieType.Value = ruimteTemperatuurRegeling.delta_ctr;
                        temperatuurcorrectieAuto.Value = ruimteTemperatuurRegeling.delta_roomaut;
                        koelingLogic.validate(temperatuurcorrectieTypeProperty, temperatuurcorrectieType);
                        koelingLogic.validate(temperatuurcorrectieAutoProperty, temperatuurcorrectieAuto);
                        ntaEntityData.saveprop(temperatuurcorrectieType);
                        ntaEntityData.saveprop(temperatuurcorrectieAuto);
                    }
                    else if (propdataRuimte && propdataRuimte.Value === 'VERW-AFG_RUIM_CER') {
                        let ruimteTemperatuurRegeling = koelingLogic.ruimteTemperatuurRegelingTypes.find(function (x) { return x.Id === 'KOEL-AFG_TYPE_RUIM_7'; });

                        temperatuurcorrectieType.Value = ruimteTemperatuurRegeling.delta_ctr;
                        temperatuurcorrectieAuto.Value = ruimteTemperatuurRegeling.delta_roomaut;
                        koelingLogic.validate(temperatuurcorrectieTypeProperty, temperatuurcorrectieType);
                        koelingLogic.validate(temperatuurcorrectieAutoProperty, temperatuurcorrectieAuto);
                        ntaEntityData.saveprop(temperatuurcorrectieType);
                        ntaEntityData.saveprop(temperatuurcorrectieAuto);
                    }

                }
                if (prop.Id === 'KOEL-AFG-VENT_INV') {
                    const propdataAfgVent = entdata.PropertyDatas['KOEL-AFG-VENT_P'];
                    if (propdata.Value === 'VERW-AFG-VENT_INV_FOR') {
                        const propAfgVent = koelingLogic.koelAfgifteVentilatorenProperties['KOEL-AFG-VENT_P'];

                        propdataAfgVent.Value = '10';
                        ntaRounding.roundAndAddZeros(propAfgVent, propdataAfgVent);
                        koelingLogic.validate(propAfgVent, propdataAfgVent);
                        ntaEntityData.saveprop(propdataAfgVent);
                    }
                }
                if (prop.Id === 'KOEL-OPWEK_TYPE') { // KO05
                    koelingLogic.checkPrioOpwekker(entdata);

                    const propToestel = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
                    koelingLogic.getSelectionTable(propToestel, entdata, "");
                    koelingLogic.setDescriptionInstallation(entdata);
                }

                if (prop.Id === 'KOEL-OPWEK_TYPE' || prop.Id === 'KOEL-OPWEK_INVOER') {
                    let propToestel = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
                    koelingLogic.getSelectionTable(propToestel, entdata, "");
                    koelingLogic._setSelectionTableValues(entdata, 'C');
                }

                if (prop.Id === 'KOEL-OPWEK_INVOER' || prop.Id === 'KOEL-OPWEK_TYPE') {
                    // Conditie KOY
                    checkFabricagejaarToestel(entdata);

                    // Voer validatie uit voor conditie KOAN
                    let propToestel = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];
                    koelingLogic.getSelectionTable(propToestel, entdata, "");

                    setEnergiefracties();
                }

                if (prop.Id === 'KOEL-OPWEK_ENER') {
                    setEnergiefracties();
                }

                if (prop.Id === 'KOEL-DISTR-BIN_INV' || prop.Id === 'KOEL-DISTR-BIN_ISO_LEI') { // Zet dekking en labda constructie op n.v.t.
                    let propBinnenDekking = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_DEK'];
                    let propBinnenLabdaConstructie = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_CON'];
                    let propBinnenLabdaIsolatie = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_ISO'];
                    let propBinnenLabdaLeiding = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_LEI'];
                    let propdataBinnenDekking = koelingLogic.koelDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenDekking.Id];
                    let propdataBinnenLabdaConstructie = koelingLogic.koelDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaConstructie.Id];
                    let propdataBinnenLabdaIsolatie = koelingLogic.koelDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaIsolatie.Id];
                    let propdataBinnenLabdaLeiding = koelingLogic.koelDistributieEigenschappenLeidingenBinnenData.PropertyDatas[propBinnenLabdaLeiding.Id];


                    if (binnenDekkingShouldBeHidden()) { // Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                        propdataBinnenDekking.Value = 'n.v.t.';
                        propdataBinnenLabdaConstructie.Value = 'n.v.t.';
                        koelingLogic.validate(propBinnenDekking, propdataBinnenDekking);
                        koelingLogic.validate(propBinnenLabdaConstructie, propdataBinnenLabdaConstructie);
                        ntaEntityData.saveprop(propdataBinnenDekking);
                        ntaEntityData.saveprop(propdataBinnenLabdaConstructie);
                    }
                    else { // Reset
                        if (propdataBinnenDekking.Value === 'n.v.t.') {
                            propdataBinnenDekking.Value = '';
                            ntaEntityData.saveprop(propdataBinnenDekking);
                        }
                        if (propdataBinnenLabdaConstructie.Value === 'n.v.t.') {
                            propdataBinnenLabdaConstructie.Value = '';
                            ntaEntityData.saveprop(propdataBinnenLabdaConstructie);
                        }
                    }

                    if (binnenLeidingIsOnGeisoleerd()) { // Als de rij van binnen niet zichtbaar hoort te zijn nvt invullen
                        if (propdataBinnenLabdaIsolatie.Value !== 'n.v.t.') {
                            propdataBinnenLabdaIsolatie.Value = 'n.v.t.';
                            koelingLogic.validate(propBinnenLabdaIsolatie, propdataBinnenLabdaIsolatie);
                            ntaEntityData.saveprop(propdataBinnenLabdaIsolatie);
                        }
                        propdataBinnenLabdaLeiding.Value = '';
                        ntaEntityData.saveprop(propdataBinnenLabdaLeiding);
                    }
                    else {
                        if (propdataBinnenLabdaLeiding.Value !== 'n.v.t.') {
                            propdataBinnenLabdaLeiding.Value = 'n.v.t.';
                            koelingLogic.validate(propBinnenLabdaLeiding, propdataBinnenLabdaLeiding);
                            ntaEntityData.saveprop(propdataBinnenLabdaLeiding);
                        }
                        propdataBinnenLabdaIsolatie.Value = '';
                        ntaEntityData.saveprop(propdataBinnenLabdaIsolatie);
                    }
                }

                if (prop.Id === 'KOEL-DISTR-BUI_INV' || prop.Id === 'KOEL-DISTR-BUI_ISO_LEI') { // Zet dekking en labda constructie op n.v.t.
                    let propBuitenDekking = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_DEK'];
                    let propBuitenLabdaConstructie = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_CON'];
                    let propBuitenLabdaIsolatie = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_ISO'];
                    let propBuitenLabdaLeiding = koelingLogic.koelDistributieEigenschappenLeidingenProperties['KOEL-DISTR-EIG_LAB_LEI'];
                    let propdataBuitenDekking = koelingLogic.koelDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenDekking.Id];
                    let propdataBuitenLabdaConstructie = koelingLogic.koelDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaConstructie.Id];
                    let propdataBuitenLabdaIsolatie = koelingLogic.koelDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaIsolatie.Id];
                    let propdataBuitenLabdaLeiding = koelingLogic.koelDistributieEigenschappenLeidingenBuitenData.PropertyDatas[propBuitenLabdaLeiding.Id];

                    if (buitenDekkingShouldBeHidden()) { // Als de rij van buiten niet zichtbaar hoort te zijn nvt invullen
                        propdataBuitenDekking.Value = 'n.v.t.';
                        propdataBuitenLabdaConstructie.Value = 'n.v.t.';
                        koelingLogic.validate(propBuitenDekking, propdataBuitenDekking);
                        koelingLogic.validate(propBuitenLabdaConstructie, propdataBuitenLabdaConstructie);
                        ntaEntityData.saveprop(propdataBuitenDekking);
                        ntaEntityData.saveprop(propdataBuitenLabdaConstructie);
                    }
                    else { // Reset
                        if (propdataBuitenDekking.Value === 'n.v.t.') {
                            propdataBuitenDekking.Value = '';
                            ntaEntityData.saveprop(propdataBuitenDekking);
                        }

                        if (propdataBuitenLabdaConstructie.Value === 'n.v.t.') {
                            propdataBuitenLabdaConstructie.Value = '';
                            ntaEntityData.saveprop(propdataBuitenLabdaConstructie);
                        }
                    }

                    if (buitenLeidingIsOngeisoleerd()) { // Als de rij van buiten niet zichtbaar hoort te zijn nvt invullen
                        if (propdataBuitenLabdaIsolatie.Value !== 'n.v.t.') {
                            propdataBuitenLabdaIsolatie.Value = 'n.v.t.';
                            koelingLogic.validate(propBuitenLabdaIsolatie, propdataBuitenLabdaIsolatie);
                            ntaEntityData.saveprop(propdataBuitenLabdaIsolatie);
                        }
                        propdataBuitenLabdaLeiding.Value = '';
                        ntaEntityData.saveprop(propdataBuitenLabdaLeiding);
                    }
                    else {
                        if (propdataBuitenLabdaLeiding.Value !== 'n.v.t.') {
                            propdataBuitenLabdaLeiding.Value = 'n.v.t.';
                            koelingLogic.validate(propBuitenLabdaLeiding, propdataBuitenLabdaLeiding);
                            ntaEntityData.saveprop(propdataBuitenLabdaLeiding);
                        }
                        propdataBuitenLabdaIsolatie.Value = '';
                        ntaEntityData.saveprop(propdataBuitenLabdaIsolatie);
                    }
                }
                koelingLogic.validateDependencies(prop, entdata);
                ntaDependencyValidation.checkChangedField(prop, entdata, koelingLogic);
            }
            koelingLogic.validate(prop, propdata);

        } //-- end: saveValue ------------------------------------------------------------------------//

        function CheckPropdataInList(prop, entdata, list) {
            let propdata = entdata.PropertyDatas[prop.Id];
            let listItem = null;
            let valueChanged = false;
            if (propdata && list) {
                if (list.length === 0) {
                    //-- bij 0 items in de lijst moet de propdata.value op op default gezet worden gezet worden
                    if (propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    }
                } else if (list.length === 1 && (!prop.Domain || prop.Domain && prop.Domain.DomainType !== 4 && prop.Domain.DomainType !== 5)) {
                    //-- bij 1 item in de lijst moet de propdata.value gelijk worden aan de waarde uit de lijst
                    if (propdata.Value !== list[0].Id) {
                        propdata.Value = list[0].Id;
                        valueChanged = true;
                    }
                } else if (list.length === 1 && prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) && list.Singleresult) {
                    //-- 1 list item voor selectietabel
                    if (propdata.Value !== list[0].Id) {
                        propdata.Value = list[0].Id;
                        valueChanged = true;
                    }
                }

                if (propdata.Value && list.length > 1) {
                    //-- als propdata.Value ongelijk is aan null moet ik kijken of het item in de lijst zit
                    listItem = _getListItem(prop, entdata, list);
                    if (!listItem && propdata.Value !== prop.DefaultValue) {
                        propdata.Value = prop.DefaultValue;
                        valueChanged = true;
                    } else {
                        //-- als ik prop domaintype 4 of 5 ben moet autocompletslecteditems atlijd zetten
                        if (prop.Domain && (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5)) {
                            koelingLogic._setAutocompleteSelectedItem(prop, entdata, listItem);
                        }
                    }
                }
            }
            //-- en nu de selectedItems zetten voor de property als het domaintype 4 of 5 is. Voor de md-autocomplete moet dit
            if (valueChanged) {
                koelingLogic._setAutocompleteSelectedItem(prop, entdata, listItem);
            }
            koelingLogic.validate(prop, propdata);
            if (valueChanged) {
                ntaEntityData.saveprop(propdata);
            }
        } //-- end: CheckPropdataInList ------------------------------------------------------------------------//


        function _getListItem(prop, entdata, list = null) {
            if (prop && prop.Domain && prop.Domain.DomainType > 1 && entdata) {
                var tmpList = null;
                var tmpId;
                var propData = entdata.PropertyDatas[prop.Id];
                switch (prop.Id) {
                    case 'KOEL-OPWEK_TOEW':
                        tmpId = parseInt(propData.Value);
                        tmpList = entdata.filteredItems;
                        break;
                    case 'KOEL-OPWEK_REGIO':
                        tmpId = propData.Value; //-- geen parseInt want Value is een txt en geen productnummer
                        tmpList = entdata.filteredItemsRegios;
                        break;
                    default:
                        tmpId = propData.Value;
                        tmpList = list; // de gefilterde getCodedValue list. Op deze list moet ik checken
                        if (!tmpList) {
                            //-- anders ongefilterde codedValue list gebruiken
                            tmpList = prop.Domain.Codes;
                        }
                        break;
                }

                var listValue = null;
                if (propData.Value && tmpList && tmpList.length > 0) {
                    listValue = tmpList.find(function (x) { return x.Id === tmpId; });
                }
                return listValue;
            } else {
                return null;
            }

        } //-- end: _getListItem ------------------------------------------------------------------------//

        koelingLogic._setAutocompleteSelectedItem = function (prop, entdata, selectedItem = null) {
            // dit moet alleen voor velden van het domain type 4 of 5.
            if (prop.Domain.DomainType === 4 || prop.Domain.DomainType === 5) {
                if (!selectedItem) {
                    selectedItem = _getListItem(prop, entdata);
                }

                switch (prop.Id) {
                    case 'KOEL-OPWEK_TOEW':
                        if (selectedItem) {
                            entdata.selectedItem = selectedItem;
                            entdata.searchText = selectedItem.Value;
                        } else {
                            entdata.selectedItem = null;
                            entdata.searchText = "";
                        }
                        break;
                    case 'KOEL-OPWEK_REGIO':
                        if (selectedItem) {
                            entdata.selectedItemRegio = selectedItem;
                            entdata.searchTextRegio = selectedItem.Value;
                        } else {
                            entdata.selectedItemRegio = null;
                            entdata.searchTextRegio = "";
                        }
                        break;
                    default:
                        break;
                }
            }
        } //-- end: _setAutocompleteSelectedItem ------------------------------------------------------------------------//


        koelingLogic.isHidden = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            //-- Propdata zal niet altijd bestaan, als een property met een andere entiteit wordt doorgegeven. Dit gebeurt vooral bij tabellen waar de kolomheader ook verborgen moet kunnen worden.
            const propdata = prop.getData(entdata);

            //-- onderstaande variable komen steeds voor bij de check voor isHidden, vandaag hierboven gedeclareerd
            if (entdata.EntityId === 'KOEL-OPWEK') {
                var opwekkerTypeValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                if (opwekkerTypeValue) {
                    var opwekkerType = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === opwekkerTypeValue; });
                }

                var propInvoerOpwekker = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_INVOER'];
                var invoerOpwekker = entdata.PropertyDatas[propInvoerOpwekker.Id].Value;
            }

            let showit = false;
            let relevant = null;

            switch (prop.Id) {
                case 'KOEL-OPWEK_INVOER': { // KO06
                    // Conditie [KOAM]
                    showit = conditieKOAM(opwekkerType);
                    break;
                }
                case 'KOEL-OPWEK_BODEM_BRON_TEMP': {
                    // Conditie [KOAV] toon alleen in de eerste kolom waarvoor geldt dat KO05 = 'koudeopslag - bodem'
                    for (const koelOpwekker of koelingLogic.koelOpwekkersData) {
                        const typeOpwekkerValue = koelOpwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                        if (typeOpwekkerValue === 'KOEL-OPWEK_TYPE_4') {
                            if (koelOpwekker === entdata) {
                                showit = true;
                            }
                            break;
                        }
                    }
                    break;
                }
                case 'KOEL-OPWEK_VERGJR_BRON': { // KO33
                    showit = conditieKOBC(entdata);
                    break;
                }
                case 'KOEL-OPWEK_GEM': { // KO07
                    // Conditie [KOAD]
                    const koad = koelingLogic.opwekkerNr(entdata) === 1;

                    if (koad) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-OPWEK_A': { // KO08

                    // Conditie KOAD
                    const koad = koelingLogic.opwekkerNr(entdata) === 1;

                    // Conditie KOAA
                    const gemeenschappelijk = entdata.PropertyDatas['KOEL-OPWEK_GEM'].Value;
                    //-- alleen laten zien als gebouwtype ongelijk is aan apparatementgebouw of utiliteitsgebouw
                    const koaa = gemeenschappelijk === 'KOEL-OPWEK_GEM_WEL';

                    showit = koad && koaa;
                    break;
                }
                case 'KOEL-OPWEK_REGIO': { // KO09
                    showit = false;

                    // Conditie KOD
                    if (opwekkerType && opwekkerType.key2 === 'EK' && invoerOpwekker && invoerOpwekker === 'VERW-OPWEK_INVOER_PROD') {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-OPWEK_FABR': { // KO10
                    // Conditie KOY, deel van deze conditie wordt in de methode checkFabricagejaarToestel afgehandeld
                    let invoer = entdata.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
                    let opwekkerType = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;

                    if (invoerOpwekker && invoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && opwekkerTypeValue && (opwekkerTypeValue === 'KOEL-OPWEK_TYPE_2' || opwekkerTypeValue === 'KOEL-OPWEK_TYPE_10') && ntaSharedLogic.getBouwjaar() < 2007) {
                        showit = true;
                        relevant = true;
                    }
                    else if (invoerOpwekker && invoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && opwekkerTypeValue && (opwekkerTypeValue === 'KOEL-OPWEK_TYPE_2' || opwekkerTypeValue === 'KOEL-OPWEK_TYPE_10') && ntaSharedLogic.getBouwjaar() >= 2007) {
                        showit = false;
                        relevant = true;
                    }
                    else {
                        showit = false;
                        relevant = false;
                    }

                    break;
                }
                case 'KOEL-OPWEK_TOEW': { // KO11
                    // Conditie [KOE]
                    showit = (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' && (!opwekkerType || opwekkerType.key2 !== 'BWP'))
                        || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF' && (opwekkerTypeValue === 'KOEL-OPWEK_TYPE_2' || opwekkerTypeValue === 'KOEL-OPWEK_TYPE_10');
                    break;
                }
                case 'KOEL-OPWEK_TOE': { // KO12
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    const kof = conditieKOF(); // [KOF]

                    showit = koam && kof;
                    break;
                }
                case 'KOEL-OPWEK_TOE_NON': { // KO13
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    const koj = conditieKOJ(entdata); // [KOJ]

                    showit = koam && koj;
                    break;
                }
                case 'KOEL-OPWEK_EER_NORM': { // KO32
                    // Conditie [KOAX]
                    showit = ['VERW-OPWEK_INVOER_EIG_A', 'VERW-OPWEK_INVOER_EIG_B', 'VERW-OPWEK_INVOER_EIG_C'].includes(invoerOpwekker)
                        && opwekkerType && ['COMP', 'ABS'].includes(opwekkerType.key2);
                    break;
                }
                case 'KOEL-OPWEK_CON': { // KO14
                    // Conditie [KOH]
                    const propEERVerkl = ntabuilding.properties['KOEL-OPWEK_EER_NORM'];
                    showit = !koelingLogic.isHidden(propEERVerkl, entdata) && propEERVerkl.getValue(entdata) === 'KOEL-OPWEK_EER_NORM_NEN_EN_14511';
                    break;
                }
                case 'KOEL-OPWEK_TOE_BEH': { // KO16
                    // Conditie KOP
                    if (koelingLogic.opwekkerNr(entdata) === 1) {
                        showit = true;
                    }
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_GEL_KOUDE_NON': { // KO17
                    // Conditie KOAR
                    showit = true;
                    if (opwekkerType && (opwekkerType.key2 === 'EK' || opwekkerType.key2 === 'ONB' || opwekkerType.key2 === 'BWP')) {
                        showit = false;
                    }
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_EER_NON': { // KO18
                    // Conditie KOQ
                    showit = conditieKOQ(opwekkerType, invoerOpwekker);

                    // Relevantie hangt af of de EER bepaald moet worden door de rekenkern. Deze prop heeft Usage=3;
                    relevant = showit; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                    const propToestel = ntabuilding.properties['KOEL-OPWEK_TOEW'];
                    const product = koelingLogic.getProduct(propToestel, entdata);
                    if (product && product.EERRekenkern) {
                        relevant = false;
                    }

                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_EER': { // KO19
                    // Conditie KOU
                    showit = opwekkerType && opwekkerType.key2 !== 'EK' && opwekkerType.key2 !== 'BWP' && !conditieKOQ(opwekkerType, invoerOpwekker);

                    break;
                }
                case 'KOEL-OPWEK_PRI_NON': { // KO20
                    // Conditie KOO
                    showit = conditieKOO(opwekkerType, invoerOpwekker);

                    relevant = showit; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_PRI': { // KO21
                    // Conditie KOX
                    showit = opwekkerType && opwekkerType.key2 === 'EK' && !conditieKOO(opwekkerType, invoerOpwekker);

                    break;
                }
                case 'KOEL-OPWEK_NEN7125VERKL': { // KO30
                    // Conditie KOAU
                    if (opwekkerType && opwekkerType.key2 === 'EK') {
                        //-- alleen zichtbaar bij eigen waarde invoer
                        if (invoerOpwekker && (invoerOpwekker !== 'VERW-OPWEK_INVOER_PROD' && invoerOpwekker !== 'VERW-OPWEK_INVOER_FORF')) {
                            showit = true;
                        }
                        //-- ongeacht of deze property zichtbaar is of niet is hij alleen relevant als er sprake is van externe warmtelevering
                        relevant = true;
                    }
                    break;
                }
                case 'KOEL-OPWEK_FPREN_NON': // TO47
                case 'KOEL-OPWEK_KCO_NON': { // TO49
                    // Conditie KOAS
                    if (opwekkerType && opwekkerType.key2 === 'EK' && invoerOpwekker && (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF')) {
                        showit = true;
                    }
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_FPREN': // TO48
                case 'KOEL-OPWEK_KCO': { // TO50
                    // Conditie KOAT
                    if (opwekkerType && opwekkerType.key2 === 'EK' && invoerOpwekker && (invoerOpwekker !== 'VERW-OPWEK_INVOER_PROD' && invoerOpwekker !== 'VERW-OPWEK_INVOER_FORF')) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-OPWEK_ENER_NON': { // KO22
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    let kor = false;

                    // Conditie KOR
                    if (invoerOpwekker && (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF' || invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_A')) {
                        kor = true;
                    }

                    if (koam && kor) {
                        showit = true;
                    }

                    //-- De Usage van KO22 is 3, dus als hij een waarde van de rekenkern moet ontvangen, moet hij toch niet relevant zijn.
                    if (showit) {
                        const opwekkers = koelingLogic.koelOpwekkersData;
                        const invoerOpwekker1 = opwekkers[0].PropertyDatas['KOEL-OPWEK_INVOER'].Value;

                        relevant = (opwekkers.length === 1 && ['VERW-OPWEK_INVOER_FORF', 'VERW-OPWEK_INVOER_PROD', 'VERW-OPWEK_INVOER_EIG_A'].includes(invoerOpwekker1))
                            || (opwekkers.length > 1 && opwekkers.some(opwekker => ['VERW-OPWEK_INVOER_EIG_B', 'VERW-OPWEK_INVOER_EIG_C'].includes(opwekker.PropertyDatas['KOEL-OPWEK_INVOER'].Value)));
                    } else {
                        relevant = false; //expliciet relevant toekenen, voordat mogelijk showit wordt gewijzigd doordat we in een maatregel werken. [MW-A]
                    }

                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_ENER': { // HO23
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    let kov = false;

                    // Conditie KOV
                    if (invoerOpwekker && (invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_B' || invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C')) {
                        kov = true;
                    }

                    if (koam && kov) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-OPWEK_HULP_NON': { // HO24
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    let kog = false;
                    let koaw = koelingLogic.opwekkerNr(entdata) === 1;

                    // Conditie KOG
                    let propPrimairEditable = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_HULP'];
                    if (koelingLogic.isHidden(propPrimairEditable, entdata)) {
                        kog = true;
                    }

                    if (koam && kog && koaw) {
                        showit = true;
                    }

                    relevant = false;

                    if (invoerOpwekker && (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF')) {
                        let toestelId = parseInt(entdata.PropertyDatas['KOEL-OPWEK_TOEW'].Value);
                        let toestel = koelingLogic.selectionTable.find(function (x) { return x.Id === toestelId; });
                        if (toestel && toestel.HulpGen) { // hulpenergie_gen_incl = ja
                            relevant = true;

                            let numHulpenergie = ntaSharedLogic.parseFloat(propdata.Value);

                            if (numHulpenergie && !isNaN(numHulpenergie)) {
                                propdata.Value = '0';
                                ntaRounding.roundAndAddZeros(prop, propdata);
                                koelingLogic.saveValue(prop, entdata);
                            }
                        }
                    }

                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-OPWEK_HULP': { // HO25
                    const koam = conditieKOAM(opwekkerType); // [KOAM]
                    const koaw = koelingLogic.opwekkerNr(entdata) === 1;

                    const kot = koelingLogic.koelOpwekkersData.some(function (opwekker, index) {
                        var invoerOpwekker = opwekker.PropertyDatas[propInvoerOpwekker.Id].Value;
                        // Conditie KOT
                        return invoerOpwekker && invoerOpwekker === 'VERW-OPWEK_INVOER_EIG_C'; // eigen waarde opwekkingsrendement, fractie en hulpenergie
                    });

                    if (koam && kot && koaw) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-OPWEK_NORM': {
                    //-- deze propertie staat niet in formulieren.xlsx, maar is bij productspecifiek nodig om de waarde uit C_Model kolom "norm" op slaan
                    //-- deze "norm" bepaald bij productspecifiek wat het praktijkrendementcorrectiefactor prprsi getal moet zijn.
                    showit = false;
                    relevant = invoerOpwekker === 'VERW-OPWEK_INVOER_PROD';
                    break;
                }
                case 'KOEL-OPWEK_WCCTRLEN':
                case 'KOEL-OPWEK_FCGEN_INV_FORF':
                case 'KOEL-OPWEK_WCGEN_INV_FORF': {
                    showit = false; /// NOOIT visible
                    relevant = true;  /// altijd relevant. Waarde wordt gezet in de bepaalxxxxx functions
                    break;
                }
                case 'KOEL-DISTR_WCAUX': {
                    //-- de WCauxdisinEER is NOOIT visible. Dit is een waarde die bepaald moet worden ivm conditie KDA. De waarde is wel altijd relevant en
                    //-- default is deze waarde false;
                    //-- waarde van deze property wordt bepaald bij de validateDependecy
                    showit = false;
                    relevant = true;
                    break;
                }
                case 'KOEL-DISTR_ONTW': { // KD03
                    // Conditie KDB
                    let verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;

                    if (verdampersysteem && verdampersysteem === 'KOEL-DISTR_VERDAMP_3') {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR_WAT': { // KD04
                    // Conditie KDB
                    let verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;

                    if (verdampersysteem && verdampersysteem === 'KOEL-DISTR_VERDAMP_3') {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR-BIN_INV': { // KD06
                    // Conditie KDB
                    const kdb = !koelingLogic.leidingenIsHidden();

                    // Conditie KDD
                    const kdd = ntaSharedLogic.isUtiliteit() || ntaSharedLogic.isDetailOpname();

                    showit = kdb && kdd;
                    break;
                }
                case 'KOEL-DISTR-BIN_LEN_NON': { // KD07
                    // Conditie [KDB]
                    const kdb = !koelingLogic.leidingenIsHidden();

                    // Conditie [KDF]
                    const propInvoerLeidingen = ntabuilding.properties['KOEL-DISTR-BIN_INV'];
                    const kdf = !koelingLogic.isHidden(propInvoerLeidingen, entdata)
                        && !['VERW-DISTR-BIN_INV_D', 'VERW-DISTR-BIN_INV_G', 'VERW-DISTR-BIN_INV_H'].includes(propInvoerLeidingen.getValue(entdata));

                    showit = kdb && kdf;
                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-DISTR-BIN_MAXLEN': { // KD33
                    // Conditie [KDB]
                    const kdb = !koelingLogic.leidingenIsHidden();

                    showit = kdb && conditieKDG(); //[KDG]
                    break;
                }
                case 'KOEL-DISTR-BIN_LEN': { // KD08
                    // Conditie [KDB]
                    const kdb = !koelingLogic.leidingenIsHidden();

                    // Conditie [KDAR]
                    const propInvoerLeidingen = ntabuilding.properties['KOEL-DISTR-BIN_INV'];
                    const kdar = !koelingLogic.isHidden(propInvoerLeidingen, entdata)
                        && ['VERW-DISTR-BIN_INV_D', 'VERW-DISTR-BIN_INV_G', 'VERW-DISTR-BIN_INV_H'].includes(propInvoerLeidingen.getValue(entdata));

                    showit = kdb && kdar;
                    break;
                }
                case 'KOEL-DISTR-BIN_ISO_LEI': { // KD09
                    showit = true;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        showit = false;
                    }

                    break;
                }
                case 'KOEL-DISTR-BIN_ISO_KLE': { // KD10
                    let kdb = true;
                    let kdi = true;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDI
                    let isolatieLeidingen = entdata.PropertyDatas['KOEL-DISTR-BIN_ISO_LEI'].Value;
                    if (isolatieLeidingen && isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G') {
                        kdi = false;
                    }

                    if (kdb && kdi) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR-BIN_ON': { // KD11
                    let kdb = true;
                    let kdj = false;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDJ
                    let isolatieLeidingen = entdata.PropertyDatas['KOEL-DISTR-BIN_ISO_LEI'].Value;
                    if (isolatieLeidingen && isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G') {
                        kdj = true;
                    }

                    if (kdb && kdj) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR-BUI_INV': { // KD13
                    showit = true;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        showit = false;
                    }

                    break;
                }
                case 'KOEL-DISTR-BUI_LEN_NON': { // KD14
                    let kdb = true;
                    let kdm = false;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDM
                    let invoer = entdata.PropertyDatas['KOEL-DISTR-BUI_INV'].Value;
                    if (invoer && (invoer === 'VERW-DISTR-BUI_INV_B' || invoer === 'VERW-DISTR-BUI_INV_E' || invoer === 'VERW-DISTR-BUI_INV_F')) {
                        kdm = true;
                    }

                    if (kdb && kdm) {
                        showit = true;
                    }

                    showit = showit && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-DISTR-BUI_LEN': { // KD15
                    let kdb = true;
                    let kdn = false;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDN
                    let invoer = entdata.PropertyDatas['KOEL-DISTR-BUI_INV'].Value;
                    if (invoer && (invoer === 'VERW-DISTR-BUI_INV_A' || invoer === 'VERW-DISTR-BUI_INV_C' || invoer === 'VERW-DISTR-BUI_INV_D')) {
                        kdn = true;
                    }

                    if (kdb && kdn) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR-BUI_ISO_LEI': { // KD16
                    let kdb = true;
                    let kdy = true;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDY
                    let invoer = entdata.PropertyDatas['KOEL-DISTR-BUI_INV'].Value;
                    if (invoer && invoer === 'VERW-DISTR-BUI_INV_H') {
                        kdy = false;
                    }

                    if (kdb && kdy) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR-BUI_ISO_KLE': { // KD17
                    let kdb = true;
                    let kdy = true;
                    let kdo = true;

                    // Conditie KDB
                    if (koelingLogic.leidingenIsHidden()) {
                        kdb = false;
                    }

                    // Conditie KDY
                    let invoer = entdata.PropertyDatas['KOEL-DISTR-BUI_INV'].Value;
                    if (invoer && invoer === 'VERW-DISTR-BUI_INV_H') {
                        kdy = false;
                    }

                    // Conditie KDO
                    let isolatieLeidingen = entdata.PropertyDatas['KOEL-DISTR-BUI_ISO_LEI'].Value;
                    if (isolatieLeidingen && isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G') {
                        kdo = false;
                    }

                    if (kdb && kdy && kdo) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-DISTR_EIGLEI': { // KD18
                    // Conditie [KDP]
                    showit = conditieKDP();
                    break;
                }
                case 'KOEL-DISTR-EIG_DEK': // KD22
                case 'KOEL-DISTR-EIG_LAB_CON': { // KD23
                    // Conditie KDR
                    showit = !dekkingIsHidden();
                    relevant = dekkingIsRelevant(entdata);
                    break;
                }
                case 'KOEL-DISTR-EIG_LAB_ISO': { // KD24
                    // Conditie KDS
                    showit = !labdaIsolatieIsHidden();
                    relevant = labdaIsolatieIsRelevant(entdata);
                    break;
                }
                case 'KOEL-DISTR-EIG_LAB_LEI': { // KD25
                    // Conditie KDAG
                    showit = !labdaLeidingIsHidden();
                    relevant = labdaLeidingIsRelevant(entdata); //-- relevantie afhankelijk van binnen of buiten, vandaar checken met entdata
                    break;
                }
                case 'KOEL-DISTR_AANV_POMP': { // KD34
                    // Conditie KDAB
                    showit = conditieKDAB();
                    break;
                }
                case 'KOEL-DISTR_POMP_INV': { // KD26
                    // Conditie KDA
                    showit = conditieKDA();
                    break;
                }
                case 'KOEL-DISTR_POMP_VER_NON': { // KD27
                    // Conditie KDU
                    showit = !koelingLogic.pompIsHidden(entdata) && conditieKDU() && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-DISTR_POMP_VER': { // KD28
                    // Conditie KDV
                    showit = !koelingLogic.pompIsHidden(entdata) && conditieKDV();
                    break;
                }
                case 'KOEL-DISTR_POMP_EEI_NON': { // KD29
                    // Conditie KDU
                    showit = !koelingLogic.pompIsHidden(entdata) && conditieKDU() && !ntaSharedLogic.isEditingMeasure(); //[MW-A]
                    break;
                }
                case 'KOEL-DISTR_POMP_EEI': { // KD30
                    // Conditie KDX
                    showit = !koelingLogic.pompIsHidden(entdata) && conditieKDX();
                    break;
                }
                case 'KOEL-DISTR_MAXLEN': //-- HD34
                    {   //-- Conditie [KDG]
                        showit = conditieKDG();
                        break;
                    }
                case 'KOEL-DISTR_AAN_LAGEN': { // KD31
                    // Conditie KDA
                    showit = conditieKDA();
                    break;
                }
                case 'KOEL-DISTR_METER': { // KD32
                    // Conditie KDZ
                    let verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;

                    let bSitA = false;
                    let gemeenschappelijk = koelingLogic.koelOpwekkersData[0].PropertyDatas['KOEL-OPWEK_GEM'].Value;
                    if (gemeenschappelijk && gemeenschappelijk === 'KOEL-OPWEK_GEM_WEL') {
                        bSitA = true;
                    }

                    let bSitC = false;
                    for (let index = 0; index < koelingLogic.koelOpwekkersData.length; index++) {
                        let typeOpwekker = koelingLogic.koelOpwekkersData[index].PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                        if (typeOpwekker && typeOpwekker === 'KOEL-OPWEK_TYPE_7') {
                            bSitC = true;
                            break;
                        }
                    }

                    if (verdampersysteem === 'KOEL-DISTR_VERDAMP_3' && (bSitA || bSitC)) {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-AFG_TYPE_AFG': { // KA07
                    showit = true;

                    //// Conditie KAO
                    //let verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;
                    //if (verdampersysteem && verdampersysteem === 'KOEL-DISTR_VERDAMP_2') {
                    //    showit = false;
                    //}

                    break;
                }
                case 'KOEL-AFG_TYPE_RUIM': { // KA09

                    // Conditie KAI
                    if (ntabuilding.ntaVersionId >= 300) {
                        showit = true;
                        break;
                    }

                    let ruimtetemperatuurregeling = entdata.PropertyDatas['KOEL-AFG_RUIM'];
                    if (ruimtetemperatuurregeling && ruimtetemperatuurregeling.Value === 'VERW-AFG_RUIM_FOR') {
                        showit = true;
                    }

                    break;
                }
                case 'KOEL-AFG_TTR_NON': { // KA10
                    if (ntabuilding.ntaVersionId >= 300) { //door conditie KAR is KA10 in >= 3.3 ook altijd verborgen.
                        showit = false;
                        break;
                    }

                    // Conditie KAC
                    showit = conditieKAC(entdata);
                    break;
                }
                case 'KOEL-AFG_TTR': { // KA11
                    // Conditie KAD
                    showit = conditieKAD(entdata);
                    break;
                }
                case 'KOEL-AFG_TAR_NON': { // KA12
                    // Conditie KAC
                    showit = conditieKAC(entdata);
                    break;
                }
                case 'KOEL-AFG_TAR': { // KA13
                    // Conditie KAD
                    showit = conditieKAD(entdata);
                    break;
                }
                case 'KOEL-AFG-VENT_INV': {
                    //check of KOEL-AFG-VENT in _afgifteventilatoren zit
                    showit = isAfgifteventilatorVisible(entdata);
                    break;
                }
                case 'KOEL-AFG-VENT_P':   // KA16
                case 'KOEL-AFG-VENT_N': { // KA17

                    //check of KOEL-AFG-VENT in _afgifteventilatoren zit
                    showit = isAfgifteventilatorVisible(entdata);

                    if (showit) {
                        // Conditie [KAL]
                        relevant = entdata.PropertyDatas['KOEL-AFG-VENT_INV'].Value !== 'VERW-AFG-VENT_INV_GEEN';
                        const afgifte = ntaEntityData.getFirstParent(entdata, 'KOEL-AFG');
                        showit = !conditieKAL(afgifte);
                    }

                    break;
                }
                case 'MEASURE-COSTS_SUBS_INV':
                case 'MEASURE-COSTS_SUBS_PER_M2':
                case 'MEASURE-COSTS_INV':
                case 'MEASURE-COSTS_PER_M2': {
                    showit = false;
                    break;
                }
                default: {
                    showit = true;
                    break;
                }
            }

            if (relevant === null) relevant = showit;

            const propdatas = [propdata, ...ntaSharedLogic.getUnitSpecificResultPropdatas(prop, entdata)];
            ntaEntityData.setPropdataStatus(propdatas, relevant, showit);

            return !showit;
        } //-- end: isHidden ------------------------------------------------------------------------//

        function isKoudeOpslag(opwekkerData) {
            /// [KOBD] verberg indien KO05 = koudeopslag – grondwater / koudeopslag – bodem / koudeopslag – oppervlaktewate
            const propType = ntabuilding.properties['KOEL-OPWEK_TYPE'];     // KO05
            return ["KOEL-OPWEK_TYPE_3", "KOEL-OPWEK_TYPE_4", "KOEL-OPWEK_TYPE_5"].includes(propType.getValue(opwekkerData));
        }
        function conditieKOQ(opwekkerType, invoerOpwekker) {
            /// [KOQ] show if key2 of table 10_0 on same row as opwekker chosen in KO05 ≠ 'EK'/'BWP' AND KO06 = forfaitair OR productspecifiek
            return opwekkerType && opwekkerType.key2 !== 'EK' && opwekkerType.key2 !== 'BWP' && (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF');
        } //-- end: conditieKOQ --------------------------------------------------------------------------------//

        function conditieKOO(opwekkerType, invoerOpwekker) {
            /// [KOO] show if key2 of table 10_0 on same row as opwekker chosen in KO05 = 'ek' AND KO06 = forfaitair OR productspecifiek
            return opwekkerType && opwekkerType.key2 === 'EK' && invoerOpwekker && (invoerOpwekker === 'VERW-OPWEK_INVOER_PROD' || invoerOpwekker === 'VERW-OPWEK_INVOER_FORF');
        }//-- end: conditieKOO --------------------------------------------------------------------------------//

        function isAfgifteventilatorVisible(afgifteventilator) {
            return Object.entries(_afgifteventilatoren).some(function (entry) {
                const [key, entdatas] = entry;
                return key.length > 38 // is gekoppeld aan zowel een afgiftesysteem als een rekenzone
                    && entdatas.includes(afgifteventilator)
            });
        } //-- end: isAfgifteventilatorVisible ------------------------------------------------------------

        koelingLogic.isReadOnly = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            const propdata = prop.getData(entdata);

            const nvt = 'n.v.t.';
            if (prop.Id === 'KOEL-AFG_TTR_NON' || prop.Id === 'KOEL-AFG_TAR_NON' || prop.Id === 'KOEL-OPWEK_FPREN_NON' || prop.Id === 'KOEL-OPWEK_KCO_NON') {
                return true;
            }
            else if (prop.Id === 'KOEL-AFG-VENT_P') {
                const invoerVentilator = entdata.PropertyDatas['KOEL-AFG-VENT_INV'].Value;
                let readOnly = false;
                switch (invoerVentilator) {
                    case 'VERW-AFG-VENT_INV_FOR':
                        readOnly = true;
                        break;

                    case null:
                    case '':
                    case 'VERW-AFG-VENT_INV_GEEN':
                        propdata.Value = nvt;
                        readOnly = true;
                        break;
                }
                if (!readOnly && propdata.Value === nvt) {
                    ntaEntityData.saveprop(propdata, prop.DefaultValue);
                }
                return readOnly;
            }
            else if (prop.Id === 'KOEL-AFG-VENT_N') {
                const invoerVentilator = entdata.PropertyDatas['KOEL-AFG-VENT_INV'].Value;
                const readOnly = invoerVentilator === 'VERW-AFG-VENT_INV_GEEN';
                if (readOnly) {
                    propdata.Value = nvt;
                } else if (!readOnly && propdata.Value === nvt) {
                    ntaEntityData.saveprop(propdata, prop.DefaultValue);
                }
                return readOnly;
            }
            else if (prop.Id === 'KOEL-OPWEK_TOE_BEH' || prop.Id === 'KOEL-OPWEK_GEL_KOUDE_NON' || prop.Id === 'KOEL-OPWEK_EER_NON' || prop.Id === 'KOEL-OPWEK_PRI_NON' || prop.Id === 'KOEL-OPWEK_ENER_NON' || prop.Id === 'KOEL-OPWEK_HULP_NON' || prop.Id === 'KOEL-OPWEK_TOE_NON') {
                return true;
            }
            else if (prop.Id === 'KOEL-DISTR-BIN_LEN_NON' || prop.Id === 'KOEL-DISTR-BUI_LEN_NON') {
                return true;
            }
            else if (prop.Id === 'KOEL-DISTR-EIG_RUIMTE') {
                return true;
            }
            else if (prop.Id === 'KOEL-DISTR_POMP_VER_NON' || prop.Id === 'KOEL-DISTR_POMP_EEI_NON') {
                return true;
            }
            else if (prop.Id === 'KOEL-DISTR_POMP_OMSCHR') {
                return true;
            }
            else if (prop.Id === 'KOEL-DISTR-EIG_DEK') { // KD22
                if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) {
                    if (binnenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) {
                    if (buitenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'KOEL-DISTR-EIG_LAB_CON') { // KD23
                if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) {
                    if (binnenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) {
                    if (buitenDekkingShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'KOEL-DISTR-EIG_LAB_ISO') { // KD24
                if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) {
                    if (binnenLabdaIsolatieShouldBeHidden()) {
                        return true;
                    }
                }
                else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) {
                    if (buitenLabdaIsolatieShouldBeHidden()) {
                        return true;
                    }
                }
            }
            else if (prop.Id === 'KOEL-DISTR-EIG_LAB_LEI') { // KD25
                if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                    return !showBinnenLabdaLeiding();
                }
                else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                    return !showBuitenLabdaLeiding();
                }
            } else if (prop.Id === 'MEASURE-COSTS_TOTAAL' || prop.Id === 'MEASURE-COSTS_SUBS_TOTAAL') {
                return true;
            }

            return false;
        } //-- end: isReadOnly ------------------------------------------------------------------------//

        koelingLogic.validate = function (prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const entdata = ntaEntityData.get(propdata.EntityDataId);
            const hidden = koelingLogic.isHidden(prop, entdata);
            const readonly = koelingLogic.isReadOnly(prop, entdata);
            let valid = ntaValidation.IsValid(koelingLogic.form_installationkoeling, prop, propdata, hidden, readonly);  //Client Validatie

            if (prop.Id === "KOEL-OPWEK_TOEW") {
                // Conditie [KOAN]
                const opwekker = koelingLogic.koelOpwekkersData.find(function (x) { return x.EntityDataId === propdata.EntityDataId; });
                const opwekkerType = opwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                const invoer = opwekker.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
                let selectionTableValid = true;
                let combiMetTapwaterValid = true;

                if (opwekkerType && (invoer === 'VERW-OPWEK_INVOER_PROD' || invoer === 'VERW-OPWEK_INVOER_FORF')) {
                    const producten = getCodedValuesSelectionTable(prop, opwekker);

                    if (producten && producten.length <= 0 && propdata.Relevant) { // Toon E042
                        selectionTableValid = false;
                    }

                    // Conditie [KOAO]
                    // Controleren hier meteen conditie [KOAO], omdat ik net de producten heb opgehaald. Indien toestel gekozen in KO11
                    // QCgen waarden heeft controleer dan of dit toestel ook gekozen is bij een warm tapwatersysteem en dat dit tapwatersysteem
                    // in veld TO71 dit koelsysteem heeft.Zo niet toon[E106]
                    if (selectionTableValid) {
                        /// als er uberhaupt geen producten zijn, hoef ik deze niet te checken
                        const product = koelingLogic.getProduct(prop, opwekker);
                        if (product && product.Wcode) {
                            /// dan is er spraken van een tapwwater-koeling combi en moet ik kijken of deze Wcode ook
                            /// ingevuld is bij een tapwater opwekker.
                            combiMetTapwaterValid = conditieKOAO(entdata);
                        }
                    }
                }

                ntaSharedLogic.setMelding("[E042]", propdata, koelingLogic.form_installationkoeling, selectionTableValid);
                ntaSharedLogic.setMelding("[E106]", propdata, koelingLogic.form_installationkoeling, combiMetTapwaterValid);

                return valid && selectionTableValid && combiMetTapwaterValid;
            }

            if (prop.Id === "KOEL-OPWEK_BODEM_BRON_TEMP") {
                let bodemBronTemperatuurE95Valid = true;
                let bodemBronTemperatuurE96Valid = true;

                if (propdata.Visible) {
                    const koelingOpwekkers = ntaEntityData.getListWithEntityId('KOEL-OPWEK');
                    for (const opwekker of koelingOpwekkers) {
                        if (opwekker.EntityDataId !== entdata.EntityDataId) {
                            //const typeOpwekkerValue = opwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                            const propdataBodemBronTemp = opwekker.PropertyDatas['KOEL-OPWEK_BODEM_BRON_TEMP'];
                            if (propdataBodemBronTemp && propdataBodemBronTemp.Touched && propdataBodemBronTemp.Visible && propdataBodemBronTemp.Value && propdataBodemBronTemp.Value !== propdata.Value) {
                                if (propdata.Value === 'KOEL-OPWEK_BODEM_BRON_TEMP_WEL') { // Conditie [KOBA]
                                    bodemBronTemperatuurE96Valid = false;
                                    break;
                                }
                                else if (propdata.Value === 'KOEL-OPWEK_BODEM_BRON_TEMP_NIET') { // Conditie [KOAZ]
                                    bodemBronTemperatuurE95Valid = false;
                                    break;
                                }
                            }
                        }
                    }
                }

                const contrl = koelingLogic.form_installationkoeling && koelingLogic.form_installationkoeling['ntainput' + propdata.PropertyDataId];
                if (contrl) { // altijd checken of contrl er is -> kan hidden zijn (ng-if) -> message wel altijd wel/niet laten zien.
                    contrl.$setValidity("[E095]", bodemBronTemperatuurE95Valid);
                    contrl.$setValidity("[E096]", bodemBronTemperatuurE96Valid);
                }
                ntaMeldingen.melding("[E095]", propdata.PropertyDataId, bodemBronTemperatuurE95Valid);
                ntaMeldingen.melding("[E096]", propdata.PropertyDataId, bodemBronTemperatuurE96Valid);

                return valid && bodemBronTemperatuurE95Valid && bodemBronTemperatuurE96Valid;
            }

            if (prop.Id === 'KOEL_OPM' && ntaSharedLogic.isValidatingVariantWithMeasureType('MEASURE-HVAC_INSTALLATIE')) {
                //Prop.Id === 'KOEL_OPM' toegevoegd, zodat deze check niet voor elke property afgaat.
                //check of er geen installatie KOELING aanwezig is in de basisberekening
                // dan alle CONSTRT-ZONW relevant maken en value ZONW_GEEN meegeven
                if (!ntaData.original.getFirstWithEntityId('KOEL')) {
                    const constrts = ntaEntityData.getListWithEntityId('CONSTRT');
                    const zonwProp = ntabuilding.properties['CONSTRT_ZONW'];
                    const propdatas = constrts.map(x => zonwProp.getData(x));
                    ntaEntityData.setPropdataStatus(propdatas, true);
                    ntaEntityData.saveprops(propdatas, 'ZONW_GEEN');
                }
            }
            if (prop.Id === 'KOEL-OPWEK_ENER') {
                koelingLogic.checkFractiePrefOpwekker(); /// hier wordt ook de validatie uitgevoerd cf [KOBE]
            }

            return valid;
        } //-- end: validate ------------------------------------------------------------------------//

        function determineAgTotaal() {
            const gebouwType = ntaSharedLogic.getGebouwType();
            const prop = ntabuilding.properties['KOEL-OPWEK_A'];

            if (gebouwType === 'TGEB_APPGEB' || gebouwType === 'TGEB_UTILIT' && ntaSharedLogic.perGebouwEnUnit() || ntaSharedLogic.voorProjectwoningen()) {
                return ntaRounding.roundAndAddZerosNewValue(prop, ntaSharedLogic.AgSumRZbySystem(koelingLogic.koelData, true));
            }

            return '';
        } //-- end: determineAgTotaal ------------------------------------------------------------------------//

        function setDefaultValueFabricagejaar() {
            koelingLogic.koelOpwekkersData.forEach(function (opwekker, index) {

                let propTOE = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_FABR'];
                let propDataTOE = opwekker.PropertyDatas[propTOE.Id];

                if (!propDataTOE.Touched) {
                    if (ntaSharedLogic.getBouwjaar() < 2007) {
                        propDataTOE.Value = 'KOEL-OPWEK_FABR_KL';
                    }
                    else if (ntaSharedLogic.getBouwjaar() >= 2007) {
                        propDataTOE.Value = 'KOEL-OPWEK_FABR_GR';
                    }
                    else {
                        propDataTOE.Value = 'KOEL-OPWEK_FABR_ONB';
                    }
                    koelingLogic.validate(propTOE, propDataTOE);
                    ntaEntityData.saveprop(propDataTOE);
                }

            });
        }

        // Conditie KOAQ
        function getBoosterwarmtepompTapwater() {
            let tapwaterOpwekkers = ntaEntityData.getParents(koelingLogic.koelData, 'TAPW-OPWEK');

            for (let index = 0; index < tapwaterOpwekkers.length; index++) {
                let propdataKoelingBoosterWarmtepomp = tapwaterOpwekkers[index].PropertyDatas['TAPW-OPWEK_BRON_BOOS_KOEL'];

                if (propdataKoelingBoosterWarmtepomp.Relevant) {
                    return tapwaterOpwekkers[index];
                }
            }

            return null;
        }

        koelingLogic.getCodedValues = function (prop, entdata) {
            if (!prop || !entdata || entdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            let typesList = [];
            let typeOpwekkerValue;

            switch (prop.Id) {
                case 'KOEL-OPWEK_TYPE': //KO05
                    typesList = koelingLogic.opwekkerTypes;

                    if (koelingLogic.opwekkerNr(entdata) === 1) {
                        // Conditie KOAQ
                        if (getBoosterwarmtepompTapwater()) {
                            typesList = typesList.filter(function (x) { return x.Id === 'KOEL-OPWEK_TYPE_11'; });
                        }
                    }
                    // Conditie KOB
                    else if (koelingLogic.opwekkerNr(entdata) > 1 || ntaSharedLogic.isUtiliteit() && ntaSharedLogic.perGebouw() ||
                        ntaSharedLogic.getGebouwType() === 'TGEB_GRWON' || ntaSharedLogic.getGebouwType() === 'TGEB_VAKWON' || ntaSharedLogic.getGebouwType() === 'TGEB_WOONW' ||
                        ntaSharedLogic.getGebouwType() === 'TGEB_WOONBN' || ntaSharedLogic.getGebouwType() === 'TGEB_WOONBB') {
                        typesList = typesList.filter(function (x) { return x.key2 !== 'ONB'; });
                    }

                    break;
                case 'KOEL-OPWEK_INVOER': // KO06
                    {
                        typesList = prop.Domain.Codes;
                        typeOpwekkerValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;

                        if (!typeOpwekkerValue) { break; }

                        let typeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === typeOpwekkerValue; });

                        // Conditie KOAE
                        if (typeOpwekker.key3 !== 'product') {
                            typesList = typesList.filter(function (x) { return x.FilterValue1 !== 'product'; });
                        }

                        // Conditie KOAF
                        if (typeOpwekker.key2 === 'ONB') {
                            typesList = typesList.filter(function (x) { return x.FilterValue2 !== 'ONB'; });
                        }

                        // Conditie KOAD
                        if (koelingLogic.opwekkerNr(entdata) > 1) {
                            typesList = typesList.filter(function (x) { return x.FilterValue3 !== '1'; });
                        }

                        // Conditie KOBD
                        if (isKoudeOpslag(entdata)) {
                            typesList = typesList.filter(function (x) { return x.FilterValue4 !== '1'; });
                        }

                        // Conditie KOAM
                        if (typeOpwekker.Id === 'KOEL-OPWEK_TYPE_11') { // Boosterpomp
                            typesList = typesList.filter(function (x) { return x.FilterValue1 === 'product'; });
                        }

                        break;
                    }
                case 'KOEL-OPWEK_GEM': // KO07
                    {
                        typesList = prop.Domain.Codes;
                        typeOpwekkerValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;

                        // Conditie KOC
                        if (typeOpwekkerValue === 'KOEL-OPWEK_TYPE_12') { // Onbekende gemeenschappelijke opwekker
                            typesList = typesList.filter(function (x) { return x.Id !== 'KOEL-OPWEK_GEM_NIET'; });
                        }

                        // Conditie KOAL
                        if (typeOpwekkerValue === 'KOEL-OPWEK_TYPE_11') { // Boosterwarmtepomp
                            typesList = typesList.filter(function (x) { return x.Id !== 'KOEL-OPWEK_GEM_WEL'; });
                        }

                        break;
                    }
                case 'KOEL-OPWEK_EER_NORM': { // KO32
                    typesList = prop.Domain.Codes;

                    // [KOAY] deze keuze tijdelijk verbergen totdat er verklaringen volgens deze norm op de markt komen
                    typesList = typesList.filter(x => x.Id !== 'KOEL-OPWEK_EER_NORM_NEN_EN_14511');

                    break;
                }
                case 'KOEL-OPWEK_CON': { // KO14
                    typesList = prop.Domain.Codes;

                    const typeOpwekkerValue = entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                    switch (typeOpwekkerValue) {
                        case 'KOEL-OPWEK_TYPE_3': // koudeopslag - grondwater
                        case 'KOEL-OPWEK_TYPE_4': // koudeopslag - bodem
                            typesList = typesList.filter(x => x.Id === 'KOEL-OPWEK_CON_18'); // bodem
                            break;
                        case 'KOEL-OPWEK_TYPE_5': // koudeopslag - oppervlaktewater
                            typesList = typesList.filter(x => x.Id === 'KOEL-OPWEK_CON_17'); // oppervlaktewater
                            break;
                    }

                    break;
                }
                case 'KOEL-DISTR_VERDAMP':
                    { //-- [KD02]
                        typesList = prop.Domain.Codes;

                        //-- Voor Conditie [KDAH] [DPK] eruit halen
                        let typeVerdamp4 = typesList.filter(function (x) { return x.Id === 'KOEL-DISTR_VERDAMP_4'; });
                        typesList = typesList.filter(function (x) { return x.Id !== 'KOEL-DISTR_VERDAMP_4'; });

                        typeOpwekkerValue = koelingLogic.koelOpwekkersData[0].PropertyDatas['KOEL-OPWEK_TYPE'].Value;
                        if (typeOpwekkerValue) {
                            let typeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === typeOpwekkerValue; });

                            // Conditie KDAG
                            if (typeOpwekker && (typeOpwekker.key2 === 'DPK' || typeOpwekker.key2 === 'VKO' || typeOpwekker.key2 === 'EK' || typeOpwekker.key2 === 'BWP')) {
                                typesList = typesList.filter(function (x) { return x.Id === 'KOEL-DISTR_VERDAMP_3'; });
                            }

                            if (typeOpwekker && typeOpwekker.key2 === 'DPK') {
                                // Conditie KDAH
                                typesList = typesList.concat(typeVerdamp4);
                                // Conditie KDAI
                                typesList = typesList.filter(function (x) { return x.Id !== 'KOEL-DISTR_VERDAMP_3'; });
                            }

                        }

                        // Conditie KDAA
                        if (koelingLogic.koelOpwekkersData.length > 1) {
                            typesList = typesList.filter(function (x) { return x.Id === 'KOEL-DISTR_VERDAMP_3'; });
                        }

                        break;
                    }
                case 'KOEL-DISTR_POMP_INV': {
                    //KD26
                    //-- deze prop maakt gebruikt van de codedvalues van VERW-DISTR_POMP_INV en daar is een extra optie bij. Die moet er bij koeling altijd uit
                    //typesList = ntaValidation.codedValues(prop, 'EW', true);
                    typesList = ntaValidation.codedValues(prop);

                    let opwekkerTypeValue = koelingLogic.koelOpwekkersData[0].PropertyDatas['KOEL-OPWEK_TYPE'].Value;

                    if (opwekkerTypeValue) {
                        let opwekkerType = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === opwekkerTypeValue; });
                        if (opwekkerType.key2 !== 'EK') {
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-DISTR_POMP_INV_E'; });
                        }
                    }

                    break;
                }
                case 'KOEL-DISTR-BIN_INV': { // KD06
                    typesList = ntaValidation.codedValues(prop);
                    // Conditie [KDAJ]
                    if (!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && !ntaSharedLogic.isGemeenschappelijkInstallatie(koelingLogic.koelData)) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'DAM'; });
                    }
                    // Conditie [KDK]
                    else if (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isBasisOpname()) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === 'DS'; });
                    }
                    // Conditie [KDAK]
                    else if ((!ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname() && ntaSharedLogic.isGemeenschappelijkInstallatie(koelingLogic.koelData)) ||
                        (ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isDetailOpname())) {
                        typesList = typesList.filter(function (x) { return x.FilterValue3 === 'DAN'; });
                    }
                    break;
                }
                case 'KOEL-DISTR-BIN_ISO_LEI': { // KD09
                    let propInvoer = koelingLogic.koelDistributieBinnenProperties['KOEL-DISTR-BIN_INV']; //-- KD06
                    let propdataInvoer = koelingLogic.koelDistributieBinnenData.PropertyDatas[propInvoer.Id];
                    let bKD06isHidden = koelingLogic.isHidden(propInvoer, entdata);
                    let bKD06Onbekend = false;
                    if (propdataInvoer && propdataInvoer.Value &&
                        (propdataInvoer.Value === 'VERW-DISTR-BIN_INV_B' || propdataInvoer.Value === 'VERW-DISTR-BIN_INV_C' ||
                         propdataInvoer.Value === 'VERW-DISTR-BIN_INV_D' || propdataInvoer.Value === 'VERW-DISTR-BIN_INV_E' ||
                         propdataInvoer.Value === 'VERW-DISTR-BIN_INV_G')) {
                        bKD06Onbekend = true;
                    }

                    typesList = ntaValidation.codedValues(prop);

                    if (bKD06isHidden || bKD06Onbekend) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'F' || x.FilterValue1 === ''; });
                    } else {
                        //-- als 'bekend' sta alleen alles met N of zonder key toe
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'N' || x.FilterValue1 === ''; });
                    }

                    // als G06 >= 1995 filter key2 '<1995' als G06 < 1995 filter key2 '>=1995'
                    if (ntaSharedLogic.getBouwjaar() >= 1995) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === '>=1995' || x.FilterValue2 === ''; });
                    }
                    else if (ntaSharedLogic.getBouwjaar() < 1995) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === '<1995' || x.FilterValue2 === ''; });
                    }

                    break;
                }
                case 'KOEL-DISTR-BUI_INV': // KD13
                    typesList = prop.Domain.Codes;

                    // Laat warmte weg, houdt alleen koeling over
                    typesList = typesList.filter(function (x) { return x.FilterValue1 !== 'W'; });

                    if (ntaSharedLogic.isBasisOpname()) { // Conditie KDK
                        typesList = typesList.filter(function (x) { return x.FilterValue2 !== 'D'; });
                    }
                    else if (ntaSharedLogic.isDetailOpname()) { // COnditie KDE
                        typesList = typesList.filter(function (x) { return x.FilterValue2 !== 'B'; });
                    }
                    break;
                case 'KOEL-DISTR-BUI_ISO_LEI': { // KD16
                    let invoer = koelingLogic.koelDistributieBuitenData.PropertyDatas['KOEL-DISTR-BUI_INV'].Value;

                    typesList = ntaValidation.codedValues(prop);

                    if (invoer && (invoer === 'VERW-DISTR-BUI_INV_D' || invoer === 'VERW-DISTR-BUI_INV_F')) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'N' || x.FilterValue1 === ''; });
                    } else if (invoer && (invoer === 'VERW-DISTR-BUI_INV_A' || invoer === 'VERW-DISTR-BUI_INV_B' || invoer === 'VERW-DISTR-BUI_INV_C' || invoer === 'VERW-DISTR-BUI_INV_E')) {
                        typesList = typesList.filter(function (x) { return x.FilterValue1 === 'F' || x.FilterValue1 === ''; });
                    }

                    // als G06 >= 1995 filter key2 '<1995' als G06 < 1995 filter key2 '>=1995'
                    if (ntaSharedLogic.getBouwjaar() >= 1995) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === '>=1995' || x.FilterValue2 === ''; });
                    }
                    else if (ntaSharedLogic.getBouwjaar() < 1995) {
                        typesList = typesList.filter(function (x) { return x.FilterValue2 === '<1995' || x.FilterValue2 === ''; });
                    }

                    break;
                }
                case 'KOEL-DISTR_WAT': {
                    typesList = ntaValidation.codedValues(prop);
                    const versie = ntabuilding.ntaVersionId >= 300 ? 'gt3.2' : 'le3.2';
                    typesList = typesList.filter(x => !x.FilterValue1 || x.FilterValue1 === versie);
                    break;
                }
                case 'KOEL-AFG_TYPE_AFG':
                    { //-- [KA07]
                        typesList = ntaValidation.codedValues(prop);

                        let verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;

                        switch (verdampersysteem) {
                            case 'KOEL-DISTR_VERDAMP_1':
                                //-- Conditie [KAN]
                                typesList = typesList.filter(function (x) { return x.FilterValue1 === 'B' || x.FilterValue1 === ''; });
                                break;
                            case 'KOEL-DISTR_VERDAMP_2':
                            case 'KOEL-DISTR_VERDAMP_4':
                                //-- Conditie [KAQ]
                                typesList = typesList.filter(function (x) { return x.Id === 'KOEL-AFG_TYPE_AFG_9'; });
                                break;
                            case 'KOEL-DISTR_VERDAMP_3':
                                //-- Conditie [KAF]
                                typesList = typesList.filter(function (x) { return x.FilterValue1 === 'A' || x.FilterValue1 === ''; });
                                break;
                            default:
                        }
                        break;
                    }
                case 'KOEL-AFG_TYPE_RUIM': // KA09

                    if (ntabuilding.ntaVersionId >= 300) {
                        typesList = koelingLogic.ruimteTemperatuurRegelingTypes.filter(function (x) { return x.key0 === 'gt3.2'; });
                        break;
                    }

                    typesList = koelingLogic.ruimteTemperatuurRegelingTypes.filter(function (x) { return x.key0 === 'le3.2' && x.key1 === 'forfaitair'; });

                    break;
                case 'KOEL-AFG-VENT_INV': // KA15
                    {
                        typesList = prop.Domain.Codes;

                        const afgifte = ntaEntityData.getFirstParent(entdata, 'KOEL-AFG');
                        const rekenzone = ntaEntityData.getFirstParent(entdata, 'RZ');
                        const firstVentilator = getAfgifteVentilatoren(afgifte, rekenzone)[0];

                        if (entdata !== firstVentilator) {
                            typesList = typesList.filter(function (x) { return x.Id !== 'VERW-AFG-VENT_INV_GEEN'; });
                        }

                        break;
                    }
                default:
                    typesList = ntaValidation.codedValues(prop);
                    break;
            }

            CheckPropdataInList(prop, entdata, typesList);
            return typesList;
        } //-- end: getCodedValues ------------------------------------------------------------------------//

        koelingLogic.getPropData = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = ntaSharedLogic.getPropData(prop, entdata);

            if (prop.Id === "KOEL-DISTR_POMP_OMSCHR") {
                propdata.Value = koelingLogic.pompNaam(entdata);
            }

            return propdata;
        };

        koelingLogic.getProduct = function (prop, entdata) {
            return prop && entdata && _getListItem(prop, entdata);
        } //-- end: getProduct  ------------------------------------------------------------------------//

        koelingLogic.propertyHeaderIsHidden = function (prop, entdataContainer) {
            if (!prop || !entdataContainer) {
                return;
            }

            switch (prop.Id) {
                case 'KOEL-DISTR-EIG_DEK': { // KD22
                    return dekkingIsHidden();
                }
                case 'KOEL-DISTR-EIG_LAB_CON': { // KD23
                    return dekkingIsHidden();
                }
                case 'KOEL-DISTR-EIG_LAB_ISO': { // KD24
                    return labdaIsolatieIsHidden();
                }
                case 'KOEL-DISTR-EIG_LAB_LEI': { // HD25
                    return labdaLeidingIsHidden();
                }
                case 'KOEL-DISTR_POMP_VER_NON': { // KD27
                    return !conditieKDU();
                }
                case 'KOEL-DISTR_POMP_VER': { // KD28
                    return !conditieKDV();
                }
                case 'KOEL-DISTR_POMP_EEI_NON': { // KD29
                    return !conditieKDU();
                }
                case 'KOEL-DISTR_POMP_EEI': { // KD30
                    return !conditieKDX();
                }
                case 'KOEL-AFG-VENT_P': { // KA16
                    return conditieKAL(entdataContainer);
                }
                case 'KOEL-AFG-VENT_N': { // KA17
                    return conditieKAL(entdataContainer);
                }
            }

            return false;
        };

        // Conditie [KAC]
        function conditieKAC(entdata) {
            let ruimtetemperatuurregeling = entdata.PropertyDatas['KOEL-AFG_RUIM'];
            return ruimtetemperatuurregeling && ruimtetemperatuurregeling.Value !== 'VERW-AFG_RUIM_EIG';
        } //conditieKAC

        // Conditie [KAD]
        function conditieKAD(entdata) {
            let ruimtetemperatuurregeling = entdata.PropertyDatas['KOEL-AFG_RUIM'];
            return ntabuilding.ntaVersionId < 300 && ruimtetemperatuurregeling && ruimtetemperatuurregeling.Value === 'VERW-AFG_RUIM_EIG';
        } //conditieKAD

        //-- Conditie [KAL]
        function conditieKAL(afgifte) {
            return getAfgifteVentilatoren(afgifte)
                .every(ventilator => [null, '', 'VERW-AFG-VENT_INV_GEEN'].includes(ventilator.PropertyDatas['KOEL-AFG-VENT_INV'].Value));
        }

        //-- Conditie [KOAM] show if KO05 ≠ 'boosterwarmtepomp'
        function conditieKOAM(opwekkerType) {
            return !opwekkerType || opwekkerType.key2 !== 'BWP';
        }

        //-- Conditie [KOAO]
        //-- Indien toestel gekozen in KO11 een setje QCgen waarden heeft controleer dan of dit toestel ook gekozen is bij
        //-- een warm tapwatersysteem en dat dit tapwatersysteem in veld TO71 dit koelsysteem heeft. Zo niet toon[E106].
        //-- Indien in geen van de tapwatersystemen bij TO71 voorkomt ga uit van een QWbd van 0 en toon geen melding;
        function conditieKOAO(entdataKoelOpw) {
            /// ik kom  hier alleen als ik een tapwaterkoelingcombi ben.
            let result = true; // toon geen melding

            const koelSysteem = ntaEntityData.getFirstParent(entdataKoelOpw, 'KOEL');
            ///-- controleer of dit koelsysteem gekozen is bij een tapwatersysteem
            const tapwSysteem = ntaEntityData.getFirstParent(koelSysteem, "TAPW-OPWEK");
            if (tapwSysteem) {
                /// er is een koppeling, dus nu moet gecontroleerd worden of de toestellen gelijk zijn
                const propdataToestelKoel = entdataKoelOpw.PropertyDatas['KOEL-OPWEK_TOEW'];

                ///-- toestel Id opzoeken van het koeltoestel
                const toestelKoelId = parseInt(propdataToestelKoel.Value);
                const toestelTapwId = parseInt(tapwSysteem.PropertyDatas["TAPW-OPWEK_TOES"].Value);
                result = toestelTapwId === toestelKoelId;
            } else {
                /// als het wel een tapwaterkoelingcombitoestel is maar geen gekozen tapwaterssysttem moet
                /// er gerekend worden met een QWbd van 0. Dit wordt afgehandeld in de koppellaag. Wanneer
                /// daar geen link met een tapwatersysteem gevonden wordt, wordt er gerekend met QWbd = 0;
            }

            return result;
        } //-- end: conditieKOAO-------------------------------------------------------------------//

        ///-- Conditie [KOBC] toon alleen als KO05 = 'koudeopslag - grondwater' AND KO06 = forfaitair
        function conditieKOBC(entdata) {
            return entdata.PropertyDatas['KOEL-OPWEK_TYPE'].Value === 'KOEL-OPWEK_TYPE_3'
                && entdata.PropertyDatas['KOEL-OPWEK_INVOER'].Value === 'VERW-OPWEK_INVOER_FORF';
        }

        /* Conditie [KOF] show if one of the following applies:
        *  - if opwekker 1 = boosterwarmtepomp when number of columns KO04 > 2  AND column C_P of C_model = empty
        *  - if opwekker 1 ≠ boosterwarmtepomp when number of columns KO04 > 1 AND column C_P of C_model = empty
        */
        function conditieKOF() {
            const prefOpwekker = koelingLogic.koelOpwekkersData[0];
            const prefOpwekkertype = koelingLogic.opwekkerTypes.find(x => x.Id === prefOpwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value);
            const vereistAantalOpwekkers = (prefOpwekkertype && prefOpwekkertype.key2 === 'BWP') ? 2 : 1;
            if (koelingLogic.koelOpwekkersData.length > vereistAantalOpwekkers) {
                const productId = parseInt(prefOpwekker.PropertyDatas['KOEL-OPWEK_TOEW'].Value);
                const product = koelingLogic.selectionTable.find(p => p.Id === productId);
                return !product || product.P === null;
            }
            return false;
        }

        /* Conditie [KOJ] show:
        *  - if opwekker 1 = boosterwarmtepomp when number of columns KO04>2 AND KO12 is not shown
        *  - if opwekker 1 ≠ boosterwarmtepomp when number of columns KO04>1 AND KO12 is not shown
        */
        function conditieKOJ(entdata) {
            const prefOpwekkertype = koelingLogic.opwekkerTypes.find(x => x.Id === koelingLogic.koelOpwekkersData[0].PropertyDatas['KOEL-OPWEK_TYPE'].Value);
            const vereistAantalOpwekkers = (prefOpwekkertype && prefOpwekkertype.key2 === 'BWP') ? 2 : 1;
            return koelingLogic.koelOpwekkersData.length > vereistAantalOpwekkers
                && koelingLogic.isHidden(ntabuilding.properties['KOEL-OPWEK_TOE'], entdata);
        }

        function dekkingIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                if (binnenDekkingShouldBeHidden()) {
                    return false;
                }
            }
            else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                if (buitenDekkingShouldBeHidden()) {
                    return false;
                }
            }

            return true;
        }

        // Conditie KDR
        function dekkingIsHidden() {
            if (binnenDekkingShouldBeHidden() && buitenDekkingShouldBeHidden()) {
                return true;
            }

            return false;
        }

        // Conditie KDR
        function binnenDekkingShouldBeHidden() {
            // Binnen
            const binnenIsolatieLeidingen = koelingLogic.koelDistributieBinnenData && koelingLogic.koelDistributieBinnenData.PropertyDatas['KOEL-DISTR-BIN_ISO_LEI'];
            if (binnenIsolatieLeidingen?.Value === 'VERW-DISTR-_ISO_LEI_E' &&
                !binnenEigenschappenDistributieleidingenIsHidden()) {
                return false;
            }

            return true;
        }

        // Conditie KDR
        function buitenDekkingShouldBeHidden() {
            // Buiten
            const buitenIsolatieLeidingen = koelingLogic.koelDistributieBuitenData.PropertyDatas['KOEL-DISTR-BUI_ISO_LEI'];
            if (buitenIsolatieLeidingen?.Value === 'VERW-DISTR-_ISO_LEI_E' &&
                !buitenEigenschappenDistributieleidingenIsHidden()) {
                return false;
            }

            return true;
        }

        function labdaLeidingIsHidden() {
            //-- labdaLeiding moet getoond worden als row1 (binnen) of row2 (buiten) niet-geisoleerde leidingen heeft
            let showit = showBinnenLabdaLeiding() || showBuitenLabdaLeiding();
            return !showit;
        }

        function showBinnenLabdaLeiding() {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !binnenEigenschappenDistributieleidingenIsHidden() && binnenLeidingIsOnGeisoleerd();
        }

        function showBuitenLabdaLeiding() {
            //-- labdaLeiding moet getoond worden als HD09 (isolatie van leiding binnen verwarmde zone) == niet-geisoleerd
            return !buitenEigenschappenDistributieleidingenIsHidden() && buitenLeidingIsOngeisoleerd();
        }

        function labdaLeidingIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            let relevant = false;
            if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                relevant = showBinnenLabdaLeiding();
            }
            else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                //-- buiten is LamdaLeiding relevant als er een relatie is en
                relevant = showBuitenLabdaLeiding();
            }

            return relevant;
        }



        function labdaIsolatieIsRelevant(entdata) {
            if (!entdata) {
                return false;
            }

            if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) { // Als binnen hidden is en buiten niet
                if (binnenLabdaIsolatieShouldBeHidden()) {
                    return false;
                }
            }
            else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) { // Als buiten hidden is en binnen niet
                if (buitenLabdaIsolatieShouldBeHidden()) {
                    return false;
                }
            }

            return true;
        }

        // Conditie KDS
        function labdaIsolatieIsHidden() {
            if (binnenLabdaIsolatieShouldBeHidden() && buitenLabdaIsolatieShouldBeHidden()) {
                return true;
            }

            return false;
        }

        // Conditie KDS
        function binnenLabdaIsolatieShouldBeHidden() {
            // Binnen
            const binnenIsolatieLeidingen = koelingLogic.koelDistributieBinnenData?.PropertyDatas['KOEL-DISTR-BIN_ISO_LEI'];
            if (binnenIsolatieLeidingen && binnenIsolatieLeidingen.Value !== 'VERW-DISTR-_ISO_LEI_G' && !binnenEigenschappenDistributieleidingenIsHidden()) {
                return false;
            }

            return true;
        }

        // Conditie KDS
        function buitenLabdaIsolatieShouldBeHidden() {
            // Buiten
            const buitenIsolatieLeidingen = koelingLogic.koelDistributieBuitenData.PropertyDatas['KOEL-DISTR-BUI_ISO_LEI'].Value;
            if (!buitenEigenschappenDistributieleidingenIsHidden() && buitenIsolatieLeidingen && buitenIsolatieLeidingen !== 'VERW-DISTR-_ISO_LEI_G') {
                return false;
            }

            return true;
        }

        function conditieKDAB() {
            // [KDAB] show when voor ALLE opwekkers geldt:
            //    * column hulpenergie_dis_incl from C_model on same row as toestel chosen in KO11 = 'ja' OF
            //    * KO05 = koudeopslag – grondwater / koudeopslag – bodem / koudeopslag – oppervlaktewater EN KO06 = eigen waarde opwekkingsrendement(en fractie)"

            const propInvoer = ntabuilding.properties["KOEL-OPWEK_INVOER"]; // KO6
            const propToestel = ntabuilding.properties['KOEL-OPWEK_TOEW'];  // KO11
            const isAllHulpDisIncl = koelingLogic.koelOpwekkersData
                .every(opwekkerData => {
                    const toestel = koelingLogic.getProduct(propToestel, opwekkerData);
                    return propInvoer.getValue(opwekkerData) === "VERW-OPWEK_INVOER_PROD" && toestel && toestel.HulpDis === true;
                });

            const isAllKoudeOpslagEnEigenwaarde = koelingLogic.koelOpwekkersData
                .every(opwekkerData => {
                    return isKoudeOpslag(opwekkerData) &&
                           ["VERW-OPWEK_INVOER_EIG_A", "VERW-OPWEK_INVOER_EIG_B"].includes(propInvoer.getValue(opwekkerData));
                });

            return isAllHulpDisIncl || isAllKoudeOpslagEnEigenwaarde;
        } //-- end: conditieKDAB --------------------------------------------------------------------------------//

        function bepaalWCauxDisInEER() {
            // WCauxdisInEER = true in de volgende gevallen:
            //  1) KD34 = aanvullende distributiepomp niet aanwezig EN
            //	2) KO05 = voor alle opwekkers geldt: (koudeopslag–grondwater OF koudeopslag–bodem OF koudeopslag–oppervlaktewater EN
            //           KO06 = eigen waarde opwekkingsrendement(en fractie)) OF
            //  3) KO06 = voor alle opwekkers geldt: productspecifiek EN C_model, kolom Hulpenergie_dis_incl = ja
            // WCauxdisInEER = false in alle andere gevallen"

            const propAanvullendePomp = ntabuilding.properties['KOEL-DISTR_AANV_POMP'];
            const geenAanvullendePomp = koelingLogic.isHidden(propAanvullendePomp, koelingLogic.koelDistributieData) ||
                                                              propAanvullendePomp.getValue(koelingLogic.koelDistributieData) === 'KOEL-DISTR_AANV_POMP_NIET';

            const WCauxDisInEER = geenAanvullendePomp && conditieKDAB();

            const propdataWCauxDisInEER = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_WCAUX'];
            ntaEntityData.saveprop(propdataWCauxDisInEER, WCauxDisInEER);
        } //-- end: bepaalWCauxDisInEER --------------------------------------------------------------------------------//

        function bepaalWCgenInvoerForfaitair(opwekkerData) {
            // per opwekker invoerWCgen doorgeven:
            //    * indien productspecifiek EN kolom 'hulpenergie_gen_incl' uit C_model = JA: eigeninvoer(waarde 0)      return false
            //    * indien KO06 = eigen waarde opwekkingsrendement, fractie en hulpenergie: eigeninvoer(waarde uit KO25) return false
            //    * in alle andere gevallen: forfaitair
            let WCgenInvoerForfaitair = true;

            const propInvoer = ntabuilding.properties['KOEL-OPWEK_INVOER'];  // KO6
            const propToestel = ntabuilding.properties['KOEL-OPWEK_TOEW'];  // KO11
            const toestel = koelingLogic.getProduct(propToestel, opwekkerData);
            if (propInvoer.getValue(opwekkerData) === "VERW-OPWEK_INVOER_PROD" && toestel && toestel.HulpGen === true) {
                WCgenInvoerForfaitair = false;
            } else if (propInvoer.getValue(opwekkerData) === "VERW-OPWEK_INVOER_EIG_C") {
                WCgenInvoerForfaitair = false;
            }

            const propdataWCgenInvoerForf = opwekkerData.PropertyDatas['KOEL-OPWEK_WCGEN_INV_FORF'];
            ntaEntityData.saveprop(propdataWCgenInvoerForf, WCgenInvoerForfaitair);
        } //-- end: bepaalWCgenInvoerForfaitair --------------------------------------------------------------------------------//

        function bepaalFCgenInvoerForfaitair() {
            // per opwekker invoerFCgen doorgeven:
            //    * indien bij opwekker 1 eigeninvoer voor de fractie (optie eigeninvoer B of C), moet dit ook voor de eventuele opeenvolgende opwekkers doorgegeven worden
            //    * in alle andere gevallen: forfaitair

            const invoerOpwekker1 = koelingLogic.koelOpwekkersData[0].PropertyDatas['KOEL-OPWEK_INVOER'].Value; // KO06
            const fCgenInvoerForfaitair = !new Set(["VERW-OPWEK_INVOER_EIG_B", "VERW-OPWEK_INVOER_EIG_C"]).has(invoerOpwekker1);

            /// en nu voor alle opwekkers dezelfde waarde zetten
            const propdatasfCgenInvoerForf = koelingLogic.koelOpwekkersData.flatMap(ed => ed.PropertyDatas['KOEL-OPWEK_FCGEN_INV_FORF']);
            ntaEntityData.saveprops(propdatasfCgenInvoerForf, fCgenInvoerForfaitair);

        } //-- end: bepaalFCgenInvoerForfaitair --------------------------------------------------------------------------------//

        function bepaalWCctrleninIsNul(opwekkerData) {
            // per opwekker WCctrleninIsNul doorgeven:
            //     * indien productspecifiek gebruik kolom 'hulpenergie_regeling_incl' uit C_model.
            //     * indien KO05 = koudeopslag – grondwater / koudeopslag – bodem / koudeopslag – oppervlaktewater EN KD06 = eigen waarde opwekkingsrendement(en fractie) > TRUE
            //     * in alle andere gevallen FALSE"
            const propInvoer = ntabuilding.properties['KOEL-OPWEK_INVOER'];  // KO6
            let WCctrlenIsNull = false;
            if (propInvoer.getValue(opwekkerData) === 'VERW-OPWEK_INVOER_PROD') {
                const propToestel = ntabuilding.properties['KOEL-OPWEK_TOEW'];  // KO11
                const toestel = koelingLogic.getProduct(propToestel, opwekkerData);
                WCctrlenIsNull = toestel && toestel.HulpReg;
            } else {
                WCctrlenIsNull = isKoudeOpslag(opwekkerData) && ["VERW-OPWEK_INVOER_EIG_A", "VERW-OPWEK_INVOER_EIG_B"].includes(propInvoer.getValue(opwekkerData))
            };

            const propdataWCctrIsNull = opwekkerData.PropertyDatas['KOEL-OPWEK_WCCTRLEN'];
            ntaEntityData.saveprop(propdataWCctrIsNull, WCctrlenIsNull);
        } //-- end: bepaalWCctrleninIsNul --------------------------------------------------------------------------------//

        // Conditie KDA
        function conditieKDA() {
            //-- [KDA] show when KD02 = 'indirecte expansie (watergedragen distributiesysteem)' AND WCauxdisInEER = false

            //-- waarde van deze property wordt bepaald bij de validateDependecy
            const propdataWCauxDisInEER = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_WCAUX'];
            const WCauxDisInEER = !!propdataWCauxDisInEER && String(propdataWCauxDisInEER.Value).toLowerCase() === 'true';

            // KD02 = 'indirecte expansie (watergedragen distributiesysteem)'
            const verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;
            return verdampersysteem === 'KOEL-DISTR_VERDAMP_3' && !WCauxDisInEER; // show
        }

        // Conditie [KDB]
        koelingLogic.leidingenIsHidden = function () {
            const verdampersysteem = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_VERDAMP'].Value;
            const isHidden = verdampersysteem !== 'KOEL-DISTR_VERDAMP_3';

            ntaEntityData.setEntityRelevancy(koelingLogic.koelDistributieBinnenData, !isHidden);
            ntaEntityData.setEntityRelevancy(koelingLogic.koelDistributieBuitenData, !isHidden);
            ntaEntityData.setEntityVisibility(koelingLogic.koelDistributieBinnenData, !isHidden);
            ntaEntityData.setEntityVisibility(koelingLogic.koelDistributieBuitenData, !isHidden);

            //-- VO 2024-05- de leidingen zichtbaar of verborgen zijn, geldt dat ook voor de eigenschappen van de distributie leidingen:
            binnenEigenschappenDistributieleidingenIsHidden();
            buitenEigenschappenDistributieleidingenIsHidden();

            return isHidden;
        };

        koelingLogic.eigenschappenLeidingenRowIsHidden = function (entdata) {
            if (koelingLogic.koelDistributieBinnenData && ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBinnenData, entdata)) {
                return binnenEigenschappenDistributieleidingenIsHidden();

            } else if (ntaSharedLogic.hasRelation(koelingLogic.koelDistributieBuitenData, entdata)) {
                return buitenEigenschappenDistributieleidingenIsHidden();
            }

            return true;
        } //-- end: eigenschappenLeidingenRowIsHidden ------------------------------------------------------------------------//

        function binnenEigenschappenDistributieleidingenIsHidden() {
            let isVisible = false;

            /// als binnendata al niet zichtbaar is moeten de eigenschappen ook niet zichtbaar zijn en blijft isHidden = true;
            if (koelingLogic.koelDistributieBinnenData?.Visible) {
                const binnenInvoerLeidingen = koelingLogic.koelDistributieBinnenData && koelingLogic.koelDistributieBinnenData.PropertyDatas['KOEL-DISTR-BIN_INV'].Value; // KD06

                /// check dan of het ge 3.3 is en op de invoer van binnen leidingen. Bij bepaale invoer zijn de eigenschappen pas zichtbaar/relevant
                isVisible = conditieKDAN()
                    && new Set(['VERW-DISTR-BIN_INV_A', 'VERW-DISTR-BIN_INV_F', 'VERW-DISTR-BIN_INV_H']).has(binnenInvoerLeidingen);
            }

            const entdata = koelingLogic.koelDistributieEigenschappenLeidingenBinnenData;
            ntaEntityData.setEntityRelevancy(entdata, isVisible);
            ntaEntityData.setEntityVisibility(entdata, isVisible);

            return !isVisible;
        } //-- end: binnenEigenschappenDistributieleidingenIsHidden -------------------------------

        function buitenEigenschappenDistributieleidingenIsHidden() {
            let isVisible = false;

            /// als buitendata al niet zichtbaar is moeten de eigenschappen ook niet zichtbaar zijn en blijft isHidden = true;
            if (koelingLogic.koelDistributieBuitenData.Visible) {
                const buitenInvoerLeidingen = koelingLogic.koelDistributieBuitenData?.PropertyDatas['KOEL-DISTR-BUI_INV'].Value; // KD13

                /// Bij bepaale invoer zijn de eigenschappen pas zichtbaar/relevant
                isVisible = new Set(['VERW-DISTR-BUI_INV_D', 'VERW-DISTR-BUI_INV_F']).has(buitenInvoerLeidingen);
            }

            const entdata = koelingLogic.koelDistributieEigenschappenLeidingenBuitenData;
            ntaEntityData.setEntityRelevancy(entdata, isVisible);
            ntaEntityData.setEntityVisibility(entdata, isVisible);

            return !isVisible;
        } //-- end: buitenEigenschappenDistributieleidingenIsHidden -------------------------------

        koelingLogic.eigenschappenDistributieLeidingenTabelIsHidden = function () {
            const propEigLei = ntaData.properties['KOEL-DISTR_EIGLEI'];
            return koelingLogic.isHidden(propEigLei, koelingLogic.koelDistributieData);
        };

        function binnenLeidingIsOnGeisoleerd() {
            let isolatieLeidingen = koelingLogic.koelDistributieBinnenData && koelingLogic.koelDistributieBinnenData.PropertyDatas['KOEL-DISTR-BIN_ISO_LEI'].Value;
            return !!isolatieLeidingen && isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G';
        } //-- end: binnenLeidingIsOnGeisoleerd ------------------------------------------------------------------------//

        function buitenLeidingIsOngeisoleerd() { // Conditie DI
            let isolatieLeidingen = koelingLogic.koelDistributieBuitenData.PropertyDatas['KOEL-DISTR-BUI_ISO_LEI'].Value;
            return !!isolatieLeidingen && isolatieLeidingen === 'VERW-DISTR-_ISO_LEI_G';
        } //-- end: buitenLeidingIsOngeisoleerd ------------------------------------------------------------------------//

        function conditieKDG() {
            // [KDG] indien le3.2 toon als: KD06='leidinglengte bekend' OR
            //                              KD06 = 'leidinglengte bekend - leidinggegevens onbekend' OR
            //                              KD06 = 'leidinglengte bekend - leidinggegevens bekend'
            //      indien gt3.2 toon als   KD35 wordt getoond EN
            //             er sprake is van KD13 = 'leidinglengte bekend' OR
            //                              KD13 = 'leidinglengte bekend - overige leidinggegevens onbekend' OR
            //                              KD13 = 'leidinglengte bekend - overige leidinggegevens bekend'

            if (ntabuilding.ntaVersionId < 300) { //le3.2
                const propInvoerLeidingen = ntabuilding.properties['KOEL-DISTR-BIN_INV'];
                return !koelingLogic.isHidden(propInvoerLeidingen, koelingLogic.koelDistributieBinnenData)
                    && ['VERW-DISTR-BIN_INV_D', 'VERW-DISTR-BIN_INV_G', 'VERW-DISTR-BIN_INV_H'].includes(propInvoerLeidingen.getValue(koelingLogic.koelDistributieBinnenData));
            } else { //gt3.2
                const propInvoerLeidingen = ntabuilding.properties['KOEL-DISTR-BUI_INV'];
                return !koelingLogic.distributiepompTabelIsHidden() && !koelingLogic.isHidden(propInvoerLeidingen, koelingLogic.koelDistributieBuitenData)
                    && ['VERW-DISTR-BUI_INV_A', 'VERW-DISTR-BUI_INV_C', 'VERW-DISTR-BUI_INV_D'].includes(propInvoerLeidingen.getValue(koelingLogic.koelDistributieBuitenData));
            }

        } //-- end: conditieKDG ---------------------------------------------------------------------------------------//

        function conditieKDP() {
            // [KDP] only show if KD06='leidinggegevens bekend'
            //                      OR 'leidinglengte forfaitair - leidinggegevens bekend'
            //                      OR 'leidinglengte nauwkeurig - leidinggegevens bekend'
            //              OR if KD13='leidinglengte bekend - overige leidinggegevens bekend'
            //                      OR 'leidinglengte onbekend - overige leidinggegevens bekend'
            const propInvoerBinnen = ntaData.properties['KOEL-DISTR-BIN_INV']; // KD06
            const binnenVisible = !!propInvoerBinnen && !koelingLogic.isHidden(propInvoerBinnen, koelingLogic.koelDistributieBinnenData)
                && !binnenEigenschappenDistributieleidingenIsHidden(); // controleert waardes van KD06

            const propInvoerBuiten = ntaData.properties['KOEL-DISTR-BUI_INV']; // KD13
            const buitenVisible = !!propInvoerBuiten && !koelingLogic.isHidden(propInvoerBuiten, koelingLogic.koelDistributieBuitenData)
                && !buitenEigenschappenDistributieleidingenIsHidden(); // controleert waarde van KD13

            return binnenVisible || buitenVisible;
        } //-- end: conditieKDP ------------------------------------------------------------------

        function conditieKDU() {
            // Conditie KDU
            let propInvoerDistributiepomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_POMP_INV']; //-- KD26
            let propdataInvoerDistributiepomp = koelingLogic.koelDistributieData.PropertyDatas[propInvoerDistributiepomp.Id];
            if (!koelingLogic.isHidden(propInvoerDistributiepomp, koelingLogic.koelDistributieData) && propdataInvoerDistributiepomp && propdataInvoerDistributiepomp.Value === 'VERW-DISTR_POMP_INV_D') {
                return true; // show
            }

            return false; // hide
        }

        function conditieKDV() {
            // Conditie KDV
            let propInvoerDistributiepomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_POMP_INV']; //-- KD26
            let propdataInvoerDistributiepomp = koelingLogic.koelDistributieData.PropertyDatas[propInvoerDistributiepomp.Id];

            if (!koelingLogic.isHidden(propInvoerDistributiepomp, koelingLogic.koelDistributieData) && propdataInvoerDistributiepomp && (propdataInvoerDistributiepomp.Value === 'VERW-DISTR_POMP_INV_D' || propdataInvoerDistributiepomp.Value === 'VERW-DISTR_POMP_INV_C')) {
                return false; // hide
            }

            return true; // show
        }

        function conditieKDX() {
            // Conditie KDX
            let propInvoerDistributiepomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_POMP_INV']; //-- KD26
            let propdataInvoerDistributiepomp = koelingLogic.koelDistributieData.PropertyDatas[propInvoerDistributiepomp.Id];

            if (!koelingLogic.isHidden(propInvoerDistributiepomp, koelingLogic.koelDistributieData) && propdataInvoerDistributiepomp && (propdataInvoerDistributiepomp.Value === 'VERW-DISTR_POMP_INV_D' || propdataInvoerDistributiepomp.Value === 'VERW-DISTR_POMP_INV_B')) {
                return false; // hide
            }
            return true; // show
        }

        function conditieKDAN() {
            //-- Conditie [KDAN] als versie gt3.2 verberg subtitle en alle velden KD06 t/m KD11 en verplaats de velden KD12 t/m KD17 naar de positie van linker kolom
            return ntabuilding.ntaVersionId < 300; // linkerkolom dus alleen tonen bij versies le3.2
        } //-- end: conditieKDAN -----------------------------------------------------------------

        function distributiepompAanwezigOpPerceel() {
            let bAanwezig = true;
            let distributiepompInvoer = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_POMP_INV'].Value;
            //-- alleen als er invoer is, is deze check relevant
            if (distributiepompInvoer) {
                bAanwezig = distributiepompInvoer !== 'VERW-DISTR_POMP_INV_E';
            }

            return bAanwezig;
        } //-- end: distributiepompIsHidden ------------------------------------------------------------------------//


        // Conditie KDAE
        koelingLogic.distributiepompTabelIsHidden = function () {

            let propDistributiePomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_POMP_INV'];
            let showit = !koelingLogic.isHidden(propDistributiePomp, koelingLogic.koelDistributieData) && distributiepompAanwezigOpPerceel();

            //[MW-B]
            if (propDistributiePomp.getValue(koelingLogic.koelDistributieData) === 'VERW-DISTR_POMP_INV_D') { //pompvermogen onbekend, EEI onbekend
                showit = showit && !ntaSharedLogic.isEditingMeasure();
            }

            return !showit;
        };

        // Conditie KDAD
        koelingLogic.distributiepompTabelNaam = function () {
            let propAanvullendePomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_AANV_POMP'];
            let propdataAanvullendePomp = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_AANV_POMP'];
            if (!koelingLogic.isHidden(propAanvullendePomp, koelingLogic.koelDistributieData) && propdataAanvullendePomp.Value === 'KOEL-DISTR_AANV_POMP_WEL') {
                return 'aanvullende distributiepompen';
            }
            else {
                return 'distributiepompen';
            }
        };

        koelingLogic.allowMultiplePumps = function () {
            let propDistributiePomp = koelingLogic.koelDistributieProperties['KOEL-DISTR_POMP_INV'];
            let propdataDistributiepomp = koelingLogic.koelDistributieData.PropertyDatas['KOEL-DISTR_POMP_INV'];
            if (!koelingLogic.isHidden(propDistributiePomp, koelingLogic.koelDistributieData) && propdataDistributiepomp.Value === 'VERW-DISTR_POMP_INV_D') {
                return false;
            }
            return true;
        };

        koelingLogic.pompIsHidden = function (pomp) {
            let showit = !koelingLogic.distributiepompTabelIsHidden();
            if (!koelingLogic.allowMultiplePumps()) {
                if (koelingLogic.pompNr(pomp) > 1) {
                    showit = false;
                }
            }
            ntaEntityData.setEntityRelevancy(pomp, showit);
            ntaEntityData.setEntityVisibility(pomp, showit);
            return !showit;
        };

        koelingLogic.distributiepompTabelWidth = function () {

            let width = koelingLogic.koelDistributiePompProperties.length + 2;

            let propVermogenNon = koelingLogic.koelDistributiePompProperties['KOEL-DISTR_POMP_VER_NON'];
            let propVermogen = koelingLogic.koelDistributiePompProperties['KOEL-DISTR_POMP_VER'];
            let propEEINon = koelingLogic.koelDistributiePompProperties['KOEL-DISTR_POMP_EEI_NON'];
            let propEEI = koelingLogic.koelDistributiePompProperties['KOEL-DISTR_POMP_EEI'];

            if (koelingLogic.propertyHeaderIsHidden(propVermogenNon, koelingLogic.koelDistributieData)) {
                width--;
            }
            if (koelingLogic.propertyHeaderIsHidden(propVermogen, koelingLogic.koelDistributieData)) {
                width--;
            }
            if (koelingLogic.propertyHeaderIsHidden(propEEINon, koelingLogic.koelDistributieData)) {
                width--;
            }
            if (koelingLogic.propertyHeaderIsHidden(propEEI, koelingLogic.koelDistributieData)) {
                width--;
            }

            if (!koelingLogic.allowMultiplePumps()) {
                width -= 2;
            }

            return width;
        };

        // Conditie KAM
        koelingLogic.alleZonesGekoppeldAanAfgiftes = function () {
            ntaMeldingen.melding("[E031]", koelingLogic.koelDataId, true);

            const relatieAlles = koelingLogic.rekenzones.every(rekenzone => koelingLogic.koelAfgifteData.some(afgifte => ntaSharedLogic.hasRelation(rekenzone, afgifte)));
            if (!relatieAlles) {
                ntaMeldingen.melding("[E031]", koelingLogic.koelDataId, false);
            }
        };

        koelingLogic.alleZonesGekoppeldAanAfgiftes();

        // Conditie KAB
        koelingLogic.alleAfgiftesGekoppeldAanZone = function () {
            ntaMeldingen.melding("[E033]", koelingLogic.koelDataId, true);

            const relatieAlles = koelingLogic.koelAfgifteData.every(afgifte => koelingLogic.rekenzones.some(rekenzone => ntaSharedLogic.hasRelation(rekenzone, afgifte)));
            if (!relatieAlles) {
                ntaMeldingen.melding("[E033]", koelingLogic.koelDataId, false);
            }
        };

        koelingLogic.alleAfgiftesGekoppeldAanZone();

        koelingLogic.checkFractiePrefOpwekker = function () {
            /// conditie [KOBE] als sprake is van 1 opwekker en waarde in veld KO23 <1 dan toon [E125]
            let fractieValid = true;
            const opwekker1 = ntaEntityData.getFirstChild(koelingLogic.koelDataId, 'KOEL-OPWEK')
            const propdataEnergieFractie1 = opwekker1.PropertyDatas[ntabuilding.properties['KOEL-OPWEK_ENER'].Id];

            if (koelingLogic.koelOpwekkersData.length === 1 && propdataEnergieFractie1.Relevant) {
                fractieValid = ntaSharedLogic.parseFloat(propdataEnergieFractie1.Value) >= 1;
            }

            ntaSharedLogic.setMelding("[E125]", propdataEnergieFractie1, koelingLogic.form_installationkoeling, fractieValid);
        }//-- end: checkFractiePrefOpwekker --------------------------------------------------------------------------------//

        koelingLogic.checkGemeenschappelijkOpwekker = function (entdata) {
            // Gecontrleerd moet worden of deze opwekker dezelfde waarde heeft voor KOEL-OPWEK_GEM als het preferente toestel.
            const prop = koelingLogic.koelOpwekkerProperties["KOEL-OPWEK_GEM"];
            const opwekkers = koelingLogic.koelOpwekkersData;

            if (opwekkers.indexOf(entdata) === 0) {
                const value = prop.getValue(entdata);
                for (const opwekker of opwekkers.slice(1)) {
                   koelingLogic.saveValue(prop, opwekker, value);
                }
            } else {
                const preferentValue = koelingLogic.koelOpwekkersData[0].PropertyDatas[prop.Id].Value;
                koelingLogic.saveValue(prop, entdata, preferentValue);
            }
        }//-- end: checkGemeenschappelijkOpwekker --------------------------------------------------------------------------------//

        // Conditie KOA
        koelingLogic.checkPrioOpwekker = function (opwekker) {
            koelingLogic.checkPrioOpwekkerVorige(opwekker);
            koelingLogic.checkPrioOpwekkerVolgende(opwekker);
        } //-- end: checkPrioOpwekker ------------------------------------------------------------------------//

        koelingLogic.checkPrioOpwekkerVorige = function (opwekker) {
            let opwekkerNummer = koelingLogic.opwekkerNr(opwekker);

            if (opwekkerNummer === 1) {
                return;
            }

            //-- Value opwekker type
            let propdataTypeOpwekker = opwekker.PropertyDatas['KOEL-OPWEK_TYPE'];
            if (!propdataTypeOpwekker.Value) {
                return;
            }

            //-- Haal opwekker type uit tabel
            let typeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === propdataTypeOpwekker.Value; });

            let index = opwekkerNummer - 2;
            for (index; index >= 0; index--) {
                let propdataVorigeTypeOpwekker = koelingLogic.koelOpwekkersData[index].PropertyDatas['KOEL-OPWEK_TYPE'];

                if (!propdataVorigeTypeOpwekker.Value) {
                    continue;
                }

                //-- Haal opwekker type uit tabel
                let vorigeTypeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === propdataVorigeTypeOpwekker.Value; });

                //-- Vergelijk prio
                if (typeOpwekker.prio < vorigeTypeOpwekker.prio) {
                    ntaMeldingen.warning("[W009]", 'Onjuiste invoer');
                    propdataTypeOpwekker.Value = '';
                    ntaEntityData.saveprop(propdataTypeOpwekker);
                    return;
                }
            }
        } //-- end: checkPrioOpwekkerVorige ------------------------------------------------------------------------//

        koelingLogic.checkPrioOpwekkerVolgende = function (opwekker) {
            let opwekkerNummer = koelingLogic.opwekkerNr(opwekker);

            if (opwekkerNummer === koelingLogic.koelOpwekkersData.length) {
                return;
            }

            //-- Value opwekker type
            let propdataTypeOpwekker = opwekker.PropertyDatas['KOEL-OPWEK_TYPE'];
            if (!propdataTypeOpwekker.Value) {
                return;
            }

            //-- Haal opwekker type uit tabel
            let typeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === propdataTypeOpwekker.Value; });
            let melding = false;

            let index = opwekkerNummer;
            for (index; index < koelingLogic.koelOpwekkersData.length; index++) {
                let propdataVolgendeTypeOpwekker = koelingLogic.koelOpwekkersData[index].PropertyDatas['KOEL-OPWEK_TYPE'];

                if (!propdataVolgendeTypeOpwekker.Value) {
                    continue;
                }

                //-- Haal opwekker type uit tabel
                let volgendeTypeOpwekker = koelingLogic.opwekkerTypes.find(function (x) { return x.Id === propdataVolgendeTypeOpwekker.Value; });

                //-- Vergelijk prio
                if (typeOpwekker.prio > volgendeTypeOpwekker.prio) {
                    melding = true;
                    propdataVolgendeTypeOpwekker.Value = '';
                    ntaEntityData.saveprop(propdataVolgendeTypeOpwekker);
                }
            }

            if (melding) {
                ntaMeldingen.warning("[W009]", 'Onjuiste invoer');
            }
        } //-- end: checkPrioOpwekkerVolgende ------------------------------------------------------------------------//

        function checkFabricagejaarToestel(opwekker) {
            // Conditie KOY
            let propdata = opwekker.PropertyDatas['KOEL-OPWEK_FABR'];
            let invoer = opwekker.PropertyDatas['KOEL-OPWEK_INVOER'].Value;
            let opwekkerType = opwekker.PropertyDatas['KOEL-OPWEK_TYPE'].Value;

            if (invoer && invoer === 'VERW-OPWEK_INVOER_FORF' && opwekkerType && (opwekkerType === 'KOEL-OPWEK_TYPE_2' || opwekkerType === 'KOEL-OPWEK_TYPE_10') && ntaSharedLogic.getBouwjaar() >= 2007) {
                propdata.Value = 'KOEL-OPWEK_FABR_GR';
                propdata.Relevant = true;
                ntaEntityData.saveprop(propdata);
            }
        }


        koelingLogic.setGeopend = function () {
            const koeling = ntaEntityData.get(koelingLogic.koelDataId);
            const propdataOpen = koeling.PropertyDatas['KOEL_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        };

        koelingLogic.initWarnings = function () {
            let koeling = ntaEntityData.get(koelingLogic.koelDataId);
            let eerderGeopendPropertyData = koeling.PropertyDatas['KOEL_OPEN'];

            // Conditie KOAH gelijk voor woningbouw en ubouw
            if (ntabuilding.ntaVersionId < 300 && ntaSharedLogic.isBasisOpname() && (!eerderGeopendPropertyData.Value || eerderGeopendPropertyData.Value === 'false')) {
                ntaMeldingen.warning("[W011]");
            }

            if (!eerderGeopendPropertyData.Value || eerderGeopendPropertyData.Value === 'false') {
                eerderGeopendPropertyData.Value = 'true';
                ntaEntityData.saveprop(eerderGeopendPropertyData);
            }
        };

        // CRUD
        koelingLogic.addAfgifte = function (typeId) {
            const propValues = typeId ? [{ "PropertyId": "KOEL-AFG_TYPE_AFG", "Value": typeId }] : [];

            const newId = ntaEntityData.create("KOEL-AFG", -1, [{ "OnCopy": 1, "OnDelete": 1, "Parent": koelingLogic.koelDataId, "ParentEntityId": "KOEL" }], [], propValues);
            const afgifte = ntaEntityData.get(newId);

            for (const rekenzone of koelingLogic.rekenzones) {
                const parentRels = [
                    { "OnCopy": 1, "OnDelete": 1, "Parent": afgifte.EntityDataId, "ParentEntityId": afgifte.EntityId },
                    { "OnCopy": 1, "OnDelete": 1, "Parent": rekenzone.EntityDataId, "ParentEntityId": rekenzone.EntityId },
                ];
                ntaEntityData.create('KOEL-AFG-VENT', -1, parentRels, [], []);
            }

            koelingLogic.alleZonesGekoppeldAanAfgiftes();
            koelingLogic.alleAfgiftesGekoppeldAanZone();

            koelingLogic.setKoelAfgiftes();

        } //-- end: addAfgifte ------------------------------------------------------------------------//

        // Als er 1 afgiftesysteem over is, koppel alle rekenzones daaraan
        koelingLogic.koppelRekenzonesAanLaatsteAfgifte = function () {
            if (koelingLogic.koelAfgifteData.length === 1) {
                const afgifte = koelingLogic.koelAfgifteData[0];
                for (const rekenzone of koelingLogic.rekenzones) {
                    if (!ntaSharedLogic.hasRelation(rekenzone, afgifte)) {
                        //koelingLogic.saveRekenzoneKoppeling(afgifte, koelingLogic.rekenzones[index], null);
                        ntaEntityData.createRelation(rekenzone.EntityDataId, afgifte.EntityDataId, false, false);
                    }
                }
            }

            koelingLogic.alleZonesGekoppeldAanAfgiftes();
            koelingLogic.alleAfgiftesGekoppeldAanZone();
        } //-- end: koppelRekenzonesAanLaatsteAfgifte ------------------------------------------------------------------------//


        koelingLogic.addPompProgress = false;
        koelingLogic.addPomp = function (pomp) {
            koelingLogic.addPompProgress = true;
            let order = -1;
            if (pomp) {
                order = pomp.Order;
            }
            let newId = ntaEntityData.create('KOEL-DISTR-POMP', order, [{ "OnCopy": 1, "OnDelete": 1, "Parent": koelingLogic.koelDistributieData.EntityDataId, "ParentEntityId": "KOEL-DISTR" }], [], []);
            koelingLogic.koelDistributiePompData = ntaEntityData.getChildren(koelingLogic.koelDistributieData, 'KOEL-DISTR-POMP');
            koelingLogic.addPompProgress = false;
        };

        koelingLogic.copyPomp = function (pomp) {
            if (!pomp) {
                return;
            }
            ntaEntityData.copy(pomp.EntityDataId);
            koelingLogic.koelDistributiePompData = ntaEntityData.getChildren(koelingLogic.koelDistributieData, 'KOEL-DISTR-POMP');
        };

        koelingLogic.deletePomp = function (pomp) {
            if (!pomp) {
                return;
            }

            if (koelingLogic.koelDistributiePompData.length <= 1) {
                return;
            }

            ntaEntityData.delete(pomp.EntityDataId, true);
            koelingLogic.koelDistributiePompData = ntaEntityData.getChildren(koelingLogic.koelDistributieData, 'KOEL-DISTR-POMP');
        };

        // Ontvangt afhankelijk veld dat gevalideerd moet worden
        koelingLogic.validateDependencies = function (prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
                case 'KOEL-OPWEK_TYPE': {
                    const checkValue = prop.getValue(entdata);

                    let hidden = koelingLogic.isHidden(prop, entdata);
                    if (ntaValidation.hasCodedValues(prop)) {
                        koelingLogic.getCodedValues(prop, entdata);
                    }
                    koelingLogic.setDescriptionInstallation(entdata);

                    if (checkValue !== prop.getData(entdata).Value) {
                        koelingLogic.saveValue(prop, entdata);
                    }

                    bepaalWCctrleninIsNul(entdata);
                    bepaalWCauxDisInEER();
                    break;
                }

                case 'KOEL-OPWEK_TOEW': {
                    let hidden = koelingLogic.isHidden(prop, entdata);
                    koelingLogic.getSelectionTable(prop, entdata);
                    koelingLogic.setDescriptionInstallation(entdata);
                    koelingLogic._setSelectionTableValues(entdata, 'C');
                    setEnergiefracties();

                    bepaalWCgenInvoerForfaitair(entdata);
                    bepaalWCctrleninIsNul(entdata);
                    bepaalWCauxDisInEER();
                    break;
                }
                case 'KOEL-OPWEK_INVOER': {
                    bepaalFCgenInvoerForfaitair();
                    bepaalWCgenInvoerForfaitair(entdata);
                    bepaalWCctrleninIsNul(entdata);
                    bepaalWCauxDisInEER();

                    koelingLogic.checkFractiePrefOpwekker();
                    break;
                }
                case 'KOEL-OPWEK_REGIO': {
                    const propToestel = koelingLogic.koelOpwekkerProperties['KOEL-OPWEK_TOEW'];

                    //-- nieuwe keuzelijst voor toestel samenstellen
                    koelingLogic.getSelectionTable(propToestel, entdata, "");
                    koelingLogic.setDescriptionInstallation(entdata);
                    koelingLogic._setSelectionTableValues(entdata, 'C');
                    break;
                }
                case 'KOEL-DISTR_AANV_POMP': {
                    bepaalWCauxDisInEER();
                    break;
                }
                default: {
                    const checkValue = prop.getValue(entdata);

                    let hidden = koelingLogic.isHidden(prop, entdata);
                    if (ntaValidation.hasCodedValues(prop)) {
                        koelingLogic.getCodedValues(prop, entdata);
                    }

                    if (checkValue !== prop.getData(entdata).Value) {
                        koelingLogic.saveValue(prop, entdata);
                    }

                    break;
                }
            }
        };

        function init() {
            //if (!ntabuilding.canSave()) return;

            // Opwekking properties en entiteiten
            koelingLogic.setKoelOpwekkers();

            setDefaultValuesOpwekkers();

            setDefaultValueFabricagejaar();

            // Check opwekkers op conditie KOY
            koelingLogic.koelOpwekkersData.forEach(function (opwekker, index) {
                checkFabricagejaarToestel(opwekker);
            });

            if (koelingLogic.koelAfgifteData.length <= 0) {
                koelingLogic.addAfgifte();
            }

            koelingLogic.koppelRekenzonesAanLaatsteAfgifte();
        }

        init();
        initSelectionTable();

    }


    return KoelingLogic;
}]);
