﻿angular.module('projectModule')
    .controller("BuildingResultsTOjuliController",
        ['$scope', '$controller', 'ntaData', 'ntaValidation', 'ntaEntityData', 'ntaRounding', 'ntaSharedLogic', 'ntaDependencyValidation', 'ResultsTOjuliFactory',
function ($scope,   $controller,   ntaData,   ntaValidation,   ntaEntityData,   ntaRounding,   ntaSharedLogic,   ntaDependencyValidation, ResultsTOjuliFactory ) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope });
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;

    //== Instance data ============================================================================
    const tojuliLogic = new ResultsTOjuliFactory(ntaDependencyValidation);
    ctrl.toJuliLogic = tojuliLogic;

    //== Exports ==================================================================================

    ctrl.actieveBerekening = 0;

    /// properties
    ctrl.TOjuliProperties = tojuliLogic.tojuliProperties
    ctrl.GTOProperties = tojuliLogic.gtoProperties;
    ctrl.LSTRMProperties = tojuliLogic.lstrmProperties; /// berekening cf bijlage AA
    ctrl.tempmeth_prop = tojuliLogic.tojuliFormProperties['RESULT-TOJULI_FORM_TEMPMETH'];
    ctrl.gto_prop = tojuliLogic.tojuliFormProperties['RESULT-TOJULI_FORM_GTO'];

    //resultaten
    ctrl.TOjuliResultaten = tojuliLogic.TOjuliResultaten;
    ctrl.GTOResultaten = tojuliLogic.GTOResultaten;
    ctrl.LSTRMResultaten = tojuliLogic.LSTRMResultaten;
    ctrl.TOjuliForm = tojuliLogic.TOjuliForm;

    ctrl.isGebouw = isGebouw;
    ctrl.isNieuwbouw = ntaSharedLogic.isNieuwbouw;
    ctrl.isUtiliteit = ntaSharedLogic.isUtiliteit;
    ctrl.isReadOnly = tojuliLogic.isReadOnly;
    ctrl.isAppPerGebouw = tojuliLogic.isAppPerGebouw;
    ctrl.isAppPerAppartement = tojuliLogic.isAppPerAppartement;
    ctrl.isBerekendPerApp_Unit = tojuliLogic.isBerekendPerApp_Unit;
    ctrl.isHidden = tojuliLogic.isHidden;

    ctrl.getIcon = tojuliLogic.getIcon;
    ctrl.berekeningen = tojuliLogic.berekeningen;
    ctrl.getResult = tojuliLogic.getResult;
    ctrl.getRisicoOververhitting = tojuliLogic.getRisicoOververhitting;
    ctrl.getCodedValues = tojuliLogic.getCodedValues;

    ctrl.isVersionLe32 = tojuliLogic.isVersionLe32;
    ctrl.isHidden = tojuliLogic.isHidden;

    ctrl.showToJuli = tojuliLogic.showToJuliTable;
    ctrl.showGto = tojuliLogic.showGtoTable;
    ctrl.showLstrm = tojuliLogic.showLstrmTable;

    ctrl.conditionG = tojuliLogic.conditionG;
    ctrl.conditionS = tojuliLogic.conditionS;
    ctrl.conditionW = tojuliLogic.conditionW;

    ctrl.getGTOProperties = cat => ctrl.GTOProperties.filter(x => x.Id.startsWith("RESULT-GTO_" + cat));
    ctrl.getLSTRMProperties = cat => ctrl.LSTRMProperties.filter(x => x.Id.startsWith("RESULT-LSTRM_" + cat));
    ctrl.showChooser = () => ctrl.berekeningen.length > 1;
    ctrl.getForm = getForm;

    Object.assign(ctrl, {
        saveValue,
        hasZNV,
        getRZName,
        getPropDataValue,
        selecteerBerekening,
    });

    $scope.endFormValidation = tojuliLogic.endFormValidation();

    //== Initialization ===========================================================================

    //-- VO 2024-08-22: omdat TOJULI geen eigen enige entiteit heeft (de vier entiteiten zijn of
    //-- per unit of per unitrekenzone) is het vlaggetje voor 'Geopend' toegevoegd aan entity
    //-- gebouw GEB.
    const gebouw = ntaEntityData.getFirstWithEntityId('GEB');
    ctrl.setGeopend('GEB_TOJULI_FORM_OPEN', gebouw);

    //== Implementation ===========================================================================
    angular.element(function () { //alle touched properties valideren na het laden van form
        tojuliLogic.startFormValidation();
        base.initializeUnitSpacing();

        const key = getKeyActiveBerekeningId();
        const sessionBerekeningId = sessionStorage.getItem(key);
        const actieveBerekening = ctrl.berekeningen.find(b => b.Id === sessionBerekeningId)
            ?? ctrl.berekeningen[0];
        selecteerBerekening(actieveBerekening?.Id, 'instant');
    });

    function getForm() {
        return tojuliLogic.form_tojuli;
    }

    function isGebouw(id = ctrl.actieveBerekening) {
        return tojuliLogic.isGebouw(id);
    } //-- end: isGebouw --------------------------------------------------------------------------

    function saveValue (prop, entdata) {
        tojuliLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function getPropDataValue(prop, entdata) {
        if (!prop || !prop.Id || !entdata) {
            return;
        }

        let value = prop.getValue(entdata) || 0; //-- als waarde null is omdat hij niet berekend is, dan 0 laten zien
        switch (prop.Id) {
            case 'RESULT-TOJULI_WEINIG_RAMEN':  // TO24
            case 'RESULT-TOJULI_BEP_ZON': {     // TO25
                value = {
                    'true': 'ja',
                    'false': 'nee',
                }[value] || '-';

                if (prop.Id === 'RESULT-TOJULI_WEINIG_RAMEN') {
                    /// als er een raamfactor is, moet deze weergegeven worden achter ja/nee
                    const raamfactor = entdata.PropertyDatas['RESULT-TOJULI_RAAMFACTOR']?.Value || '';
                    if (raamfactor) {
                        value += ` (factor ramen: ${raamfactor})`;
                    }
                }

                break;
            }
            case 'RESULT_TOJULI_TYPE_KOEL':
            case 'RESULT_TOJULI_RISICO':
            case 'RESULT-TOJULI_KOELCAP': {
                // laat de waarde onveranderd
                break;
            }
            default: {
                if (prop.Domain?.DomainType === 2) { // coded values
                    value = value && prop.getCode(entdata).Value;
                } else { //nummer
                    // afgeronde waarde laten zien, maar niet mee rekenen
                    value = ntaRounding.roundAndAddZerosNewValue(prop, value);
                    if (typeof value === 'string') {
                        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                    }
                }
            }
        }

        return value;
    }; //-- end: getPropDataValue -----------------------------------------------------------------

    function getKeyActiveBerekeningId() {
        return `building-${ntaData.buildingId}:ResultsTOjuli:berekeningId`;
    } //-- end: getKeyActiveBerekeningId ------------------------------------------------------

    function selecteerBerekening(id, behavior = 'smooth') {
        ctrl.actieveBerekening = id;

        tojuliLogic.setActiveDataAndValidate(ctrl.actieveBerekening);

        const tile = document.getElementById('berekening+' + id);
        if (tile) {
            tile.scrollIntoView({ block: 'nearest', inline: 'center', behavior });

            const key = getKeyActiveBerekeningId();
            sessionStorage.setItem(key, id);
        }
    } //-- end: selecteerBerekening ---------------------------------------------------------------

    function getRZName(entTOJuli) {
        const rz = ntaEntityData.findEntity(entTOJuli, '^RZ', '^UNIT-RZ.^RZ');
        return rz && rz.PropertyDatas["RZ_OMSCHR"].Value || '';
    } //-- end: getRZName -------------------------------------------------------------------------

    function hasZNV() {
        /// I20 / I21 niet isHidden
        /// [L] toon indien op één van de in de berekening aanwezige 'constructies' formulieren in kolom C17=aanwezig
        return ntaEntityData.getListWithEntityId('CONSTRT')
            .some(ed => ed.PropertyDatas['CONSTRT_ZNVENT'].Value === 'ZOMERNVENT_AANW');
    } //-- end: hasZNV ----------------------------------------------------------------------------

}]);