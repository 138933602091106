﻿'use strict';
angular.module('projectModule').controller("BuildingAttrController", ['$scope', '$controller', '$log', '$q', '$mdDialog', 'ntaData', 'projecttree', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ntaMeldingen', 'settingsMenuData', 'ntaDependencyValidation', 'AttributesFactory', function ($scope, $controller, $log, $q, $mdDialog, ntaData, projecttree, ntabuilding, ntaValidation, ntaEntityData, ntaSharedLogic, ntaMeldingen, settingsMenuData, ntaDependencyValidation, AttributesFactory) {
    const vm = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(vm, base);

    var attrLogic = new AttributesFactory(ntaDependencyValidation);
    vm.getAttrLogic = function () {
        return attrLogic;
    };

    //Data
    vm.ntaValidation = ntaValidation;
    vm.properties = attrLogic.properties;
    vm.extraProperties = attrLogic.extraProperties;
    vm.extraBuildingattributes = attrLogic.extraBuildingattributes;
    vm.buildingattributes = attrLogic.buildingattributes;
    vm.selectedFile = null;
    vm.fileSizeLimit = 5 * 1024 * 1024;
    vm.thumbnailSrc = null;
    vm.thumbnailLoaded = false;

    //Form
    angular.element(async function () { //alle touched properties valideren na het laden van form
        vm.properties.forEach(function (prop, index) {
            var propdata = vm.buildingattributes.PropertyDatas[prop.Id];
            validate(prop, propdata);
        });

        await loadPicturePreview();
    });

    $scope.endFormValidation = function () {
        attrLogic.endFormValidation();
    };

    vm.getForm = function () {
        return attrLogic.form_attrib;
    };

    vm.saveValue = function (prop, entdata) {
        attrLogic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    };
    vm.saveValueAutocomplete = attrLogic.saveValueAutocomplete;

    var validate = attrLogic.validate;

    vm.onValidateCallback = function (prop, entdata, value) {
        if (entdata && prop.Id === "GEB_TYPEGEB") {
            var propdata = prop.getData(entdata);
            if (propdata && value !== propdata.Value) {
                var warning = ntabuilding.warnings.find(function (x) { return x.Id === "[W001]"; }).Value;
                return ntaMeldingen.confirm_before_save_dialog("Wijzigen type gebouw", warning, attrLogic.form_attrib, propdata, value, function () { vm.saveValue(prop); });
            }
        }
        return true;
    };

    vm.getPropData = function (prop, entdata) {
        return base.getPropData(prop, entdata);
    };

    vm.getPropDataAutocomplete = function (prop) {
        if (!prop) {
            return;
        }
        if (vm.buildingattributes) {
            var propData = vm.buildingattributes.PropertyDatas[prop.Id];
            if (propData.Value) {
                var cvs = ntaValidation.codedValues(prop);
                if (cvs && cvs.length > 2) { // is coded value
                    var cvData = cvs.find(function (x) { return x.Id === propData.Value; });
                    var aRes = { Value: cvData };
                    return aRes;
                }
            }
            return;
        } else {
            return prop.DefaultValue;
        }
    };

    vm.handleFileSelect = function (files, propId) {
        if (files && files.length > 0) {
            const file = files[0];

            // Define allowed image MIME types
            const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

            // Check MIME type
            if (!allowedImageTypes.includes(file.type)) {
                $log.error("The selected file is not a valid image.");
                ntaMeldingen.warning("[W148]");
                return;
            }

            if (file.size <= vm.fileSizeLimit) {
                vm.selectedFile = file;

                const reader = new FileReader();
                reader.onload = function (e) {
                    vm.thumbnailSrc = e.target.result;
                    vm.thumbnailLoaded = true;

                    const formData = new FormData();
                    formData.append('file', vm.selectedFile);
                    formData.append('buildingId', `${ntabuilding.buildingId}`);

                    return attrLogic.uploadPicture(formData, 'GEB-EXTRA_FOTO', 'GEB-EXTRA_THUMBNAIL', vm.imagePreview);
                };
                reader.readAsDataURL(file);
            } else {
                $log.error("File size exceeds the 5MB limit.");
                vm.selectedFile = null;
                ntaMeldingen.warning("[W146]");
            }
        }
        // Reset the value to allow selecting the same file again
        const fileInput = document.getElementById('fileInput');
        fileInput.value = "";

    };

    vm.deletePicture = async function () {
        try {
            if (await attrLogic.deletePicture(ntabuilding.buildingId)) {
                vm.thumbnailLoaded = false;
                vm.thumbnailSrc = null;
            } else {
                alert('Het verwijderen is niet goed gegaan');
            };

        } catch (error) {
            $log.error(error)
        }
    }; //-- end: deletePicture ----------------------------------------------------------------

    async function loadPicturePreview()
    {
        if (!attrLogic.extraBuildingattributes) {
            return
        }

        vm.thumbnailLoaded = false;
        vm.thumbnailSrc = null;

        const propFoto = attrLogic.extraProperties && attrLogic.extraProperties['GEB-EXTRA_FOTO'];
        const propdataFoto = propFoto?.getData(attrLogic.extraBuildingattributes);

        try {
            const thumbnailFile = await attrLogic.getThumbnail(propdataFoto);
            if (thumbnailFile) {
                /// thumbnailFile is een file location naar een tijdelijk bestand
                vm.thumbnailSrc = thumbnailFile;
                vm.thumbnailLoaded = true;
            }
        } catch {
            $log.error("Laden thumbnail gaat nog niet goed.");            
        }
    }

    vm.getPropertyType = function (prop) {
        return prop && prop.PropertyType;
    };

    vm.isHidden = attrLogic.isHidden;
    vm.getCodedValues = attrLogic.getCodedValues;

    attrLogic.setGeopend();

}]);