﻿angular.module('projectModule')
    .factory('MeasureUFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityData', 'ntaSharedLogic', 'ntaDeltas', 'ListCache', 'RelationData', 'ntaMeasureData', 'BuildingBegrenzingFactory', 'ntaMeldingen',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityData,   ntaSharedLogic,   ntaDeltas,   ListCache,   RelationData,   ntaMeasureData,   BuildingBegrenzingFactory,   ntaMeldingen ) {
    'use strict';

    // Constantes die voor elke instantie hetzelfde zijn

    /// relatie tussen waarde uit property MEASURE-TYPE en waarde uit property LIBCONSTRT_TYPE
    const _libConstrTypeByMeasureType = new Map(Object.entries({
        'MEASURE-UW_GGL_RAAM': 'TRANSTYPE_RAAM',
        'MEASURE-UD_DEUR': 'TRANSTYPE_DEUR',
        'MEASURE-UD_PANEEL': 'PNL_KOZ',
    }));

    return function MeasureULogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================

        const _entdataMeasure = ntaEntityData.get(measureId);
        const _entdataMeasureU = ntaEntityData.getFirstChild(_entdataMeasure, 'MEASURE-U');
        const _listCache = new ListCache();
        const _isVersionGe33 = ntabuilding.ntaVersionId >= 300;

        const begrenzingLogic = new BuildingBegrenzingFactory(0, null);

        /// [U-E] Indien als maatregel 'UW en/of ggl raam' is gekozen toon 'UW en/of ggl raam'.
        /// Indien als maatregel 'UD en/of ggl deur' is gekozen toon 'UD en/of ggl deur'.
        /// Indien als maatregel 'UD paneel in kozijn' is gekozen toon 'UD paneel in kozijn'
        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);

        /// libconstructie die meegenomen worden moet gecheckt worden op type.
        const _libConstrType = _libConstrTypeByMeasureType.get(_measureType.Id);


        //== Exports ==============================================================================
        self.entdataMeasureU = _entdataMeasureU;
        self.properties = ntaData.properties[_entdataMeasureU.EntityId];
        self.measureType = _measureType;
        self.isItemChecked = ntaMeasureData.isItemChecked;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            // specifieke methods voor maatregelen
            getTileDetails,
            getAlleKosten,
            generateDeltas,
            getMatchingConstrEntdatas,
            toggleItemChecked,

            // standaard methods tbv validatie
            isHidden,
            isReadOnly,
            hasCodedValues,
            getCodedValues,
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
            onValidateCallback,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        /// voor de oppervlaktebepaling moet ik weten of de libmethode "oppervlakte per kozijnmerk is" ja of nee.
        function isOppPerKozijn() {
            return ntaEntityData.getFirstWithEntityId('LIBCONSTRFORM')
                .PropertyDatas['LIBCONSTRFORM_KOZ'].Value === 'KOZKENM_OPP';
        } //-- end: isOppPerKozijn ----------------------------------------------------------------

        function getTileDetails(render) {
            const lines = [];
            lines.push({ name: 'nieuwe U', value: render.value('MEASURE-U_U') });
            lines.push({});
            lines.push({ name: 'totale oppervlakte', value: render.value('MEASURE-U_A_TOT') });
            if (ntaSharedLogic.showCosts()) {
                lines.push({});
                lines.push(render.detail('MEASURE-U_KOSTEN_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-U_SUBS_TOT'));
                if (_isVersionGe33) lines.push(render.detail('MEASURE-U_KOSTEN_TOT_MIN_SUBSIDIE'));
            }
            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getAlleKosten() {
            const totCostProp = ntaData.properties["MEASURE-U_KOSTEN_TOT"];
            const investering = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureU), 0);

            const totSubsProp = ntaData.properties["MEASURE-U_SUBS_TOT"];
            const subsidie = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureU), 0);

            const totMinSubsProp = ntaData.properties["MEASURE-U_KOSTEN_TOT_MIN_SUBSIDIE"];
            const totaal = ntaSharedLogic.parseFloat(totMinSubsProp?.getValue(_entdataMeasureU), 0);

            return {
                investering,
                subsidie,
                totaal,
            };
        } //-- end: getAlleKosten ----------------------------------------------------------------

        function generateDeltas(shadowId) {
            const deltas = [];

            const propdataU = _entdataMeasureU.PropertyDatas['MEASURE-U_U'];
            const propdataGgl = _entdataMeasureU.PropertyDatas['MEASURE-U_GGL'];
            const valueU = propdataU.Relevant ? propdataU.Value : null;
            const valueGgl = propdataGgl.Relevant ? propdataGgl.Value : null;

            if (conditionA()) {
                // vervang waarde U- en/of Ggl-property van gekozen lib-item met waarde van MEASURE-U_U resp. MEASURE-U_GGL
                const libConstrT = ntaEntityData.getFirstParent(_entdataMeasureU, 'LIBCONSTRT');
                if (!libConstrT) { //kan voorkomen als je nog niks hebt geselecteerd bij deze maatregel
                    return deltas;
                }
                const propdataU = libConstrT.PropertyDatas['LIBCONSTRT_U'];
                if (propdataU.Relevant) {
                    const delta = new ntaDeltas.DeltaPropertyData(shadowId, propdataU.PropertyDataId, propdataU, 'Replace');
                    delta.Value = valueU || delta.Value; //-- U-waarde kan leeg zijn als alleen Ggl vervangen moet worden
                    deltas.push(delta);
                }

                const propdataGgl = libConstrT.PropertyDatas['LIBCONSTRT_G'];
                if (propdataGgl.Relevant) {
                    const delta = new ntaDeltas.DeltaPropertyData(shadowId, propdataGgl.PropertyDataId, propdataGgl, 'Replace');
                    delta.Value = valueGgl || delta.Value; //-- Ggl-waarde kan leeg zijn als alleen U vervangen moet worden
                    deltas.push(delta);
                }

            } else if (conditionB()) {
                const constrEntdatas = getMatchingConstrEntdatas();

                const constrByBegrenzingen = constrEntdatas.reduce((constrsByBegrenzing, constr) => {
                    const begrId = ntaEntityData.getFirstParent(constr, 'BEGR').EntityDataId;
                    const constrs = constrsByBegrenzing.get(begrId) || [];
                    if (constrs.length === 0) {
                        constrsByBegrenzing.set(begrId, constrs);
                    }
                    constrs.push(constr);
                    return constrsByBegrenzing;
                }, new Map());

                for (const [begrId, constructies] of constrByBegrenzingen) {
                    // Maak een nieuwe virtuele LIBCONSTRT voor elke gebruikte LIBCONSTRT (met de nieuwe waardes), en koppel elke betreffende CONSTRT aan die virtuele kopie.
                    const newLibConstrTPseudoEntdatas = new Map();
                    const libConstrTs = constructies.flatMap(ed => ntaEntityData.getParents(ed, 'LIBCONSTRT')).distinct();
                    for (const libConstrT of libConstrTs) {
                        // maak nieuw lib-item aan met waarde rc-property van MEASURE-RC_RC (kopie van lib-item van de constructie)
                        const newId = uuid.v4();
                        deltas.push(new ntaDeltas.DeltaEntityData(shadowId, newId, libConstrT, 'Replace'));

                        for (const propdata of libConstrT.PropertyDatas) {
                            const propDelta = new ntaDeltas.DeltaPropertyData(shadowId, newId + ':' + propdata.PropertyId, propdata, 'Replace');
                            propDelta.EntityDataId = newId;
                            if (propdata.Relevant) {
                                if (propdata.PropertyId === 'LIBCONSTRT_U') {
                                    propDelta.Value = valueU || propDelta.Value;     //-- U-waarde kan leeg zijn als alleen Ggl vervangen moet worden
                                } else if (propdata.PropertyId === 'LIBCONSTRT_G') {
                                    propDelta.Value = valueGgl || propDelta.Value;   //-- Ggl-waarde kan leeg zijn als alleen U vervangen moet worden
                                }
                            }
                            deltas.push(propDelta);
                        }

                        const libConstrPseudoEntdata = {
                            BuildingId: libConstrT.BuildingId,
                            EntityDataId: newId,
                            EntityId: libConstrT.EntityId,
                        };
                        newLibConstrTPseudoEntdatas.set(libConstrT.EntityDataId, libConstrPseudoEntdata);
                    }

                    const existingDeltas = ntaData.deltas.has(shadowId) ? [...ntaData.deltas.get(shadowId).values()] : [];
                    for (const constrEntdata of constructies) {

                        const oldRelations = ntaEntityData.getParentRelations(constrEntdata, 'LIBCONSTRT');

                        // verwijder relatie(s) van de gekozen begrenzingen met lib-items
                        for (const oldRelation of oldRelations) {
                            const oldRelDelta = new ntaDeltas.DeltaRelationData(shadowId, oldRelation.EntityRelationDataId, oldRelation, 'Delete');
                            deltas.push(oldRelDelta);
                        }

                        // haal nieuw (pseudo)lib-item met waarde rc-property van MEASURE-RC_RC (kopie van lib-item van de constructie)
                        const oldRelation = oldRelations[0];
                        const newLibConstrPseudoEntdata = newLibConstrTPseudoEntdatas.get(oldRelation.Parent);

                        //Is er al een relatie met een virtuele LibConstrT? Neem de U / ggl waarden over van de oude virtuele LIBCONSTRT
                        const existingConstrDeltas = existingDeltas.filter(x => x.Child === constrEntdata.EntityDataId && x.Action === 'Replace');
                        for (const existingConstrDelta of existingConstrDeltas) {
                            //Neem de U / ggl waarden die niet zijn gewijzigd over van de oude virtuele LIBCONSTRT
                            if (valueU === null) {
                                const existingLibConstrUDelta = existingDeltas.find(x => x.EntityDataId === existingConstrDelta.Parent && x.PropertyId === 'LIBCONSTRT_U' && x.Action === 'Replace');
                                if (existingLibConstrUDelta) {
                                    deltas.find(x => x.EntityDataId === newLibConstrPseudoEntdata.EntityDataId && x.PropertyId === 'LIBCONSTRT_U' && x.Action === 'Replace').Value = existingLibConstrUDelta.Value;
                                }
                            }
                            if (valueGgl === null) {
                                const existingLibConstrGDelta = existingDeltas.find(x => x.EntityDataId === existingConstrDelta.Parent && x.PropertyId === 'LIBCONSTRT_G' && x.Action === 'Replace');
                                if (existingLibConstrGDelta) {
                                    deltas.find(x => x.EntityDataId === newLibConstrPseudoEntdata.EntityDataId && x.PropertyId === 'LIBCONSTRT_G' && x.Action === 'Replace').Value = existingLibConstrGDelta.Value;
                                }
                            }

                            // relatie naar nieuwe parent laten wijzen
                            existingConstrDelta.Parent = newLibConstrPseudoEntdata.EntityDataId;
                            existingConstrDelta.Id = newLibConstrPseudoEntdata.EntityDataId + ':' + existingConstrDelta.Child; // id van de relatie aangepassen
                        }

                        // maak nieuwe relatie aan tussen de gekozen begrenzingen en nieuw aangemaakte libconstr (alleen als er geen bestaanden relatie is
                        if (existingConstrDeltas.length === 0) {
                            const newRelation = new RelationData(newLibConstrPseudoEntdata, constrEntdata, 0, 0);
                            const newRelDelta = new ntaDeltas.DeltaRelationData(shadowId, newRelation.EntityRelationDataId, newRelation, 'Replace');
                            deltas.push(newRelDelta);
                        }
                    }
                }
            }

            return deltas;
        } //-- end: generateDeltas ----------------------------------------------------------------

        function isReadOnly(prop) {
            if (!prop)
                return true;

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                case 'MEASURE-U_A_TOT':
                    return true;

                case 'MEASURE-U_KOSTEN_TOT':
                    return conditionD();
                case 'MEASURE-U_SUBS_TOT':
                    return conditionQ();
            }

            return false;
        } //-- end: isReadOnly --------------------------------------------------------------------

        function isHidden(prop, entdata = _entdataMeasureU) {
            if (typeof prop === 'string') prop = ntaData.properties[prop];
            if (!prop || !entdata) {
                return true;
            }
            const propdata = entdata.PropertyDatas[prop.Id];
            if (!propdata) {
                return true;
            }

            let visible = true;
            let relevant = null; // null = zelfde als visible

            switch (prop.Id) {
                case 'MEASURE_OPEN':
                case 'MEASURE-U_KOSTEN_TOT_MIN_SUBSIDIE': {
                    visible = false;
                    break;
                }
                case 'MEASURE-U_LIBCONSTR_ID': {
                    visible = conditionA();
                    break;
                }
                case 'MEASURE-U_VERVANG_ITEM':
                case 'MEASURE-U_BEGR_IDS':
                case 'MEASURE-U_A_GT': {
                    visible = conditionB();
                    break;
                }
                case 'MEASURE-U_U': {
                    visible = conditionA() || conditionK();
                    break;
                }
                case 'MEASURE-U_U_GT': {
                    visible = conditionB() && conditionK();
                    break;
                }
                case 'MEASURE-U_GGL': {
                    visible = (conditionA() && !conditionM()) || conditionL();
                    break;
                }
                case 'MEASURE-U_GGL_GT': {
                    visible = conditionB() && conditionL();
                    break;
                }
                case 'MEASURE-U_KOSTEN_INV':       // U17
                case 'MEASURE-U_KOSTEN_TOT': {     // U19
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-U_KOSTEN_PER_M2': {  // U18
                    visible = conditionC() && conditionD();
                    break;
                }
                case 'MEASURE-U_SUBS_INV':       // U22
                case 'MEASURE-U_SUBS_TOT': {     // U24
                    visible = conditionC();
                    break;
                }
                case 'MEASURE-U_SUBS_PER_M2': {  // U23
                    visible = conditionC() && conditionQ();
                    break;
                }

            }

            if (relevant === null) relevant = visible;
            ntaEntityData.setPropdataStatus(propdata, relevant, visible);
            return !visible;
        } //-- end: isHidden ----------------------------------------------------------------------

        function conditionA() {
            // [U-A] toon indien U03 = vervang 'UW en/of ggl raam / UD deur / UD paneel in kozijn' uit bibliotheek
            return _entdataMeasureU.PropertyDatas['MEASURE-U_METHODE'].Value === 'MEASURE-U_METHODE_LIB';
        } //-- end: conditionA --------------------------------------------------------------------

        function conditionB(measureU = _entdataMeasureU) {
            // [U-B] toon indien U03 = zoek en vervang 'UW en/of ggl raam / UD deur / UD paneel in kozijn' in berekening
            return measureU.PropertyDatas['MEASURE-U_METHODE'].Value === 'MEASURE-U_METHODE_U';
        } //-- end: conditionB --------------------------------------------------------------------

        function conditionC() {
            // [U-C] toon indien bij instellingen gekozen voor 'maatwerk advies'
            return ntaSharedLogic.showCosts();
        } //-- end: conditionC --------------------------------------------------------------------

        function conditionD() {
            // [U-D] toon indien U17 = kostenkentallen per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureU.PropertyDatas['MEASURE-U_KOSTEN_INV'].Value === 'MEASURE_KOSTEN_PER_M2';
        } //-- end: conditionD --------------------------------------------------------------------

        function conditionK() {
            // [U-K] toon als U07 = vervang U-waarden / vervang U- en ggl-waarden
            const waardeU07 = ["MEASURE-U_VERVANG_U", "MEASURE-U_VERVANG_U_GGL"];
            return waardeU07.includes(_entdataMeasureU.PropertyDatas['MEASURE-U_VERVANG_ITEM'].Value);
        } //-- end: conditionK---------------------------------------------------------------------//

        function conditionL() {
            // [U-L] toon als U07 = vervang ggl-waarden / vervang U- en ggl-waarden
            const waardeU07 = ["MEASURE-U_VERVANG_GGL", "MEASURE-U_VERVANG_U_GGL"];
            return waardeU07.includes(_entdataMeasureU.PropertyDatas['MEASURE-U_VERVANG_ITEM'].Value);
        } //-- end: conditionL---------------------------------------------------------------------//

        function conditionM() {
            /// [U-M] verberg als maatregel 'UD paneel in kozijn' is gekozen
            return _measureType.Id === 'MEASURE-UD_PANEEL';
        } //-- end: conditionM---------------------------------------------------------------------//

        function conditionO_P(entdata, valueToSave) {
            //[U-O] Indien dit veld gewijzigd wordt controleer of de maatregel gebruikt wordt in één of meerdere varianten.
            //Controleer of bij deze varianten de wijzigingen van U - waarden niet dezelfde constructie betreffen en controleer apart of maatregel van de ggl niet dezelfde constructie betreffen.
            //Indien maatregelen dezelfde constructie en dezelfde grootheid(Uwaarde / gglwaarde) betreffen geef dan melding[W117] en zet dit veld terug op de oorspronkelijke keuze.
            //[U-P] Indien een checkbox gewijzigd wordt controleer of de maatregel gebruikt wordt in één of meerdere varianten.
            //Controleer of bij deze varianten de wijzigingen van U - waarden niet dezelfde constructie betreffen en controleer apart of maatregel van de ggl niet dezelfde constructie betreffen.
            //Indien maatregelen dezelfde constructie en dezelfde grootheid(Uwaarde / gglwaarde) betreffen geef dan melding[W118] en zet de checkbox terug op de oorspronkelijke keuze.

            const measure = ntaEntityData.getFirstParent(entdata, "MEASURE");
            const propMeasureType = ntaData.properties['MEASURE_TYPE'];
            const typeValue = propMeasureType.getValue(measure);

            const variants = ntaEntityData.findEntities(measure, 'VARIANT-MEASURE.^VARIANT', 'VARIANT');
            const propVariantName = ntaData.properties['VARIANT_NAAM'];
            const variantNames = [];
            for (const variant of variants) {
                const measures = ntaEntityData.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE').filter(x => propMeasureType.getValue(x) === typeValue);
                if (ntaSharedLogic.hasConstructionOverlap(measure, measures, valueToSave)) {
                    variantNames.push(propVariantName.getValue(variant));
                }
            }
            return variantNames;
        } //-- end: conditionO_P--------------------------------------------------------------------//

        function conditionQ() {
            //[U-Q] toon indien U23 = subsidie per m²
            return ntaSharedLogic.showCosts() && _entdataMeasureU.PropertyDatas['MEASURE-U_SUBS_INV']?.Value === 'MEASURE_SUBSIDIE_PER_M2';
        } //-- end: conditionK ----------------------------------------------------------------

        function calculateTotalArea() {
            const constrEntdatas = getMatchingConstrEntdatas();
            return constrEntdatas.reduce((area, constr) => area + getConstrArea(constr), 0);
        } //-- end: calculateTotalArea ------------------------------------------------------------

        function getMatchingConstrEntdatas() {
            const constrParentEntdatas = [];

            if (conditionA()) {
                // de som van alle oppervlakte waar deze constructie in de berekening is gebruikt (veld U05)
                const libConstrT = ntaEntityData.getFirstParent(_entdataMeasureU, 'LIBCONSTRT');
                if (libConstrT) {
                    constrParentEntdatas.push(libConstrT);
                }

            } else if (conditionB()) {
                // de som van alle oppervlakte die voldoen aan de filters in de velden U08 t/m U11 [U-F]
                const begrEntdatas = ntaEntityData.getParents(_entdataMeasureU, 'BEGR');
                constrParentEntdatas.push(...begrEntdatas);
            }

            let constrEntdatas = constrParentEntdatas.flatMap(ed => ntaEntityData.getChildren(ed, 'CONSTRT'))
                .filter(ed => ed.Relevant)
                .filter(ed => constructieConformType(ed));

            if (conditionB()) {
                // [U-F] het gaat om de constructies die aan alle kenmerken van de velden U08 t/m U11 voldoen (dus EN)
                const propdataU = _entdataMeasureU.PropertyDatas['MEASURE-U_U_GT']; // U09
                if (propdataU.Relevant) {
                    const minU = ntaSharedLogic.parseFloat(propdataU.Value);
                    if (!isNaN(minU)) {
                        constrEntdatas = constrEntdatas.filter(constr => {
                            const u = getConstrU(constr);
                            return !isNaN(u) && u > minU;
                        });
                    }
                }

                const propdataGgl = _entdataMeasureU.PropertyDatas['MEASURE-U_GGL_GT'];  // U10
                if (propdataGgl.Relevant) {
                    const minGgl = ntaSharedLogic.parseFloat(propdataGgl.Value);
                    if (!isNaN(minGgl)) {
                        constrEntdatas = constrEntdatas.filter(constr => {
                            const ggl = getConstrGgl(constr);
                            return !isNaN(ggl) && ggl > minGgl;
                        });
                    }
                }

                const propdataA = _entdataMeasureU.PropertyDatas['MEASURE-U_A_GT'];  // U11
                if (propdataA.Relevant) {
                    const minA = ntaSharedLogic.parseFloat(propdataA.Value);
                    if (!isNaN(minA)) {
                        constrEntdatas = constrEntdatas.filter(constr => {
                            const area = getConstrArea(constr);
                            return !isNaN(area) && area > minA;
                        });
                    }
                }
            }

            return constrEntdatas;
        } //-- end: getMatchingConstrEntdatas -----------------------------------------------------

        function constructieConformType(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRT');
            return lib && lib.PropertyDatas['LIBCONSTRT_TYPE'].Value === _libConstrType;
        } //-- end: constructieConformType---------------------------------------------------------

        function getLibOpp(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRT');
            const oppValue = lib && lib.PropertyDatas['LIBCONSTRT_AC'].Value;
            return ntaSharedLogic.parseFloat(oppValue, 0);
        } //-- end: getConstrU -------------------------------------------------------------------

        function getConstrU(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRT');
            const uValue = lib && lib.PropertyDatas['LIBCONSTRT_U'].Value;
            return ntaSharedLogic.parseFloat(uValue);
        } //-- end: getConstrU -------------------------------------------------------------------

        function getConstrGgl(entdataConstr) {
            const lib = ntaEntityData.getFirstParent(entdataConstr, 'LIBCONSTRT');
            const gglValue = lib && lib.PropertyDatas['LIBCONSTRT_G'].Value;
            return ntaSharedLogic.parseFloat(gglValue);
        } //-- end: getConstrGgl -------------------------------------------------------------------

        function getConstrArea(entdataConstr) {
            if (!entdataConstr) return 0;

            const unit = ntaEntityData.findEntities(entdataConstr, '^BEGR^UNIT-RZ^UNIT')[0];
            let aantalUnits = 1;
            if (unit) {
                const propAantA = ntaData.properties['UNIT_AANTA'];
                const propAantU = ntaData.properties['UNIT_AANTU'];
                const propdataAantA = propAantA.getData(unit);
                const propdataAantU = propAantU.getData(unit);
                aantalUnits = ntaSharedLogic.parseFloat(propdataAantA.Relevant ? propdataAantA.Value : propdataAantU.Relevant ? propdataAantU.Value : "1");
            }

            if (isOppPerKozijn()) {
                const oppPerKozijn = getLibOpp(entdataConstr);
                const propdataAantal = entdataConstr.PropertyDatas['CONSTRT_AANT'];
                const aantal = ntaSharedLogic.parseFloat(propdataAantal.Value);
                return oppPerKozijn * aantal * aantalUnits;
            } else {
                const propdataArea = entdataConstr.PropertyDatas['CONSTRT_OPP'];
                const area = ntaSharedLogic.parseFloat(propdataArea.Value);
                return area * aantalUnits;
            }
        } //-- end: getConstrArea -----------------------------------------------------------------

        function hasCodedValues(prop) {
            return ntaValidation.hasCodedValues(prop);
        } //-- end: hasCodedValues ----------------------------------------------------------------

        function getCodedValues(prop) {
            let codedValues = [];
            switch (prop.Id) {
                case 'MEASURE-U_METHODE': { // U03
                    const codedValuesToReplace = ntaValidation.codedValues(prop);
                    /// de tekst {{ctrl.measureType}} vervangen door measureType
                    codedValues = codedValuesToReplace.map(cv => ({
                        Id: cv.Id,
                        Value: cv.Value.replace("{{ctrl.measureType}}", _measureType.Value),
                    }));
                    break;
                }
                case 'MEASURE-U_LIBCONSTR_ID': { // U05
                    const libConstrs = getLibConstrs();
                    codedValues = libConstrs.map(libConstrT => ({
                        Id: libConstrT.EntityDataId,
                        Value: libConstrT.PropertyDatas['LIBCONSTRT_OMSCHR'].Value + ` (U = ${libConstrT.PropertyDatas['LIBCONSTRT_U'].Value} / g<sub>ggl</sub> ${libConstrT.PropertyDatas['LIBCONSTRT_G'].Value})`,
                    }));
                    break;
                }
                case 'MEASURE-U_BEGR_IDS': { // U08
                    codedValues = getBegrenzingen();
                    break;
                }
                case 'MEASURE-U_VERVANG_ITEM': { // U07
                    codedValues = ntaValidation.codedValues(prop);
                    if (conditionM()) {
                        /// alleen keuze voor U-waarde tonen [U-M]
                        codedValues = codedValues.filter(cv => cv.Id === 'MEASURE-U_VERVANG_U');
                    }
                    break;
                }
                default: {
                    codedValues = ntaValidation.codedValues(prop);
                    break;
                }
            }

            // Zorg dat we alleen een nieuwe lijst teruggeven als deze gewijzigde waardes heeft,
            //  anders denkt AngularJS steeds dat het om een compleet nieuwe lijst gaat, en triggert
            //  deze oneindige digests.
            return _listCache.useCacheIfUnchanged(prop.Id, codedValues, (a, b) => a.Id === b.Id && a.Value === b.Value);
        } //-- end: getCodedValues ----------------------------------------------------------------

        function getLibConstrs() {
            const libConstrs = ntaEntityData.getListWithEntityId('LIBCONSTRT')
                .filter(libConstrT => libConstrT.PropertyDatas['LIBCONSTRT_TYPE'].Value === _libConstrType)
                .filter(libConstrT => libConstrT.Relevant);
            return libConstrs;
        } //-- end: getLibConstrs -----------------------------------------------------------------

        function getBegrenzingen() {
            /// toon onder elkaar alle unieke begrenzingen waarin een transparante constructie aanwezig is met
            /// type = raam als U01 = vervangen UW raam; met
            /// type = deur als U01 = vervangen UD deur; met
            /// type paneel als U01 = vervangen UD paneel.
            /// op zoek naar alle constructie die een libconstructie van dit type hebben.
            const constrTIds = ntaEntityData.getListWithEntityId('CONSTRT')
                .filter(c => c.Relevant)
                .filter(c => {
                    const libconstr = ntaEntityData.getFirstParent(c, 'LIBCONSTRT');
                    return libconstr && libconstr.PropertyDatas['LIBCONSTRT_TYPE'].Value === _libConstrType;
                })
                .map(c => c.EntityDataId);
            /// nu de begrenzingen ophalen van constructie en ontdubbelen.
            const begrenzingen = [...new Set(ntaEntityData.getListWithEntityId('BEGR')
                .filter(begr => {
                    /// controleer of deze begrenzing een constrT constructie heeft die in constrTIds zit
                    const constrTIdsBegr = ntaEntityData.getChildIds(begr, 'CONSTRT');
                    return constrTIds.some(c => constrTIdsBegr.includes(c));
                })
                .filter(begr => ntaEntityData.getParents(begr, 'RZ').length === 0)
            )];

            const begrenzingOrientaties = ntaMeasureData.getBegrenzingByUniqueBegrenzing(begrenzingen, begrenzingLogic);
            if (_listCache.isChanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual)) {
                //controleer de relaties tussen measure en begrenzingen
                const propBegrIds = ntaData.properties['MEASURE-U_BEGR_IDS'];
                ntaMeasureData.checkBegrRelations(propBegrIds, _entdataMeasureU, begrenzingOrientaties);
            }

            return _listCache.useCacheIfUnchanged('begrenzingOrientaties', begrenzingOrientaties, _listCache.areObjectsEqual);
        } //-- end: getBegrenzingen -------------------------------------------------------------------

        async function toggleItemChecked(prop, entdata, item) {
            ntaMeasureData.toggleItemChecked(prop, entdata, item);
            validate(prop, prop.getData(entdata));

            //controleer of maatregel in variant zit en of deze keuze niet een conflict oplevert met andere maatregelen in deze variant
            // Zo ja, dan check terugdraaien
            const variantNames = conditionO_P(entdata);
            if (variantNames.length > 0) {
                await ntaMeldingen.warning('[W118]', undefined, [{ from: '{naam varianten}', to: variantNames.join(' / ') }]);
                ntaMeasureData.toggleItemChecked(prop, entdata, item);
                validate(prop, prop.getData(entdata));
                return;
            }

            const totalArea = calculateTotalArea();
            saveValue('MEASURE-U_A_TOT', entdata, totalArea);
        } //-- end: toggleItemChecked -------------------------------------------------------------------

        function saveValue(propOrId, entdata, newValue) {
            const prop = typeof propOrId === 'string' ? ntaData.properties[propOrId] : propOrId;

            if (!prop) {
                return false;
            }

            let result = ntaSharedLogic.saveValue(prop, entdata, newValue, self);

            const value = prop.getValue(entdata);
            switch (prop && prop.Id) {
                case 'MEASURE-U_LIBCONSTR_ID': {
                    const libEntdata = ntaEntityData.get(value);
                    const libEntdataId = libEntdata && libEntdata.EntityDataId;

                    const existingRelations = ntaEntityData.getParentRelations(entdata, 'LIBCONSTRT');
                    const validRelation = existingRelations.find(rel => rel.Parent === libEntdataId);
                    const relationsToDelete = existingRelations.filter(rel => rel !== validRelation);
                    for (const relation of relationsToDelete) {
                        ntaEntityData.deleteRelation(relation.EntityRelationDataId);
                        result = true;
                    }
                    if (!validRelation && libEntdataId) {
                        ntaEntityData.createRelation(libEntdataId, entdata.EntityDataId, false, false);
                        result = true;
                    }
                    break;
                }
                case 'MEASURE-U_METHODE': {
                    // na opslag methode deze check uitvoeren of de maatregel in een variant zit en of deze keuze niet een conflict oplevert met andere maatregelen in deze variant
                    // dit kan niet in de dependency-validate omdat deze vaak van 'buiten' wordt aangeroepen voor het bepalen van de oppervlakte
                    const variantNames = conditionO_P(entdata, value);
                    if (variantNames.length > 0) {
                        if (value === 'MEASURE-U_METHODE_LIB') {
                            const libProp = ntaData.properties['MEASURE-U_LIBCONSTR_ID'];
                            result = saveValue(libProp, entdata, null) || result; // leeg maken
                        } else { //'MEASURE-U_METHODE_U'
                            result = saveValue('MEASURE-U_VERVANG_ITEM', entdata, null) || result; // leeg maken
                        }
                    }
                    break;
                }
            }

            return result;
        } //-- end: saveValue ---------------------------------------------------------------------

        async function onValidateCallback(prop, entdata, valueToSave) {
            switch (prop && prop.Id || prop) {
                case 'MEASURE-U_LIBCONSTR_ID':
                case 'MEASURE-U_VERVANG_ITEM': {
                    const oldValue = prop.getValue(entdata); // oude waarde bewaren
                    saveValue(prop, entdata, valueToSave); // nieuwe alvast opslaan voor getMatchingConstrEntdatas
                    const variantNames = conditionO_P(entdata, valueToSave);
                    if (variantNames.length > 0) {
                        await ntaMeldingen.warning('[W117]', undefined, [{ from: '{naam varianten}', to: variantNames.join(' / ') }]);
                        saveValue(prop, entdata, oldValue); // oude waarde terugzetten
                        return;
                    }
                    break;
                }
            }
        } //-- end: onValidateCallback ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = isReadOnly(prop) && prop.Id !== 'MEASURE-U_A_TOT'; // Validatie moet alsnog wél gebeuren voor de totale oppervlakte, ondanks dat deze read-only is

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                case 'MEASURE-U_A_TOT': {
                    /// melding heeft alleen zin als Value niet leeg, anders is er nog geen ingevoerde waarde en is
                    ///[U-G] toon [E113] indien U12 = 0
                    let isValid = true;
                    if (propdata.Value) {
                        isValid = ntaSharedLogic.parseFloat(propdata.Value) > 0;
                    }

                    ntaSharedLogic.setMelding('[E113]', propdata, self.form, isValid, propdata.Touched);

                    valid = valid && isValid;
                    break;
                }
                case 'MEASURE-U_KOSTEN_TOT_MIN_SUBSIDIE': {
                    calculateCosts();
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = true;

            switch (prop.Id) {
                case 'MEASURE-U_METHODE':      // U03
                case 'MEASURE-U_LIBCONSTR_ID': // U05
                case 'MEASURE-U_BEGR_IDS':     // U08
                case 'MEASURE-U_U_GT':         // U09
                case 'MEASURE-U_GGL_GT':       // U10
                case 'MEASURE-U_A_GT': {       // U11
                    // Als één van deze velden gewijzigd is, dan moet de totale oppervlakte opnieuw berekend worden
                    const totalArea = calculateTotalArea();
                    saveValue('MEASURE-U_A_TOT', entdata, totalArea);
                    break;
                }
                case 'MEASURE-U_A_TOT':         // U09
                case 'MEASURE-U_KOSTEN_TOT':    // U15
                case 'MEASURE-U_KOSTEN_INV':    // U13
                case 'MEASURE-U_KOSTEN_PER_M2': // U14
                case 'MEASURE-U_SUBS_INV':      // U22
                case 'MEASURE-U_SUBS_PER_M2':   // U23
                case 'MEASURE-U_SUBS_TOT': {    // U25
                    // Als één van deze velden gewijzigd is, dan moeten de totale kosten en subsidie opnieuw berekend worden
                    calculateCosts();
                    break;
                }
            }

            if (performDefaultChecks) {
                isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function calculateCosts() {
            let changed = false;

            const totCostProp = ntaData.properties["MEASURE-U_KOSTEN_TOT"];
            const totSubsProp = ntaData.properties["MEASURE-U_SUBS_TOT"];
            let totalCost = ntaSharedLogic.parseFloat(totCostProp?.getValue(_entdataMeasureU), 0);
            let totalSubs = ntaSharedLogic.parseFloat(totSubsProp?.getValue(_entdataMeasureU), 0);
            // Als één van deze velden gewijzigd is, dan moeten de totale kosten opnieuw berekend worden
            if (conditionD()) { // [U-D] U17 = kostenkentallen per m²
                // indien U17 = kostenkentallen per m²: U18 x U12
                const area = ntaSharedLogic.parseFloat(_entdataMeasureU.PropertyDatas['MEASURE-U_A_TOT'].Value); // U12
                const costPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureU.PropertyDatas['MEASURE-U_KOSTEN_PER_M2'].Value); // U18
                totalCost = area * costPerM2;
                changed = saveValue('MEASURE-U_KOSTEN_TOT', _entdataMeasureU, totalCost) || changed;
            }
            if (conditionQ()) { // [U-Q] U22 = subsidie per m²
                // indien U23 = subsidie per m²: U23 x U12
                const area = ntaSharedLogic.parseFloat(_entdataMeasureU.PropertyDatas['MEASURE-U_A_TOT']?.Value); // U12
                const subsPerM2 = ntaSharedLogic.parseFloat(_entdataMeasureU.PropertyDatas['MEASURE-U_SUBS_PER_M2']?.Value); // U23
                totalSubs = area * subsPerM2;
                changed = saveValue('MEASURE-U_SUBS_TOT', _entdataMeasureU, totalSubs) || changed;
            }
            /// en bereken de totale kosten door de subsidie van de investeringskosten af te trekken
            changed = saveValue('MEASURE-U_KOSTEN_TOT_MIN_SUBSIDIE', _entdataMeasureU, totalCost - totalSubs) || changed;

            /// en bereken tot slot de nieuwe variantkosten voor de varianten die gebruik maken van deze measure
            if (changed) {
                const variants = ntaEntityData.findEntities(_entdataMeasure, "VARIANT-MEASURE.^VARIANT", "VARIANT");
                self.dependencyValidator.calculateCostsVariants(variants);
            }

            return changed;
        } //-- end: calculateCosts ----------------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasureU];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityData.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
