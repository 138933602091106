﻿angular.module('indexModule')
    .controller("IndexRapportageController",
        ['$mdDialog', '$scope', 'indextree', 'progressCircle',
function ($mdDialog, $scope, indextree, progressCircle) {
    const vm = this;
    let selectedBerekeningen = $scope.selectedBerekeningen;
    vm.isCalcNeeded = indextree.isCalcNeeded;
    $scope.pdfOptions = {
        includeVerklaringenSelectedProducts: true,
        includeOpmerkingen: true,
        includeBouwkundigeBibliotheek: true,
        onlyEnergieprestatie: false,
        onlyOverzicht: false,
        reportOfBuilding: true,
        reportPerUnit: false
    };
    let pdfOptions = $scope.pdfOptions;
    $scope.showReportOptions = false;
    $scope.rapportPerTypeName = "";

    vm.templates = [];
    vm.selectedTemplate = null;
    vm.selectedFormat = 'Word';

    function init() {
        const shouldShow = shouldShowReportOptions();
        $scope.showReportOptions = shouldShow;
        if (shouldShow) {
            $scope.rapportPerTypeName = getRapportPerTypeName();
        }
    }

    vm.selectTemplate = function (template) {
        if (vm.selectedTemplate === template) {
            vm.selectedTemplate = null;
        } else {
            vm.selectedTemplate = template;
        }
    };

    vm.isSelectedTemplate = function () {
        return !vm.selectedTemplate;
    }

    vm.selectFormat = function (format) {
        vm.selectedFormat = format;
    };


    vm.createMwaRapportage = async function () {
        vm.closeDialog();
        if (!selectedBerekeningen || selectedBerekeningen.length == 0) {
            return;
        }
        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) {
            indextree.warning('[W050]'); // de berekening heeft geen resultaten
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createMwaRapport(0, selectedBerekeningen.map(bld => bld.BuildingId), vm.selectedTemplate);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    }


    vm.createPdfRapportage = async function () {
        vm.closeDialog();
        if (!selectedBerekeningen || selectedBerekeningen.length == 0) {
            return;
        }
        if (selectedBerekeningen.some(bld => vm.isCalcNeeded(bld))) {
            indextree.warning('[W050]'); // de berekening heeft geen resultaten
        } else {
            progressCircle.setShowProgressValue(true, 'rapportage', true);
            try {
                await indextree.createRapportBerekening(selectedBerekeningen.map(bld => bld.BuildingId), $scope.pdfOptions);
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }
    }

    async function loadTemplates(templateToSelect = vm.selectedTemplate) {
        vm.templates = await indextree.getMwaTemplates();
        vm.selectedTemplate = templateToSelect
            && (vm.templates.find(t => t.name === templateToSelect.name && t.location == templateToSelect.location)
             ?? vm.templates.find(t => t.name === templateToSelect.name));
    }
    loadTemplates();

    vm.deleteTemplate = async function (template) {
        const confirm = $mdDialog.confirm({
            title: 'Verwijderen template maatwerkrapportage',
            textContent: `U staat op het punt om template ‘${template.name}’ te verwijderen.\n\nWeet u het zeker?`,
            ariaLabel: 'Verwijderen',
            ok: 'Ja',
            cancel: 'Nee',
            multiple: true
        });

        try {
            await $mdDialog.show(confirm);
        } catch (err) {
            return; // cancel
        }

        await indextree.deleteTemplate(template);
        vm.templates = vm.templates.filter(t => t !== template);
    };

    vm.downloadTemplate = indextree.downloadTemplate;

    // Define allowed image MIME types
    const allowedTemplateFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    vm.canEditPublicTemplates = indextree.isdev;

    vm.handleFileSelect = async function (files, location = 'licence') {
        const file = files && files[0];
        if (!file)
            return;

        // Check MIME type
        if (!allowedTemplateFileTypes.includes(file.type)) {
            indextree.warning("[W147]", '', { multiple: true });
            return;
        }

        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async function (e) {
                const byteArray = new Uint8Array(e.target.result);
                const blob = new Blob([byteArray], { type: file.type });

                var formData = new FormData();
                formData.append('file', blob);
                formData.append('templateName', file.name);
                formData.append('location', location);

                await indextree.uploadTemplate(formData);

                await loadTemplates({ name: file.name, location });

                resolve(vm.selectedTemplate);
            };
            reader.onerror = function (error) {
                $log.error(error)
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    vm.clickDownloadOverzicht = function () {
        clearInclusionOptions();
        if (pdfOptions.onlyOverzicht) {
            pdfOptions.onlyEnergieprestatie = false;
        }
    }

    vm.clickDownloadEnergieprestatie = function () {
        clearInclusionOptions();
        if (pdfOptions.onlyEnergieprestatie) {
            pdfOptions.onlyOverzicht = false;
        }
    }

    vm.clickIncludeOption = function () {
        pdfOptions.onlyEnergieprestatie = false;
        pdfOptions.onlyOverzicht = false;
    }

    vm.clickReportOfProject = function () {
        if (pdfOptions.reportOfBuilding) {
            pdfOptions.reportPerUnit = false;
        }
    }

    vm.clickReportPerUnit = function () {
        if (pdfOptions.reportPerUnit) {
            pdfOptions.reportOfBuilding = false;
        }
    }

    vm.showReportOptions = function () {
        return reportForUnitShouldBeHidden();
        //return this.showReportOptions;
    }

    vm.closeDialog = function () {
        $mdDialog.cancel();
    };

    function shouldShowReportOptions() {
        return reportForUnitShouldBeHidden();
        //return selectedBerekeningen.some(x => isApartmentUnit(x) || isUtilityUnit(x));
    }

    function reportForUnitShouldBeHidden() {
        return false;//I.v.m. bugs in de rapportage per unit is het voor release 3.3.2.2. nog niet gewenst om deze optie in de client te tonen.
    }

    function getRapportPerTypeName() {
        const typeNames = selectedBerekeningen          // alle geselecteerde berekeningen
            .map(building => getBuildingUnitType(building)) // ...daarvan het type gebouweenheid
            .filter(typeName => typeName);              // ...dat niet leeg is
        return [...new Set(typeNames)] // distinct         ...zonder duplicaten
            .sort()                                     //  ...alfabetisch gesorteerd
            .join(' / ');                               // ...samengevoegd met ' / ' ertussen
    }

    function getBuildingUnitType(building) {
        if (building.Results.RZFORM_CALCUNIT == "RZUNIT_PROJECT") {
            return "projectwoning";
        }

        if (isApartmentUnit(building)) {
            return "appartement"
        }

        if (isUtilityUnit(building)) {
            return "unit"
        }
    }

    function isUtilityUnit(building) {
        return (building.Results.GEB_TYPEGEB == "TGEB_UTILIT" && building.Results.RZFORM_CALCUNIT == "RZUNIT_GEBUNIT");
    }

    function isApartmentUnit(building) {
        return (building.Results.GEB_TYPEGEB == "TGEB_APPGEB" && building.Results.RZFORM_CALCUNIT == "RZUNIT_GEBAPP")
    }

    function clearInclusionOptions() {
        pdfOptions.includeVerklaringenSelectedProducts = false;
        pdfOptions.includeOpmerkingen = false;
        pdfOptions.includeBouwkundigeBibliotheek = false;
    }

    init();
}]);