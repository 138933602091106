﻿angular.module('projectModule')
    .factory("VariantsFactory",
        ['ntabuilding', 'ntaData', 'ntaEntityData', 'ntaValidation', '$log', '$mdDialog', 'progressCircle', 'ntaSharedLogic', 'projecttree', 'ntaVariant', 'ntaDeltas', 'HubConnection',
function (ntabuilding,   ntaData,   ntaEntityData,   ntaValidation,   $log,   $mdDialog,   progressCircle,   ntaSharedLogic,   projecttree,   ntaVariant,   ntaDeltas,   HubConnection) {
    'use strict';

    function VariantsLogic(ntaDependencyValidation) {
        const variantsLogic = this;

        variantsLogic.form_variants = null;

        variantsLogic.dependencyValidator = ntaDependencyValidation;

        variantsLogic.properties = ntabuilding.properties['VARIANT'];
        variantsLogic.variants = () => ntaVariant.getList().filter(x => x.Relevant);
        variantsLogic.saveVariants = saveVariants;
        variantsLogic.isVersionGe33 = ntabuilding.ntaVersionId >= 300;

        /////////////////////////////////////////////////////////////////////////////////////////// CRUD akties /////////////////////////////////////////////////////////////////////////////////////////////////////
        variantsLogic.addVariant = function () {
            progressCircle.setShowProgressValue(true, 'aanmaken nieuwe variant');
            try {

                return ntaVariant.create();

            } finally {
                progressCircle.setShowProgressValue(false);
            }
        }; //-- end: addVariant --------------------------------------------------------------------------------//

        variantsLogic.copyClick = function (id) {
            if (!id) {
                return;
            }

            progressCircle.setShowProgressValue(true, 'kopiëren variant');
            const newEntdatas = ntaVariant.copy(id);

            //-- naam van de variant uitbreiden met " - kopie"
            const variant = newEntdatas.find(e => e.EntityId === 'VARIANT');
            const propdataNaam = variant && variant.PropertyDatas["VARIANT_NAAM"];
            if (propdataNaam) {
                const newName = propdataNaam.Value + " - kopie";
                ntaEntityData.saveprop(propdataNaam, newName);
            }
            progressCircle.setShowProgressValue(false);
        };

        variantsLogic.deleteClick = function (id) {
            if (!id) {
                return;
            }
            const entdata = ntaEntityData.get(id);
            const variantName = ntaVariant.getName(entdata);
            const confirm = $mdDialog.confirm()
                .title('Verwijderen variant')
                .htmlContent('U staat op het punt om variant "' + variantName + '" te verwijderen. Weet u het zeker?')
                .ariaLabel('Verwijderen')
                .targetEvent(event)
                .ok('Ja')
                .cancel('Nee');

            $mdDialog.show(confirm).then(function () {
                ntaVariant.deleteVariant(id);
            });
        };

        variantsLogic.saveValue = function (prop, entdata, newValue) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            } else if (prop && prop.PropertyId) {
                prop = ntaData.properties[prop.PropertyId];
            }
            return ntaSharedLogic.saveValue(prop, entdata, newValue, variantsLogic);
        }; //-- end: saveValue --------------------------------------------------------------------

        variantsLogic.isHidden = function (prop, entdata) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            }
            if (!prop || !entdata) return true;

            const propdata = prop.getData(entdata);

            let visible = true;
            let relevant = null; // null => gelijk aan visible

            switch (prop.Id) {
                case 'VARIANT_ERROR':
                    visible = false;
                    break;

                case 'VARIANT_NAAM':
                    visible = true;
                    break;

                case 'VARIANT_KOSTEN_TOT_MIN_SUBSIDIE':
                case 'VARIANT_SUBSIDIE_TOT':
                case 'VARIANT_KOSTEN_TOT':
                    visible = ntaVariant.conditieOVC();
                    break;

                case 'VARIANT_NCW':
                    visible = false;
                    break;

                case 'VARIANT_LEVENSDUUR':
                case 'VARIANT_TVT':
                    visible = ntaVariant.conditieOVC();
                    break;
            }

            if (relevant === null) relevant = visible;

            ntaEntityData.setPropdataStatus(propdata, relevant, visible);

            return !visible;
        }; //-- end: isHidden ---------------------------------------------------------------------

        variantsLogic.isReadOnly = function (prop, entdata) {
            if (typeof prop === 'string') {
                prop = ntaData.properties[prop];
            }
            if (!prop) return true;

            switch (prop.Id) {
                case 'VARIANT_LEVENSDUUR':
                    return false;

                case 'VARIANT_ERROR':
                case 'VARIANT_KOSTEN_TOT_MIN_SUBSIDIE':
                case 'VARIANT_SUBSIDIE_TOT':
                case 'VARIANT_KOSTEN_TOT':
                case 'VARIANT_TVT':
                    return true;
            }
        }; //-- end: isHidden ---------------------------------------------------------------------

        variantsLogic.validate = function (prop, propdata, entdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return false;
            }
            const hidden = variantsLogic.isHidden(prop, entdata);
            const readOnly = variantsLogic.isReadOnly(prop, entdata);
            return ntaValidation.IsValid(variantsLogic.form_variants, prop, propdata, hidden, readOnly);  //Client Validatie
        };

        variantsLogic.validateDependencies = function (prop, entdata) {
            // er zijn geen dependencies om te valideren
        }; //-- end: validateDependencies ---------------------------------------------------------

        variantsLogic.startFormValidation = function () {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), variantsLogic);
        };

        variantsLogic.endFormValidation = function () {
            if (ntaData.current.shadowId !== null) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), variantsLogic);
        };

        function getAllEntDatas() {
            return variantsLogic.variants()
                .filter(ed => ed); // null en undefined wegfilteren
        }

        async function saveVariants(variants = variantsLogic.variants()) {
            const progressText = `variant${variants.length > 1 ? 'en' : ''} opslaan...`;
            progressCircle.setProgressValue(0);
            progressCircle.setShowProgressValue(true, progressText, true);
            try {
                const connection = new HubConnection('/hubs/progress', {
                    progressText,
                });
                await connection.whenConnected();
                const runId = uuid.v4();
                const runPromise = connection.runWithId('RepeatLastEvent', runId);

                // saveVariantsOnServer gaat trigger opleveren waardoor de runPromise resolvet.
                await saveVariantsOnServer(variants, runId);

                const newBuildings = await runPromise;

                // zorgen dat de nieuwe berekening ook in de lijst links opgenomen worden
                projecttree.berekeningen.push(...newBuildings);

                const alert = $mdDialog.alert();
                if (newBuildings.length === 1) {
                    alert.title('Variant opgeslagen');
                    alert.textContent('De variant is met succes opgeslagen als berekening.');
                } else {
                    alert.title('Varianten opgeslagen');
                    alert.textContent(`Er zijn ${newBuildings.length} varianten opgeslagen als berekening.`);
                }
                alert.ok('Sluiten');
                return $mdDialog.show(alert);

            } catch (err) {
                $log.error(err, `during saving of ${variants.length} variants`);
                progressCircle.setShowProgressValue(false);
                const message = typeof err === 'string' ? err + '\n\n' : '';
                return $mdDialog.show($mdDialog.alert({
                    title: "Opslaan is (deels) mislukt",
                    textContent: message + 'Probeer het nog een keer, of neem contact op met de helpdesk.',
                    ok: "Sluiten",
                }));
            } finally {
                progressCircle.setShowProgressValue(false);
            }
        } //-- end: saveVariants ------------------------------------------------------------------

        async function saveVariantsOnServer(variants, runId) {

            // delta’s verzamelen voor alle opgegeven varianten
            const allVariantDeltas = [];

            for (const variant of variants) {
                // Ruim evt. oude/achterhaalde delta’s van de variant op
                ntaEntityData.deleteDeltas(variant);

                // een (schone) schaduwkopie samenstellen voor variant, en daarin de delta’s doorvoeren
                const variantId = variant.EntityDataId;

                await ntaData.switchToShadow(variantId);

                // genereer delta's per maatregel van variant
                const measures = ntaEntityData.findEntities(variant, 'VARIANT-MEASURE.^MEASURE', '^MEASURE');
                for (const measure of measures) {
                    const logic = ntaDependencyValidation.getMeasureLogic(measure);
                    let deltas = [];
                    if (logic && logic.generateDeltas) {
                        deltas = logic.generateDeltas(variantId);
                    } else {
                        const deltasByKey = ntaData.deltas.get(measure.EntityDataId);
                        // Kopieer alle delta’s van deze maatregel, en zet erbij dat ze bij deze variant horen
                        deltas = deltasByKey && [...deltasByKey.values()]
                            .map(delta => Object.assign(Object.create(null), delta, { ShadowId: variantId }))
                            || [];
                    }
                    if (deltas.length) {
                        ntaData.mergeDeltas(variantId, deltas); // TODO: waarmee worden ze samengevoegd?
                    } else {
                        $log.warn('Geen delta’s gegenereerd of gevonden voor', measure, deltas);
                    }
                }

                // deltas ophalen en toepassen. Hou bij welke delta’s overbodig waren (en dus niet opgeslagen hoeven worden)
                const deltas = (ntaData.deltas.get(variantId) || new Map()).values();
                const [_, skippedDeltas] = ntaDeltas.apply(deltas, ntaData.shadow);

                // delta’s verzamelen om later op te slaan
                const deltasToSkip = new Set(skippedDeltas);
                const deltasToSave = (ntaData.deltas.get(variantId) || new Map()).values();
                for (const delta of deltasToSave) {
                    if (!deltasToSkip.has(delta)) {
                        allVariantDeltas.push(delta);
                    }
                }
            }
            await ntaData.switchToShadow(null);

            // naar server sturen
            const response = await fetch('/Buildings/SaveVariants', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    buildingId: ntaData.buildingId,
                    variantIds: variants.map(v => v.EntityDataId),
                    deltas: allVariantDeltas,
                    runId,
                })
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
        } //-- end: saveVariantsOnServer ----------------------------------------------------------

    }

    return VariantsLogic;
}]);