﻿angular.module('projectModule')
    .controller('VariantController',
        ['$scope', '$controller', '$routeParams', 'ntaData', 'ntaValidation', 'VariantFactory', 'BuildingResultsFactory', 'ntaEntityDataOrg', 'ntaDependencyValidation', 'ntaSharedLogic',
function ($scope,   $controller,   $routeParams,   ntaData,   ntaValidation,   VariantFactory,   BuildingResultsFactory,   ntaEntityDataOrg,   ntaDependencyValidation,   ntaSharedLogic) {
    'use strict';
    const ctrl = this;
    const base = $controller('NTABaseController', { $scope: $scope })
    angular.extend(ctrl, base);

    //== Imports ==================================================================================
    ctrl.ntaValidation = ntaValidation;


    //== Instance data ============================================================================
    ctrl.variantId = $routeParams.shadowId;
    const logic = new VariantFactory(ctrl.variantId, ntaDependencyValidation);

    const resultsLogic = new BuildingResultsFactory(ctrl.variantId, ntaSharedLogic.showCosts());
    ctrl.resultsLogic = resultsLogic;

    //== Exports ==================================================================================
    ctrl.logic = logic;

    ctrl.properties = logic.properties;
    ctrl.entdata = logic.entdataVariant;
    ctrl.subsidieEntity = ntaData.entities['VARIANT-SUBS'];
    ctrl.subsidieproperties = ntaData.properties['VARIANT-SUBS'];

    ctrl.isReadOnly = logic.isReadOnly;
    ctrl.getCodedValues = logic.getCodedValues;
    ctrl.getForm = () => logic.form;
    ctrl.getPropertyType = prop => prop && prop.PropertyType;
    ctrl.getAvailableMeasureItems = logic.getAvailableMeasureItems;
    ctrl.showCosts = ntaSharedLogic.showCosts;
    ctrl.getVariantMeasures = logic.getVariantMeasures;
    ctrl.getMeasureName = logic.getMeasureName;
    ctrl.getMeasureSubsidy = logic.getMeasureSubsidy;
    ctrl.getSubsidies = logic.getSubsidies;

    Object.assign(ctrl, {
        getPlaceholder,
        saveValue,
        addVariantMeasure,
        deleteVariantMeasure,
        addSubsidie,
        copySubsidie,
        deleteSubsidie,
    });

    $scope.endFormValidation = logic.endFormValidation;

    $scope.$on('settingsChanged', function (event, args) {
        resultsLogic.setExtraResultParents(ctrl.variantId, ntaSharedLogic.showCosts());
        resultsLogic.setEnergieResultaten(resultsLogic.getActiveBerekeningId());
    });

    //== Initialization ===========================================================================
    ctrl.variantTitle = ctrl.entdata.PropertyDatas['VARIANT_NAAM'].Value;

    ctrl.measureVariantSortList = [];
    ctrl.sortedSubsidies = [];
    updateMeasureVariantSortList();
    updateSortedSubsidies();

    angular.element(function () { //alle touched properties valideren na het laden van form
        logic.startFormValidation();
        logic.setGeopend();
    });

    ctrl.sortableMeasures = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityDataOrg.SaveReOrder(ctrl.measureVariantSortList);
        }
    };

    ctrl.sortableSubsidies = {
        handle: '.moveEnt',
        stop: function (e, ui) {
            ntaEntityDataOrg.SaveReOrder(ctrl.sortedSubsidies);
        }
    };


    //== Implementation ===========================================================================

    function saveValue(prop, entdata) {
        logic.saveValue(prop, entdata);
        base.initializeUnitSpacing();
    } //-- end: saveValue -------------------------------------------------------------------------

    function addVariantMeasure(item) {
        logic.addVariantMeasure(item);
        updateMeasureVariantSortList();
    }

    function deleteVariantMeasure(entdata) {
        logic.deleteVariantMeasure(entdata);
        updateMeasureVariantSortList();
    }

    function addSubsidie() {
        logic.addSubsidie();
        updateSortedSubsidies();
    }

    function copySubsidie(entdata) {
        logic.copySubsidie(entdata);
        updateSortedSubsidies();
    }

    function deleteSubsidie(entdata) {
        logic.deleteSubsidie(entdata);
        updateSortedSubsidies();
    }

    function updateMeasureVariantSortList() {
        ctrl.measureVariantSortList = logic.getVariantMeasures().slice();
    } //-- end: updateVlakkenList -----------------------------------------------------------------

    function updateSortedSubsidies() {
        ctrl.sortedSubsidies = logic.getSubsidies().slice();
    } //-- end: updateSortedSubsidies -------------------------------------------------------------

    function getPlaceholder(prop, entdata) {
        return base.getPlaceholder(prop, entdata, ctrl.logic);
    } //-- end: getPlaceholder ------------------------------------------------------------------------//



}]);
