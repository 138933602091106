﻿angular.module('cmsModule').controller("CmsController", ['$scope', '$controller', '$log', '$q', '$mdDialog', function ($scope, $controller, $log, $q, $mdDialog) {
    'use strict';
    const vm = this;

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("hubs/broadcast")
        .build();

    angular.element(function () { //alle touched properties valideren na het laden van form
        connection.start().then(function () {
            console.log("Connected to BroadcastHub");
        }).catch(function (err) {
            return console.error(err.toString());
        });
    });
    connection.on("ReceiveBroadcast", function (broadcasts) {
        const currentBroadcastIds = [];
        const broadcastQueue = [];
        if (Array.isArray(broadcasts) && broadcasts.length > 0) {
            broadcasts.forEach(function (broadcast) {

                const broadcastId = broadcast.Id;
                currentBroadcastIds.push(broadcastId);

                if (localStorage.getItem(`broadcast_${broadcastId}`) && broadcast.RepeatInterval == 0) {
                    console.log('Broadcast al weergegeven');
                }
                else {
                    broadcastQueue.push(broadcast);
                    localStorage.setItem(`broadcast_${broadcastId}`, broadcastId);
                }
            });
            showNextBroadcast(broadcastQueue)
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith("broadcast_")) {
                    const storedBroadcastId = localStorage.getItem(key);
                    if (!currentBroadcastIds.includes(storedBroadcastId)) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }
    });

    function showNextBroadcast(queue) {
        if (queue.length > 0) {
            const broadcast = queue.shift();

            Swal.fire({
                title: 'Mededeling',
                showConfirmButton: false,
                showCloseButton: true,
                html: broadcast.Message,
                icon: null,
                toast: true,
                position: 'top',
                background: 'rgb(255, 171, 64)',
                timer: 15000, // 15 seconden
                customClass: {
                    popup: 'custom-swal-toast',
                    closeButton: 'custom-swal-close'
                }
            }).then(() => {
                showNextBroadcast(queue);
            });
        }
    }
}]);