﻿angular.module('projectModule')
    .service('ntaFindAndReplace',
        ['$log', '$mdDialog', 'ntabuilding', 'ntaEntityData', 'ntaRounding',
function ($log,   $mdDialog,   ntabuilding,   ntaEntityData,   ntaRounding) {
    'use strict';
    const self = this;

    /// ntaFindAndReplace verzorgt het zoeken en vervangen van de inhoud van propdata's. In eerste instantie voor bouwkundige bibliotheek items U en ggl-waarden

    /// -- Instance variables ---------------------------------------------------------------------
    const _replaceMethod = {
        ALLE: 'vervang alle ',
        ZOEKEN: 'zoek en vervang ',
    }

    const _libConstrT_U = 'LIBCONSTRT_U';
    const _libConstrT_G = 'LIBCONSTRT_G';
    const _libConstrD_Rc = 'LIBCONSTRD_RC';

    const _replaceProperties = [_libConstrT_U, _libConstrT_G, _libConstrD_Rc]
        .map(propId => ntabuilding.properties[propId]);

    const _errorMeldingen = {
        leeg: 'Invoerveld verplicht',
        geengetal: 'Waarde is geen getal',
        invalidvalue:'Geen geldige waarde',
        waardegroter:'Waarde moet groter zijn dan ${Min}',
        waardegrotergelijk:'Waarde moet groter zijn dan of gelijk zijn aan ${Min}',
        waardekleiner: 'Waarde moet kleiner zijn dan ${Max}',
        waardekleinergelijk: 'Waarde moet kleiner zijn dan of gelijk zijn aan ${Max}',
        waardegrotermaximaal: 'Waarde moet groter dan ${Min} en maximaal ${Max} zijn',
        waardeminimaalenmaximaal: 'Waarde moet minimaal ${Min} en maximaal ${Max} zijn',
    }

    /// -- Exports --------------------------------------------------------------------------------
    self.showFindAndReplaceDialog = showFindAndReplaceDialog;


    /// -- Initialization -------------------------------------------------------------------------

    ///-- maak een dictionary van de mogelijke replaceproperties
    for (const rp of _replaceProperties) {
        _replaceProperties[rp.Id] = rp;
    }

    ///-- maak een lijst met alle mogelijke opties die op het formulier getoond kunnen worden. Afhankelijk
    ///-- van de aanroep van deze service(met welke propIds) wordt deze
    ///-- optielijst gefilterd voor het formulier
    const _replaceOptions = [
        { 'id': 'A', 'method': _replaceMethod.ALLE, 'properties': [_replaceProperties[_libConstrT_U]], 'name': 'U-waarden', 'entityId': _replaceProperties[_libConstrT_U].EntityId },
        { 'id': 'B', 'method': _replaceMethod.ALLE, 'properties': [_replaceProperties[_libConstrD_Rc]], 'name': 'R<sub>c</sub>-waarden', 'entityId': _replaceProperties[_libConstrD_Rc].EntityId },
        { 'id': 'C', 'method': _replaceMethod.ALLE, 'properties': [_replaceProperties[_libConstrT_G]], 'name': 'g<sub>gl</sub>-waarden', 'entityId': _replaceProperties[_libConstrT_G].EntityId },
        { 'id': 'D', 'method': _replaceMethod.ALLE, 'properties': [_replaceProperties[_libConstrT_U], _replaceProperties[_libConstrT_G]], 'name': 'U- en g<sub>gl</sub>-waarden', 'entityId': _replaceProperties[_libConstrT_U].EntityId },
        { 'id': 'E', 'method': _replaceMethod.ZOEKEN, 'properties': [_replaceProperties[_libConstrT_U]], 'name': 'U-waarden', 'entityId': _replaceProperties[_libConstrT_U].EntityId },
        { 'id': 'F', 'method': _replaceMethod.ZOEKEN, 'properties': [_replaceProperties[_libConstrD_Rc]], 'name': 'R<sub>c</sub>-waarden', 'entityId': _replaceProperties[_libConstrD_Rc].EntityId },
        { 'id': 'G', 'method': _replaceMethod.ZOEKEN, 'properties': [_replaceProperties[_libConstrT_G]], 'name': 'g<sub>gl</sub>-waarden', 'entityId': _replaceProperties[_libConstrT_G].EntityId },
        { 'id': 'H', 'method': _replaceMethod.ZOEKEN, 'properties': [_replaceProperties[_libConstrT_U], _replaceProperties[_libConstrT_G]], 'name': 'U- en g<sub>gl</sub>-waarden', 'entityId': _replaceProperties[_libConstrT_U].EntityId },
    ]


    /// -- Implementation -------------------------------------------------------------------------

    async function showFindAndReplaceDialog(propertyIds = []) {
        try {
            return await $mdDialog.show({
                locals: {
                    propertyIds,
                },
                controller: DialogFindReplaceController,
                controllerAs: 'ctrl',
                templateUrl: '/src/app/templates/dialog_zoek_vervang.html?v=' + ntabuilding.commit,
                disableParentScroll: false,
                clickOutsideToClose: true,
            });
        } catch (err) {
            if (err) $log.warn(err);
        }
    }

    function DialogFindReplaceController($scope, $mdDialog, propertyIds) {
        const ctrl = this;

        // De keuzes voor het formulier
        //-- alle option properties moeten voorkomen in de propertyIds ander mag de optie niet mee
        $scope.replaceOptions = _replaceOptions.filter(option => option.properties.every(prop => propertyIds.includes(prop.Id)));

        //-- ik moet een ladekast maken met properties voor zoekwaarden en properties voor vervang waarden
        const _values = _replaceProperties
            .filter(prop => propertyIds.includes(prop.Id))
            .map(prop => ({
                property: prop,
                zoeken: { 'waarde': '', 'error': '', 'touched': false, 'relevant': true },
                vervangen: { 'waarde': '', 'error': '', 'touched': false, 'relevant': true }
            }));
        $scope.properties = _values;

        $scope.execute = function () {
            if (ctrl.action) {
                $mdDialog.hide();
                //-- en nu gaat het gebeuren.
                const selectedOption = _replaceOptions.find(option => option.id === ctrl.action);
                const buildingId = ntabuilding.buildingId;

                //-- haal alle entitydatas op waarin de properties zitten die evt gezocht en daarna vervangen moeten worden.
                let entityDatas = ntaEntityData.getListWithEntityId(selectedOption.entityId, buildingId, true)
                switch (selectedOption.method) {
                    case _replaceMethod.ZOEKEN: {
                        //-- bij zoeken moeten alle properties uit de selectedOption voldoen aan de zoekwaarde die voor die invoer is meegegeven. Alleen de entititeit die
                        //-- daaraan voldoet mag mee in de lijst met entityDatas
                        const propertyIds = selectedOption.properties.map(p => p.Id);
                        entityDatas = entityDatas.filter(ed => {
                            //-- de propids uit de selected options moeten voldoen aan hun zoekwaarde uit de betreffende value
                            const checkpropdatas = ed.PropertyDatas.filter(pd => propertyIds.includes(pd.PropertyId));
                            return checkpropdatas.every(pd => pd.Value === _values.find(v => v.property.Id === pd.PropertyId).zoeken.waarde);
                        });
                        break;
                    }
                }
                //-- nu iedere property uit de selectedOption apart als array met één prop toegevoegd worden aan de change array
                for (const prop of selectedOption.properties) {
                    const value = _values.find(v => v.property.Id === prop.Id);
                    const propdatas = entityDatas
                        .map(x => x.PropertyDatas[prop.Id])
                        .filter(x => x.Relevant)
                        //-- ook kan er per propdata een conditie gelden of hij vervangen mag worden of niet. Bijvoorbeeld: alleen waarden vervangen
                        //-- voor U en ggl als er sprake is van vrije invoer.
                        .filter(x => isVervangenToegestaan(x, prop.Id));

                    //-- nu voor alle propdatas de nieuwe waarde in één keer vervangen
                    ntaEntityData.saveprops(propdatas, value.vervangen.waarde);
                }
            }
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        ctrl.getOptionName = getOptionName;
        ctrl.getPropertyName = getPropertyName;
        ctrl.actionChanged = actionChanged;
        ctrl.isAktieZoekenNaar = isAktieZoekenNaar;
        ctrl.getErrorInput = getErrorInput;
        ctrl.validateWaarde = validateWaarde;
        ctrl.formValid = formValid;

        //-- default is het de laatste optie in het lijstje
        ctrl.action = $scope.replaceOptions[$scope.replaceOptions.length - 1].id;
        actionChanged();

        function getOptionName(option) {
            return option.method + option.name;
        }

        function getErrorInput(prop, zoeken, checkTouched = true) {
            const touched = prop && (zoeken ? prop.zoeken.touched : prop.vervangen.touched);
            if (checkTouched && !touched)
                return;

            const methodName = zoeken ? "ntainput_zoeken_" : "ntainput_vervangen_";
            const form = $scope.frm_findandreplace;

            return form[methodName + prop.property.Id];
        }

        function validateWaarde(prop, zoeken) {
            const inputControl = getErrorInput(prop, zoeken, false);

            if (!inputControl) {
                return;
            }

            const invoer = zoeken ? prop.zoeken : prop.vervangen;
            invoer.error = '';

            const melding = validate(invoer, prop.property);

            const inValid = !!melding;
            if (inValid) {
                //-- nieuwe errorMelding zetten
                invoer.error = melding;
            }
            inputControl.$setValidity("error", !inValid);
        }

        function formValid() {
            //-- verzamel alle relevante velden
            let checkValues = _values.filter(v => v.zoeken.relevant).map(v => v.zoeken);
            checkValues = checkValues.concat(_values.filter(v => v.vervangen.relevant).map(x => x.vervangen));

            //-- deze moeten aangeraakt zijn en een lege error string hebben. Anders valid false
            let valid = checkValues.every(x => x.touched) && checkValues.every(x => x.error === '');

            return valid;
        }

        function getPropertyName(property) {
            let str = property.Name || "";
            if (str) {
                const unit = property.Unit || "";
                if (unit) {
                    str += " [" + unit + "]";
                }
            }
            return str;
        }

        function isVervangenToegestaan(propdata, propertyId) {
            if (!propdata) {
                return false;
            }

            let toegestaan = true;
            switch (propertyId) {
                case _libConstrT_U:
                case _libConstrT_G:
                    {
                        //alleen vervangen mogelijk als er sprake is van vrije invoer
                        toegestaan = ntaEntityData.get(propdata.EntityDataId).PropertyDatas["LIBCONSTRT_METH"].Value === "TRANS_VRIJE_INV";
                        break;
                    }
                case _libConstrD_Rc:
                    {
                        //alleen vervangen mogelijk als er sprake is van vrije invoer
                        toegestaan = ntaEntityData.get(propdata.EntityDataId).PropertyDatas["LIBCONSTRD_METH"].Value === "VRIJE_INV";
                        break;
                    }
                default:
            }
            return toegestaan;
        }

        function isAktieZoekenNaar() {
            const selectedOption = _replaceOptions.find(option => option.id === ctrl.action);
            return selectedOption && (selectedOption.method === _replaceMethod.ZOEKEN);
        }

        function actionChanged() {
            const selectedOption = _replaceOptions.find(option => option.id === ctrl.action);

            if (!selectedOption) {
                return;
            }

            //-- alle properties in de scope afgaan. als ze voorkomen in de selectedOption dan zijn ze relevant
            for (const v of _values) {
               v.vervangen.relevant = selectedOption.properties.some(p => p.Id === v.property.Id);
            }

            //-- als de methode ZOEKEN is moet ook de zoek relevantie gezet worden anders altijd false
            for (const v of _values) {
                v.zoeken.relevant = selectedOption.method === _replaceMethod.ZOEKEN ? selectedOption.properties.some(p => p.Id === v.property.Id) : false;
            }
        }
    }

    function validate(invoer, property) {
        let result = null; //invalid key. bij leeg result is de waarde valid

        //-- na valideren is de waarde altijd aangeraakt.
        invoer.touched = true;

        let value = invoer.waarde;
        if (value) {
            const prop = property;
            let flvalue = parseFloat(value.replace(/,/, '.'));
            if (isNaN(flvalue)) { // check voor NaN
                result = _errorMeldingen.geengetal;
            } else {
                flvalue = ntaRounding.ntaround(flvalue, prop.Rounding);
                value = flvalue.toString().replace('.', ",");

                if (prop.Rounding === 'R020') {
                    if (value.length === 1) {
                        value = value + ',' + '0';
                    }
                    else if (value[0] === '0' && value.includes(',')) {
                        value = value + '0';
                    }
                }
                else {
                    //0-en aanvullen obc decimals
                    const len = value.length;
                    let indx = value.indexOf(",");
                    indx = indx === -1 ? len : indx + 1; //geen komma -> index = lengte, wel komma -> index + 1 omdat de komma niet moet worden meegeteld...
                    const decimals = ntaRounding.getDecimals(flvalue, prop.Rounding);
                    const numzero = decimals - (len - indx);
                    if (len === indx && numzero > 0) {
                        value = value + ",";
                    }
                    for (let i = 0; i < numzero; i++) {
                        value = value + "0";
                    }
                }
                //-- nu de juiste geformateerde waarde zetten.
                invoer.waarde = value;

                let Min = '';
                let Max = '';
                let valid = true;
                if (prop.Domain !== null) { //valideren
                    if (prop.Domain.DomainType === 1) { //range -> voorlopig geen ondersteuning voor meerdere ranges

                        Min = Number.MAX_VALUE * -1;
                        Max = Number.MAX_VALUE;
                        if (prop.Domain.Ranges[0].Min !== null) { // null is min value
                            Min = prop.Domain.Ranges[0].Min;
                        }
                        if (prop.Domain.Ranges[0].Max !== null) { // null is max value
                            Max = prop.Domain.Ranges[0].Max;
                        }

                        if (prop.Domain.Ranges[0].MinExcl) { // range exclusief min -> bv 0 - 1000, false indien waarde 0 of klenier is
                            if (flvalue <= Min) {
                                valid = false;
                            }
                        } else { // range inclusief min -> bv 0 - 1000, false indien waarde klenier is dan 0
                            if (flvalue < Min) {
                                valid = false;
                            }
                        }
                        if (prop.Domain.Ranges[0].MaxExcl) { // range exclusief max -> bv 0 - 1000, false indien waarde 1000 of groter is
                            if (flvalue >= Max) {
                                valid = false;
                            }
                        } else { // range inclusief max -> bv 0 - 1000, false indien waarde groter is dan 1000
                            if (flvalue > Max) {
                                valid = false;
                            }
                        }

                        if (!valid) {
                            if (prop.Domain.Ranges[0].Min === null || prop.Domain.Ranges[0].Max === null) {
                                if (prop.Domain.Ranges[0].Max === null) {
                                    if (prop.Domain.Ranges[0].MinExcl) {
                                        result = _errorMeldingen.waardegroter; // Waarde moet groter zijn dan {{prop.Domain.Ranges[0].Min}}

                                    } else {
                                        result = _errorMeldingen.waardegrotergelijk; // Waarde moet groter zijn dan of gelijk zijn aan {{prop.Domain.Ranges[0].Min}}
                                    }
                                }
                                if (prop.Domain.Ranges[0].Min === null) {
                                    if (prop.Domain.Ranges[0].MaxExcl) {
                                        result = _errorMeldingen.waardekleiner; // Waarde moet kleiner zijn dan {{prop.Domain.Ranges[0].Max}}
                                    } else {
                                        result = _errorMeldingen.waardekleinergelijk; // Waarde moet kleiner zijn dan of gelijk zijn aan {{prop.Domain.Ranges[0].Max}}
                                    }
                                }
                            } else {
                                if (prop.Domain.Ranges[0].MinExcl) {
                                    result = _errorMeldingen.waardegrotermaximaal; // Waarde moet groter dan {{prop.Domain.Ranges[0].Min}} en maximaal {{prop.Domain.Ranges[0].Max}} zijn
                                }
                                else {
                                    result = _errorMeldingen.waardeminimaalenmaximaal; // valt binnen een specifieke range. Kan worden weergegeven in ntaMeldingen.melding
                                }
                            }
                        }
                    } else {
                        result = _errorMeldingen.invalidvalue; // geen geldige waarde
                    }
                }
                if (result) {
                    result = result.replace('${Min}', Min);
                    result = result.replace('${Max}', Max);
                }
            }
        } else {
            result = _errorMeldingen.leeg;
        }

        return result;
    }
}]);
