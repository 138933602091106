﻿/* eslint-disable no-irregular-whitespace */
angular.module('projectModule')
    .factory('MeasurePvFactory',
        ['$log', 'ntaData', 'ntabuilding', 'ntaValidation', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaDeltas', 'ntaRounding', 'InstallationDataFactory',
function ($log,   ntaData,   ntabuilding,   ntaValidation,   ntaEntityDataOrg,   ntaSharedLogic,   ntaDeltas,   ntaRounding,   InstallationDataFactory) {
    'use strict';

    return function MeasurePvLogic(measureId, ntaDependencyValidation) {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const _instData = new InstallationDataFactory(ntaEntityDataOrg);

        const _measureId = measureId;
        const _entdataMeasure = ntaEntityDataOrg.get(_measureId);
        const _entdataMeasurePv = ntaEntityDataOrg.getFirstChild(_entdataMeasure, 'MEASURE-PV');
        const _measureType = ntaData.properties['MEASURE_TYPE'].getCode(_entdataMeasure);
        const _isVersionGe33 = ntabuilding.ntaVersionId >= 300;

        //== Exports ==============================================================================
        self.entdataMeasurePv = _entdataMeasurePv;
        self.properties = ntaData.properties[_entdataMeasurePv.EntityId];
        self.measureType = _measureType;

        // al deze methods exporteren zodat ze publiek beschikbaar zijn
        Object.assign(self, {
            canHaveSubmeasures: true,

            // specifieke methods voor maatregelen
            getTileDetails,
            getAlleKosten,
            getSubmeasures,

            // standaard methods tbv validatie
            saveValue,
            validate,
            validateDependencies,
            startFormValidation,
            endFormValidation,
            setGeopend,
        });

        //== Initialization =======================================================================

        // (geen initialisatie nodig)


        //== Implementation =======================================================================

        function getTileDetails(render) {
            const lines = [];

            if (ntaSharedLogic.showCosts()) {
                //-- VO 2024-11-13: in tegenstelling tot de specificaties is vandaag toch besloten (MSA<-->VO) om de
                //-- kosten per systeem achterwege te laten. Toch commentarieren we het even uit, ipv verwijderen
                //-- omdat het misschien toch weer terug moet.
                //lines.push({ name: 'kosten per systeem:' });

                //const buildingData = getShadowBuildingData(['INSTALLATIE', 'PV', 'PV-VELD', 'MEASURE-COSTS']);
                //const installations = getInstallations(buildingData);

                //let prop = ntaData.properties['MEASURE-PV_TOT_KOSTEN']; //-- willekeurig prop om mee te geven aan roundAndAddZeros ivm juiste rondingsweergave
                //for (const installation of installations) {
                //    const amount = ntaSharedLogic.calculatePVCosts(installation.EntityDataId, buildingData)[2];

                //    const formattedAmount = ntaRounding.roundAndAddZerosNewValue(prop, amount);

                //    const propdataOmschr = installation.PropertyDatas['INSTALL_NAAM'];
                //    const name = propdataOmschr.Value;
                //    lines.push({ name: '  ' + name, value: render.propValue('MEASURE-COSTS_TOTAAL', formattedAmount) });
                //}

                //if (lines.length === 1) {
                //    lines.push({ name: '  (geen kosten opgegeven)' });
                //}

                //lines.push({});
                const results = getAlleKosten();
                lines.push({ name: 'investeringskosten', value: render.propValue('MEASURE-PV_TOT_KOSTEN', results.investering) });
                if (_isVersionGe33) lines.push({ name: 'subsidie', value: render.propValue('MEASURE-PV_TOT_SUBSIDIE', results.subsidie) });
                if (_isVersionGe33) lines.push({ name: 'totale kosten', value: render.propValue('MEASURE-PV_TOT_KOSTEN_MIN_SUBSIDIE', results.totaal) });
            }

            return lines;
        } //-- end: getTileDetails ----------------------------------------------------------------

        function getAlleKosten(buildingData = getShadowBuildingData(['INSTALLATIE', 'PV', 'PV-VELD', 'MEASURE-COSTS']), installations = getInstallations(buildingData)) {
            let totalInvestering = 0.0;
            let totalSubsidie = 0.0;
            let totalKosten = 0.0;
            for (const installation of installations) {
                const [investering, subsidie, totaal] = ntaSharedLogic.calculatePVCosts(installation.EntityDataId, buildingData);
                if (!isNaN(investering)) {
                    totalInvestering += investering;
                }
                if (!isNaN(subsidie)) {
                    totalSubsidie += subsidie;
                }
                if (!isNaN(totaal)) {
                    totalKosten += totaal;
                }
            }

            // dan het totaal opslaan. Dit moet altijd in de basisberekening gebeuren. -> ntaEntityDataOrg
            saveAmount('MEASURE-PV_TOT_KOSTEN', totalInvestering); //-- prop heette voor 3.3 al zo, maar wordt nu gebruikt voor investeringkosten
            saveAmount('MEASURE-PV_TOT_SUBSIDIE', totalSubsidie);
            saveAmount('MEASURE-PV_TOT_KOSTEN_MIN_SUBSIDIE', totalKosten);

            return {
                investering: totalInvestering,
                subsidie: totalSubsidie,
                totaal: totalKosten
            };

            function saveAmount(propId, amount) {
                const prop = ntaData.properties[propId];
                const propdata = prop?.getData(_entdataMeasurePv);
                if (prop) {
                    const formattedAmount = ntaRounding.roundAndAddZerosNewValue(prop, amount);
                    ntaEntityDataOrg.saveprop(propdata, formattedAmount);
                }
            }
        } //-- end: getAlleKosten -----------------------------------------------------------------

        function getSubmeasures() {
            const projectId = ntaData.projectdata.ProjectId;
            const buildingId = _entdataMeasure.BuildingId;
            const baseUrl = `/Project/${projectId}/Berekening/${buildingId}/Maatregelen/${_measureId}`;

            const propType = ntaData.properties['INSTALL_TYPE'];

            const installations = getInstallations();
            const submeasures = installations
                .map(ed => ({
                    id: ed.EntityDataId,
                    name: _instData.getName(ed, true),
                    icon: propType && propType.getCode(ed) && propType.getCode(ed).ImageUrl || '',
                    url: `${baseUrl}/${propType.getValue(ed).toLowerCase()}/${ed.EntityDataId}`,
                }));
            return submeasures;
        } //-- end: getSubmeasures ----------------------------------------------------------------

        function getShadowBuildingData(entityIds = null) {
            return ntaDeltas.getShadowBuildingData(_entdataMeasure.EntityDataId, entityIds);
        } //-- end: getShadowBuildingData ---------------------------------------------------------

        function getInstallations(buildingData = getShadowBuildingData(['INSTALLATIE'])) {
            const propType = ntaData.properties['INSTALL_TYPE'];

            return buildingData.getListWithEntityId('INSTALLATIE')
                .filter(ed => propType.getValue(ed) === "INST_PV")
                .sort(_instData.orderInstallations);
        } //-- end: getInstallations --------------------------------------------------------------

        function saveValue(prop, entdata, newValue) {
            return ntaSharedLogic.saveValue(prop, entdata, newValue, self, ntaEntityDataOrg);
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!ntabuilding.canSave()) return;
            if (!prop || !propdata || propdata.BuildingId !== ntabuilding.buildingId) {
                return;
            }

            const hidden = true; //isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            const propdata = prop.getData(entdata);
            const checkValue = propdata.Value;

            let performDefaultChecks = false;

            switch (prop.Id) {
                default:
                    break;
            }

            if (performDefaultChecks) {
                //-- VO 2024-11-13: measure-infiltratie-factory heeft geen isHidden, omdat er gebruik
                //-- gemaakt wordt van het reguliere infiltratie formulier. Daarom is performDefaultChecks ook
                //-- false en kom ik hier nooit. Mocht dit veranderen en isHidden moet weer 'aan', dan
                //-- een isHidden en getCodedValues implementeren.
                //isHidden(prop, entdata);
                if (ntaValidation.hasCodedValues(prop)) {
                    //getCodedValues(prop);
                }
                if (propdata.Value !== checkValue) {
                    saveValue(prop, entdata);
                }
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            if (![null, _entdataMeasure.EntityDataId].includes(ntaData.current.shadowId)) return [];

            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() {
            return [_entdataMeasurePv];
        } //-- end: getAllEntDatas ----------------------------------------------------------------

        function setGeopend() {
            const propdataOpen = _entdataMeasure.PropertyDatas['MEASURE_OPEN'];
            ntaEntityDataOrg.saveprop(propdataOpen, 'true');
        } //-- end: setGeopend --------------------------------------------------------------------

    };
}]);
