﻿angular.module('ntaModule')
    .factory('ListCache',
        ['$log',
function ($log) {
    'use strict';

    return function ListCache(defaultAreItemsEqual = (a, b) => a === b) {
        const self = this;

        /// == Description ========================================================================
        // Gebruik ListCache.useCacheIfUnchanged om alleen een nieuwe lijst terug te geven als de
        //  inhoud van de lijst is veranderd. Als er niets veranderd is, geef dan iedere keer exact
        //  dezelfde lijst (d.w.z.hetzelfde object) terug.
        // Dit om te voorkomen dat AngularJS steeds een nieuwe digest start omdat-ie denkt dat het
        //  om een heel andere lijst gaat, wat leidt tot een infdig-fout.
        // Aan de parameter ‘areItemsEqual’ kan een functie meegegeven worden die ‘true’ teruggeeft
        //  als de twee opgegeven items gelijkwaardig worden geacht. Als er niet expliciet een
        //  functie aan die parameter wordt meegegeven, dan wordt de parameter `defaultAreItemsEqual`
        //  van de factory gebruikt, die standaard gewoon kijkt of de items hetzelfde object zijn.


        /// == Imports ============================================================================



        /// == Instance variables =================================================================

        const _cachedLists = new Map();

        /// == Exports ============================================================================

        Object.assign(self, {
            add: (cacheId, list) => useCacheIfUnchanged(cacheId, list, () => false),
            useCacheIfUnchanged,
            getIfRecent,
            evictFromCache,
            evictFromCacheWhen,
            areObjectsEqual,
            isChanged,
        });


        /// == Initialization =====================================================================



        /// == Implementation =====================================================================
        function isChanged(cacheId, list, areItemsEqual = defaultAreItemsEqual, scanPropertiesOfObject = false) {
            let cacheItem = _cachedLists.get(cacheId);
            const changed = !cacheItem
                || cacheItem.list.length !== list.length
                || cacheItem.list.some((item, index) => !areItemsEqual(item, list[index]))
                || (scanPropertiesOfObject ? !arePropertiesOfObjectsEqual(cacheItem.list, list) : false);

            return changed;
        } //-- end: isChanged ---------------------------------------------------------------------

        function getIfRecent(cacheId, maxAgeMs, defaultList = undefined) {
            const cacheItem = _cachedLists.get(cacheId);
            if (cacheItem && performance.now() <= cacheItem.at + maxAgeMs) {
                return cacheItem.list;
            } else {
                return defaultList;
            }
        } //-- end: getIfRecent -------------------------------------------------------------------

        function evictFromCache(cacheId) {
            _cachedLists.delete(cacheId);
        } //-- end: evictFromCache ----------------------------------------------------------------

        function evictFromCacheWhen(predicate) {
            for (const [cacheId, list] of _cachedLists) {
                if (predicate(cacheId, list)) {
                    evictFromCache(cacheId);
                }
            }
        } //-- end: evictFromCacheWhen ------------------------------------------------------------

        function useCacheIfUnchanged(cacheId, list, areItemsEqual = defaultAreItemsEqual, scanPropertiesOfObject = false) {
            let cacheItem = _cachedLists.get(cacheId);
            if (!cacheItem) {
                cacheItem = {
                    list: list,
                    at: performance.now(),
                };
                _cachedLists.set(cacheId, cacheItem);
            } else {
                if (isChanged(cacheId, list, areItemsEqual, scanPropertiesOfObject)) {
                    cacheItem.list = list;
                }
                cacheItem.at = performance.now();
            }
            return cacheItem.list;
        } //-- end: useCacheIfUnchanged -----------------------------------------------------------

        function arePropertiesOfObjectsEqual(cachedList, list) {
            return cachedList.every((item, index) => areObjectsEqual(item, list[index]));
        } //-- end: arePropertiesOfObjectsEqual ---------------------------------------------------

        function areObjectsEqual(obj1, obj2) {
            // Check if both parameters are objects
            if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
                return obj1 === obj2;
            }

            // Check for null on both sides
            if (obj1 === null || obj2 === null) {
                return obj1 === obj2;
            }

            // Get the keys of the objects
            const keys1 = Object.keys(obj1).filter(x => x !== "$$hashKey");
            const keys2 = Object.keys(obj2).filter(x => x !== "$$hashKey");

            // Check if the number of keys is the same
            if (keys1.length !== keys2.length) {
                return false;
            }

            // Iterate over the keys and compare the values
            for (let key of keys1) {
                // Check if the current key exists in both objects
                if (!obj2.hasOwnProperty(key)) {
                    return false;
                }

                // Recursively compare nested objects
                if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                    if (!areObjectsEqual(obj1[key], obj2[key])) {
                        return false;
                    }
                } else {
                    // Compare the values directly
                    if (obj1[key] !== obj2[key]) {
                        return false;
                    }
                }
            }

            // All keys and values are equal
            return true;

        } //-- end: areObjectsEqual ---------------------------------------------------

    };
}]);
