﻿angular.module('projectModule')
    .factory('BuildingResultsOverzichtFactory',
        ['ntaData', 'ntaEntityDataOrg', 'ntaSharedLogic', 'ntaRounding', 'ntaVariant', 'ListCache', 'ntaValidation', 'BuildingResultsFactory', 'ntaDependencyValidation', 'projecttree',
function (ntaData,   ntaEntityDataOrg,   ntaSharedLogic,   ntaRounding,   ntaVariant,   ListCache,   ntaValidation,   BuildingResultsFactory,   ntaDependencyValidation,   projecttree) {
    'use strict';
    return function ResultsOverzichtLogic() {
        const self = this;

        //== Imports ==============================================================================
        self.ntaValidation = ntaValidation;
        self.dependencyValidator = ntaDependencyValidation;

        //== Instance data ========================================================================
        const resultsLogic = new BuildingResultsFactory();
        const calcUnit = ntaSharedLogic.getCalcUnit();
        const gebouwType = ntaSharedLogic.getGebouwType();

        const _isEMGforfByVariantId = new Map();
        const _isVersionGe33 = ntaData.ntaVersion.ntaVersionId >= 300;

        //== Exports ==============================================================================
        Object.assign(self, {
            getTablesHtml,
            getTypeName,
            saveValue,
            isHidden,
            validate,
            getCodedValues,
            exportResultOverzicht,
            validateDependencies,
            startFormValidation,
            endFormValidation
        });

        //== Initialization =======================================================================
        self.resultoverzichtproperties = ntaData.properties['RESULT-OVERZICHT']
        self.resultoverzichtData = ntaEntityDataOrg.getFirstWithEntityId('RESULT-OVERZICHT');
        self.isItemChecked = resultsLogic.isItemChecked;

        resultsLogic.showPie = false;

        let _cacheEvictionTimeout;

        self.toggleItemChecked = (...args) => {
            resultsLogic.toggleItemChecked(...args);
            clearTimeout(_cacheEvictionTimeout);
            _cacheEvictionTimeout = setTimeout(() => _listCache.evictFromCache('tables'), 500);
        };

        class ResultTable {
            constructor(headRows, rows, title, description) {
                this.headRows = headRows;
                this.rows = rows;
                this.title = title;
                this.description = description;
            }
        }

        class Row {
            constructor(classes, datacells) {
                this.classes = classes;
                this.datacells = datacells;
            }
        }

        class Datacell {
            constructor(classes, colspan, rowspan, divClasses, spanClasses, html, iconClasses) {
                this.classes = classes;
                this.colspan = colspan;
                this.rowspan = rowspan;
                this.divClasses = divClasses;
                this.spanClasses = spanClasses;
                this.html = html;
                this.iconClasses = iconClasses;
            }
        }

        const _listCache = new ListCache();
        const _gebruiktEnergie = {
            koeling: false,
            actieveKoeling: false,
            bevochtiging: false,
            gas: false,
            elektriciteit: true,
            warmtelevering: false,
            koudelevering: false,
            biomassa: false,
            olie: false,
        };
        let _tarievenPpe;
        let _tarievenVast;

        if (!self.resultoverzichtData) {
            const newId = ntaEntityDataOrg.create('RESULT-OVERZICHT', -1, [], []);
            self.resultoverzichtData = ntaEntityDataOrg.get(newId);
            const propKenmerken = self.resultoverzichtproperties['RESULT-OVERZICHT_KENM'];
            const defaultCodes = propKenmerken.Domain.Codes.filter(x => x.FilterValue1 === 'Default').map(x => x.Id);
            const defaultValue = defaultCodes.join("|") + "|";
            saveValue(propKenmerken, self.resultoverzichtData, defaultValue);
        }

        ntaDependencyValidation.calculateCostsVariants();

        //== Implementation =======================================================================

        function getTablesHtml() {
            let lines = _listCache.getIfRecent('tables', 2500);
            if (!lines) {
                lines = getTables()
                    .flatMap(table => {
                        const lines = ['<div class="responsive-table">'];
                        if (table.title) lines.push(`<div class="ppSubTitle">${table.title}</div>`);
                        if (table.description) lines.push(`<div>${table.description}</div>`);
                        if (table.rows.length) {
                            lines.push('<table class="ntaTable table" style="width: 99.5%; white-space: nowrap;">');
                            lines.push('<thead>');
                            for (const headRow of table.headRows) {
                                lines.push(`<tr class="${renderClasses(headRow.classes)}">`);
                                for (const datacell of headRow.datacells) {
                                    lines.push(`
                                        <td class="${renderClasses(datacell.classes)}" colspan="${datacell.colspan}" rowspan="${datacell.rowspan}">
                                            <span class="${renderClasses(datacell.textClasses)}">${datacell.html}</span>
                                        </td>
                                    `);
                                }
                                lines.push(`</tr>`);
                            }
                            lines.push('</thead>');
                            lines.push('<tbody>');
                            for (const row of table.rows) {
                                lines.push(`<tr class="${renderClasses(row.classes)}">`);
                                for (const datacell of row.datacells) {
                                    if (datacell.html !== undefined) {
                                        lines.push(`
                                            <td class="${renderClasses(datacell.classes)}" colspan="${datacell.colspan}" rowspan="${datacell.rowspan}">
                                                <div class="${renderClasses(datacell.divClasses)}">
                                                    <span class="${renderClasses(datacell.spanClasses)}">${datacell.html || ''}</span>
                                                    <i class="${renderClasses(datacell.iconClasses)}" aria-hidden="true">&nbsp;</i>
                                                </div>
                                            </td>
                                        `);
                                    }
                                }
                                lines.push(`</tr>`);
                            }
                            lines.push('</tbody>');
                            lines.push('</table>');
                        }
                        lines.push(`</div>`);
                        return lines;
                    });

                // de gewijzigde HTML opslaan in de cache
                lines = _listCache.useCacheIfUnchanged('tables', lines);
            }

            return lines.join('\n');
        } //-- end: getTablesHtml ---------------------------------------------------------------------

        function renderClasses(classes) {
            if (!classes) return '';
            if (classes instanceof Array) return classes.join(' ');
            return classes;
        } //-- end: renderClasses ---------------------------------------------------------------------

        function encodeHtml(text) {
            const element = document.createElement('span');
            element.textContent = text;
            return element.innerHTML;
        } //-- end: encodeHtml ------------------------------------------------------------------------

        function getTables() {
            const propdata = self.resultoverzichtData.PropertyDatas['RESULT-OVERZICHT_TYPE'];
            if (!propdata.Relevant || !propdata.Value) {
                return [];
            }

            checkOpwekkersAanwezig();
            refreshTarieven();
            resultsLogic.setExtraResultParents();
            const tables = [];
            if (isPerVariant()) { //per variant
                //De eerste tabel krijgt default de naam 'basisberekening' en toont de resultaten van NTA_BASIS (zie toelichting onderaan pagina).
                //Voor elke variant is er vervolgens een tabel met de resultaten van NTA_VAR_x.
                //Als er 3 varianten zijn, zijn er dus in totaal 4 tabellen.Toon de naam van de betreffende variant zoals deze op de tegel van de variant staat.

                ////NTA_BASIS
                const table = new ResultTable([], [], 'basisberekening');
                const titlerow = new Row(['ui-state-default'], []);
                const titlecell = new Datacell(['topRow'], 28, 1, [], ['topRowText'], 'Overzicht resultaten basisberekening', []);
                titlerow.datacells.push(titlecell);
                table.headRows.push(titlerow);

                table.headRows.push(createRow(getHeaderFields()));
                table.headRows.push(createRow(getFields()));

                resultsLogic.setExtraResultParents();
                for (const berekening of resultsLogic.getBerekeningen()) {
                    resultsLogic.setEnergieResultaten(berekening.Id);
                    const newRow = getRowFromData(berekening);
                    table.rows.push(newRow);
                }

                //colspan header tabel staat op 28, dit klopt mogelijk niet. -> afleiden van aantal cellen onderste rij
                titlecell.colspan = table.rows[table.rows.length - 1].datacells.length;

                tables.push(table);

                //NTA_VAR_x
                if (ntaSharedLogic.showVariants()) {
                    const variants = ntaEntityDataOrg.getListWithEntityId('VARIANT');
                    for (const variant of variants) {
                        const prop = ntaData.properties['VARIANT_NAAM'];
                        const table = new ResultTable([], [], prop.getValue(variant));
                        table.description = isKenmerkChecked('RESULT-OVERZICHT_KENM11') ? '<span>Maatregelen:</span>' + getVariantOmschrijving(variant) : '';
                        const titlerow = new Row(['ui-state-default'], []);
                        const titlecell = new Datacell(['topRow'], 28, 1, [], ['topRowText'], 'Overzicht resultaten ' + prop.getValue(variant), []);
                        titlerow.datacells.push(titlecell);
                        table.headRows.push(titlerow);

                        table.headRows.push(createRow(getHeaderFields([variant])));
                        table.headRows.push(createRow(getFields(variant)));

                        resultsLogic.setExtraResultParents(variant, ntaSharedLogic.isMaatwerkadvies());
                        for (const berekening of resultsLogic.getBerekeningen()) {
                            resultsLogic.setEnergieResultaten(berekening.Id);
                            const newRow = getRowFromData(berekening, '', variant);
                            table.rows.push(newRow);
                        }

                        //colspan header tabel staat op 28, dit klopt mogelijk niet. -> afleiden van aantal cellen onderste rij
                        titlecell.colspan = table.rows[table.rows.length - 1].datacells.length;

                        tables.push(table);
                    }
                }
            } else { //per woning/appartement/gebouw/unit
                const titleTable = new ResultTable([], [], 'Resultaten per ' + conditieRO_AK());
                tables.push(titleTable);

                //[RO-AA] toon als RO04 checkbox 'variant omschrijving' is aangevinkt. Voor de basisberekening dit veld overslaan.
                //[RO-AM] verberg als er geen varianten zijn.
                const variants = ntaSharedLogic.showVariants() ? ntaEntityDataOrg.getListWithEntityId('VARIANT') : [];
                const propVarNaam = ntaData.properties['VARIANT_NAAM'];
                const propMaatrNaam = ntaData.properties['MEASURE_NAAM'];
                if (variants.length > 0 && isKenmerkChecked('RESULT-OVERZICHT_KENM11')) {
                    const table = new ResultTable([], [], '');
                    const titlerow = new Row(['ui-state-default'], []);
                    const titlecell = new Datacell(['topRow'], 2, 1, [], ['topRowText'], 'Varianten overzicht', []);
                    titlerow.datacells.push(titlecell);
                    table.headRows.push(titlerow);

                    const headerrow = new Row([], []);
                    const headercell1 = new Datacell(['overzicht_rightborder', 'overzicht_nobottomborder'], 1, 1, [], [], 'variant naam', []);
                    const headercell2 = new Datacell(['overzicht_nobottomborder', 'overzicht_nobottomborder'], 1, 1, [], [], 'maatregelen', []);
                    headerrow.datacells.push(headercell1);
                    headerrow.datacells.push(headercell2);
                    table.headRows.push(headerrow);

                    for (const variant of variants) {
                        const measures = ntaVariant.getMeasures(variant);
                        const newRow = new Row([], []);
                        const cellVarNaam = new Datacell(['overzicht_rightborder'], 1, Math.max(1, measures.length), [], ['buildingresult'], propVarNaam.getValue(variant), []);
                        newRow.datacells.push(cellVarNaam);

                        const firstMeasure = measures.shift(); //Eerste overslaan
                        const cellMaatrNaam = new Datacell([], 1, 1, [], ['buildingresult'], propMaatrNaam.getValue(firstMeasure), []);
                        newRow.datacells.push(cellMaatrNaam);
                        table.rows.push(newRow);
                        for (const measure of measures) {
                            const newRow = new Row([], []);

                            const cellLeeg = new Datacell([], 1, 1, [], [], undefined, []); //lege cel, ivm rowspan (measures.length)
                            newRow.datacells.push(cellLeeg);

                            const cellMaatrNaam = new Datacell([], 1, 1, [], ['buildingresult'], propMaatrNaam.getValue(measure), []);
                            newRow.datacells.push(cellMaatrNaam);

                            table.rows.push(newRow);
                        }

                    }
                    tables.push(table);
                }

                //tabel per woning met als eerste rij de basisberekening en een vervolgrij voor elke variant in de berekening.
                const tableVariants = [null].concat(variants);
                for (const berekening of resultsLogic.getBerekeningen()) {
                    const table = new ResultTable([], [], '');
                    const titlerow = new Row(['ui-state-default'], []);
                    const titlecell = new Datacell(['topRow'], 28, 1, [], ['topRowText'], 'Overzicht resultaten ' + getUnitOmschr(berekening), []);
                    titlerow.datacells.push(titlecell);
                    table.headRows.push(titlerow);

                    table.headRows.push(createRow(getHeaderFields(tableVariants)));
                    table.headRows.push(createRow(getFields(null, tableVariants)));

                    //NTA_BASIS
                    resultsLogic.setExtraResultParents(null, false);
                    resultsLogic.setEnergieResultaten(berekening.Id);
                    const basisRow = getRowFromData(berekening, 'basisberekening', null, tableVariants);
                    table.rows.push(basisRow);

                    if (ntaSharedLogic.isMaatwerkadvies()) {
                        //MWA_BASIS_BASISFIT
                        resultsLogic.setExtraResultParents(null, true);
                        resultsLogic.setEnergieResultaten(berekening.Id);
                        const basisRow = getRowFromData(berekening, 'gefitte berekening', null, tableVariants);
                        table.rows.push(basisRow);
                    }
                    if (ntaSharedLogic.showVariants()) {
                        //NTA_VAR_x OR MWA_VAR_BASISFIT_x
                        const variants = ntaEntityDataOrg.getListWithEntityId('VARIANT');
                        for (const variant of variants) {
                            resultsLogic.setExtraResultParents(variant, ntaSharedLogic.isMaatwerkadvies());
                            resultsLogic.setEnergieResultaten(berekening.Id);
                            const newRow = getRowFromData(berekening, propVarNaam.getValue(variant), variant, tableVariants);
                            table.rows.push(newRow);
                        }
                    }

                    //colspan header tabel staat op 28, dit klopt mogelijk niet. -> afleiden van aantal cellen onderste rij
                    titlecell.colspan = table.rows[table.rows.length - 1].datacells.length;

                    tables.push(table);
                }
            }

            return tables;
        } //-- end: getTables ---------------------------------------------------------------------

        function getRowFromData(berekening, name = '', variant = null, variants = [variant]) {
            const newRow = new Row([], []);
            const fields = getFields(variant, variants);
            for (const field of fields) {

                let iconClasses = resultsLogic.getIcon(berekening, field.data.id);
                let value = '';
                if (field.data.id === 'Name') {
                    value = name ? name : berekening.Name;
                } else if (field.data.id === 'Investeringskosten') {
                    if (variant)
                        value = ntaVariant.getInvesteringskosten(variant, false);
                    else
                        value = 'n.v.t.';
                } else if (field.data.id === 'Subsidies') {
                    if (variant)
                        value = ntaVariant.getSubsidies(variant, false);
                    else
                        value = 'n.v.t.';
                } else if (field.data.id === 'TotaleKosten') {
                    if (variant)
                        value = ntaVariant.getTotaleKosten(variant, false);
                    else
                        value = 'n.v.t.';
                } else if (field.data.id === 'TVT') {
                    if (variant)
                        value = ntaVariant.getTerugverdientijd(variant);
                    else
                        value = 'n.v.t.';
                } else if (field.data.id === 'NCW') {
                    if (variant)
                        value = ntaVariant.getNettoContanteWaarde(variant);
                    else
                        value = 'n.v.t.';
                } else if (!field.data.id) {
                    value = '--';
                } else if (field.data.id === 'RESULT_TOJULI_RISICO') {
                    //R12       Toon 'maak EP berekening per appartement' als TO28 ='maak EP berekening per appartement' bij 1 of meer rekenzones
                    //en anders toon 'voldoet niet' als TO28 = 'voldoet niet' bij 1 of meer rekenzones
                    //en anders toon 'voldoet' als TO28 op het formulier TOjuli bij alle rekenzones is 'voldoet'.

                    //[RO-AI]  indien rij ongelijk 'basisberekening' EN maatwerkadvies is actief toon de resultaten van NTA_BASIS (in rij 'gefitte berekening) of NTA_VAR_x (in rijen 'variant'). Dit is dus in afwijking van de beschrijving bij RO44.
                    let values;

                    const isMaatwerkadvies = resultsLogic.getExtraResultParents().forTailoredAdvice;
                    if (name !== 'basisberekening' && isMaatwerkadvies) { // indien rij ongelijk 'basisberekening' EN maatwerkadvies
                        resultsLogic.setExtraResultParents(variant, false); // NTA_BASIS of NTA_VAR_x
                        resultsLogic.setEnergieResultaten(berekening.Id);

                        values = resultsLogic.getResults(berekening, field.data.id);
                        iconClasses = resultsLogic.getIcon(berekening, field.data.id);

                        resultsLogic.setExtraResultParents(variant, true);// terug zetten op MWA_BASIS_BASISFIT of MWA_VAR_BASISFIT_x
                        resultsLogic.setEnergieResultaten(berekening.Id);
                    } else {
                        resultsLogic.setExtraResultParents(variant, isMaatwerkadvies);
                        values = resultsLogic.getResults(berekening, field.data.id);
                    }

                    const texts = resultsLogic.textRisicoOververhitting;
                    if (values.some(v => v === texts.maakBerekeningPerAppartement)) {
                        value = texts.maakBerekeningPerAppartement;
                    } else if (values.some(v => v === texts.voldoetNiet)) {
                        value = texts.voldoetNiet;
                    } else if (values.every(v => v === texts.voldoet || v === '') && values.some(v => v === texts.voldoet)) {
                        value = texts.voldoet;
                    } else if (values.some(v => v === texts.ntb)) {
                        value = texts.ntb;
                    } else {
                        value = '';
                        iconClasses = '';
                    }

                    //[RO-AY] indien G04 = appartementengebouw EN Z23 = per gebouw en per appartement toon een leeg veld op de rij van het hele gebouw
                    if (ntaEntityDataOrg.get(berekening.Id).EntityId === 'GEB' && gebouwType === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP') {
                        value = '';
                        iconClasses = '';
                    }
                } else if (field.data.id === 'energiekosten') {
                    // [RO-BD] toon in de rijen 'basisberekening' de waarde 'n.v.t.'
                    if (name === 'basisberekening') {
                        value = 'n.v.t.';
                    } else {
                        const energiegebruik = resultsLogic.getEnergiegebruikResultaat();
                        const amount = berekenEnergiekosten(field.data.energiedrager, energiegebruik);
                        /// een kostenwaarde ronden we af met Intl.NumberFormat { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        value = Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
                    }
                } else {
                    const prop = ntaData.properties[field.data.id];
                    switch (prop.EntityId) {
                        case 'PRESTATIE': {
                            value = resultsLogic.getResult(berekening, field.data.id);

                            if (field.data.id.includes('_EIS')) {
                                //[RO-AG] indien rij ongelijk 'basisberekening' EN maatwerkadvies is actief: toon 'n.v.t.'
                                if (name !== 'basisberekening' && resultsLogic.getExtraResultParents().forTailoredAdvice) {
                                    value = 'n.v.t.';
                                }
                                //B1, B2 en B3 neem deze over van het werkblad 'BENG indicator'; indien deze niet bepaald wordt het veld leeg laten
                                if (!ntaSharedLogic.isNieuwbouw() || !berekening.isGebouw) {
                                    value = '';
                                }
                            }

                            break;
                        }
                        case 'RESULT-ENERGIEGEBRUIK': {
                            if (prop.Id === 'RESULT-NETTO_WARMTEVRAAG' && !ntaSharedLogic.isUtiliteit()) {
                                value = ntaRounding.roundAndAddZerosNewValue(null, resultsLogic.getEnergiegebruikResultaat().PropertyDatas['RESULT-NETTO_WARMTEVRAAG'].Value, 'R016')
                            } else {
                                value = resultsLogic.getPropDataShowValue(prop, resultsLogic.getEnergiegebruikResultaat());
                            }
                            break;
                        }
                        case 'RESULT-ENERGIEFUNCTIE': {
                            const prim = resultsLogic.getFunctionPropdataValue(field.data.cat, '', 'RESULT-ENERGIEFUNCTIE_RES_ENER_NONPRIM');
                            const hulp = resultsLogic.getFunctionPropdataValue(field.data.cat, '', 'RESULT-ENERGIEFUNCTIE_RES_HULPENER_NONPRIM');
                            const prop = ntaData.properties['RESULT-ENERGIEFUNCTIE_RES_ENER_PRIM'];
                            value = ntaRounding.roundAndAddZerosNewValue(prop, prim + hulp, 'R016')
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                            break;
                        }
                    }
                }

                if (iconClasses) {
                    //[RO-AH] indien rij ongelijk 'basisberekening' EN maatwerkadvies is actief: geen symbool tonen
                    iconClasses = name !== 'basisberekening' && resultsLogic.getExtraResultParents().forTailoredAdvice ? [] : ['BENGcheck', 'fa'].concat(iconClasses.split(' '));
                }

                const newCell = new Datacell(field.dataClasses.classes, 1, 1, field.dataClasses.divClasses, field.dataClasses.spanClasses, value, iconClasses);
                newRow.datacells.push(newCell)
            }
            return newRow;
        }//-- end: getRowFromData ---------------------------------------------------------------------

        function berekenEnergiekosten(energiedrager, energiegebruik) {
            const energiedragerPropIds = {
                gas: ['RESULT-GAS_GEBGEB', 'ENER-TARV_GAS'],        // RO90; RO29
                elektriciteit: ['RESULT-KOSTEN_ELEKTR', 'ENER-TARV_E_LEV'], // RO91
                warmtelevering: ['RESULT-EWEK_EW', 'ENER-TARV_W'],  // RO92; RO30
                koudelevering: ['RESULT-EWEK_EK', 'ENER-TARV_K'],   // RO93; RO31
                biomassa: ['RESULT-BOIM_GEBGEB', 'ENER-TARV_BIOM'], // RO94; RO32
                olie: ['RESULT-OLIE_TOT', 'ENER-TARV_OLIE'],        // RO95; RO88
            };
            if (energiedrager === 'totaal') {                       // RO96
                return Object.keys(energiedragerPropIds)            // optelling RO90 t/m RO95
                    .reduce((sum, drager) => sum + berekenEnergiekosten(drager, energiegebruik), 0);
            }
            let amount = 0;
            const [propIdQuantity, propIdTarief] = energiedragerPropIds[energiedrager] || [];
            if (propIdQuantity) {
                /// vraag via resultLogic de propdataValue op. Dit is de string waarde zonder duizendtallen maar met evt.
                /// een andere waarde dan de waarde die opgeslagen is en de entdata 'energiegebruik'.
                const quantity = ntaSharedLogic.parseFloat(resultsLogic.getPropDataValue(propIdQuantity, energiegebruik));

                if (!isNaN(quantity)) {
                    let variableCost = ntaSharedLogic.parseFloat(_tarievenPpe?.PropertyDatas[propIdTarief]?.Value, 0);
                    let fixedCost = ntaSharedLogic.parseFloat(_tarievenVast?.PropertyDatas[propIdTarief]?.Value, 0);
                    // bij elektriciteit halen we de kosten kant-en-klaar uit de rekenkern en zijn de variable kosten dus 1.
                    variableCost = propIdQuantity !== 'RESULT-KOSTEN_ELEKTR' ? variableCost : 1;
                    // [RO-BC] als verbuik 0 is dan zijn de vaste kosten (ER20) ook 0.
                    fixedCost = quantity !== 0.0 ? fixedCost : 0;

                    amount = quantity * variableCost + fixedCost;
                }
            }
            return amount;
        } //-- end: berekenEnergiekosten ----------------------------------------------------------

        function createRow(fields) {
            const newRow = new Row([], []);
            for (const field of fields) {
                const cell = new Datacell(field.classes, field.colspan, 1, [], [], field.text, []);
                newRow.datacells.push(cell);
            }
            return newRow;
        } //-- end: createRow ---------------------------------------------------------------------

        function getVariantOmschrijving(variant) {
            let description = '<ul>';
            const prop = ntaData.properties['MEASURE_NAAM'];
            const measures = ntaVariant.getMeasures(variant);
            for (const measure of measures) {
                description += '<li>' + encodeHtml(prop.getValue(measure)) + '</li>';
            }
            return description + '</ul>';
        } //-- end: getVariantOmschrijving --------------------------------------------------------

        //[W]
        //Z23 = projectwoningen-- > toon 'projectwoningen'
        //G04='utiliteitsgebouw' en Z23='per gebouw en per unit' --> toon 'units'
        //G04 = appartementengebouw EN Z23 = per gebouw en per appartement-- > toon 'appartementen
        function getTypeName() {
            if (isPerVariant()) {
                switch (calcUnit) {
                    case 'RZUNIT_PROJECT': return 'projectwoningen'
                    case 'RZUNIT_GEBUNIT': return 'units'
                    case 'RZUNIT_GEBAPP': return 'appartementen'
                    default: return '';
                }
            } else {
                return 'variant';
            }
        } //-- end: getTypeName -------------------------------------------------------------------

        function getUnitOmschr(berekening) {
            let unit = ntaEntityDataOrg.get(berekening.Id);
            if (unit.EntityId === "UNIT") {
                const prop = ntaData.properties['UNIT_OMSCHR'];
                return prop.getValue(unit);
            } else {
                return 'hele gebouw'; //GEB
            }
        }

        function isPerVariant() {
            const typePropdata = self.resultoverzichtData.PropertyDatas['RESULT-OVERZICHT_TYPE'];
            return typePropdata.Relevant && typePropdata.Value === 'RESULT-OVERZICHT_TYPE_PER_VAR';
        } //-- end: isPerVariant ---------------------------------------------------------------------


        function saveValue(prop, entdata, newValue) {
            const oldValue = prop.getValue(entdata);
            ntaSharedLogic.saveValue(prop, entdata, newValue, self);
            if (newValue === undefined || prop.getValue(entdata) !== oldValue) {
                _listCache.evictFromCache('tables');
            }
        } //-- end: saveValue ---------------------------------------------------------------------

        function validate(prop, propdata) {
            if (!prop || !propdata) {
                return;
            }

            const hidden = isHidden(prop);
            const readOnly = true; //isReadOnly(prop);

            let valid = ntaValidation.IsValid(self.form, prop, propdata, hidden, readOnly);

            switch (prop.Id) {
                default: {
                    break;
                }
            }

            return valid;
        } //-- end: validate ---------------------------------------------------------------------

        function isHidden(prop) {
            if (!prop) {
                return;
            }

            let showit = true;

            switch (prop.Id) {
                case 'RESULT-OVERZICHT_TYPE': {
                    showit = conditionRO_E();
                    if (!showit) {
                        saveValue(prop, self.resultoverzichtData, 'RESULT-OVERZICHT_TYPE_PER_GEB');
                    }
                    break;
                }
                default:
                    showit = true;
                    break;
            }
            return !showit;
        } //-- end: isHidden ---------------------------------------------------------------------

        function conditionRO_E() {
            //[RO-E] toon indien Z23 = per gebouw en per appartement / voor projectwoningen
            //EN onder instellingen 'maatwerkadvies' niet geselecteerd is;
            //als het veld verborgen is gebruik maken van de keuze 'overzicht per woning/appartement/unit'.

            return (calcUnit === 'RZUNIT_GEBAPP' || ntaSharedLogic.voorProjectwoningen()) && !ntaSharedLogic.isMaatwerkadvies();
        } //-- end: conditionRO_E ---------------------------------------------------------------------

        function getCodedValues(prop) {
            if (!prop) {
                return;
            }
            let values = ntaValidation.codedValues(prop)


            //conditie [RO-AK]
            if (prop.Id === 'RESULT-OVERZICHT_TYPE') {
                const type = conditieRO_AK();
                const typeGeb = values.find(x => x.Id === 'RESULT-OVERZICHT_TYPE_PER_GEB');
                typeGeb.Value = 'overzicht per ' + type;
            }

            if (prop.Id === 'RESULT-OVERZICHT_KENM') {
                const checkedKenmerken = new Set(prop.getValue(self.resultoverzichtData)?.split('|') ?? []);
                const kenmerkenToHide = new Set();

                //[RO-AW] indien le3-2 toon 'TOjuli' / indien ge3-3 toon 'risico op oververhitting'
                if (ntaData.ntaVersion.ntaVersionId >= 300)
                    values.find(x => x.Id === 'RESULT-OVERZICHT_KENM4').Value = 'risico op oververhitting';

                //conditie [RO-H] verberg als RO04 checkbox 'energieprestatie indicatoren' leeg OF G05 !== nieuwbouw
                if (!checkedKenmerken.has('RESULT-OVERZICHT_KENM1') || !ntaSharedLogic.isNieuwbouw()) { //'energieprestatie indicatoren'
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM2');  //'energieprestatie eisen'
                }
                //[RO-AM] verberg als er geen varianten zijn
                if (ntaEntityDataOrg.getListWithEntityId('VARIANT').length === 0) {
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM11'); //'variant omschrijving'
                }
                //[RO-AP] toon alleen als maatwerkadvies
                if (!ntaSharedLogic.isMaatwerkadvies()) {
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM14'); //'jaarlijkse energiekosten'
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM7');  //'investeringskosten, TVT en NCW'
                }

                if (ntaSharedLogic.isUtiliteit()) {
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM4');
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM8');
                } else {
                    kenmerkenToHide.add('RESULT-OVERZICHT_KENM9');
                }

                // Verborgen kenmerken wegfilteren
                values = values.filter(x => !kenmerkenToHide.has(x.Id));

                // Evt. wijzigingen opslaan
                let shouldSave = false;
                for (const kenmerk of kenmerkenToHide) {
                    const deleted = checkedKenmerken.delete(kenmerk);
                    shouldSave ||= deleted;
                }
                if (shouldSave) {
                    checkedKenmerken.delete(''); // vanwege de extra ‘|’
                    saveValue(prop, self.resultoverzichtData, [...checkedKenmerken].join('|') + '|');
                }
            }
            return values;
        } //-- end: getCodedValues ----------------------------------------------------------------

        //conditie [RO-AK]
        function conditieRO_AK() {
            let type = 'woning';
            type = (gebouwType === 'TGEB_APPGEB' && calcUnit === 'RZUNIT_GEBAPP')
                || gebouwType === 'TGEB_APP'
                ? 'appartement'
                : type;
            type = (gebouwType === 'TGEB_APPGEB' || gebouwType === 'TGEB_UTILIT')
                && calcUnit === 'RZUNIT_GEB'
                ? 'gebouw'
                : type;
            type = (gebouwType === 'TGEB_UTILIT' && calcUnit === 'RZUNIT_GEBUNIT')
                || gebouwType === 'TGEB_UTILUNIT'
                ? 'unit'
                : type;

            return type;
        } //-- end: conditieRO_AK -----------------------------------------------------------------

        function isKenmerkChecked(kenmerk) {
            if (!kenmerk) {
                return;
            }
            const prop = self.resultoverzichtproperties['RESULT-OVERZICHT_KENM'];
            const item = { Id: kenmerk };
            return self.isItemChecked(prop, self.resultoverzichtData, item);
        } //-- end: getKenmerkChecked -------------------------------------------------------------


        function heeftEis() {
            return isKenmerkChecked('RESULT-OVERZICHT_KENM1') && isKenmerkChecked('RESULT-OVERZICHT_KENM2'); //[RO-J]
        } //-- end: heeftEis ----------------------------------------------------------------------

        function isEMGforf(variant = null) {
            let result = isKenmerkChecked('RESULT-OVERZICHT_KENM1'); // [RO-K] toon als RO04 checbox 'energieprestatie indicatoren' is aangevinkt
            if (result) {
                const variantId = variant && variant.EntityDataId || variant || null;
                result = _isEMGforfByVariantId.get(variantId);
                if (result === undefined) {
                    result = !!ntaSharedLogic.isEMGforf(variantId); // [Q]
                    _isEMGforfByVariantId.set(variantId, result);
                }
            }
            return result;
        } //-- end: isEMGforf ---------------------------------------------------------------------

        function checkOpwekkersAanwezig() { //[RO-U] [RO-V] [RO-W]
            for (const energiedrager of Object.keys(_gebruiktEnergie)) {
                _gebruiktEnergie[energiedrager] = false;
            }

            _gebruiktEnergie.elektriciteit = true;

            const variants = [null].concat(ntaSharedLogic.showVariants() ? ntaEntityDataOrg.getListWithEntityId('VARIANT') : []);
            for (const variant of variants) {
                const ntaMwa = ntaSharedLogic.isMaatwerkadvies()
                    ? variant ? [true] : [false, true]
                    : [false];
                for (const forTailoredAdvice of ntaMwa) {
                    resultsLogic.setExtraResultParents(variant, forTailoredAdvice);
                    for (const berekening of resultsLogic.getBerekeningen()) {
                        resultsLogic.setEnergieResultaten(berekening.Id);

                        _gebruiktEnergie.koeling ||= resultsLogic.catHasResult('RESULT_KOEL');
                        _gebruiktEnergie.actieveKoeling ||= resultsLogic.isKoelcapaciteit(berekening.Id);
                        _gebruiktEnergie.bevochtiging ||= resultsLogic.catHasResult('RESULT_BEVO');

                        const energiegebruik = resultsLogic.getEnergiegebruikResultaat();

                        _gebruiktEnergie.gas ||= hasEnergiegebruik('RESULT-GAS_GEBGEB', energiegebruik); // [RO-U]
                        _gebruiktEnergie.warmtelevering ||= hasEnergiegebruik('RESULT-EWEK_EW', energiegebruik); // [RO-V]
                        _gebruiktEnergie.koudelevering ||= hasEnergiegebruik('RESULT-EWEK_EK', energiegebruik); // [RO-W]
                        _gebruiktEnergie.biomassa ||= hasEnergiegebruik('RESULT-BOIM_GEBGEB', energiegebruik); // [RO-AO]
                        _gebruiktEnergie.olie ||= hasEnergiegebruik('RESULT-OLIE_TOT', energiegebruik); // [RO-BA]
                    }
                }
            }
        } //-- end: checkOpwekkersAanwezig --------------------------------------------------------

        function hasEnergiegebruik(energiedragerPropId, energiegebruik) {
            const textValue = resultsLogic.getPropDataValue(energiedragerPropId, energiegebruik);
            const value = ntaSharedLogic.parseFloat(textValue);
            return value > 0;
        } //-- end: hasEnergiegebruik -------------------------------------------------------------

        function refreshTarieven() {
            _tarievenPpe = null;
            _tarievenVast = null;
            if (ntaSharedLogic.showCosts()) {
                for (const tarieven of ntaEntityDataOrg.getListWithEntityId('ENER-TARV')) {
                    switch (tarieven.PropertyDatas['ENER-TARV_TYPE'].Value) {
                        case 'ENER-TARV_TYPE_PPE': _tarievenPpe = tarieven; break;
                        case 'ENER-TARV_TYPE_VK': _tarievenVast = tarieven; break;
                    }
                }
            }
        } //-- end: refreshTarieven ---------------------------------------------------------------

        async function exportResultOverzicht(type) {
            const json = JSON.stringify(getTables());
            try {
                return await projecttree.downloadFile(`Projects/ExportResultOverzicht?buildingId=${ntaData.buildingId}&type=${type}`, null, json);
            } catch (err) {
                $log.error(err, 'while attempting to export resultoverzicht', ntaData.buildingId);
                await ntaAlert.showError();
                return false;
            }
        } //-- end: exportResultOverzicht ----------------------------------------------------------


        function validateDependencies(prop, entdata) {
            if (!prop || !entdata) {
                return;
            }

            switch (prop.Id) {
            }
        } //-- end: validateDependencies ----------------------------------------------------------

        function startFormValidation() {
            return ntaSharedLogic.startFormValidation(getAllEntDatas(), self);
        } //-- end: startFormValidation -----------------------------------------------------------

        function endFormValidation() {
            return ntaSharedLogic.endFormValidation(getAllEntDatas(), self);
        } //-- end: endFormValidation -------------------------------------------------------------

        function getAllEntDatas() { // Geeft alle entdatas in één array terug
            return []
                .concat(self.resultoverzichtData)
                .filter(entdata => entdata) // filter alle nulls en undefineds eruit
                ;
        } //-- end: getAllEntDatas ----------------------------------------------------------------


        function getHeaderFields(variants = [null]) { // variant null betekent de basisberekening
            const isAnyEMGForfaitair = variants.some(variant => isEMGforf(variant));
            const headerFields = [
                {
                    text: getTypeName(), //[RO-F]
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder'],
                    colspan: 1,
                    isVisible: true,
                },
                {
                    text: 'energiebehoefte [kWh/m²]',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: heeftEis() ? 2 : 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-I]
                },
                {
                    text: 'fossiel [kWh/m²]',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: (heeftEis() ? 2 : 1) + (isAnyEMGForfaitair ? 1 : 0),
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-N]
                },
                {
                    text: 'hernieuwbaar [%]',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: heeftEis() ? 2 : 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-O]
                },
                {
                    text: 'TO<sub>juli;max</sub>',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: !ntaSharedLogic.isUtiliteit() && isKenmerkChecked('RESULT-OVERZICHT_KENM4')
                        && !(ntaSharedLogic.getGebouwType() === 'TGEB_APPGEB' && ntaSharedLogic.perGebouw()),  //[RO-L]
                },
                {
                    text: 'risico op oververhitting', // RO86 / RO87
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: ntaData.ntaVersion.ntaVersionId >= 300
                        && !ntaSharedLogic.isVakantiewoning()
                        && !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw()
                        && isKenmerkChecked('RESULT-OVERZICHT_KENM4'),  //[RO-AX] toon als RO04 checkbox 'TO-juli' / 'Risico op oververhitting' is aangevinkt EN WN EN versie = ge3.3 EN G04 ≠ vakantiewoning
                },
                {
                    text: 'label',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM5'), //[RO-M]
                },
                {
                    text: 'compactheid',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM13'), //[RO-AS]
                },
                {
                    text: 'E<sub>H;nd;net</sub>',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM6'), //[RO-P]
                },
                {
                    text: ntaSharedLogic.isUtiliteit() ? 'reno-standaard ' : 'standaard',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: ntaSharedLogic.isUtiliteit() ?
                        isKenmerkChecked('RESULT-OVERZICHT_KENM9') :
                        isKenmerkChecked('RESULT-OVERZICHT_KENM8'), //[RO-Q]
                },
                {
                    text: 'elektriciteit gebruik op meter [kWh]',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 4,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3'), //[RO-R]
                },
                {
                    text: 'aardgas gebr.',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.gas, //[RO-R] [RO-U]
                },
                {
                    text: 'warmtelevering',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.warmtelevering, //[RO-R] [RO-V]
                },
                {
                    text: 'koudelevering',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.koudelevering, //[RO-R] [RO-W]
                },
                {
                    text: 'biomassa gebr.',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.biomassa, //[RO-R] [RO-AO]
                },
                {
                    text: 'olie gebr.',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.olie, //RO-BA
                },
                {
                    text: 'energiegebruik [kWh]',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: ntaSharedLogic.isUtiliteit() ?
                                            /*ja*/
                                                _gebruiktEnergie.koeling ?
                                                /*ja*/
                                                    _gebruiktEnergie.bevochtiging ?
                                                        /*ja*/  6
                                                        /*nee*/:5
                                                /*nee*/
                                                    : _gebruiktEnergie.bevochtiging ?
                                                        /*ja*/  5
                                                        /*nee*/:4
                                            /*nee*/
                                                : _gebruiktEnergie.koeling ?
                                                    /*ja*/  4
                                                    /*nee*/:3,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM10'), //[RO-S]
                },
                {
                    text: 'CO₂-emissie',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM12'), //[RO-AR]
                },
                {
                    text: 'aardgas',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.gas, //[RO-BB] [RO-U]
                },
                {
                    text: 'elektriciteit',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.elektriciteit, //[RO-BB]
                },
                {
                    text: 'warmtelevering',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.warmtelevering, //[RO-BB] [RO-V]
                },
                {
                    text: 'koudelevering',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.koudelevering, //[RO-BB] [RO-W]
                },
                {
                    text: 'biomassa',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.biomassa, //[RO-BB] [RO-AO]
                },
                {
                    text: 'olie',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.olie, //[RO-BB] [RO-BA]
                },
                {
                    text: 'totale energiekosten',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14'), //[RO-BB]
                },
                {
                    text: 'investeringskosten',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                },
                {
                    text: 'subsidies',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: _isVersionGe33 && isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                },
                {
                    text: 'totale kosten',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: _isVersionGe33 && isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                },
                {
                    text: 'TVT',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                },
                {
                    text: 'NCW',
                    classes: ['overzicht_rightborder', 'overzicht_nobottomborder', 'overzicht_centeralign'],
                    colspan: 1,
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                },
            ];

            return headerFields
                .filter(item => item.isVisible);
        } //-- end: getHeaderFields ----------------------------------------------------------------

        function getFields(variant = null, variants = [variant]) {
            const isEMGForfaitair = isEMGforf(variant);
            const isAnyEMGForfaitair = variants.some(v => isEMGforf(v)); // null betekent de basisberekening
            const fields = [
                {
                    text: '',
                    classes: ['overzicht_rightborder'],
                    isVisible: true,
                    data: { id: 'Name' },
                    dataClasses: { classes:['overzicht_rightborder'], divClasses: [], spanClasses: ['buildingresult']},
                },
                {
                    text: 'eis',
                    classes: ['overzicht_rightalign'],
                    isVisible: heeftEis(),
                    data: { id: 'EP_BENG1_EIS' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'resultaat',
                    classes: ['overzicht_rightborder overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-K]
                    data: { id: 'EP_BENG1' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'eis',
                    classes: ['overzicht_rightalign'],
                    isVisible: heeftEis(),
                    data: { id: 'EP_BENG2_EIS' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'resultaat',
                    classes: isAnyEMGForfaitair ? ['overzicht_rightalign'] : ['overzicht_rightalign', 'overzicht_rightborder'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-K]
                    data: { id: 'EP_BENG2' },
                    dataClasses: { classes: isAnyEMGForfaitair ? [] : ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'EMG<sub>forf</sub>',
                    classes: ['overzicht_rightalign', 'overzicht_rightborder'],
                    isVisible: isAnyEMGForfaitair,
                    data: { id: isEMGForfaitair ? 'EP_BENG2_EMGFORF' : null },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'eis',
                    classes: ['overzicht_rightalign'],
                    isVisible: heeftEis(),
                    data: { id: 'EP_BENG3_EIS' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'resultaat',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM1'), //[RO-K]
                    data: { id: 'EP_BENG3' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: !ntaSharedLogic.isUtiliteit() && isKenmerkChecked('RESULT-OVERZICHT_KENM4')
                        && !(ntaSharedLogic.getGebouwType() === 'TGEB_APPGEB' && ntaSharedLogic.perGebouw()), //[RO-L]
                    data: { id: 'EP_TOJULI' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: ntaData.ntaVersion.ntaVersionId >= 300
                        && !ntaSharedLogic.isVakantiewoning()
                        && !ntaSharedLogic.isUtiliteit() && ntaSharedLogic.isNieuwbouw()
                        && isKenmerkChecked('RESULT-OVERZICHT_KENM4'),  //[RO-AX] toon als RO04 checkbox 'TO-juli' / 'Risico op oververhitting' is aangevinkt EN WN EN versie = ge3.3 EN G04 ≠ vakantiewoning
                    data: { id: 'RESULT_TOJULI_RISICO' }, // RO86 / RO87
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '',
                    classes: ['overzicht_rightborder'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM5'), //[RO-M]
                    data: { id: 'EP_ENERGIELABEL' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '',
                    classes: ['overzicht_rightborder'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM13'), //[RO-AS]
                    data: { id: 'RESULT-OPP_VORMFACTOR' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[kWh/m²]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM6'), //[RO-P]
                    data: { id: 'RESULT-EP_WARMTEBEHOEFTE' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[kWh/m²]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: ntaSharedLogic.isUtiliteit() ?
                        isKenmerkChecked('RESULT-OVERZICHT_KENM9') :
                        isKenmerkChecked('RESULT-OVERZICHT_KENM8'), //[RO-Q]
                    data: { id: 'RESULT-NETTO_WARMTEVRAAG' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'gebouwgeb.',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3'), //[RO-R]
                    data: { id: 'RESULT-ELEKTR_GEBGEB' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'niet gebouwgeb.',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3'), //[RO-R]
                    data: { id: 'RESULT-ELEKTR_NIETGEBGEB' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'opgewekte elek.',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3'), //[RO-R]
                    data: { id: 'RESULT-ELEKTR_OPGEWEKT' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'totaal',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3'), //[RO-R]
                    data: { id: 'RESULT-ELEKTR_TOT' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[m³aeq]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.gas, //[RO-R] [RO-U]
                    data: { id: 'RESULT-GAS_GEBGEB' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[GJ]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.warmtelevering, //[RO-R] [RO-V]
                    data: { id: 'RESULT-EWEK_EW' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[GJ]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.koudelevering, //[RO-R] [RO-W]
                    data: { id: 'RESULT-EWEK_EK' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[m³aeq]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.biomassa, //[RO-R] [RO-AO]
                    data: { id: 'RESULT-BOIM_GEBGEB' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'liter',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM3') && _gebruiktEnergie.olie, //[RO-BA]
                    data: { id: 'RESULT-OLIE_TOT' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'verw.',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM10'), //[RO-S]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_VERW' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'tapw.',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM10'), //[RO-S]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_TAPW' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'koel',
                    classes: ['overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM10') && _gebruiktEnergie.koeling, //[RO-S] [RO-X]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_KOEL' },
                    dataClasses: { classes: [], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'vent.',
                    classes: ntaSharedLogic.isUtiliteit() ? ['overzicht_rightalign'] : ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM10'), //[RO-S]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_VENT' },
                    dataClasses: { classes: ntaSharedLogic.isUtiliteit() ? [] : ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'verlicht.',
                    classes: _gebruiktEnergie.bevochtiging ? ['overzicht_rightalign'] : ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: ntaSharedLogic.isUtiliteit() && isKenmerkChecked('RESULT-OVERZICHT_KENM10'), //[RO-S]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_VERL' },
                    dataClasses: { classes: _gebruiktEnergie.bevochtiging ? [] : ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: 'bevocht.',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible: ntaSharedLogic.isUtiliteit() && isKenmerkChecked('RESULT-OVERZICHT_KENM10') && _gebruiktEnergie.bevochtiging, //[RO-S] [RO-Y]
                    data: { id: 'RESULT-ENERGIEFUNCTIE_CAT', cat: 'RESULT_BEVO' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[kg]',
                    classes: ['overzicht_rightborder', 'overzicht_rightalign'],
                    isVisible:isKenmerkChecked('RESULT-OVERZICHT_KENM12'), //[RO-AR]
                    data: { id: 'RESULT-CO2_CO2' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_rightalign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.gas, //[RO-BB] [RO-U]
                    data: { id: 'energiekosten', energiedrager: 'gas' }, // energiekosten aardgas
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.elektriciteit, //[RO-BB]
                    data: { id: 'energiekosten', energiedrager: 'elektriciteit' }, // energiekosten elektriciteit
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.warmtelevering, //[RO-BB] [RO-V]
                    data: { id: 'energiekosten', energiedrager: 'warmtelevering' }, // energiekosten warmtelevering
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.koudelevering, //[RO-BB] [RO-W]
                    data: { id: 'energiekosten', energiedrager: 'koudelevering' }, // energiekosten koudelevering
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.biomassa, //[RO-BB] [RO-AO]
                    data: { id: 'energiekosten', energiedrager: 'biomassa' }, // energiekosten biomassa
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14') && _gebruiktEnergie.olie, //[RO-BB] [RO-BA]
                    data: { id: 'energiekosten', energiedrager: 'olie' }, // energiekosten olie
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€/jaar]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM14'), //[RO-BB]
                    data: { id: 'energiekosten', energiedrager: 'totaal' }, // totale energiekosten
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                    data: { id: 'Investeringskosten' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: _isVersionGe33 && isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                    data: { id: 'Subsidies' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: _isVersionGe33 && isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                    data: { id: 'TotaleKosten' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                    data: { id: 'TVT' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
                {
                    text: '[€]',
                    classes: ['overzicht_rightborder', 'overzicht_centeralign'],
                    isVisible: isKenmerkChecked('RESULT-OVERZICHT_KENM7'), //[RO-Z]
                    data: { id: 'NCW' },
                    dataClasses: { classes: ['overzicht_rightborder'], divClasses: ['overzicht_centeralign'], spanClasses: ['buildingresult'] },
                },
            ];

            return fields
                .filter(item => item.isVisible);
        } //-- end: getFields ----------------------------------------------------------------
    };
}]);
