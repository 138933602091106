﻿angular.module('projectModule')
    .service('ntaStorage',
        ['$log', '$mdToast', 'progressCircle', 'ntaLocal', 'ntaAlert', 'ntaData',
function ($log,   $mdToast,   progressCircle,   ntaLocal,   ntaAlert,   ntaData) {
    'use strict';
    const self = this;

    /// ntaStorage verzorgt het opslaan van data naar de server, handelt de SignalR-verbinding af,
    //   en de terugkoppeling van de server.

    /// -- Imports --------------------------------------------------------------------------------

    // signalR staat in libman.json, en wordt geladen in Views\Shared\__Layout.cshtml


    /// -- Instance variables ---------------------------------------------------------------------

    let _clientId;
    ntaLocal.getClientId().then(clientId => _clientId = clientId); // localforage is asynchroon; we kunnen de clientId dus alleen zo opvragen

    const _toastConnection = $mdToast.simple()
        .textContent('Verbinding kwijt. Herstellen...')
        .action('×').actionKey('h').actionHint('druk op Ctrl-H om te sluiten')
        .hideDelay(0)
        .capsule(false);

    const _reconnectionActions = []; // een lijst van uit te voeren functies zodra we een SignalR-verbinding hebben
    const _hubUrl = '/hubs/building';
    const _reconnectionIntervals = [0, 1000, 1000, 4000, 4000, 10000, 10000].concat(Array.from({ length: 45 * 60 / 30 - 1 }).map(x => 30000));
    const _connection = new signalR.HubConnectionBuilder()
        .withUrl(_hubUrl)
        .configureLogging({
            log: (logLevel, message) => {
                let method = 'debug';
                switch (logLevel) {
                    case signalR.LogLevel.Critical: method = 'error'; break;
                    case signalR.LogLevel.Error: method = 'error'; break;
                    case signalR.LogLevel.Warning: method = 'warn'; break;
                    case signalR.LogLevel.Information: method = 'info'; break;
                }
                $log[method](`SignalR ${_hubUrl}:`, message);
            }
        })
        // blijf 45 minuten lang proberen de verbinding te herstellen: onmiddellijk, na 1, 2, 6, 10, 20 en 30 secondes, en daarna om de 30 secondes
        .withAutomaticReconnect(_reconnectionIntervals)
        .build();
    let _connectionToastTimeout; // de timeout tussen het wegvallen van de verbinding en het weergeven van de _toastConnection

    const _dataToSave = []; // Lijst met updates die binnen 1 seconde van elkaar zijn aangemaakt.
    const _buildingRequestPromises = new Map();
    let _countdownStarted = false;
    let _savingToServer = 0;
    let _outstandingRequests = 0;
    let _subsequentNetworkErrors = 0;

    /// De class NetworkError wordt gebruikt om een netwerkfout aan te geven, anders gezegd: als fetch een fout oplevert.
    class NetworkError extends Error {
        constructor(innerError, ...args) {
            super(innerError && innerError.message || innerError, ...args);
            this.name = NetworkError.name;
            if (innerError && typeof innerError !== 'string') {
                this.innerError = innerError;
            }
        }
    }

    /// -- Exports --------------------------------------------------------------------------------

    self.enabled = true;

    self.on = _connection.on.bind(_connection);
    self.getConnectionId = () => _connection.connectionId;
    self.whenConnected = whenConnected;
    self.addUpdate = updateData => _dataToSave.push(updateData);
    self.insertUpdates = updateDatas => _dataToSave.unshift(...updateDatas);
    self.startCountdownToSave = startCountdownToSave;
    self.saveDataToServer = saveDataToServer;
    self.hasUnsavedData = hasUnsavedData;
    self.whenDoneSaving = whenDoneSaving;
    self.showErrorAndReload = showErrorAndReload;
    self.getCalculationResults = getCalculationResults;
    self.NetworkError = NetworkError;


    /// -- Initialization -------------------------------------------------------------------------

    ntaLocal.on(ntaLocal.forgetUpdates.name, async (buildingId, processedIds) => {
        if (!processedIds || processedIds.length === 0) return;
        try {
            const connection = await whenConnected();
            await connection.send('DeleteUpdates', buildingId, processedIds);
        } catch (err) {
            $log.warn('Building', buildingId, ':', err, 'tijdens doorgeven van', ntaLocal.forgetUpdates.name);
        }
    });
    _connection.on('updatesProcessed', (buildingId, processedIds) => {
        ntaLocal.forgetUpdates(buildingId, processedIds);
    });
    _connection.on('updateError', (buildingId, message, processedIds) => {
        ntaLocal.forgetUpdates(buildingId, processedIds);
        $log.error('Oeps', `Building ${buildingId}: server error "${message}";`, processedIds);
    });
    _connection.onreconnecting(error => {
        _connectionToastTimeout = setTimeout(() => $mdToast.show(_toastConnection), 5000); // wacht tot de verbinding minstens 5 secondes is weggebleven alvorens het aan de gebruiker te tonen
    })
    _connection.onreconnected(onReconnected);
    _connection.onclose(error => {
        $mdToast.show(_toastConnection);

        setTimeout(reconnect, 0);
    });

    // start de verbinding
    reconnect();

    // Bij het verlaten van de pagina, evt. openstaande gegevens gauw nog naar de server sturen:

    // Mobiele browsers vuren geen 'beforeunload' af; daar moeten we het doen met 'visibilitychange'.
    document.addEventListener('visibilitychange', function logData() {
        if (document.visibilityState === 'hidden') {
            // Stuur evt. klaarstaande wijzigingen direct naar de server
            saveDataToServer(true);
        }
    });

    // Desktopbrowsers vuren wel een 'beforeunload' af, en weer niet altijd een 'visibilitychange'.
    window.addEventListener("beforeunload", event => {
        // Stuur evt. klaarstaande wijzigingen direct naar de server
        saveDataToServer(true);

        // Als er nog requests uitstaan, geef dan een waarschuwing
        if (hasUnsavedData()) {
            event.preventDefault();
            return event.returnValue = "Er worden nog gegevens opgeslagen, momentje...";
        }
    });


    /// -- Implementation -------------------------------------------------------------------------

    async function reconnect(attempt = 0) {
        try {
            await _connection.start();
        } catch (err) {
            if (attempt === 0) {
                $mdToast.show(_toastConnection);
                $log.warn(`Cannot start SignalR ${_hubUrl} connection:`, err);
            }
            const timeoutMS = _reconnectionIntervals[attempt];
            if (typeof timeoutMS === 'number') {
                setTimeout(() => reconnect(attempt + 1), timeoutMS);
            } else {
                $log.error(`Really cannot start SignalR ${_hubUrl} connection:`, err);
                $mdToast.hide();
                await ntaAlert.showNetworkError(); // ververst de pagina na sluiten dialoog
            }
            return;
        }

        // Seintje sturen dat alle terugkoppeling voortaan (ook) via deze verbinding moet.
        // Geef meteen de calculationId mee (indien aanwezig); dan kan de server nagaan of die
        //  berekening al klaar is; en zo ja, CalculationDisconnected naar de client sturen.
        const buildingId = ntaData.buildingId;
        const calculationId = ntaData.getBuildingCalculationId(buildingId);
        await _connection.send('NotifyReconnected', buildingId, calculationId);

        // de initiële ‘connection’ telt niet als ‘reconnection’, dus we moeten zelf ‘onReconnected’ aanroepen
        onReconnected(_connection.connectionId);
    } //-- reconnect

    async function showErrorAndReload(reload = true) {
        const result = await ntaAlert.showError();
        if (reload) {
            await whenDoneSaving(() => location.reload());
        }
        return result;
    } //-- showErrorAndReload

    /// whenConnected: voert de meegegeven functie ‘action’ uit zodra er een SignalR-verbinding aanwezig is.
    ///  De meegegeven function ‘action’ wordt aangeroepen met als parameter het connection-object.
    ///  Deze functie geeft een Promise terug; als er een ‘action’ is meegegeven, resolvet deze
    ///   met het resultaat van die functie; als er geen ‘action’ meegegeven is, dan resolvet de
    ///   Promise met het connection-object zodra er verbinding is.
    async function whenConnected(action = connection => connection) {
        if (_connection.state === 'Connected') {
            return action(_connection);
        } else {
            const promise = new Promise((resolve, reject) => {
                _reconnectionActions.push(() => {
                    try {
                        const result = action(_connection);
                        if (result && typeof result.then === 'function') { // dan is het ook een promise-achtige, en moeten we die awaiten
                            result.then(r => resolve(r));
                            if (typeof result.catch === 'function') {
                                result.catch(r => reject(r));
                            }
                        } else {
                            resolve(result);
                        }
                    } catch (err) {
                        reject(err);
                    }
                });
            });
            if (_connection.state === 'Disconnected') {
                // Herstel de verbinding indien nodig (zou niet nodig moeten zijn)
                reconnect();
            }
            return promise;
        }
    } //-- whenConnected

    function onReconnected(connectionId) {
        clearTimeout(_connectionToastTimeout);
        _connectionToastTimeout = null;
        $mdToast.hide();

        let action = _reconnectionActions.shift();
        while (action) {
            try {
                action(connectionId);
            } catch (err) {
                $log.error(err);
            }
            action = _reconnectionActions.shift();
        }
    } //-- onReconnected

    function hasUnsavedData() {
        return _outstandingRequests || _dataToSave.length || _countdownStarted || _savingToServer;
    } //-- hasUnsavedData

    function startCountdownToSave(timeoutMS = 1000) {
        if (!_countdownStarted) {
            _countdownStarted = true;
            setTimeout(() => {
                // alleen daadwerkelijk naar de server sturen als we een SignalR-verbinding hebben
                //  (we moeten namelijk de connectionId goed zetten)
                whenConnected(() => saveDataToServer());
            }, timeoutMS); //samenvoegen binnen 1 seconde
        }
    } //-- startCountdownToSave

    function saveDataToServer(keepalive = false) {
        _countdownStarted = false;
        if (!self.enabled) {
            return Promise.resolve();
        }
        if (_dataToSave.length > 0) {
            _savingToServer++;
            const dataToSave = _dataToSave.splice(0); //_dataToSave leegmaken

            // voorkom dat er updates van meer dan één gebouw bij elkaar zitten; groepeer ze per buildingId
            const updatesByBuildingId = new Map();
            for (const data of dataToSave) {
                // zorg dat de juiste connectionId is meegegeven
                data.connectionId = _connection.connectionId || data.connectionId;
                data.clientId = data.clientId || _clientId;

                // Groeperen op buildingId
                const buildingUpdates = updatesByBuildingId.get(data.bldId) || [];
                if (buildingUpdates.length === 0) {
                    updatesByBuildingId.set(data.bldId, buildingUpdates);
                }
                buildingUpdates.push(data);
            }

            // Stuur nu de updates per gebouw naar de server
            const promises = [];
            for (const [buildingId, buildingUpdates] of updatesByBuildingId) {
                const promise = saveBuildingDataToServer(buildingId, buildingUpdates, keepalive, _buildingRequestPromises.get(buildingId));
                _buildingRequestPromises.set(buildingId, promise);
                promises.push(promise);
            }

            // We zijn pas klaar met opslaan als elke promise vervuld is of afgewezen
            Promise.allSettled(promises)
                .finally(() => {
                    _savingToServer--;
                });

            // Maar we kunnen verder zodra alle promises vervuld zijn, of er één afgewezen is.
            return Promise.all(promises);
        } else {
            return Promise.resolve();
        }
    } //-- end: saveDataToServer

    async function saveBuildingDataToServer(buildingId, buildingUpdates, keepalive, promisePreviousRequest) {
        if (promisePreviousRequest && !keepalive) {
            try {
                await promisePreviousRequest;
            } catch (err) {
                $log.warn(`Building ${buildingId}`, err, 'tijdens het awaiten van vorig request met', buildingUpdates);
            }
        }

        _outstandingRequests++;
        try {
            const response = await updateBuildingData(buildingUpdates, keepalive);

            _subsequentNetworkErrors = 0; // als we hier zijn gekomen, is de netwerkverbinding in elk geval ok

            // we lezen de body als tekst, en parsen die zelf als JSON, zodat we bij niet-JSON content de originele content kunnen loggen.
            const text = await response.text();

            if (response.status === 202) {
                // Accepted; dan zijn de gegevens correct ontvangen, en zal het resultaat via SignalR doorgegeven worden.
            } else if (response.status === 502) { // Bad Gateway; the specified CGI application encountered an error and the server terminated the process.
                $log.warn('Oeps', `Building ${buildingId}: `, response, ' tijdens verzenden van ', buildingUpdates);
                return makeNextAttempt(true);

            } else if (response.status === 503) { // Service Unavailable (de site is in onderhoud, waarschijnlijk wegens een nieuwe oplevering)
                await ntaAlert.showMaintenance();
                return makeNextAttempt(false);

            } else if (response.redirected) { // redirect gebeurt alleen als we uitgelogd zijn
                $log.warn('Uitgelogd', `Building ${buildingId}: `, response, ' tijdens verzenden van ', buildingUpdates);
                return ntaAlert.showLogin();

            } else {
                // controleer dat we geen fout hebben teruggekregen, maar juist wel resultaten
                let data;
                try {
                    data = JSON.parse(text);
                } catch (err) {
                    const sections = ['Oeps', `Building ${buildingId}: `, response.status, response.statusText, err, ' tijdens parsen van ', text, ' na verzenden van ', buildingUpdates];
                    if (text.includes('The request timed out')) {
                        $log.error(...sections);
                        return showErrorAndReload();
                    } else {
                        $log.warn(...sections);
                        return makeNextAttempt(true);
                    }
                }

                if (data && data.error) {
                    throw new Error(data.error);

                } else {
                    throw response;
                }
            }
        } catch (err) {
            if (err instanceof NetworkError) {
                _subsequentNetworkErrors++;
                $log.warn(`Netwerkprobleem (${_subsequentNetworkErrors})`, `Building ${buildingId}: `, err, ' tijdens verzenden van ', buildingUpdates);
                if (_subsequentNetworkErrors > 3) {
                    await ntaAlert.showNetworkError();
                }
                return makeNextAttempt(false);
            }
            $log.error('Oeps', `Building ${buildingId}: `, err, ' tijdens verzenden van ', buildingUpdates);
            return showErrorAndReload();
        } finally {
            _outstandingRequests--;
        }


        // Hulpfunctie voor als het misgaat, en we het nog een keer willen proberen
        async function makeNextAttempt(incrementCounter) {
            const [remainingUpdates, expiredUpdates] = await ntaLocal.getUpdatesForNextAttempt(buildingId, buildingUpdates, incrementCounter ? 1 : 0);
            if (expiredUpdates.length > 0) {
                await ntaLocal.forgetUpdates(buildingId, expiredUpdates.map(bu => bu.id));
            }
            if (remainingUpdates.length > 0) {
                $log.warn(`Building ${buildingId}: hernieuwde poging voor `, remainingUpdates);
                await saveBuildingDataToServer(buildingId, buildingUpdates, keepalive);
            }
            if (expiredUpdates.length > 0) {
                $log.error('Oeps', `Building ${buildingId}: Verlopen wijzigingen werden weggegooid:`, expiredUpdates);
                await showErrorAndReload(); // TODO: specifiekere melding? "5x geprobeerd, ik geef het op". Of juist helemaal geen melding geven?
            }
        }
    } //-- end: saveBuildingDataToServer


    /// whenDoneSaving: wacht met het uitvoeren van de meegegeven ‘procedure’ tot er geen
    ///  openstaande wijzigingen meer zijn; maar niet langer dan de opgegeven ‘timeoutMS’.
    /// Een progressCircle is zichtbaar tot ‘waitAfterMS’ millisecondes nadat ‘procedure’ is afgelopen.
    /// Geeft een Promise terug, die resolvet als ‘procedure’ is uitgevoerd (en geeft het resultaat ervan terug),
    ///  of faalt als er een exception is opgetreden.
    const _whenDoneSavingDefaultOptions = {
        title: 'even geduld...',
        timeoutMS: 60000,
        waitAfterMS: 500,
        leaveProgress: false,
    };
    async function whenDoneSaving(procedure = () => undefined, options = _whenDoneSavingDefaultOptions) {
        if (typeof procedure !== 'function' && (!options || options === _whenDoneSavingDefaultOptions)) {
            options = procedure;
            procedure = () => undefined;
        }

        progressCircle.setShowProgressValue(true, 'wachten tot gegevens verwerkt zijn...');
        const { title, timeoutMS, waitAfterMS, leaveProgress } = Object.assign({}, _whenDoneSavingDefaultOptions, options);
        const timeout = Date.now() + timeoutMS;

        return new Promise((resolve, reject) => {
            const interval = setInterval(attempt, 100);

            async function attempt() {
                try {
                    if (!hasUnsavedData() || Date.now() > timeout) {
                        clearInterval(interval);
                        progressCircle.setShowProgressValue(true, title);
                        const result = procedure();
                        const finalResult = result instanceof Promise ? await result : result;
                        setTimeout(() => {
                            if (!leaveProgress) {
                                progressCircle.setShowProgressValue(false);
                            }
                            resolve(finalResult);
                        }, waitAfterMS);
                    }
                } catch (err) {
                    clearInterval(interval);
                    reject(err);
                    progressCircle.setShowProgressValue(false);
                }
            } //-- attempt
        });
    } //-- whenDoneSaving

    /// updateBuildingData: stuurt de opgegeven updates naar de server om verwerkt te worden
    ///  Als ‘keepalive’ true is, dan blijft de verbinding in de lucht ook als de pagina verlaten wordt; maar dit werkt alleen als de updateData < 64KiB is.
    function updateBuildingData(updateData, keepalive = false) {
        const jsonData = JSON.stringify(updateData);
        return new Promise((resolve, reject) => {
            fetch("Buildings/UpdateBuildingData", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonData,
                keepalive: !!keepalive && jsonData.length < 64000, // keepalive is beperkt tot 64KiB; laat 1535 bytes over voor de zekerheid.
            })
                .then(response => resolve(response))
                .catch(reason => reject(new NetworkError(reason)));
        });
    } //-- updateBuildingData

    async function getCalculationResults(buildingId, calculationId) {
        const response = await fetch(`/api/data/building/${buildingId}/results?calculationId=${calculationId}`);
        const reply = await response.json();
        if (!response.ok) {
            throw new NetworkError(reply.error || reply);
        }
        return reply;
    } //-- end: getCalculationResults -------------------------------------------------------------

}]);